import { forwardRef } from 'react';
import { styled } from '../../stitches.config';
import { IconButton } from '../IconButton';

export const StyledBadge = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'fit-content',
  whiteSpace: 'nowrap',
  py: 2,
  px: 10,
  disabled: {
    backgroundColor: '$neutral-blue-100',
    color: '$neutral-blue-500',
    fill: '$neutral-blue-500',
    '& .remove-badge': {
      cursor: 'not-allowed',
    },
  },
  variants: {
    size: {
      xs: {
        lineHeight: '0.75rem',
        fontSize: '$xs',
        borderRadius: '$md',
        px: 6,
        '& .remove-badge': {
          height: '0.7rem',
          width: '0.7rem',
        },
        '& svg': {
          height: 12,
          minWidth: 12,
        },
      },
      sm: {
        lineHeight: '1rem',
        px: 8,
        fontSize: '$xs',
        borderRadius: '$xl',
        '& .remove-badge': {
          height: '0.95rem',
          width: '0.95rem',
        },
      },
      base: {
        lineHeight: '1.25rem',
        fontSize: '$xs',
        borderRadius: '$2xl',
        '& .remove-badge': {
          height: '1.2rem',
          width: '1.2rem',
        },
      },
      lg: {
        lineHeight: '1.5rem',
        px: 12,
        fontSize: '$base',
        borderRadius: '$3xl',
        '& .remove-badge': {
          height: '1.45rem',
          width: '1.45rem',
        },
        '& svg': {
          height: 16,
          minWidth: 16,
        },
      },
    },
    variant: {
      success: {
        backgroundColor: '$success-200',
        color: '$success-800',
        fill: '$success-800',
      },
      danger: {
        backgroundColor: '$danger-200',
        color: '$danger-800',
        fill: '$danger-800',
      },
      warning: {
        backgroundColor: '$warning-200',
        color: '$warning-800',
        fill: '$warning-800',
      },
      info: {
        backgroundColor: '$blue-100',
        color: '$blue-700',
        fill: '$blue-700',
      },
      blue: {
        backgroundColor: '$blue-500',
        color: 'white',
        fill: 'white',
      },
      disabled: {
        backgroundColor: '$neutral-blue-100',
        color: '$neutral-blue-500',
        fill: '$neutral-blue-500',
      },
      secondary: {
        backgroundColor: '$light-blue-200',
        color: '$light-blue-800',
        fill: '$light-blue-800',
      },
      pink: {
        backgroundColor: '$pink-200',
        color: '$pink-800',
        fill: '$pink-800',
      },
      violet: {
        backgroundColor: '$violet-100',
        color: '$violet-800',
        fill: '$violet-800',
      },
      teal: {
        backgroundColor: '$teal-200',
        color: '$teal-900',
        fill: '$teal-900',
      },
      purple: {
        backgroundColor: '$purple-200',
        color: '$purple-700',
        fill: '$purple-900',
      },
      'dark-purple': {
        backgroundColor: '$purple-600',
        color: '$white',
        fill: '$white',
      },
      orange: {
        backgroundColor: '$orange-200',
        color: '$orange-600',
        fill: '$orange-600',
      },
      outline: {
        backgroundColor: '$background-component',
        color: '$neutral-blue-700',
        border: '1px dashed $neutral-blue-400',
      },
      'outline-success': {
        backgroundColor: '$background-component',
        color: '$green-800',
        border: '1px dashed $green-500',
      },
      gray: {
        backgroundColor: '$neutral-blue-200',
        color: '$neutral-blue-700',
        fill: '$neutral-blue-700',
      },
    },
    shape: {
      oval: {
        borderRadius: '$xl',
      },
      rectangle: {
        borderRadius: '$base',
      },
    },
  },
  defaultVariants: {
    size: 'base',
    variant: 'info',
    shape: 'oval',
  },
  compoundVariants: [
    {
      size: 'xs',
      shape: 'oval',
      css: {
        borderRadius: '$lg',
      },
    },
    {
      size: 'sm',
      shape: 'rectangle',
      css: {
        borderRadius: '$base',
      },
    },
    {
      size: 'xs',
      shape: 'rectangle',
      css: {
        borderRadius: '$base',
      },
    },
    {
      size: 'base',
      shape: 'rectangle',
      css: {
        borderRadius: '$base',
      },
    },
    {
      size: 'lg',
      shape: 'rectangle',
      css: {
        borderRadius: '$md',
      },
    },
  ],
});

export interface BadgeProps extends React.ComponentProps<typeof StyledBadge> {
  as?: React.ElementType;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const Badge = forwardRef<HTMLElement, BadgeProps>(
  ({ children, onRemove, disabled, size, ...rest }, ref) => (
    <StyledBadge ref={ref} aria-disabled={disabled} size={size} {...rest}>
      {children}
      {onRemove && (
        <IconButton
          className='remove-badge'
          label='remove'
          name='x'
          size='xs'
          shape='circle'
          disabled={disabled}
          onClick={onRemove}
          css={{
            ml: 4,
            p: 0,
            fill: 'inherit',
            hover: {
              backgroundColor: 'transparent',
            },
            active: {
              backgroundColor: 'transparent',
            },
          }}
        />
      )}
    </StyledBadge>
  ),
);

Badge.displayName = 'Badge';
