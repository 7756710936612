import { ApiKeyRequestLog } from '@betterleap/client';
import { Badge, Flex, FlexProps, Text } from '@betterleap/ui';
import { format } from 'date-fns';
import { extractUrlPath } from 'helper/exctractUrlPath';
import { useSearchParams } from 'react-router-dom';

export const LogItem = ({
  log,
  ...rest
}: FlexProps & { log: ApiKeyRequestLog }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClick = () => {
    searchParams.set('requestId', log.requestId);
    setSearchParams(searchParams);
  };

  return (
    <Flex
      justify='between'
      css={{
        '& + &': {
          borderTop: '1px solid $border-light',
        },
        px: 16,
        cursor: 'pointer',
        hover: {
          backgroundColor: '$gray-100',
        },
        selected: {
          backgroundColor: '$blue-100',
        },
      }}
      onClick={handleClick}
      data-selected={log.requestId === searchParams.get('requestId')}
      {...rest}
    >
      <Badge variant='gray' size='sm' shape='rectangle'>
        {log.requestMethod}
      </Badge>
      <Text size='sm'>{extractUrlPath(log.requestPath)}</Text>
      <Text size='sm' css={{ color: '$text-secondary' }}>
        {format(new Date(log.requestTime), 'MM/dd/yyyy h:mm:ss a')}
      </Text>
      <Badge
        variant={log.responseStatus === 200 ? 'success' : 'danger'}
        size='sm'
        shape='rectangle'
      >
        {log.responseStatus}
      </Badge>
    </Flex>
  );
};
