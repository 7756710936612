import {
  cloneElement,
  ComponentProps,
  forwardRef,
  FunctionComponentElement,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Node } from '@react-types/shared';
import { merge } from 'lodash';
import { useOnClickOutside } from 'usehooks-ts';
import { useListState } from 'react-stately';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Spinner } from '../Spinner';

import { List, ListBox, ListBoxProps, ListInput } from '../ListBox';
import {
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
} from '../Popover';
import { Flex } from '../Flex';
import { styled } from '../../stitches.config';
import { InputProps } from '../Input';

const StyledFlex = styled(Flex, {
  variants: {
    size: {
      lg: {
        height: 48,
      },
      sm: {
        height: 32,
      },
      base: {
        height: 38,
      },
    },
  },
  defaultVariants: {
    size: 'base',
  },
});

/**
 * TODO:
 * lots of typing improvements.
 * build out multiselect implementation
 * complete adding aria attributes
 */
export interface ComboboxProps
  extends Omit<
      ComponentProps<typeof StyledFlex>,
      'value' | 'defaultValue' | 'onChange' | 'children' | 'autoFocus'
    >,
    Pick<
      ListBoxProps,
      | 'value'
      | 'defaultValue'
      | 'filterSelected'
      | 'emptyState'
      | 'options'
      | 'children'
      | 'multi'
      | 'onChange'
    > {
  as?: React.ElementType;
  closeOnSelect?: boolean;
  typeahead?: boolean;
  clearable?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  shouldFilter?: boolean;
  autoFocus?: boolean | 'first' | 'last';
  placeholder?: string;
  leftIcon?: InputProps['leftIcon'];
  rightIcon?: InputProps['rightIcon'];
  popoverContent?: FunctionComponentElement<PopoverContentProps>;
  error?: string | boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  renderSelection?: (item: Node<any>) => JSX.Element;
  onFilterChange?: (filter: string) => void;
}

export const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  (
    {
      options,
      value,
      defaultValue,
      onChange,
      onFilterChange,
      clearable = true,
      typeahead = true,
      closeOnSelect = true,
      filterSelected = false,
      autoFocus = 'first',
      multi,
      children,
      error,
      placeholder,
      emptyState,
      disabled,
      size,
      css,
      loading,
      leftIcon,
      rightIcon,
      shouldFilter,
      popoverContent = (
        <PopoverContent />
      ) as FunctionComponentElement<PopoverContentProps>,
      renderSelection = (item: Node<any>) => item?.rendered,
      onClick,
      ...rest
    },
    ref,
  ) => {
    const selectionRef = useRef(new Map<string | number, Node<any>>());
    const inputRef = useRef<HTMLInputElement>();
    const combinedRef = useMergeRefs(inputRef, ref);
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);
    const [menuWidth, setMenuWidth] = useState<number>();
    const menuContainerRef = useRef<HTMLDivElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);
    const toggleOpenButtonRef = useRef<HTMLButtonElement>(null);
    const defaultValues =
      typeof defaultValue === 'string' ? [defaultValue] : defaultValue;

    let values = value;
    if (value && typeof value === 'string') {
      values = [value];
    }

    const state = useListState({
      selectionMode: multi ? 'multiple' : 'single',
      selectedKeys: values,
      defaultSelectedKeys: defaultValues,
      items: options,
      children,
      ...rest,
    });

    const debouncedCallback = useCallback((entry: ResizeObserverEntry) => {
      requestAnimationFrame(() => {
        setMenuWidth(entry.borderBoxSize[0].inlineSize);
      });
    }, []);

    useResizeObserver(menuContainerRef, debouncedCallback);

    const setFilter = (newValue: string) => {
      // simulate onChange event
      if (inputRef.current) {
        const nativeValueSetter = Object.getOwnPropertyDescriptor(
          Object.getPrototypeOf(inputRef.current),
          'value',
        )?.set;

        nativeValueSetter?.call(inputRef.current, newValue);
        inputRef.current.dispatchEvent(
          new InputEvent('input', { bubbles: true }),
        );
      }
    };

    const focusOnComboBox = () => {
      if (!disabled && inputRef.current) {
        inputRef.current.focus();
      }
    };

    const hasSelection = !multi && !state.selectionManager.isEmpty;
    const showSelection = !multi && ((typeahead && !open) || !typeahead);

    const openMenu = useCallback(() => {
      if (!disabled) {
        setOpen(true);

        focusOnComboBox();
      }
    }, [disabled]);

    const closeMenu = () => {
      setOpen(false);
      setFilter('');
      focusOnComboBox();
    };

    const handleComboBoxClick = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (!open) {
        openMenu();
      }

      onClick?.(e);
    };

    const hasSections = [...state.collection].some(
      (node) => [...node.childNodes].length,
    );

    useOnClickOutside(menuContainerRef, (e) => {
      const isActuallyOutside =
        (e?.target as HTMLElement).id !== inputRef.current?.id &&
        !popoverRef?.current?.contains(e.target as any);
      if (isActuallyOutside && open) {
        closeMenu();
      }
    });

    return (
      <Popover open={open}>
        <List
          value={Array.from(state.selectionManager.selectedKeys)}
          async={loading !== undefined}
          multi={multi}
          clearable={clearable}
          filterSelected={filterSelected}
          shouldFilter={shouldFilter}
          onChange={(keys, selected) => {
            onChange?.(keys, selected);

            state.selectionManager.setSelectedKeys(keys);

            const newSelections = new Map();
            keys.forEach((key) => {
              const item =
                state.collection.getItem(key) ?? selectionRef.current.get(key);
              newSelections.set(key, item);
            });

            selectionRef.current = newSelections;

            if (!multi) {
              setFilter('');
            }

            if (!multi && closeOnSelect) {
              closeMenu();
            }
          }}
        >
          <StyledFlex
            ref={menuContainerRef}
            size={size}
            onClick={handleComboBoxClick}
            css={merge(
              {
                width: '100%',
                border: '1px solid $neutral-blue-400',
                paddingLeft: 16,
                overflow: 'hidden',
                backgroundColor: '$background-component',
                borderRadius: '$2xl',
                focus: {
                  boxShadow: '$focus',
                  borderColor: '$border-light',
                  invalid: {
                    boxShadow: '$focus-invalid',
                    borderColor: '$border-danger',
                  },
                },
                invalid: {
                  borderColor: '$border-danger',
                },
                disabled: {
                  fill: '$text-disabled',
                  color: '$text-disabled',
                  backgroundColor: '$background-disabled',
                },
              },
              css,
            )}
            data-invalid={!!error}
            data-disabled={disabled}
            data-focus={focused}
            {...rest}
          >
            {leftIcon &&
              cloneElement(leftIcon, {
                color: leftIcon.props.color ?? '$neutral-blue-400',
                size: leftIcon.props.size ?? 18,
                ...leftIcon.props,
                css: {
                  mr: 8,
                  ...leftIcon.props.css,
                },
              })}
            {showSelection && (
              <Box css={{ fontSize: '$sm', overflow: 'hidden' }}>
                {[...state.selectionManager.selectedKeys]
                  .map(
                    (key) =>
                      state.collection.getItem(key) ??
                      selectionRef.current.get(key as string | number),
                  )
                  .map(renderSelection as any)}
              </Box>
            )}
            <ListInput
              ref={combinedRef}
              role='combobox'
              aria-autocomplete='both'
              aria-expanded={open}
              aria-label={rest['aria-label']}
              aria-hidden={!typeahead}
              readOnly={!typeahead || !open}
              css={{
                p: 0,
                border: 0,
                flexGrow: 1,
                flexBasis: '5%',
                backgroundColor: 'transparent',
                '& input': {
                  p: 0,
                  lineHeight: 1,
                },
                readOnly: {
                  cursor: 'default',
                },
                focus: {
                  boxShadow: 'none',
                },
              }}
              onFocus={() => {
                setFocused(true);
              }}
              onBlur={() => {
                setFocused(false);
              }}
              onKeyDown={(e) => {
                switch (e.key) {
                  case ' ':
                    if (!open) {
                      e.preventDefault();
                      openMenu();
                    }
                    break;
                  case 'Escape':
                    closeMenu();
                    break;
                  case 'Backspace':
                    if (clearable && !inputRef.current?.value && hasSelection) {
                      e.preventDefault();
                      state.selectionManager.toggleSelection(
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        state.selectionManager.lastSelectedKey!,
                      );

                      if (!open) {
                        setOpen(true);
                      }
                    }
                  default:
                }
              }}
              onChange={(filter) => onFilterChange?.(filter)}
              disabled={disabled}
              placeholder={hasSelection ? '' : placeholder}
              leftIcon={<></>}
            />
            {!disabled && loading && <Spinner />}
            {rightIcon &&
              cloneElement(rightIcon, {
                color: rightIcon.props.color ?? '$neutral-blue-400',
                size: rightIcon.props.size ?? 18,
                ...rightIcon.props,
                css: {
                  mx: 8,
                  ...rightIcon.props.css,
                },
              })}
            {!disabled && hasSelection && clearable && !loading && !multi && (
              <IconButton
                className='bl-combobox-clear-button'
                label='clear input'
                name='x'
                size='sm'
                color='$danger-base'
                onClick={(e) => {
                  e.stopPropagation();

                  setFilter('');
                  state.selectionManager.clearSelection();
                  onChange?.([], []);

                  if (!open) {
                    setOpen(true);
                    focusOnComboBox();
                  }
                }}
              />
            )}
            <PopoverTrigger
              asChild
              onClick={(e) => {
                e.stopPropagation();

                if (open) {
                  closeMenu();
                } else {
                  openMenu();
                }
              }}
            >
              <IconButton
                ref={toggleOpenButtonRef}
                aria-hidden
                tabIndex={-1}
                size={size}
                label='open options menu'
                disabled={disabled}
                name={open ? 'chevron-up' : 'chevron-down'}
                className='combobox-toggle-open-button'
                css={{
                  ml: 8,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  focus: {
                    boxShadow: 'none',
                  },
                  disabled: {
                    backgroundColor: '$backgound-disabled',
                  },
                  expanded: {
                    backgroundColor: '$background-muted',
                  },
                }}
              />
            </PopoverTrigger>
          </StyledFlex>
          {cloneElement<PopoverContentProps>(
            popoverContent,
            {
              style: {
                width: menuWidth,
                display:
                  !emptyState && !(options as Array<any>)?.length
                    ? 'none'
                    : 'block',
                minHeight: (options as Array<any>)?.length > 2 ? 115 : 0,
              },
              align: 'end',
              avoidCollisions: false,
              alignOffset: -1,
              sideOffset: 8,
              arrow: false,
              onOpenAutoFocus: (e) => e.preventDefault(),
              ...popoverContent.props,
              css: {
                p: hasSections ? 0 : 8,
                zIndex: '$combo-menu',
                overflow: 'auto',
                maxHeight:
                  'min(var(--radix-popover-content-available-height), 600px)',
                ...popoverContent.props.css,
              },
              ref: popoverRef,
            },
            <ListBox
              css={{ width: '100%' }}
              options={options}
              autoFocus={autoFocus}
              aria-label={rest['aria-label']}
              emptyState={emptyState}
            >
              {children}
            </ListBox>,
          )}
        </List>
      </Popover>
    );
  },
);

Combobox.displayName = 'Combobox';
