/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var UpsertExperienceRequest;
(function (UpsertExperienceRequest) {
    let employmentType;
    (function (employmentType) {
        employmentType["FULL_TIME"] = "FULL_TIME";
        employmentType["PART_TIME"] = "PART_TIME";
        employmentType["SELF_EMPLOYED"] = "SELF_EMPLOYED";
        employmentType["FREELANCE"] = "FREELANCE";
        employmentType["CONTRACT"] = "CONTRACT";
        employmentType["INTERNSHIP"] = "INTERNSHIP";
    })(employmentType = UpsertExperienceRequest.employmentType || (UpsertExperienceRequest.employmentType = {}));
    let locationType;
    (function (locationType) {
        locationType["REMOTE"] = "REMOTE";
        locationType["ONSITE"] = "ONSITE";
        locationType["HYBRID"] = "HYBRID";
    })(locationType = UpsertExperienceRequest.locationType || (UpsertExperienceRequest.locationType = {}));
})(UpsertExperienceRequest || (UpsertExperienceRequest = {}));
