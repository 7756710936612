/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var SourceContactByLinkedInUrlDto;
(function (SourceContactByLinkedInUrlDto) {
    let source;
    (function (source) {
        source["ATS"] = "ATS";
        source["EXTENSION"] = "extension";
        source["CSV"] = "CSV";
        source["PUBLIC_API"] = "Public API";
        source["INTERNAL_ACCOUNT_MANAGER"] = "Internal Account Manager";
        source["ADD_BY_LINKED_IN_BUTTON"] = "Add by LinkedIn Button";
        source["CANDIDATE_JOINED_WORKSPACE"] = "Candidate Joined Workspace";
    })(source = SourceContactByLinkedInUrlDto.source || (SourceContactByLinkedInUrlDto.source = {}));
})(SourceContactByLinkedInUrlDto || (SourceContactByLinkedInUrlDto = {}));
