import * as TabsPrimitive from '@radix-ui/react-tabs';
import { forwardRef, useContext } from 'react';
import { styled } from '../../stitches.config';
import { TabsContext } from './Tabs';

const StyledList = styled(TabsPrimitive.List, {
  display: 'flex',
  overflow: 'auto',
  gap: 8,
  variants: {
    tabStyle: {
      pill: {
        gap: 4,
        border: '1px solid $neutral-blue-300',
        p: 4,
        borderRadius: '$2xl',
      },
    },
  },
});

export type TabListProps = React.ComponentProps<typeof StyledList>;

export const TabsList = forwardRef<HTMLDivElement, TabListProps>(
  ({ children, ...rest }, ref) => {
    const { tabStyle } = useContext(TabsContext);

    return (
      <StyledList
        ref={ref}
        tabStyle={tabStyle as TabListProps['tabStyle']}
        aria-label='Tabs'
        {...rest}
      >
        {children}
      </StyledList>
    );
  },
);

TabsList.displayName = 'TabsList';
