import { parse, stringify } from 'qs';
import { paginationConfig } from '../constants';

export const getPaginationParams = (
  paginationKeys?: string[],
): PaginationParams => {
  const { page, pageSize, sortBy, sortOrder, search, tab, ...rest } = parse(
    window.location.search.slice(1),
  );

  const returningParams = { ...rest } as PaginationParams;

  if (page) returningParams.page = parseInt(page as string, 10);
  if (pageSize) returningParams.pageSize = parseInt(pageSize as string, 10);
  if (sortBy) returningParams.sortBy = sortBy;
  if (sortOrder) returningParams.sortOrder = sortOrder;
  if (search) returningParams.search = search;
  if (tab) returningParams.tab = tab;
  if (paginationKeys) {
    paginationKeys.forEach((key) => {
      if (rest[`${key}page`])
        returningParams[`${key}page` as string] = parseInt(
          (rest[`${key}page`] as string) || '1',
          10,
        );
      if (rest[`${key}pageSize`])
        returningParams[`${key}pageSize` as string] = parseInt(
          (rest[`${key}pageSize`] as string) ||
            paginationConfig.pageSize.toString(),
          10,
        );
    });
  }
  return returningParams;
};

export const createPaginationQueryString = (
  params: Record<string, unknown>,
) => {
  if (!window) return '';
  const assingParams: Record<string, unknown> = {};
  Object.keys(params).forEach((el) => {
    if (params[el]) {
      assingParams[el] = params[el];
    }
  });
  return stringify({ ...assingParams }, { arrayFormat: 'comma' });
};

export const triggerSort = (
  params: Record<string, unknown>,
  sortBy: string | string[],
  sortOrder: string,
) =>
  createPaginationQueryString({
    ...params,
    sortBy,
    sortOrder,
    page: 1,
  });

export const triggerTab = (params: Record<string, unknown>, tab: string) =>
  createPaginationQueryString({
    ...params,
    tab,
    page: 1,
  });

export const triggerSearch = (
  params: Record<string, unknown>,
  search: string,
) =>
  createPaginationQueryString({
    ...params,
    search,
    page: 1,
  });

export const triggerPage = (
  params: Record<string, unknown>,
  page: number,
  pageSize: number,
  paginationKey?: string,
) =>
  createPaginationQueryString({
    ...params,
    [`${paginationKey || ''}page`]: page,
    [`${paginationKey || ''}pageSize`]: pageSize,
  });
