export class RecruiterApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create or update recruiter
     * @returns any
     * @throws ApiError
     */
    createOrUpdateRecruiter({ requestBody, xTestUser, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/recruiter',
            headers: {
                'x-test-user': xTestUser,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update recruiter
     * @returns any
     * @throws ApiError
     */
    updateRecruiter({ requestBody, xTestUser, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/recruiter',
            headers: {
                'x-test-user': xTestUser,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get logged in recruiter
     * @returns Recruiter
     * @throws ApiError
     */
    getRecruiter({ xTestUser, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/recruiter',
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
    /**
     * Get all recruiters
     * @returns Recruiter
     * @throws ApiError
     */
    getRecruiters({ xTestUser, pageSize, page, profileCompletedAt, search, ids, specializationIds, bannerHidden, recruiterStatus, services, withoutTest = false, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/recruiter/all',
            headers: {
                'x-test-user': xTestUser,
            },
            query: {
                'pageSize': pageSize,
                'page': page,
                'profileCompletedAt': profileCompletedAt,
                'search': search,
                'ids': ids,
                'specializationIds': specializationIds,
                'bannerHidden': bannerHidden,
                'recruiterStatus': recruiterStatus,
                'services': services,
                'withoutTest': withoutTest,
            },
        });
    }
    /**
     * Get recruiter for company
     * @returns Recruiter
     * @throws ApiError
     */
    getRecruiterForCompany({ id, xTestUser, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/recruiter/company/{id}',
            path: {
                'id': id,
            },
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
    /**
     * Accept recruiter
     * @returns Recruiter
     * @throws ApiError
     */
    acceptRecruiter({ id, xTestUser, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/recruiter/{id}/accept',
            path: {
                'id': id,
            },
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
    /**
     * Reject recruiter
     * @returns Recruiter
     * @throws ApiError
     */
    rejectRecruiter({ id, xTestUser, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/recruiter/{id}/reject',
            path: {
                'id': id,
            },
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
    /**
     * Get recruiter by id
     * @returns Recruiter
     * @throws ApiError
     */
    getRecruiterById({ id, xTestUser, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/recruiter/{id}',
            path: {
                'id': id,
            },
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
    /**
     * @returns Recruiter
     * @throws ApiError
     */
    updateRecruiterById({ id, requestBody, xTestUser, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/recruiter/{id}',
            path: {
                'id': id,
            },
            headers: {
                'x-test-user': xTestUser,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
