import { MutableRefObject, useEffect, useRef } from 'react';

export const useResizeObserver = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: (entry: ResizeObserverEntry) => void,
) => {
  const observerRef = useRef(
    new ResizeObserver(([entry]) => {
      callback(entry);
    }),
  );

  useEffect(() => {
    const resizeObserver = observerRef.current;

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current]);
};
