export class ContactNoteApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Notes for contact
     * @returns ContactNote
     * @throws ApiError
     */
    getContactNotes({ contactId, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-note',
            query: {
                'pageSize': pageSize,
                'page': page,
                'contactId': contactId,
            },
        });
    }
    /**
     * Create new note.
     * @returns ContactNote
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-note',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
