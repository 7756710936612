import { Controller, useForm } from 'react-hook-form';
import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Form,
  FormProps,
  BoundInput as Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PromiseModal,
  showToast,
  Spinner,
  Text,
} from '@betterleap/ui';
import { OrganizationApiKey } from '@betterleap/client';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Toggle from 'react-toggle';
import { useMutation } from 'react-query';
import { apiClient } from 'lib/apiClient';
import { AxiosError } from 'axios';

interface EditApiKeyModalProps {
  apiKey: OrganizationApiKey;
}

const schema = z.object({
  creditsToAdd: z
    .string()
    .transform((val) => parseInt(val, 10))
    .refine((val) => !isNaN(val), {
      message: 'Invalid number format',
    }),
  canOverdraft: z.boolean(),
});

const resolver = zodResolver(schema);
export type EditApiKeyForm = z.infer<typeof schema>;

export const EditApiKeyModal: PromiseModal<EditApiKeyModalProps> = ({
  apiKey,
  onSubmit,
  onDismiss,
}) => {
  const { control, handleSubmit } = useForm<EditApiKeyForm>({
    resolver,
    defaultValues: {
      creditsToAdd: undefined,
      canOverdraft: apiKey.canOverdraft ?? false,
    },
  });

  const updateApiKey = useMutation(
    ({ creditsToAdd, canOverdraft }: EditApiKeyForm) => {
      return apiClient.apiKeyManagement.updateApiKeyCredits({
        apiKeyId: apiKey.id,
        requestBody: {
          credits: apiKey.totalCredits + creditsToAdd,
          canOverdraft: canOverdraft,
        },
      });
    },
    {
      onSuccess: () => {
        onSubmit();
        showToast({
          title: 'Api Key Updated',
          description: `Api Key has been updated successfully`,
          variant: 'success',
        });
      },
      onError: (error: AxiosError) => {
        onSubmit();
        showToast({
          title: 'Error',
          description: `Something went wrong: ${error.message}`,
          variant: 'danger',
        });
      },
    },
  );

  const submit = (data: EditApiKeyForm) => {
    updateApiKey.mutate(data);
  };

  return (
    <Modal>
      <ModalHeader>
        <Flex justify='center' css={{ py: 8 }}>
          <AlertIcon
            variant='purple'
            name='key'
            size='lg'
            shape='rounded-square'
          />
        </Flex>
        <ModalTitle css={{ paddingBottom: 24 }}>Edit API Key</ModalTitle>
      </ModalHeader>
      <ModalBody css={{ p: 4, pb: 30 }}>
        <Form
          control={control as FormProps['control']}
          css={{ width: '100%' }}
          onSubmit={handleSubmit(submit)}
        >
          <Flex vertical css={{ gap: 16 }}>
            <Controller
              control={control}
              name='canOverdraft'
              render={({ field: { onChange, value } }) => (
                <Box>
                  <Label
                    css={{
                      mb: 8,
                    }}
                  >
                    Allow Overdraft
                  </Label>
                  <Flex css={{ gap: 8, height: 38, mb: 20 }}>
                    {value}
                    <Toggle
                      checked={value}
                      onChange={(e) => onChange(e.currentTarget.checked)}
                    />
                    <Text>{value ? 'Enabled' : 'Disabled'}</Text>
                  </Flex>
                </Box>
              )}
            />
            <Input
              label='Add Credits'
              placeholder='100'
              type='number'
              name='creditsToAdd'
              id='api-key-name'
            />
          </Flex>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Flex justify='center'>
          <Button variant='gray' onClick={onDismiss} css={{ mr: 8 }}>
            Cancel
          </Button>
          <Button css={{ minWidth: 100 }} onClick={handleSubmit(submit)}>
            {updateApiKey.isLoading ? <Spinner variant='white' /> : 'Update'}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default EditApiKeyModal;
