export class EnrichmentApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * /person/enrichment
     * Find non-sensitive personal information matching the lookup parameters. Must provide either profile URL (such as LinkedIn URL or GitHub URL), email address, or name (either full name or both first and last names) and company (name or website domain/URL).
     * @returns PersonEnrichmentResult Best match for the person lookup. This response will cost 1 credit for results not previously returned.
     * @throws ApiError
     */
    enrichPerson({ profileUrl, email, name, firstName, lastName, companyName, companyUrl, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/public/v1/person/enrichment',
            query: {
                'profile_url': profileUrl,
                'email': email,
                'name': name,
                'first_name': firstName,
                'last_name': lastName,
                'company_name': companyName,
                'company_url': companyUrl,
            },
            errors: {
                400: `Invalid request. Please see the message in the response body for details.`,
                401: `API key not provided or invalid. Please provide a valid API key in the X-API-Key header.`,
                402: `No credits available. Credits must be purchased or overdraft credits must be enabled.`,
                404: `No match found for the person lookup. This response will not cost any credits.`,
            },
        });
    }
    /**
     * /person/enrichment/updated_fields
     * Get the list of fields which have been updated since the last time the person was requested. A version number may be provided which will be used to compare any changes in the most recent version. This endpoint will not cost any credits.
     * @returns UpdatedFieldsResponse List of fields which have been updated since the last time the person was requested.
     * @throws ApiError
     */
    getUpdatedFields({ id, version, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/public/v1/person/enrichment/updated_fields',
            query: {
                'id': id,
                'version': version,
            },
            errors: {
                401: `API key not provided or invalid. Please provide a valid API key in the X-API-Key header.`,
                404: `Invalid person or version ID, or version not provided and person was not previously requested.`,
            },
        });
    }
}
