var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// react-shim.js
import * as React from "react";

// src/components/ChatMessageBubble.tsx
import { cva } from "cva";
import { motion } from "framer-motion";
import { useRef as useRef2, useState } from "react";

// src/hooks/useIntersectionObserver.ts
import { useEffect, useRef } from "react";
function useIntersectionObserver(callback) {
  const ref2 = useRef(null);
  const cbRef = useRef(callback);
  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry == null ? void 0 : entry.isIntersecting) {
        cbRef.current(entry.isIntersecting);
      }
    }, { threshold: 0.5 });
    const element = ref2.current;
    if (!element)
      return;
    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  }, []);
  return ref2;
}

// src/models.ts
var ChatMessageStatus = /* @__PURE__ */ ((ChatMessageStatus2) => {
  ChatMessageStatus2["Initial"] = "initial";
  ChatMessageStatus2["Sending"] = "sending";
  ChatMessageStatus2["Sent"] = "sent";
  ChatMessageStatus2["Error"] = "error";
  return ChatMessageStatus2;
})(ChatMessageStatus || {});

// src/components/Attachments.tsx
import React2 from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

// src/icons/Close.tsx
var CloseIcon = (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
  xmlns: "http://www.w3.org/2000/svg",
  width: 12,
  height: 12,
  viewBox: "0 0 12 12",
  fill: "none"
}, props), /* @__PURE__ */ React.createElement("path", {
  fill: "#656F7D",
  fillRule: "evenodd",
  d: "M.558.558a.625.625 0 0 1 .884 0L6 5.116 10.558.558a.625.625 0 1 1 .884.884L6.884 6l4.558 4.558a.625.625 0 1 1-.884.884L6 6.884l-4.558 4.558a.625.625 0 1 1-.884-.884L5.116 6 .558 1.442a.625.625 0 0 1 0-.884",
  clipRule: "evenodd"
}));

// src/icons/Document.tsx
var DocumentIcon = (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
  xmlns: "http://www.w3.org/2000/svg",
  width: 12,
  height: 16,
  viewBox: "0 0 12 16",
  fill: "none"
}, props), /* @__PURE__ */ React.createElement("path", {
  fill: "#656F7D",
  fillRule: "evenodd",
  d: "M2.25 1.813a.937.937 0 0 0-.937.937v10.5c0 .518.42.938.937.938h7.5c.518 0 .938-.42.938-.938V6.06c0-.05-.02-.097-.055-.132l-4.061-4.06a.19.19 0 0 0-.133-.056zM.188 2.75C.188 1.61 1.11.688 2.25.688h4.19c.347 0 .681.138.927.384l4.061 4.06c.246.247.384.58.384.929v7.189c0 1.14-.923 2.063-2.062 2.063h-7.5A2.06 2.06 0 0 1 .188 13.25zm3 5.25c0-.31.251-.562.562-.562h4.5a.563.563 0 0 1 0 1.125h-4.5A.563.563 0 0 1 3.188 8m0 3c0-.31.251-.562.562-.562h4.5a.562.562 0 1 1 0 1.124h-4.5A.56.56 0 0 1 3.188 11",
  clipRule: "evenodd"
}));

// src/icons/Download.tsx
var DownloadIcon = (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
  xmlns: "http://www.w3.org/2000/svg",
  width: 20,
  height: 20,
  fill: "none"
}, props), /* @__PURE__ */ React.createElement("path", {
  fill: "#656F7D",
  fillRule: "evenodd",
  d: "M3.958 14.167c0 1.035.84 1.875 1.875 1.875h8.334c1.035 0 1.875-.84 1.875-1.875v-.834a.625.625 0 1 1 1.25 0v.834c0 1.726-1.4 3.125-3.125 3.125H5.833a3.125 3.125 0 0 1-3.125-3.125v-.834a.625.625 0 1 1 1.25 0zm2.267-4.609a.625.625 0 0 1 .884 0l2.266 2.267V3.332a.625.625 0 1 1 1.25 0v8.492l2.266-2.267a.625.625 0 0 1 .884.884l-3.333 3.333a.625.625 0 0 1-.884 0l-3.333-3.333a.625.625 0 0 1 0-.884",
  clipRule: "evenodd"
}));

// src/components/Attachments.tsx
var Attachments = ({ files, removeUpload, className }) => {
  if (!(files == null ? void 0 : files.length)) {
    return null;
  }
  return /* @__PURE__ */ React2.createElement("div", {
    className: `flex gap-4 ${className}`
  }, files.map((file) => {
    const key = "id" in file ? file.id : file.file.name;
    return /* @__PURE__ */ React2.createElement(FileItem, {
      key,
      file,
      onDelete: removeUpload
    });
  }));
};
var FileItem = ({ file, onDelete }) => {
  if ("downloadUrl" in file) {
    return /* @__PURE__ */ React2.createElement("a", {
      key: file.id,
      href: file.downloadUrl,
      target: "_blank",
      className: `flex relative items-center text-black justify-between p-1.5 text-sm font-medium bg-white border-[#E8EAED] border rounded-[12px] overflow-hidden hover:border-blue-500 transition-all ${!file && "opacity-50"}`
    }, /* @__PURE__ */ React2.createElement(DocumentIcon, {
      className: "text-gray-500 bg-[#F7F8F9] p-1 rounded-[6px] w-7 h-7"
    }), /* @__PURE__ */ React2.createElement("span", {
      className: "ml-2"
    }, file.fileName), /* @__PURE__ */ React2.createElement("a", {
      href: file.downloadUrl,
      className: "ml-2"
    }, /* @__PURE__ */ React2.createElement(DownloadIcon, {
      className: "w-5 h-5 text-gray-500"
    })));
  }
  const { file: fileData, result, error, progress } = file;
  return /* @__PURE__ */ React2.createElement("div", {
    key: fileData.name,
    className: `flex relative items-center text-black justify-between p-1.5 text-sm font-medium bg-white border-[#E8EAED] border rounded-[12px] overflow-hidden ${!result && "opacity-50"}`
  }, /* @__PURE__ */ React2.createElement(DocumentIcon, {
    className: "text-gray-500 bg-[#F7F8F9] p-1 rounded-[6px] w-7 h-7"
  }), /* @__PURE__ */ React2.createElement("span", {
    className: "ml-2"
  }, fileData.name), error && /* @__PURE__ */ React2.createElement("div", {
    className: "flex items-center ml-4 text-red-600"
  }, /* @__PURE__ */ React2.createElement(ExclamationCircleIcon, {
    className: "w-5 h-5"
  }), /* @__PURE__ */ React2.createElement("span", {
    className: "ml-2"
  }, error.message)), onDelete && /* @__PURE__ */ React2.createElement("button", {
    onClick: () => onDelete(file),
    className: "bg-white pl-2 mr-2"
  }, /* @__PURE__ */ React2.createElement(CloseIcon, {
    className: "w-3 h-3 text-gray-500 -mb-[2px]"
  })), !result && /* @__PURE__ */ React2.createElement("span", {
    style: { width: `${progress}%` },
    className: `z-0 opacity-10 text-blue-600 bg-blue-500 absolute top-0 bottom-0 left-0 transition-all ease-in-out duration-200`
  }));
};

// src/components/ChatMessageContent.tsx
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
var ChatMessageContent = ({ content }) => {
  return /* @__PURE__ */ React.createElement(ReactMarkdown, {
    remarkPlugins: [remarkGfm],
    linkTarget: "_blank",
    allowedElements: ["b", "strong", "a", "i", "em", "p", "span"],
    components: {
      b: ({ children }) => /* @__PURE__ */ React.createElement("strong", {
        className: "font-semibold"
      }, children),
      strong: ({ children }) => /* @__PURE__ */ React.createElement("strong", {
        className: "font-semibold"
      }, children),
      a: ({ children, href }) => /* @__PURE__ */ React.createElement("a", {
        href,
        target: "_blank",
        rel: "noreferrer",
        className: "underline"
      }, children),
      i: ({ children }) => /* @__PURE__ */ React.createElement("em", {
        className: "italic"
      }, children),
      em: ({ children }) => /* @__PURE__ */ React.createElement("em", {
        className: "italic"
      }, children)
    }
  }, content);
};

// src/components/ChatMessageBubble.tsx
var chatMessageOuter = cva({
  base: "flex flex-col"
});
var chatMessageBubble = cva({
  base: "whitespace-pre-wrap text-[#1A1C20] leading-6",
  variants: {
    isSending: {
      true: "bg-opacity-50"
    }
  },
  defaultVariants: {
    isSending: false
  }
});
function ChatMessageBubble({ message, user, onRead }) {
  var _a;
  const timeoutRef = useRef2();
  const isCurrentUser = message.senderId === user.id;
  const isSending = isCurrentUser && message.status === "sending" /* Sending */;
  const readByMe = isCurrentUser || ((_a = message.userState) == null ? void 0 : _a.some((state) => state.userId === user.id && !!state.readAt));
  const attachments = message.attachments;
  const [inView, setInView] = useState(readByMe);
  const ref2 = useIntersectionObserver(() => {
    if (!inView) {
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        setInView(true);
        onRead(message.id);
      }, 2e3);
    }
  });
  return /* @__PURE__ */ React.createElement(motion.div, {
    ref: ref2,
    initial: isSending ? { y: isCurrentUser ? 10 : 0 } : false,
    animate: { y: 0 },
    className: chatMessageOuter(),
    "data-id": message.id
  }, /* @__PURE__ */ React.createElement("div", {
    className: chatMessageBubble({
      isSending
    })
  }, /* @__PURE__ */ React.createElement(ChatMessageContent, {
    content: message.content
  })), !!(attachments == null ? void 0 : attachments.length) && /* @__PURE__ */ React.createElement("div", {
    className: "py-4"
  }, /* @__PURE__ */ React.createElement(Attachments, {
    files: attachments
  })));
}

// src/components/ChatWindow.tsx
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { useMemo, useRef as useRef6 } from "react";
import { Button, FileTrigger } from "react-aria-components";

// src/context/ChatAppContext.tsx
import {
  createContext,
  useCallback,
  useContext,
  useEffect as useEffect4,
  useReducer,
  useRef as useRef3
} from "react";

// src/context/types.ts
var ActionType = /* @__PURE__ */ ((ActionType2) => {
  ActionType2["SET_USER"] = "SET_USER";
  ActionType2["SEND_MESSAGE"] = "SEND_MESSAGE";
  ActionType2["SEND_MESSAGE_SUCCESS"] = "SEND_MESSAGE_SUCCESS";
  ActionType2["SEND_MESSAGE_ERROR"] = "SEND_MESSAGE_ERROR";
  ActionType2["READ_MESSAGE"] = "READ_MESSAGE";
  ActionType2["WORKSPACE_UPDATED"] = "WORKSPACE_UPDATED";
  ActionType2["ADD_MESSAGE"] = "ADD_MESSAGE";
  ActionType2["TYPING_STATUS_UPDATE"] = "TYPING_STATUS_UPDATE";
  ActionType2["SET_DRAFT"] = "SET_DRAFT";
  ActionType2["START_POLLING"] = "START_POLLING";
  ActionType2["STOP_POLLING"] = "STOP_POLLING";
  ActionType2["SET_WORKSPACES"] = "SET_WORKSPACES";
  ActionType2["ADD_WORKSPACE"] = "ADD_WORKSPACE";
  ActionType2["SET_AUTH_STATE"] = "SET_AUTH_STATE";
  return ActionType2;
})(ActionType || {});

// src/context/chat-app-reducer.ts
var initialState = {
  config: {
    apiBaseUrl: "http://localhost:4000/api",
    apiNamespace: "candidate"
  },
  authState: "signedOut",
  workspaces: [],
  localLastUpdatedAt: 0,
  remoteLastUpdatedAt: 0
};
var workspaceReducer = (state, action) => {
  var _a, _b, _c, _d, _e, _f;
  switch (action.type) {
    case "SEND_MESSAGE" /* SEND_MESSAGE */:
      return __spreadProps(__spreadValues({}, state), {
        chats: (_a = state.chats) == null ? void 0 : _a.map((chat) => {
          var _a2, _b2;
          if (chat.id === action.payload.chatId) {
            return __spreadProps(__spreadValues({}, chat), {
              draft: "",
              inflightMessages: [
                ...chat.inflightMessages || [],
                {
                  id: action.payload._tempId,
                  chatId: chat.id,
                  senderId: (_a2 = action.payload.message.sender) == null ? void 0 : _a2.id,
                  sender: action.payload.message.sender,
                  content: action.payload.message.content,
                  type: "default",
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  status: "sending" /* Sending */,
                  userState: [
                    {
                      userId: (_b2 = action.payload.message.sender) == null ? void 0 : _b2.id,
                      chatId: chat.id,
                      messageId: action.payload._tempId,
                      readAt: new Date().toISOString()
                    }
                  ]
                }
              ]
            });
          }
          return chat;
        })
      });
    case "SEND_MESSAGE_SUCCESS" /* SEND_MESSAGE_SUCCESS */:
      return __spreadProps(__spreadValues({}, state), {
        chats: (_b = state.chats) == null ? void 0 : _b.map((chat) => {
          var _a2;
          if (chat.id === action.payload.chatId) {
            const messages = chat.messages || [];
            return __spreadProps(__spreadValues({}, chat), {
              inflightMessages: (_a2 = chat.inflightMessages) == null ? void 0 : _a2.filter((msg) => msg.id !== action.payload._tempId),
              messages: [
                ...messages.filter((msg) => msg.id !== action.payload.message.id),
                __spreadProps(__spreadValues({}, action.payload.message), {
                  status: "sent" /* Sent */
                })
              ]
            });
          }
          return chat;
        })
      });
    case "ADD_MESSAGE" /* ADD_MESSAGE */:
      return __spreadProps(__spreadValues({}, state), {
        chats: (_c = state.chats) == null ? void 0 : _c.map((chat) => {
          if (chat.id === action.payload.chatId) {
            const messages = chat.messages || [];
            const hasMessage = messages.some((msg) => msg.id === action.payload.message.id);
            if (hasMessage) {
              return chat;
            }
            return __spreadProps(__spreadValues({}, chat), {
              messages: [...messages, action.payload.message]
            });
          }
          return chat;
        })
      });
    case "SEND_MESSAGE_ERROR" /* SEND_MESSAGE_ERROR */:
      return __spreadProps(__spreadValues({}, state), {
        chats: (_d = state.chats) == null ? void 0 : _d.map((chat) => {
          if (chat.id === action.payload.chatId) {
            const inflightMessages = chat.inflightMessages || [];
            return __spreadProps(__spreadValues({}, chat), {
              inflightMessages: inflightMessages.map((message) => {
                if (message.id === action.payload._tempId) {
                  return __spreadProps(__spreadValues({}, message), {
                    status: "error" /* Error */
                  });
                }
                return message;
              })
            });
          }
          return chat;
        })
      });
    case "SET_DRAFT" /* SET_DRAFT */:
      return __spreadProps(__spreadValues({}, state), {
        chats: (_e = state.chats) == null ? void 0 : _e.map((chat) => {
          if (chat.id === action.payload.chatId) {
            return __spreadProps(__spreadValues({}, chat), {
              draft: action.payload.draft
            });
          }
          return chat;
        })
      });
    case "READ_MESSAGE" /* READ_MESSAGE */:
      return __spreadProps(__spreadValues({}, state), {
        chats: (_f = state.chats) == null ? void 0 : _f.map((chat) => {
          if (chat.id === action.payload.chatId) {
            const messages = chat.messages || [];
            return __spreadProps(__spreadValues({}, chat), {
              messages: messages.map((message) => {
                var _a2;
                if (message.id === action.payload.messageId) {
                  const nextUserState = ((_a2 = message.userState) == null ? void 0 : _a2.filter((messageUserState) => messageUserState.userId !== action.payload.userId)) || [];
                  nextUserState.push({
                    userId: action.payload.userId,
                    readAt: action.payload.readAt,
                    messageId: message.id
                  });
                  return __spreadProps(__spreadValues({}, message), {
                    userState: nextUserState
                  });
                }
                return message;
              })
            });
          }
          return chat;
        })
      });
  }
  return state;
};
var appReducer = (state, action) => {
  switch (action.type) {
    case "SEND_MESSAGE" /* SEND_MESSAGE */:
    case "SEND_MESSAGE_SUCCESS" /* SEND_MESSAGE_SUCCESS */:
    case "ADD_MESSAGE" /* ADD_MESSAGE */:
    case "SEND_MESSAGE_ERROR" /* SEND_MESSAGE_ERROR */:
    case "SET_DRAFT" /* SET_DRAFT */:
    case "READ_MESSAGE" /* READ_MESSAGE */:
      return __spreadProps(__spreadValues({}, state), {
        workspaces: state.workspaces.map((workspace) => {
          if (workspace.id === action.payload.workspaceId) {
            return workspaceReducer(workspace, action);
          }
          return workspace;
        })
      });
    case "SET_USER" /* SET_USER */:
      return __spreadProps(__spreadValues({}, state), {
        authState: action.payload.user ? "signedIn" : "signedOut",
        user: action.payload.user
      });
    case "SET_AUTH_STATE" /* SET_AUTH_STATE */:
      return __spreadProps(__spreadValues({}, state), {
        authState: action.payload
      });
    case "SET_WORKSPACES" /* SET_WORKSPACES */:
      return __spreadProps(__spreadValues({}, state), {
        localLastUpdatedAt: Date.now(),
        workspaces: deepMergeWorkspaces(state.workspaces, action.payload.workspaces)
      });
    case "WORKSPACE_UPDATED" /* WORKSPACE_UPDATED */:
      return __spreadProps(__spreadValues({}, state), {
        remoteLastUpdatedAt: action.payload.updatedAt
      });
    case "ADD_WORKSPACE" /* ADD_WORKSPACE */:
      if (state.workspaces.some((w) => w.id === action.payload.workspace.id)) {
        return state;
      }
      return __spreadProps(__spreadValues({}, state), {
        workspaces: [...state.workspaces, action.payload.workspace]
      });
    case "TYPING_STATUS_UPDATE" /* TYPING_STATUS_UPDATE */:
      return __spreadProps(__spreadValues({}, state), {
        workspaces: state.workspaces.map((workspace) => {
          var _a;
          return __spreadProps(__spreadValues({}, workspace), {
            chats: (_a = workspace.chats) == null ? void 0 : _a.map((chat) => {
              if (chat.id === action.payload.chatId) {
                return __spreadProps(__spreadValues({}, chat), {
                  typingStatus: {
                    userName: action.payload.userName,
                    isTyping: action.payload.isTyping
                  }
                });
              }
              return chat;
            })
          });
        })
      });
    default:
      return state;
  }
};
var deepMergeWorkspaces = (oldWorkspaces, newWorkspaces) => {
  const mergedWorkspaces = newWorkspaces.map((newWorkspace) => {
    const oldWorkspace = oldWorkspaces.find((w) => w.id === newWorkspace.id);
    if (!oldWorkspace) {
      return newWorkspace;
    }
    return __spreadProps(__spreadValues(__spreadValues({}, oldWorkspace), newWorkspace), {
      chats: deepMergeChats(oldWorkspace.chats || [], newWorkspace.chats || [])
    });
  });
  return mergedWorkspaces;
};
var deepMergeChats = (oldChats, newChats) => {
  const mergedChats = newChats.map((newChat) => {
    const oldChat = oldChats.find((c) => c.id === newChat.id);
    if (!oldChat) {
      return newChat;
    }
    return __spreadProps(__spreadValues(__spreadValues({}, oldChat), newChat), {
      messages: newChat.messages,
      inflightMessages: oldChat.inflightMessages
    });
  });
  return mergedChats;
};

// src/hooks/useInitWorkspaces.ts
import { useEffect as useEffect2, useState as useState2 } from "react";

// src/apiClient.ts
import { ApiClient } from "@betterleap/client";
import { BetterleapHttpRequest, TOKEN, getEnvVar } from "@betterleap/shared";
var apiClientConfig = {
  BASE: (getEnvVar("REACT_APP_API_URL") || getEnvVar("VITE_API_URL") || "").replace("/api", ""),
  TOKEN: async () => {
    var _a;
    return (_a = TOKEN.get()) != null ? _a : "";
  }
};
var apiClient = new ApiClient(apiClientConfig, BetterleapHttpRequest);

// src/hooks/useInitWorkspaces.ts
function useInitWorkspaces() {
  const appCtx = useChatAppContext();
  const authState = appCtx.state.authState;
  const enabled = appCtx.enabled && authState === "signedIn";
  const { user, localLastUpdatedAt, remoteLastUpdatedAt } = appCtx.state;
  const [didFetchWorkspaces, setDidFetchWorkspaces] = useState2(false);
  const fetchWorkspaces = async () => {
    try {
      if (!enabled) {
        return;
      }
      let response;
      if (appCtx.state.config.apiNamespace === "candidate") {
        response = await apiClient.chat.getWorkspaces();
      } else {
        response = await apiClient.chatWorkspace.getOrgDefaultWorkspace();
      }
      const data = response.data;
      if (data) {
        appCtx.dispatch({
          type: "SET_WORKSPACES" /* SET_WORKSPACES */,
          payload: {
            workspaces: data.workspaces
          }
        });
        if (!user) {
          appCtx.dispatch({
            type: "SET_USER" /* SET_USER */,
            payload: {
              user: data.user
            }
          });
        }
        setDidFetchWorkspaces(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect2(() => {
    fetchWorkspaces();
  }, [enabled]);
  useEffect2(() => {
    if (localLastUpdatedAt < remoteLastUpdatedAt && user) {
      console.log("Local workspaces are outdated, fetching new workspaces. ", {
        localLastUpdatedAt,
        remoteLastUpdatedAt
      });
      fetchWorkspaces();
    }
  }, [localLastUpdatedAt, remoteLastUpdatedAt]);
  return {
    didFetchWorkspaces
  };
}

// src/hooks/useChatGateway/index.ts
import { useEffect as useEffect3, useState as useState3 } from "react";
import { io } from "socket.io-client";
var IS_DEV = true;
var socketGatewayId = null;
var socketHandle = null;
var socketInit = false;
var gatewayIdInterceptor = (_event, data) => {
  if ("__gatewayId" in data) {
    const { __gatewayId } = data;
    if (!socketGatewayId) {
      socketGatewayId = __gatewayId;
      console.info("Socket Gateway ID set to: ", socketGatewayId);
    } else if (socketGatewayId !== __gatewayId) {
      console.error(`Gateway ID mismatch! Expected: ${socketGatewayId}, got: ${__gatewayId}`);
    }
  }
};
function useChatGateway(ctx) {
  const [connected, setConnected] = useState3(false);
  const url = new URL("/", ctx.state.config.apiBaseUrl);
  const onMessageHandler = (data) => {
    const { message, workspaceId } = data;
    ctx.dispatch({
      type: "ADD_MESSAGE" /* ADD_MESSAGE */,
      payload: {
        chatId: message.chatId,
        workspaceId: workspaceId || "",
        message
      }
    });
  };
  const onReadHandler = (data) => {
    const { messageId, readAt, userId, workspaceId, chatId } = data;
    ctx.dispatch({
      type: "READ_MESSAGE" /* READ_MESSAGE */,
      payload: {
        chatId,
        messageId,
        readAt,
        userId,
        workspaceId
      }
    });
  };
  const onTypingHandler = (data) => {
    ctx.dispatch({
      type: "TYPING_STATUS_UPDATE" /* TYPING_STATUS_UPDATE */,
      payload: data
    });
  };
  const onWorkspaceUpdatedHandler = (data) => {
    const { updatedAt } = data;
    ctx.dispatch({
      type: "WORKSPACE_UPDATED" /* WORKSPACE_UPDATED */,
      payload: {
        updatedAt
      }
    });
  };
  useEffect3(() => {
    if (!ctx.enabled || ctx.state.authState !== "signedIn") {
      return;
    }
    console.log("Connecting to ChatGateway...");
    const connectSocket = async () => {
      if (socketHandle || socketInit) {
        return;
      }
      socketInit = true;
      const token = await ctx.getUserToken();
      if (!token) {
        console.error("No token found, cannot connect to ChatGateway.");
        return;
      }
      console.log("Connecting to ChatGateway with token: ", token);
      const socket = io(url.toString(), {
        auth: {
          token
        },
        transports: ["websocket"]
      });
      const callback = () => {
        console.log("Socket connected? ", socket.connected);
        setConnected(socket.connected);
        if (!socket.connected) {
          socketGatewayId = null;
        }
      };
      socketHandle = socket;
      socket.on("connect", callback);
      socket.on("disconnect", callback);
      socket.onAny(gatewayIdInterceptor);
      socket.on("chat:message" /* MESSAGE */, onMessageHandler);
      socket.on("chat:message:read" /* READ */, onReadHandler);
      socket.on("chat:user:typing" /* TYPING */, onTypingHandler);
      socket.on("workspace:updated" /* WORKSPACE_UPDATED */, onWorkspaceUpdatedHandler);
    };
    connectSocket();
    return () => {
      console.log("Disconnecting from ChatGateway...");
      if (socketHandle) {
        socketHandle.off("chat:message" /* MESSAGE */, onMessageHandler);
        socketHandle.off("chat:message:read" /* READ */, onReadHandler);
        socketHandle.off("chat:user:typing" /* TYPING */, onTypingHandler);
        socketHandle.off("workspace:updated" /* WORKSPACE_UPDATED */, onWorkspaceUpdatedHandler);
        socketHandle.offAny(gatewayIdInterceptor);
        socketHandle.disconnect();
        socketInit = false;
        socketHandle = null;
      }
    };
  }, [ctx.enabled, ctx.state.authState]);
  const sendEvent = (eventName, data) => {
    return new Promise((resolve, reject) => {
      if (!socketHandle) {
        reject("Socket not connected - " + eventName);
        return;
      }
      if (IS_DEV) {
        console.log("Sending event: ", eventName, data);
      }
      socketHandle.emit(eventName, data, (response) => {
        resolve(response);
      });
    });
  };
  const sendMessageAndWait = async (workspaceId, chatId, message, attachmentIds) => {
    return sendEvent("chat:message" /* MESSAGE */, {
      workspaceId,
      chatId,
      message,
      attachmentIds
    });
  };
  const markMessageAsRead = async (messageId, chatId) => {
    return sendEvent("chat:message:read" /* READ */, {
      chatId,
      messageId
    });
  };
  const setIsTyping = (isTyping, chatId) => {
    return sendEvent("chat:user:typing" /* TYPING */, {
      isTyping,
      chatId,
      lastUpdated: Date.now()
    });
  };
  return {
    connected,
    sendMessageAndWait,
    markMessageAsRead,
    setIsTyping
  };
}

// src/context/ChatAppContext.tsx
var ChatAppContext = createContext({
  showGlobalError: () => {
  },
  showToast: () => {
  },
  getUserToken: () => Promise.resolve(""),
  state: initialState,
  dispatch: () => Promise.resolve(),
  gateway: {
    connected: false,
    setIsTyping: () => Promise.resolve({}),
    sendMessageAndWait: () => Promise.resolve({}),
    markMessageAsRead: () => Promise.resolve({})
  },
  enabled: true
});
var InitWorkspaces = ({ children }) => {
  useInitWorkspaces();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, children);
};
var ChatAppContextProvider = ({
  children,
  initialState: initialState2,
  getUserToken,
  showGlobalError,
  showToast: showToast3,
  enabled = true
}) => {
  const [state, dispatch] = useReducer(appReducer, __spreadValues(__spreadValues({}, initialState), initialState2));
  const stateRef = useRef3(state);
  useEffect4(() => {
    stateRef.current = state;
  }, [state]);
  const getState = useCallback(() => stateRef.current, []);
  const enhancedDispatch = useCallback(async (action) => {
    if (typeof action === "function") {
      return action(dispatch, getState);
    } else {
      return dispatch(action);
    }
  }, [dispatch, getState]);
  const context = {
    state,
    dispatch: enhancedDispatch,
    getUserToken,
    showGlobalError,
    showToast: showToast3,
    enabled
  };
  const gateway = useChatGateway(context);
  return /* @__PURE__ */ React.createElement(ChatAppContext.Provider, {
    value: __spreadProps(__spreadValues({}, context), {
      gateway
    })
  }, /* @__PURE__ */ React.createElement(InitWorkspaces, null, children));
};
var useChatAppContext = () => useContext(ChatAppContext);
var useChatState = () => useChatAppContext().state;

// src/utils.ts
import { groupBy, mapValues } from "lodash";
import { format, isThisYear, isToday, isYesterday } from "date-fns";
import { showToast } from "@betterleap/ui";
function userHasReadMessage(message, userId) {
  var _a;
  return Boolean((_a = message.userState) == null ? void 0 : _a.some((state) => {
    return state.userId === userId && !!state.readAt;
  }));
}
function calcUnreadCount(chat, userId) {
  var _a;
  const notMine = ((_a = chat.messages) == null ? void 0 : _a.filter((message) => {
    return message.senderId !== userId;
  })) || [];
  return notMine.reduce((acc, message) => {
    return acc + (userHasReadMessage(message, userId) ? 0 : 1);
  }, 0);
}
function sortChatsByMostRecent(chats) {
  const sorter = (a, b) => {
    var _a, _b;
    const aMessages = a.messages || [];
    const bMessages = b.messages || [];
    const aCreatedAt = ((_a = aMessages[aMessages.length - 1]) == null ? void 0 : _a.createdAt) || a.createdAt;
    const bCreatedAt = ((_b = bMessages[bMessages.length - 1]) == null ? void 0 : _b.createdAt) || b.createdAt;
    if (aCreatedAt > bCreatedAt)
      return -1;
    if (aCreatedAt < bCreatedAt)
      return 1;
    return 0;
  };
  if (!chats) {
    return [];
  }
  return chats.slice().sort(sorter);
}
var getWorkspaceAvatar = ({
  workspace,
  user,
  subject
}) => {
  if ((user == null ? void 0 : user.role) === "recruiter") {
    return getSenderAvatar({ sender: subject });
  } else {
    return getSenderAvatar({ workspace });
  }
};
var getSenderAvatar = ({
  workspace,
  sender
}) => {
  switch (sender == null ? void 0 : sender.role) {
    case "candidate":
      return sender == null ? void 0 : sender.imageUrl;
    default:
      return workspace == null ? void 0 : workspace.avatarUrl;
  }
};
function formatDate(date) {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (isThisYear(date)) {
    return format(date, "MMMM d");
  } else {
    return format(date, "MMMM d, yyyy");
  }
}
var getMessageBreaks = (messages, userId) => {
  let lastDate = void 0;
  let firstUnreadId = void 0;
  const dateBreaks = {};
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const date = formatDate(new Date(message.createdAt));
    if (date !== lastDate) {
      dateBreaks[message.id] = date;
      lastDate = date;
    }
    if (!firstUnreadId && message.senderId !== userId && !userHasReadMessage(message, userId)) {
      firstUnreadId = message.id;
    }
  }
  return { dateBreaks, firstUnreadId };
};
var groupByDateAndAuthor = (messages) => {
  const messagesByDate = groupBy(messages, (message) => {
    return formatDate(new Date(message.createdAt));
  });
  return mapValues(messagesByDate, (messages2) => groupMessagesByAuthor(messages2));
};
var groupMessagesByAuthor = (messages) => {
  if (!messages.length)
    return [];
  let grouped = [];
  let lastAuthor = messages[0].senderId;
  let authorMessages = [messages[0]];
  for (let i = 1; i < messages.length; i++) {
    const message = messages[i];
    if (message.senderId !== lastAuthor) {
      grouped.push(authorMessages);
      authorMessages = [message];
    } else {
      authorMessages.push(message);
    }
    lastAuthor = message.senderId;
  }
  if (authorMessages.length) {
    grouped.push(authorMessages);
  }
  return grouped;
};
var VALID_FILE_TYPES = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "application/doc",
  "application/docx",
  "text/plain"
];
var MAX_FILE_SIZE = 2 * 1024 * 1024;
var validateFile = (file) => {
  if (!VALID_FILE_TYPES.includes(file.type)) {
    showToast({
      title: "Invalide file type",
      description: `Unsupported file type: ${file.type}.`,
      variant: "danger"
    });
    return { isValid: false, errorMessage: "Unsupported file type" };
  }
  if (file.size > MAX_FILE_SIZE) {
    showToast({
      title: "File is too large",
      description: `File size exceeds 2MB limit.`,
      variant: "danger"
    });
    return { isValid: false, errorMessage: "File is too large" };
  }
  return { isValid: true };
};
var checkAndUploadFiles = (fileList, uploadFiles) => {
  if (!fileList)
    return;
  const files = Array.from(fileList);
  const filtered = files.filter((file) => validateFile(file).isValid);
  uploadFiles(filtered);
};
var inactiveDragAreaStyle = {
  transition: "all 0.3s ease",
  border: "2px solid #fff"
};
var activeDragAreaStyle = __spreadProps(__spreadValues({}, inactiveDragAreaStyle), {
  border: "2px dashed #cccccc",
  borderColor: "#0066ff",
  backgroundColor: "#e6f7ff",
  color: "#0066ff"
});

// src/context/selectors.ts
var workspaceHasMember = (w, userId) => {
  var _a;
  return Boolean((_a = w.members) == null ? void 0 : _a.some((m) => m.id === userId));
};
var workspaceHasChatsWithMember = (w, userId) => {
  var _a;
  return Boolean((_a = w.chats) == null ? void 0 : _a.some((c) => {
    var _a2;
    return (_a2 = c.users) == null ? void 0 : _a2.some((m) => m.id === userId);
  }));
};
var allChatsSelector = (workspaces) => workspaces.reduce((acc, w) => {
  if (w.chats) {
    acc.push(...w.chats.map((c) => {
      var _a;
      const candidate = (_a = c.users) == null ? void 0 : _a.find((u) => u.role === "candidate");
      return __spreadProps(__spreadValues({}, c), {
        subjectUser: candidate,
        subject: c.subject || (candidate ? "" + (constructFullName(candidate) || (candidate == null ? void 0 : candidate.email)) : "New Chat")
      });
    }));
  }
  return acc;
}, []);
var getChatUrl = (_appState, chatId) => `/chat/${chatId}`;
var getWorkspaceChat = (workspace, user) => {
  var _a;
  const chat = (_a = workspace.chats) == null ? void 0 : _a.find((c) => {
    var _a2;
    return (_a2 = c.users) == null ? void 0 : _a2.find((u) => u.id === (user == null ? void 0 : user.id));
  });
  return chat;
};
var findWorkspaceById = (appState, workspaceId) => appState.workspaces.find((w) => w.id === workspaceId);
var authStateSelector = (state) => state.authState;
var chatUserDisplayName = (user) => {
  return !user ? "" : constructFullName(user) || user.email;
};
var constructFullName = (user) => {
  var _a, _b;
  const firstName = (_a = user == null ? void 0 : user.profile) == null ? void 0 : _a.firstName;
  const lastName = (_b = user == null ? void 0 : user.profile) == null ? void 0 : _b.lastName;
  return `${firstName != null ? firstName : ""} ${lastName != null ? lastName : ""}`.trim();
};
var chatHasRecruiterReply = (chat) => {
  var _a;
  return (_a = chat.messages) == null ? void 0 : _a.some((m) => {
    var _a2;
    return ((_a2 = m.sender) == null ? void 0 : _a2.role) === "recruiter";
  });
};
var unrepliedChatsSelector = (allChats) => {
  return allChats.filter((c) => !chatHasRecruiterReply(c));
};
var repliedChatsSelector = (allChats) => {
  return allChats.filter((c) => chatHasRecruiterReply(c));
};
var unreadChatsSelector = (allChats, userId) => {
  return allChats.filter((c) => userId && calcUnreadCount(c, userId) > 0);
};
var unreadChatsCount = (appState) => {
  var _a;
  const allChats = allChatsSelector(appState.workspaces);
  const unreadChats = unreadChatsSelector(allChats, (_a = appState.user) == null ? void 0 : _a.id);
  return unreadChats.length;
};
var unreadMessagesCount = (appState) => {
  const allChats = allChatsSelector(appState.workspaces);
  const unreadCount = allChats.reduce((acc, chat) => {
    var _a, _b;
    const unreadCount2 = ((_a = appState.user) == null ? void 0 : _a.id) ? calcUnreadCount(chat, (_b = appState.user) == null ? void 0 : _b.id) : 0;
    return acc + unreadCount2;
  }, 0);
  return unreadCount;
};

// src/hooks/useChatUpload.ts
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable
} from "firebase/storage";
import { useRef as useRef4, useState as useState4 } from "react";

// src/hooks/useChatFetch.ts
function getBaseUri(namespace) {
  switch (namespace) {
    case "recruiter":
    case "admin":
      return "/api/chat-workspace";
    case "candidate":
      return "/api/chat";
    default:
      throw new Error(`Unknown namespace: ${namespace}`);
  }
}
function isDefaultNamespace(namespace) {
  return namespace === "recruiter" || namespace === "admin";
}
function getWorkspaces(namespace) {
  return workspacesEndpoint(namespace);
}
function workspacesEndpoint(namespace) {
  const base = getBaseUri(namespace);
  if (isDefaultNamespace(namespace)) {
    return `${base}/org-default`;
  } else {
    return `${base}/workspaces`;
  }
}
function messagesEndpoint(namespace, workspaceId, chatId) {
  const base = getBaseUri(namespace);
  if (isDefaultNamespace(namespace)) {
    return `${base}/${workspaceId}/chats/${chatId}/messages`;
  } else {
    return `${base}/${chatId}/messages`;
  }
}
function markAsReadEndpoint(namespace, workspaceId, chatId, messageId) {
  const base = getBaseUri(namespace);
  if (isDefaultNamespace(namespace)) {
    return `${base}/${workspaceId}/chats/${chatId}/messages/${messageId}/read`;
  } else {
    return `${base}/${chatId}/messages/${messageId}/read`;
  }
}
function joinWorkspaceEndpoint(_namespace, workspaceIdOrSlug) {
  return `/api/chat/workspaces/${workspaceIdOrSlug}/join`;
}
function publicWorkspaceEndpoint(_namespace, workspaceIdOrSlug) {
  return `/api/chat-workspace/${workspaceIdOrSlug}`;
}
function useChatFetch({ useToken = true } = {}) {
  const ctx = useChatAppContext();
  const wrappedFetch = async (uri, options) => {
    const url = new URL(uri, ctx.state.config.apiBaseUrl);
    let headers = (options == null ? void 0 : options.headers) || {};
    if (useToken) {
      const token = await ctx.getUserToken();
      headers = __spreadProps(__spreadValues({}, options == null ? void 0 : options.headers), {
        credentials: "include",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      });
    }
    const res = await fetch(url.toString(), __spreadProps(__spreadValues({}, options), {
      headers
    }));
    if (res.ok && res.status >= 200 && res.status <= 299) {
      const json = await res.json();
      return json.data;
    }
    return null;
  };
  return wrappedFetch;
}
var useChatFetch_default = useChatFetch;

// src/hooks/useChatUpload.ts
function randomishString(timestamp) {
  const random = Math.floor(Math.random() * 1e4).toString(16);
  return `c${timestamp.toString(16)}_${random}`;
}
function mapsToUploadStates(progressMap, resultsMap, errorsMap) {
  return Array.from(progressMap.entries()).map(([file, progress]) => ({
    file,
    progress,
    result: resultsMap.get(file),
    error: errorsMap.get(file)
  }));
}
function useChatUpload() {
  const fetch2 = useChatFetch_default();
  const progressMap = useRef4(/* @__PURE__ */ new Map());
  const resultsMap = useRef4(/* @__PURE__ */ new Map());
  const errorsMap = useRef4(/* @__PURE__ */ new Map());
  const [uploadState, setUploadState] = useState4([]);
  const uploadFiles = async (files) => {
    const storage = getStorage();
    const promises = files.map((file) => {
      const storageRef = ref(storage, `chat-attachments/${randomishString(Date.now())}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      return new Promise((resolve, reject) => {
        uploadTask.on("state_changed", (snapshot) => {
          const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
          progressMap.current.set(file, progress);
          const nextState = mapsToUploadStates(progressMap.current, resultsMap.current, errorsMap.current);
          setUploadState(nextState);
        }, (err) => {
          errorsMap.current.set(file, err);
          reject(err);
        }, async () => {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
          const attachment = await fetch2("/api/chat/attachments", {
            method: "POST",
            body: JSON.stringify({
              gcsUrl: uploadTask.snapshot.ref.toString(),
              downloadUrl,
              fileName: file.name,
              fileSizeBytes: file.size,
              mimeType: file.type
            })
          });
          resultsMap.current.set(file, attachment);
          const nextState = mapsToUploadStates(progressMap.current, resultsMap.current, errorsMap.current);
          setUploadState(nextState);
          resolve(attachment);
        });
      });
    });
    await Promise.allSettled(promises);
    return files.map((file) => {
      var _a;
      const progress = (_a = progressMap.current.get(file)) != null ? _a : 100;
      return {
        file,
        progress
      };
    });
  };
  const removeUpload = (ref2) => {
    let file;
    if (ref2 instanceof File) {
      file = ref2;
    } else if ("file" in ref2) {
      file = ref2.file;
    }
    if (file) {
      progressMap.current.delete(file);
      resultsMap.current.delete(file);
      errorsMap.current.delete(file);
      setUploadState((prev) => prev.filter((state) => state.file !== file));
    }
  };
  const clearAll = () => {
    progressMap.current.clear();
    resultsMap.current.clear();
    errorsMap.current.clear();
    setUploadState([]);
  };
  return [uploadState, uploadFiles, removeUpload, clearAll];
}

// src/hooks/useDragOverStyle.ts
import { useState as useState5, useCallback as useCallback2 } from "react";
function useDragOverStyle(defaultStyle, dragOverStyle) {
  const [style, setStyle] = useState5(defaultStyle);
  const [dragActive, setDragActive] = useState5(false);
  const handleDragOver = useCallback2((event) => {
    event.preventDefault();
    setStyle(dragOverStyle);
    setDragActive(true);
  }, [dragOverStyle]);
  const handleDragLeave = useCallback2((_event) => {
    setStyle(defaultStyle);
    setDragActive(false);
  }, [defaultStyle]);
  const handleDrop = useCallback2((event) => {
    event.preventDefault();
    setStyle(defaultStyle);
    setDragActive(false);
  }, [defaultStyle]);
  return {
    style,
    dragActive,
    handleDragOver,
    handleDragLeave,
    handleDrop
  };
}

// src/icons/PaperClip.tsx
var PaperClipIcon = (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
  xmlns: "http://www.w3.org/2000/svg",
  width: 20,
  height: 20,
  fill: "none",
  viewBox: "0 0 20 20"
}, props), /* @__PURE__ */ React.createElement("path", {
  fill: "#656F7D",
  fillRule: "evenodd",
  d: "M10.588 3.915 5.24 9.406a4.375 4.375 0 1 0 6.187 6.188l5.215-5.203a.625.625 0 1 1 .883.885l-5.214 5.201a5.625 5.625 0 0 1-7.958-7.951L9.7 3.034a3.958 3.958 0 0 1 5.601 5.595l-5.348 5.492a2.292 2.292 0 0 1-3.241-3.241L12.2 5.39a.625.625 0 0 1 .884.884l-5.488 5.488a1.042 1.042 0 0 0 1.47 1.476l5.348-5.49a2.708 2.708 0 0 0-3.827-3.834",
  clipRule: "evenodd"
}));

// src/components/AutoGrowTextArea.tsx
import { useEffect as useEffect5, useRef as useRef5 } from "react";
function AutoGrowTextArea(_a) {
  var _b = _a, {
    className,
    autoFocus,
    style
  } = _b, props = __objRest(_b, [
    "className",
    "autoFocus",
    "style"
  ]);
  const ref2 = useRef5(null);
  useEffect5(() => {
    const textarea = ref2.current;
    if (!textarea)
      return;
    const resize = () => {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    };
    textarea.addEventListener("input", resize);
    return () => {
      textarea.removeEventListener("input", resize);
    };
  }, []);
  useEffect5(() => {
    const textarea = ref2.current;
    if (!textarea || !autoFocus)
      return;
    textarea.focus();
    textarea.setSelectionRange(textarea.value.length, textarea.value.length);
  }, [autoFocus]);
  return /* @__PURE__ */ React.createElement("textarea", __spreadValues({
    className: className + " resize-none",
    rows: 1,
    ref: ref2,
    style
  }, props));
}

// src/components/Avatar.tsx
import { cva as cva2 } from "cva";
var avatarStyles = cva2({
  base: "rounded-full flex items-center justify-center flex-shrink-0 border border-[##1a1c201a] overflow-hidden",
  variants: {
    size: {
      sm: "w-8 h-8 text-xs",
      md: "w-10 h-10 text-sm",
      lg: "w-12 h-12 text-xl"
    },
    avatar: {
      true: "bg-white",
      false: "bg-blue-200"
    }
  },
  defaultVariants: {
    size: "sm"
  }
});
var Avatar = ({
  image,
  name,
  className,
  size
}) => {
  var _a;
  return /* @__PURE__ */ React.createElement("div", {
    className: avatarStyles({
      className,
      avatar: !!image,
      size
    })
  }, image ? /* @__PURE__ */ React.createElement("img", {
    src: image,
    referrerPolicy: "no-referrer",
    className: "object-contain"
  }) : /* @__PURE__ */ React.createElement("p", {
    className: "text-blue-900 font-medium"
  }, (_a = name[0]) == null ? void 0 : _a.toUpperCase()));
};

// src/components/ChatColumn.layout.tsx
import React3 from "react";
var Container = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    style: {
      gridTemplateAreas: `
      "header"
      "list"
      "footer"
    `,
      gridTemplateColumns: "1fr",
      gridTemplateRows: "minmax(60px, auto) 1fr minmax(81px, auto)"
    },
    className: "h-full grid relative"
  }, props), children);
};
var Header = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    style: { gridArea: "header" }
  }, props), children);
};
var List = (_a) => {
  var _b = _a, { children, style } = _b, props = __objRest(_b, ["children", "style"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    style: __spreadValues({ gridArea: "list", overflow: "auto" }, style)
  }, props), children);
};
var Footer = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React3.createElement("div", __spreadValues({
    style: { gridArea: "footer" }
  }, props), children);
};

// src/components/ChatMessages.tsx
import { motion as motion2 } from "framer-motion";
import { format as format2 } from "date-fns";

// src/components/UnreadMessageBreak.tsx
import { Divider, Flex, Text } from "@betterleap/ui";
var UnreadMessageBreak = (_a) => {
  var _b = _a, {
    unreadCount,
    css
  } = _b, rest = __objRest(_b, [
    "unreadCount",
    "css"
  ]);
  return /* @__PURE__ */ React.createElement(Flex, __spreadValues({
    css: __spreadValues({ gap: 12 }, css)
  }, rest), /* @__PURE__ */ React.createElement(Text, {
    size: "xs",
    noBreak: true,
    css: { color: "$purple-600" }
  }, unreadCount, " unread messages"), /* @__PURE__ */ React.createElement(Divider, {
    css: { backgroundColor: "$purple-600" }
  }));
};

// src/components/ChatMessages.tsx
function ChatMessages({
  messages,
  user,
  workspace,
  onRead,
  unreadId,
  unreadCount
}) {
  var _a;
  const firstMessage = messages[0];
  const lastMessage = messages[messages.length - 1];
  const sender = firstMessage == null ? void 0 : firstMessage.sender;
  const senderName = (sender == null ? void 0 : sender.role) === "recruiter" ? (_a = workspace == null ? void 0 : workspace.name) != null ? _a : "" : chatUserDisplayName(firstMessage.sender);
  const isSending = firstMessage.status === "sending" /* Sending */;
  const avatar = getSenderAvatar({ workspace, sender });
  return /* @__PURE__ */ React.createElement(motion2.div, {
    initial: isSending ? { y: 10 } : false,
    animate: { y: 0 }
  }, /* @__PURE__ */ React.createElement("div", {
    className: "mb-6"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex gap-2 items-center mb-0.5"
  }, /* @__PURE__ */ React.createElement(Avatar, {
    name: senderName,
    image: avatar
  }), /* @__PURE__ */ React.createElement("p", {
    className: "text-sm font-medium text-[#1A1C20]"
  }, senderName), /* @__PURE__ */ React.createElement("span", {
    className: "text-[8px] text-[#656F7D]"
  }, "\u2022"), /* @__PURE__ */ React.createElement("p", {
    className: "text-xs text-[#656F7D]"
  }, format2(new Date(lastMessage.createdAt), "h:mm a"))), /* @__PURE__ */ React.createElement("div", {
    className: "ml-10"
  }, (sender == null ? void 0 : sender.role) === "recruiter" && /* @__PURE__ */ React.createElement("p", {
    className: "text-sm text-[#656F7D] mb-2"
  }, chatUserDisplayName(firstMessage.sender)), messages.map((message, i) => /* @__PURE__ */ React.createElement(React.Fragment, null, i !== 0 && message.id === unreadId && /* @__PURE__ */ React.createElement(UnreadMessageBreak, {
    css: { my: 24, ml: -40 },
    unreadCount
  }), /* @__PURE__ */ React.createElement(ChatMessageBubble, {
    key: message.id,
    message,
    user,
    onRead
  }))))));
}

// src/components/MessageDateBreak.tsx
import { Box, Divider as Divider2, Flex as Flex2, Text as Text2 } from "@betterleap/ui";
var MessageDateBreak = (_a) => {
  var _b = _a, {
    dateString,
    css
  } = _b, rest = __objRest(_b, [
    "dateString",
    "css"
  ]);
  return /* @__PURE__ */ React.createElement(Flex2, __spreadValues({
    css: __spreadValues({ gap: 12 }, css)
  }, rest), /* @__PURE__ */ React.createElement(Divider2, {
    css: { backgroundColor: "$neutral-blue-300" }
  }), /* @__PURE__ */ React.createElement(Box, {
    css: {
      borderRadius: 6,
      px: 6,
      py: 2,
      backgroundColor: "$neutral-blue-300"
    }
  }, /* @__PURE__ */ React.createElement(Text2, {
    size: "xs",
    noBreak: true,
    css: { color: "$neutral-blue-700" }
  }, dateString)), /* @__PURE__ */ React.createElement(Divider2, {
    css: { backgroundColor: "$neutral-blue-300" }
  }));
};

// src/components/TypingStatus.tsx
import { useState as useState6, useEffect as useEffect6 } from "react";
var TypingStatusLabel = ({
  statusInfo
}) => {
  const [label, setLabel] = useState6(null);
  useEffect6(() => {
    if (!statusInfo)
      return;
    const { userName, isTyping } = statusInfo;
    setLabel(isTyping ? `${userName} is typing...` : "");
    setTimeout(() => {
      setLabel("");
    }, 2e3);
  }, [statusInfo]);
  return /* @__PURE__ */ React.createElement("div", {
    className: "text-gray-500 text-sm pl-2 pb-2 absolute bottom-[73px] italic"
  }, label);
};

// src/components/ChatWindow.tsx
function ChatWindow({
  user,
  chat,
  onChange,
  onSubmit,
  showBackButton,
  onClickBack,
  emptyState,
  markMessageAsRead,
  setIsTyping
}) {
  var _a;
  const { state, dispatch } = useChatAppContext();
  const { typingStatus } = chat;
  const formRef = useRef6(null);
  const [uploadState, uploadFiles, removeUpload, clearAll] = useChatUpload();
  const combinedMessages = ((_a = chat.messages) == null ? void 0 : _a.concat(chat.inflightMessages || []).sort((a, b) => {
    const aCreatedAt = a.createdAt;
    const bCreatedAt = b.createdAt;
    if (aCreatedAt > bCreatedAt)
      return 1;
    if (aCreatedAt < bCreatedAt)
      return -1;
    return 0;
  })) || [];
  const groupedMessages = Object.entries(groupByDateAndAuthor(combinedMessages));
  const { firstUnreadId } = useMemo(() => getMessageBreaks(combinedMessages, user.id), [combinedMessages.length]);
  const unreadCount = useMemo(() => {
    return calcUnreadCount(chat, user.id);
  }, [combinedMessages.length]);
  const chatWorkspace = findWorkspaceById(state, chat.workspaceId);
  const { style, dragActive, handleDragOver, handleDragLeave, handleDrop } = useDragOverStyle(inactiveDragAreaStyle, activeDragAreaStyle);
  const avatar = getWorkspaceAvatar({
    workspace: chatWorkspace,
    user,
    subject: chat.subjectUser
  });
  let chatSubject = chat.subject;
  if (user.role === "candidate" && chatWorkspace) {
    chatSubject = chatWorkspace.name;
  }
  async function readMessage(messageId) {
    const payload = await markMessageAsRead(messageId, chat.id);
    dispatch({
      type: "READ_MESSAGE" /* READ_MESSAGE */,
      payload
    });
  }
  function sendMessage() {
    var _a2;
    setIsTyping(false, chat.id, Date.now());
    (_a2 = formRef.current) == null ? void 0 : _a2.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
  }
  function onInputChange(e) {
    onChange(e);
    setIsTyping(!!e.target.value, chat.id, Date.now());
  }
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Header, {
    className: "font-medium p-4 border-b flex items-center gap-2"
  }, showBackButton && /* @__PURE__ */ React.createElement("button", {
    className: "text-purple-500 mr-2 px-2",
    onClick: onClickBack
  }, /* @__PURE__ */ React.createElement(ChevronLeftIcon, {
    className: "w-5 h-5"
  })), /* @__PURE__ */ React.createElement(Avatar, {
    image: avatar,
    name: chatSubject
  }), /* @__PURE__ */ React.createElement("span", null, chatSubject)), /* @__PURE__ */ React.createElement(List, {
    className: "bg-[#F9FAFB] relative",
    onDragOver: handleDragOver,
    onDragLeave: handleDragLeave,
    style,
    onDrop: (event) => {
      event.preventDefault();
      handleDrop(event);
      checkAndUploadFiles(event.dataTransfer.files, uploadFiles);
    }
  }, dragActive && /* @__PURE__ */ React.createElement("div", {
    className: "absolute min-h-full top-0 left-0 right-0 bg-[#c6e0ff] z-10 flex justify-center items-center h-full w-full"
  }, /* @__PURE__ */ React.createElement("h2", {
    className: "text-center text-[#0066ff]"
  }, "Drop files here")), /* @__PURE__ */ React.createElement("div", {
    className: "absolute inset-0 top-0 bottom-0 left-0 right-0 p-4 space-y-2 overflow-scroll"
  }, groupedMessages.length ? groupedMessages.map(([date, daysMessages], i) => /* @__PURE__ */ React.createElement("div", {
    "data-chat-message-bubble": true
  }, /* @__PURE__ */ React.createElement(MessageDateBreak, {
    css: { mb: 24 },
    dateString: date
  }), daysMessages.map((messages) => {
    return /* @__PURE__ */ React.createElement("div", null, messages[0].id === firstUnreadId && /* @__PURE__ */ React.createElement(UnreadMessageBreak, {
      css: { mb: 24 },
      unreadCount
    }), /* @__PURE__ */ React.createElement(ChatMessages, {
      key: i,
      messages,
      workspace: chatWorkspace,
      unreadId: firstUnreadId,
      unreadCount,
      user,
      onRead: (id) => readMessage(id)
    }));
  }))) : emptyState)), /* @__PURE__ */ React.createElement(TypingStatusLabel, {
    statusInfo: typingStatus
  }), /* @__PURE__ */ React.createElement(Footer, {
    className: "border-t"
  }, /* @__PURE__ */ React.createElement("form", {
    ref: formRef,
    onSubmit: (e) => {
      e.preventDefault();
      clearAll();
      onSubmit(e);
    }
  }, uploadState.filter((state2) => !!state2.result).map((state2, i) => {
    var _a2;
    return /* @__PURE__ */ React.createElement("input", {
      type: "hidden",
      key: i,
      name: "attachmentId",
      value: (_a2 = state2.result) == null ? void 0 : _a2.id
    });
  }), /* @__PURE__ */ React.createElement("div", {
    className: `grid flex-1 grid-cols-[min-content_1fr_min-content] ${uploadState.length > 0 && "grid-rows-2 gap-4"} gap-x-4 p-4`
  }, uploadState.length > 0 && /* @__PURE__ */ React.createElement(Attachments, {
    className: "col-span-3",
    files: uploadState,
    removeUpload
  }), /* @__PURE__ */ React.createElement("div", {
    className: "row-start-2 flex"
  }, /* @__PURE__ */ React.createElement(FileTrigger, {
    onSelect: (files) => checkAndUploadFiles(files, uploadFiles),
    allowsMultiple: true
  }, /* @__PURE__ */ React.createElement(Button, {
    className: "bg-transparent"
  }, /* @__PURE__ */ React.createElement(PaperClipIcon, null)))), /* @__PURE__ */ React.createElement(AutoGrowTextArea, {
    autoFocus: true,
    name: "content",
    value: chat.draft,
    placeholder: "Type your message...",
    className: "row-start-2 px-4 py-3 min-h-12 rounded-2xl bg-[#F7F8F9] outline-[#2B6BD1] focus:shadow-[0px_0px_0px_4px_rgba(58,131,247,0.20)]",
    onChange: onInputChange,
    onKeyDown: (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        sendMessage();
      }
    }
  }), /* @__PURE__ */ React.createElement("button", {
    disabled: !chat.draft,
    className: "w-24 row-start-2 bg-[#3A83F7] disabled:bg-[#9EC8FF] disabled:text-[#E9F3FF] text-white rounded-2xl min-h-12 px-6 py-3 cursor-pointer",
    type: "submit"
  }, "Send")))));
}

// src/components/ChatsList.tsx
import { AnimatePresence } from "framer-motion";

// src/components/ChatsListItem.tsx
import { format as format3, isThisWeek, isThisYear as isThisYear2, isToday as isToday2, isYesterday as isYesterday2 } from "date-fns";
import { motion as motion3 } from "framer-motion";
import removeMd from "remove-markdown";
import { cx } from "cva";
function formatChatListTime(date) {
  if (isToday2(date)) {
    return format3(date, "h:mm a");
  } else if (isYesterday2(date)) {
    return "Yesterday";
  } else if (isThisWeek(date)) {
    return format3(date, "EEEE");
  } else if (isThisYear2(date)) {
    return format3(date, "MMMM d");
  } else {
    return format3(date, "MMMM d, yyyy");
  }
}
function ChatsListItem({ chat, user, isActive, onChatClick }) {
  var _a, _b, _c;
  const appCtx = useChatAppContext();
  const unreadCount = calcUnreadCount(chat, user.id);
  const lastMessage = (_a = chat.messages) == null ? void 0 : _a[chat.messages.length - 1];
  const lastSender = lastMessage == null ? void 0 : lastMessage.sender;
  let lastSenderName = (_c = (_b = lastSender == null ? void 0 : lastSender.profile) == null ? void 0 : _b.firstName) != null ? _c : lastSender == null ? void 0 : lastSender.email;
  if (user.id === (lastSender == null ? void 0 : lastSender.id)) {
    lastSenderName = "You";
  }
  let contentPreview = "";
  if (!isActive && chat.draft) {
    contentPreview = `Draft: ${chat.draft}`;
  } else if (lastMessage) {
    contentPreview = `${lastSenderName ? `${lastSenderName}: ` : ""}${removeMd(lastMessage.content)}`;
  }
  const chatWorkspace = findWorkspaceById(appCtx.state, chat.workspaceId);
  let chatSubject = chat.subject;
  if (user.role === "candidate" && chatWorkspace) {
    chatSubject = chatWorkspace.name;
  }
  const avatar = getWorkspaceAvatar({
    workspace: chatWorkspace,
    user,
    subject: chat.subjectUser
  });
  return /* @__PURE__ */ React.createElement(motion3.div, {
    key: chat.id,
    layout: true,
    transition: { type: "spring", stiffness: 500, damping: 30 },
    "data-unread": unreadCount,
    className: "group relative cursor-pointer border-[#E8EAED] p-1 min-h-20 transition-colors peer border-b",
    onClick: () => {
      onChatClick(getChatUrl(appCtx.state, chat.id));
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: cx("p-3 items-center flex gap-3 group-hover:bg-[#F7F8F9] rounded-xl", { "bg-blue-100": isActive })
  }, /* @__PURE__ */ React.createElement(Avatar, {
    image: avatar,
    name: chatSubject,
    size: "lg"
  }), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col min-w-0 flex-grow"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between mb-1"
  }, /* @__PURE__ */ React.createElement("p", {
    className: "flex-1 font-medium text-sm text-ellipsis whitespace-nowrap overflow-hidden"
  }, chatSubject), lastMessage && /* @__PURE__ */ React.createElement("span", {
    className: cx("text-[#87909E] text-sm whitespace-nowrap ml-auto", {
      "text-[#8B5CF6]": unreadCount > 0
    })
  }, formatChatListTime(new Date(lastMessage.createdAt)))), /* @__PURE__ */ React.createElement("div", {
    className: "flex justify-between"
  }, contentPreview && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("p", {
    className: "flex-1 text-[#4F5762] text-sm text-ellipsis whitespace-nowrap overflow-hidden"
  }, contentPreview), unreadCount > 0 && /* @__PURE__ */ React.createElement("div", {
    className: "w-[18px] h-[18px] bg-[#8B5CF6] rounded-full flex-shrink-0 flex items-center justify-center text-white text-xs font-medium text-center"
  }, unreadCount))))));
}

// src/components/ChatsList.tsx
import { Divider as Divider3, Flex as Flex3, Tab, Tabs, TabsList } from "@betterleap/ui";
function ChatList({
  chats,
  user,
  activeChatId = null,
  onChatClick,
  onFilterChange,
  headerMenu,
  footerContent
}) {
  const newestFirst = sortChatsByMostRecent(chats);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(Flex3, {
    css: { px: 16, height: 64 },
    justify: "between"
  }, /* @__PURE__ */ React.createElement("h2", {
    className: "text-lg font-medium"
  }, "Chat"), headerMenu), /* @__PURE__ */ React.createElement(Divider3, {
    css: { backgroundColor: "$neutral-blue-300" }
  }), onFilterChange && /* @__PURE__ */ React.createElement(Tabs, {
    css: { px: 16, my: 16 },
    tabStyle: "pill",
    defaultValue: "all",
    onValueChange: (val) => onFilterChange == null ? void 0 : onFilterChange(val)
  }, /* @__PURE__ */ React.createElement(TabsList, null, /* @__PURE__ */ React.createElement(Tab, {
    onClick: () => null,
    value: "all"
  }, "All Messages"), /* @__PURE__ */ React.createElement(Tab, {
    onClick: () => null,
    value: "replied"
  }, "Replied"), /* @__PURE__ */ React.createElement(Tab, {
    onClick: () => null,
    value: "unread"
  }, "Unread")))), /* @__PURE__ */ React.createElement(List, null, /* @__PURE__ */ React.createElement(AnimatePresence, null, newestFirst.map((chat) => /* @__PURE__ */ React.createElement(ChatsListItem, {
    key: chat.id,
    chat,
    user,
    isActive: activeChatId === chat.id,
    onChatClick
  })))), /* @__PURE__ */ React.createElement(Footer, null, footerContent));
}

// src/components/RecruiterChatView.tsx
import { useEffect as useEffect7, useState as useState10 } from "react";
import { useNavigate, useParams } from "react-router-dom";

// src/components/ChatWindowWrapper.tsx
import { showToast as showToast2 } from "@betterleap/ui";

// src/context/test-helpers.ts
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === "x" ? r : r & 3 | 8;
    return v.toString(16);
  });
}

// src/components/ChatWindowWrapper.tsx
function ChatWindowWrapper({
  chatId,
  showBackButton = false,
  emptyState = null,
  onClickBack = () => {
  }
}) {
  const appCtx = useChatAppContext();
  const { user } = appCtx.state;
  const { sendMessageAndWait, setIsTyping, markMessageAsRead } = appCtx.gateway;
  const chats = sortChatsByMostRecent(allChatsSelector(appCtx.state.workspaces));
  const activeChat = chatId ? chats.find((chat) => chat.id === chatId) : null;
  const sendMessage = async (workspaceId, messageChatId, content, attachmentIds) => {
    const _tempId = generateUUID();
    appCtx.dispatch({
      type: "SEND_MESSAGE" /* SEND_MESSAGE */,
      payload: {
        workspaceId,
        chatId: messageChatId,
        _tempId,
        message: { content, sender: user, attachmentIds }
      }
    });
    try {
      const res = await sendMessageAndWait(workspaceId, messageChatId, content, attachmentIds);
      if (res) {
        appCtx.dispatch({
          type: "SEND_MESSAGE_SUCCESS" /* SEND_MESSAGE_SUCCESS */,
          payload: {
            workspaceId,
            chatId: messageChatId,
            _tempId,
            message: __spreadProps(__spreadValues({}, res.message), {
              status: "sent" /* Sent */
            })
          }
        });
      }
    } catch (err) {
      const error = err;
      appCtx.dispatch({
        type: "SEND_MESSAGE_ERROR" /* SEND_MESSAGE_ERROR */,
        payload: { workspaceId, chatId: messageChatId, _tempId, error }
      });
      showToast2({
        title: "Couldn't send a message",
        description: error.message,
        variant: "danger"
      });
    }
  };
  if (activeChat) {
    return /* @__PURE__ */ React.createElement(ChatWindow, {
      key: activeChat.id,
      markMessageAsRead,
      setIsTyping,
      chat: activeChat,
      user: appCtx.state.user,
      onChange: (e) => {
        appCtx.dispatch({
          type: "SET_DRAFT" /* SET_DRAFT */,
          payload: {
            workspaceId: activeChat.workspaceId,
            chatId: activeChat.id,
            draft: e.target.value
          }
        });
      },
      showBackButton,
      onClickBack,
      onSubmit: (e) => {
        const form = e.target;
        const formData = new FormData(form);
        const input = form.content;
        const content = formData.get("content");
        const attachmentIds = formData.getAll("attachmentId");
        if (!content) {
          return;
        }
        sendMessage(activeChat.workspaceId, activeChat.id, content, attachmentIds);
        input.focus();
      },
      emptyState
    });
  }
  if (emptyState) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, emptyState);
  }
  return null;
}

// src/components/NewChatMenu.tsx
import { useState as useState8 } from "react";
import { Button as Button3 } from "react-aria-components";

// src/components/AnnouncementModal.tsx
import { useState as useState7 } from "react";
import { Dialog, Heading, Modal } from "react-aria-components";
import {
  Button as Button2,
  CheckboxOption,
  Flex as Flex4,
  Icon,
  Label,
  ListBox,
  Option,
  Text as Text3
} from "@betterleap/ui";
function AnnouncementModal({
  workspace,
  isOpen,
  setOpen
}) {
  const { state: appState, showGlobalError, showToast: showToast3 } = useChatAppContext();
  const chatFetch = useChatFetch_default();
  const [content, setContent] = useState7("");
  const [sending, setSending] = useState7(false);
  const [error, setError] = useState7(null);
  const [selectedKeys, setSelectedKeys] = useState7("all");
  const users = new Map(workspace.members.filter((m) => {
    var _a;
    return m.id !== ((_a = appState.user) == null ? void 0 : _a.id) && m.role === "candidate";
  }).map((m) => [m.id, m]));
  const selectedUsers = selectedKeys === "all" ? null : selectedKeys.map((k) => users.has(k) && users.get(k)).filter((v) => {
    return Boolean(v);
  });
  const sendAnnouncement = async () => {
    setSending(false);
    setError(null);
    if (content.trim().length === 0) {
      setError("Message cannot be empty");
      return;
    }
    const userIds = selectedKeys === "all" ? Array.from(users.keys()) : selectedUsers.map((u) => u.id);
    if (!userIds || userIds.length === 0) {
      setError("No users selected");
      return;
    }
    setSending(true);
    try {
      await chatFetch(`/api/chat-workspace/${workspace.id}/announcement`, {
        method: "POST",
        body: JSON.stringify({
          userIds,
          content
        })
      });
      showToast3("Successfully sent announcement");
      setOpen(false);
    } catch (e) {
      showGlobalError("Failed to send announcement");
      setError("Failed to send announcement");
    } finally {
      setSending(false);
    }
  };
  const hasSelectedUsers = selectedKeys === "all" || selectedUsers && (selectedUsers == null ? void 0 : selectedUsers.length) > 0;
  const canSend = hasSelectedUsers && !sending;
  return /* @__PURE__ */ React.createElement(Modal, {
    isDismissable: true,
    isOpen,
    onOpenChange: setOpen,
    className: "fixed h-screen w-screen inset-0 flex items-center justify-center bg-black/50 z-50 overflow-auto p-6"
  }, /* @__PURE__ */ React.createElement(Dialog, {
    className: "p-8 rounded-xl bg-white flex flex-col gap-6 min-w-[44rem]"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Heading, {
    slot: "title",
    className: "font-medium flex justify-center mb-2 text-center text-gray-900"
  }, "Send Announcement"), /* @__PURE__ */ React.createElement(Text3, {
    align: "center",
    size: "sm",
    css: { color: "$gray-500" }
  }, "Select candidates to share your announcement.")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Flex4, {
    css: { mb: 8 },
    justify: "between"
  }, /* @__PURE__ */ React.createElement(Label, {
    css: { fontSize: "$sm", color: "$text-secondary" }
  }, "Candidates"), /* @__PURE__ */ React.createElement(Button2, {
    variant: "link",
    onClick: () => setSelectedKeys(selectedKeys === "all" ? [] : "all")
  }, /* @__PURE__ */ React.createElement(Icon, {
    inherit: true,
    name: "check",
    size: 16,
    css: { mr: 8 }
  }), "Select all")), /* @__PURE__ */ React.createElement("div", {
    className: "relative"
  }, /* @__PURE__ */ React.createElement(ListBox, {
    css: {
      maxHeight: 256,
      pb: 70,
      after: {
        content: "",
        display: "block",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "70px",
        background: "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
        pointerEvents: "none"
      }
    },
    value: selectedKeys,
    onChange: (newKeys) => {
      setSelectedKeys(newKeys);
    },
    multi: true
  }, Array.from(users.values()).map((user) => /* @__PURE__ */ React.createElement(Option, {
    css: {
      mb: 4,
      py: 12,
      selected: {
        backgroundColor: "$blue-100"
      }
    },
    component: /* @__PURE__ */ React.createElement(CheckboxOption, null),
    key: user.id,
    id: user.id
  }, user.email))))), /* @__PURE__ */ React.createElement("div", {
    className: "flex flex-col"
  }, /* @__PURE__ */ React.createElement(Label, {
    css: { fontSize: "$sm", color: "$text-secondary", mb: 8 }
  }, "Announcement"), /* @__PURE__ */ React.createElement(AutoGrowTextArea, {
    autoFocus: true,
    style: { minHeight: 84 },
    value: content,
    onChange: (e) => setContent(e.target.value),
    disabled: sending,
    placeholder: "Type a message...",
    className: "border border-[#E8EAED] px-4 py-3 max-h-96 w-full rounded-xl"
  })), error && /* @__PURE__ */ React.createElement("p", {
    className: "text-red-600"
  }, error), /* @__PURE__ */ React.createElement(Flex4, {
    css: { gap: 8 }
  }, /* @__PURE__ */ React.createElement(Button2, {
    variant: "gray",
    onClick: () => setOpen(false),
    css: { flex: 1 },
    disabled: sending
  }, "Cancel"), /* @__PURE__ */ React.createElement(Button2, {
    css: { flex: 1 },
    disabled: !canSend,
    onClick: () => sendAnnouncement()
  }, "Send"))));
}

// src/components/NewChatMenu.tsx
import { PlusIcon } from "@heroicons/react/24/outline";
function NewChatMenu({
  workspace,
  className
}) {
  const [announcementOpen, setAnnouncementOpen] = useState8(false);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button3, {
    "aria-label": "New Chat Menu",
    onPress: () => {
      setAnnouncementOpen(true);
    },
    className: "p-1 px-3 text-sm flex items-center text-[#3A83F7] bg-white border-none whitespace-nowrap flex-shrink-0" + (className ? ` ${className}` : "")
  }, /* @__PURE__ */ React.createElement(PlusIcon, {
    className: "w-4 h-4 inline-block mr-2",
    style: { fill: "currentColor" }
  }), "New Announcement"), /* @__PURE__ */ React.createElement(AnnouncementModal, {
    workspace,
    isOpen: announcementOpen,
    setOpen: setAnnouncementOpen
  }));
}

// src/components/OrganizationLinkModal.tsx
import { useState as useState9, useRef as useRef7 } from "react";

// src/components/BetterleapLogo.tsx
var BetterleapLogo = (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
  xmlns: "http://www.w3.org/2000/svg",
  width: 41,
  height: 41,
  fill: "none"
}, props), /* @__PURE__ */ React.createElement("path", {
  fill: "#C4B5FD",
  d: "M21.606 11.07H19.69L15.29.82 17.164 0l3.464 8.077L24.09 0l1.915.82zM24.091 41l-3.463-8.036L17.164 41l-1.874-.82 4.4-10.25h1.915l4.401 10.25zM13.334 14.513l-10.309-4.1.774-1.927 8.109 3.198-3.22-8.118 1.916-.779 4.075 10.373zm17.358 23.699-4.075-10.373 1.345-1.312 10.309 4.1-.774 1.927-8.109-3.24 3.219 8.16zM1.109 25.87l-.815-1.885L8.281 20.5.293 17.015l.815-1.886 10.146 4.428v1.886zm39.076 0L30.04 21.444v-1.886l10.146-4.428.815 1.886-7.986 3.485L41 23.985zM10.604 38.212l-1.915-.738 3.219-8.16-8.109 3.24-.774-1.927 10.309-4.1 1.345 1.312zm17.358-23.699-1.345-1.353 4.075-10.373 1.915.78-3.219 8.117 8.109-3.198.774 1.927z"
}));
var BetterleapLogo_default = BetterleapLogo;

// src/components/OrganizationLinkModal.tsx
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
function FauxModal({
  className,
  children
}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: className + " min-h-screen flex items-center justify-center"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "bg-white p-12 rounded-2xl shadow-md w-full mx-auto max-w-md relative overflow-hidden"
  }, children));
}
var OrganizationLinkModal = ({
  workspaceName,
  url,
  imageUrl
}) => {
  const ref2 = useRef7(null);
  const [copied, setCopied] = useState9(false);
  function reset() {
    setTimeout(() => {
      setCopied(false);
    }, 2e3);
  }
  function copyLink() {
    if (!navigator.clipboard) {
      if (ref2.current) {
        ref2.current.select();
        document.execCommand("copy");
      }
    } else {
      navigator.clipboard.writeText(url);
    }
    setCopied(true);
    reset();
  }
  return /* @__PURE__ */ React.createElement(FauxModal, null, /* @__PURE__ */ React.createElement("div", {
    className: "text-center flex flex-col gap-2"
  }, imageUrl ? /* @__PURE__ */ React.createElement("img", {
    src: imageUrl,
    className: "max-w-full h-16 mx-auto"
  }) : /* @__PURE__ */ React.createElement(BetterleapLogo_default, {
    className: "h-10 w-10 mx-auto"
  }), /* @__PURE__ */ React.createElement("h1", {
    className: "text-2xl font-bold text-center font-alt"
  }, workspaceName, " Get Connected!")), /* @__PURE__ */ React.createElement("p", {
    className: "text-sm text-alabaster-500 mt-4 mb-6 text-center"
  }, "Share this link with candidates to get connected", " "), /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center focus-within:outline-purple-500"
  }, /* @__PURE__ */ React.createElement("input", {
    readOnly: true,
    className: "w-full border-gray-300 border py-2 pl-4 pr-0 rounded-l-lg outline-none",
    ref: ref2,
    value: url,
    onClick: (e) => {
      e.currentTarget.select();
    }
  }), /* @__PURE__ */ React.createElement("button", {
    onClick: () => copyLink(),
    className: "text-purple-500 bg-white px-3 py-2 rounded-r-lg border border-gray-300 hover:border-purple-500 hover:bg-purple-500 hover:text-white transition-colors duration-200 ease-in-out whitespace-nowrap border-l-0"
  }, copied ? "Copied!" : "Copy")));
};
var OrganizationLinkCard = ({
  workspaceName,
  url,
  imageUrl
}) => {
  const ref2 = useRef7(null);
  const [copied, setCopied] = useState9(false);
  function reset() {
    setTimeout(() => {
      setCopied(false);
    }, 2e3);
  }
  function copyLink() {
    if (!navigator.clipboard) {
      if (ref2.current) {
        ref2.current.select();
        document.execCommand("copy");
      }
    } else {
      navigator.clipboard.writeText(url);
    }
    setCopied(true);
    reset();
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "bg-white rounded-2xl shadow-md p-4 border border-gray-100 w-full"
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "mb-4"
  }, imageUrl ? /* @__PURE__ */ React.createElement("img", {
    src: imageUrl,
    className: "max-w-full h-5"
  }) : null, /* @__PURE__ */ React.createElement("h2", {
    className: "text-base font-medium mb-2"
  }, workspaceName), /* @__PURE__ */ React.createElement("p", {
    className: "text-[#656F7D] text-xs"
  }, "Get connected. Share this link:")), /* @__PURE__ */ React.createElement("div", {
    className: "flex items-center gap-2"
  }, /* @__PURE__ */ React.createElement("input", {
    readOnly: true,
    className: "w-full py-2 px-3 bg-[#F7F8F9] text-sm rounded-xl outline-none ",
    ref: ref2,
    value: url,
    onClick: (e) => {
      e.currentTarget.select();
    }
  }), /* @__PURE__ */ React.createElement("button", {
    onClick: () => copyLink(),
    className: "flex items-center text-[#3A83F7] bg-[#E9F3FF] px-3 py-2 rounded-xl border border-[#E9F3FF] hover:border-[#C3DEFF] hover:bg-[#C3DEFF] transition-colors duration-200 ease-in-out text-sm whitespace-nowrap"
  }, /* @__PURE__ */ React.createElement(DocumentDuplicateIcon, {
    className: "stroke-text-[#3A83F7] h-4 w-4 mr-2"
  }), copied ? "Copied!" : "Copy"))));
};

// src/components/RecruiterChatView.tsx
function RecruiterChatView({
  url,
  emptyState,
  onChatClick,
  onClickBack
}) {
  const appCtx = useChatAppContext();
  const { user, workspaces } = appCtx.state;
  const { chatId } = useParams();
  const [inboxType, setInboxType] = useState10("all");
  const defaultWorkspace = workspaces[0];
  const isMobile = false;
  const navigate = useNavigate();
  const chats = sortChatsByMostRecent(allChatsSelector(appCtx.state.workspaces));
  const replied = repliedChatsSelector(chats);
  const unread = unreadChatsSelector(chats, user == null ? void 0 : user.id);
  useEffect7(() => {
    if (!chatId && chats.length > 0) {
      navigate(getChatUrl(appCtx.state, chats[0].id));
    }
  }, [chatId, chats]);
  if (!appCtx.state.user) {
    return /* @__PURE__ */ React.createElement("div", null, "Loading...");
  }
  let selectedChats = [];
  switch (inboxType) {
    case "all":
      selectedChats = chats;
      break;
    case "replied":
      selectedChats = replied;
      break;
    case "unread":
      selectedChats = unread;
      break;
  }
  const chatList = /* @__PURE__ */ React.createElement(ChatList, {
    chats: selectedChats,
    user: appCtx.state.user,
    activeChatId: chatId,
    onChatClick,
    onFilterChange: (filter) => setInboxType(filter),
    headerMenu: /* @__PURE__ */ React.createElement(NewChatMenu, {
      workspace: defaultWorkspace,
      className: "ml-auto"
    }),
    footerContent: /* @__PURE__ */ React.createElement("div", {
      className: "p-4"
    }, /* @__PURE__ */ React.createElement(OrganizationLinkCard, {
      workspaceName: defaultWorkspace.name,
      url
    }))
  });
  if (chats.length === 0) {
    return /* @__PURE__ */ React.createElement(OrganizationLinkModal, {
      workspaceName: defaultWorkspace.name,
      url,
      imageUrl: defaultWorkspace.imageUrl
    });
  }
  const chatWindow = /* @__PURE__ */ React.createElement(ChatWindowWrapper, {
    chatId,
    emptyState,
    showBackButton: false,
    onClickBack
  });
  if (isMobile) {
    return /* @__PURE__ */ React.createElement("div", {
      className: "bg-white h-dvh"
    }, chatId ? chatWindow : chatList);
  }
  return /* @__PURE__ */ React.createElement("div", {
    className: "bg-white h-[calc(100dvh-86px)] flex mx-auto border-l border-r border-[#E8EAED]"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "basis-1/4 min-w-[20rem] border-r border-[#E8EAED]"
  }, chatList), /* @__PURE__ */ React.createElement("div", {
    className: "basis-3/4"
  }, chatWindow));
}

// src/hooks/useMediaQuery.ts
import { useEffect as useEffect8, useState as useState11 } from "react";
function useMediaQuery(query, defaultMatches = false) {
  const [matches, setMatches] = useState11(defaultMatches);
  useEffect8(() => {
    const listener = (e) => {
      setMatches(e.matches);
    };
    const mql = window.matchMedia(query);
    mql.addEventListener("change", listener);
    setMatches(mql.matches);
    return () => {
      mql.removeEventListener("change", listener);
    };
  }, [query]);
  return matches;
}

// src/hooks/useIsMobile.ts
function useIsMobile() {
  return useMediaQuery("(max-width: 768px)");
}

// src/components/CandidateChat.layout.tsx
import React4 from "react";
var Container2 = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React4.createElement("div", __spreadValues({
    style: {
      gridTemplateColumns: "1fr 2fr"
    },
    className: "bg-white h-dvh grid [grid-template-areas:'header_header''chat_chat'] md:[grid-template-areas:'header_header''chatList_chat'] [grid-template-rows:65px_1fr] md:[grid-template-rows:80px_1fr]"
  }, props), children);
};
var Header2 = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React4.createElement("div", __spreadValues({
    style: { gridArea: "header" }
  }, props), children);
};
var ChatList2 = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React4.createElement("div", __spreadValues({
    style: { gridArea: "chatList" }
  }, props), children);
};
var Chat = (_a) => {
  var _b = _a, { children } = _b, props = __objRest(_b, ["children"]);
  return /* @__PURE__ */ React4.createElement("div", __spreadValues({
    style: { gridArea: "chat" }
  }, props), children);
};

// src/components/CandidateChatView.tsx
import { useEffect as useEffect9 } from "react";
function CandidateChatView({
  chatId,
  emptyState,
  header,
  chatListFooter,
  onChatClick,
  onClickBack
}) {
  var _a;
  const appCtx = useChatAppContext();
  const isMobile = useIsMobile();
  if (!appCtx.state.user) {
    return /* @__PURE__ */ React.createElement("div", null, "Loading...");
  }
  const chats = allChatsSelector(appCtx.state.workspaces);
  const betterleapWorkspace = appCtx.state.workspaces.find((w) => w.slug === "betterleap");
  const betterleapChat = (_a = betterleapWorkspace == null ? void 0 : betterleapWorkspace.chats) == null ? void 0 : _a[0];
  useEffect9(() => {
    if (!isMobile && !chatId && betterleapChat) {
      onChatClick(getChatUrl(appCtx.state, betterleapChat.id));
    }
  }, [chatId, betterleapChat]);
  const chatList = /* @__PURE__ */ React.createElement(ChatList, {
    chats,
    user: appCtx.state.user,
    activeChatId: chatId,
    onChatClick,
    footerContent: chatListFooter
  });
  const chatWindow = /* @__PURE__ */ React.createElement(ChatWindowWrapper, {
    chatId,
    emptyState,
    showBackButton: isMobile,
    onClickBack
  });
  let content = null;
  if (isMobile) {
    content = /* @__PURE__ */ React.createElement(Chat, {
      className: "overflow-hidden"
    }, chatId ? chatWindow : chatList);
  } else {
    content = /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ChatList2, {
      className: "border-r border-[#E8EAED]"
    }, chatList), /* @__PURE__ */ React.createElement(Chat, {
      className: "overflow-hidden"
    }, chatWindow));
  }
  return /* @__PURE__ */ React.createElement(Container2, null, /* @__PURE__ */ React.createElement(Header2, null, header), content);
}

// src/components/EmptyStateSvg.tsx
var EmptyStateSVG = (props) => /* @__PURE__ */ React.createElement("svg", __spreadValues({
  xmlns: "http://www.w3.org/2000/svg",
  width: 290,
  height: 226,
  fill: "none"
}, props), /* @__PURE__ */ React.createElement("path", {
  fill: "url(#prefix__a)",
  fillRule: "evenodd",
  d: "M250.843 153.609c-6.859 20.607-35.551 28.416-56.585 41.486-19.332 12.013-34.558 30.233-59.507 30.653-26.678.448-49.811-13.537-69.28-28.487-21.742-16.695-46.385-35.682-44.896-59.187 1.49-23.538 25.317-43.078 52.002-53.015 22.13-8.242 46.43 7.359 71.184 7.141 30.689-.27 62.352-23.012 87.592-8.537 25.636 14.702 27.364 46.293 19.49 69.946",
  clipRule: "evenodd"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#D6D9DE",
  d: "M264.517 72.658c.025-.211.332-.211.357 0l.211 1.776a4.996 4.996 0 0 0 4.372 4.372l1.776.21c.211.026.211.333 0 .358l-1.776.21a4.996 4.996 0 0 0-4.372 4.373l-.211 1.775c-.025.212-.332.212-.357 0l-.211-1.775a4.995 4.995 0 0 0-4.372-4.372l-1.775-.211c-.212-.025-.212-.332 0-.358l1.775-.21a4.995 4.995 0 0 0 4.372-4.372zM167.517.658c.025-.211.332-.211.357 0l.211 1.776a4.996 4.996 0 0 0 4.372 4.372l1.776.21c.211.026.211.333 0 .358l-1.776.21a4.996 4.996 0 0 0-4.372 4.373l-.211 1.775c-.025.212-.332.212-.357 0l-.211-1.775a4.995 4.995 0 0 0-4.372-4.372l-1.775-.211c-.212-.025-.212-.332 0-.357l1.775-.211a4.995 4.995 0 0 0 4.372-4.372z"
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "#D6D9DE",
  strokeLinecap: "round",
  strokeWidth: 2.435,
  d: "M70.783 34.408H60.217M72.565 28.777l-9.29-5.032M77.037 25.544l-4.812-9.406"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 195.174,
  cy: 20.673,
  r: 3.957,
  stroke: "#D6D9DE",
  strokeWidth: 2.435
}), /* @__PURE__ */ React.createElement("path", {
  stroke: "#D6D9DE",
  strokeWidth: 2.435,
  d: "M41.13 107.369c0 1.949-1.7 3.652-3.956 3.652s-3.957-1.703-3.957-3.652c0-1.948 1.701-3.652 3.957-3.652s3.956 1.704 3.956 3.652Z"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 230.174,
  cy: 102.673,
  r: 3.957,
  stroke: "#D6D9DE",
  strokeWidth: 2.435
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#D6D9DE",
  d: "M58.517 112.658c.025-.211.332-.211.357 0l.211 1.776a4.996 4.996 0 0 0 4.372 4.372l1.776.211c.211.025.211.332 0 .357l-1.776.211a4.995 4.995 0 0 0-4.372 4.372l-.21 1.775c-.026.212-.333.212-.358 0l-.21-1.775a4.995 4.995 0 0 0-4.373-4.372l-1.775-.211c-.212-.025-.212-.332 0-.357l1.775-.211a4.996 4.996 0 0 0 4.372-4.372z"
}), /* @__PURE__ */ React.createElement("g", {
  clipPath: "url(#prefix__b)"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#F0F1F3",
  stroke: "#fff",
  strokeWidth: 2,
  d: "m201.891 125.382 2.475 10.503-18.802-11.583-.241-.149h-54.794c-2.925 0-5.869-1.331-8.09-3.372-2.228-2.047-3.615-4.699-3.615-7.233v-.995h70.291c3.943 0 6.96-1.614 8.969-4.15 1.986-2.508 2.938-5.854 2.938-9.294V58.15h2.643c2.986 0 5.02.962 6.323 2.469 1.324 1.53 2.012 3.757 2.012 6.492v46.437c0 2.744-.691 5.428-2.074 7.397-1.357 1.932-3.395 3.208-6.261 3.208h-2.064z"
}), /* @__PURE__ */ React.createElement("path", {
  fill: "#E8EAED",
  stroke: "#fff",
  strokeWidth: 2,
  d: "m94.887 105.187.283-1.126-1.156-.113c-3.247-.318-6.552-1.515-9.02-3.322C82.52 98.816 81 96.491 81 93.908V41.319c0-2.856 1.857-5.931 4.744-8.344 2.871-2.4 6.569-3.975 9.925-3.975h82.8c3.335 0 6.598 1.558 9.04 3.926 2.448 2.373 3.97 5.458 3.97 8.393v52.592c0 2.892-1.483 5.409-3.88 7.234-2.405 1.831-5.691 2.926-9.13 2.926h-60.857l-.242.149-26.32 16.221zm4.936-40.632c0 4.836 3.753 8.837 8.48 8.837 4.721 0 8.479-4 8.479-8.837s-3.753-8.838-8.479-8.838c-4.727 0-8.48 4.001-8.48 8.838Zm27.937 0c0 4.836 3.753 8.837 8.479 8.837 4.727 0 8.48-4.001 8.48-8.837 0-4.837-3.753-8.838-8.48-8.838-4.726 0-8.479 4.001-8.479 8.838Zm27.94 0c0 4.837 3.758 8.837 8.484 8.837 4.718 0 8.48-4 8.48-8.837s-3.753-8.838-8.48-8.838c-4.726 0-8.484 4-8.484 8.838Z"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 108.5,
  cy: 64.5,
  r: 8.5,
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 136.5,
  cy: 64.5,
  r: 8.5,
  fill: "#fff"
}), /* @__PURE__ */ React.createElement("circle", {
  cx: 164.5,
  cy: 64.5,
  r: 8.5,
  fill: "#fff"
})), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", {
  id: "prefix__a",
  x1: 159.857,
  x2: 155.894,
  y1: 185.896,
  y2: 98.377,
  gradientUnits: "userSpaceOnUse"
}, /* @__PURE__ */ React.createElement("stop", {
  stopColor: "#ECE7E7",
  stopOpacity: 0
}), /* @__PURE__ */ React.createElement("stop", {
  offset: 1,
  stopColor: "#fff"
})), /* @__PURE__ */ React.createElement("clipPath", {
  id: "prefix__b"
}, /* @__PURE__ */ React.createElement("path", {
  fill: "#fff",
  d: "M80 28h133v110H80z"
}))));
export {
  ActionType,
  Avatar,
  CandidateChatView,
  ChatAppContextProvider,
  ChatList,
  ChatMessageBubble,
  ChatMessageStatus,
  ChatWindow,
  EmptyStateSVG,
  RecruiterChatView,
  allChatsSelector,
  authStateSelector,
  chatHasRecruiterReply,
  chatUserDisplayName,
  constructFullName,
  findWorkspaceById,
  getChatUrl,
  getWorkspaceChat,
  getWorkspaces,
  joinWorkspaceEndpoint,
  markAsReadEndpoint,
  messagesEndpoint,
  publicWorkspaceEndpoint,
  repliedChatsSelector,
  unreadChatsCount,
  unreadChatsSelector,
  unreadMessagesCount,
  unrepliedChatsSelector,
  useChatAppContext,
  useChatFetch,
  useChatState,
  workspaceHasChatsWithMember,
  workspaceHasMember,
  workspacesEndpoint
};
