import routeNames from 'constants/routeNames';
import { User } from '@betterleap/client';
import { Button, Flex, Link, Text, useModal } from '@betterleap/ui';
import ConfirmModal, {
  ConfirmModalProps,
} from 'components/modules/Modals/ConfirmModal/ConfirmModal';
import UpdateMaxCsvUploadsModal, {
  UpdateMaxCsvUploadsModalProps,
} from 'components/modules/Modals/UpdateMaxCsvUploadsModal/UpdateMaxCsvUploadsModal';
import styles from './UserCard.module.scss';

interface UserCardProps {
  user: User;
  handleMasquerade: () => void;
  handleBlock: () => void;
  handleDelete: () => void;
  handleUpdateUser: (maxCsvContactsCreatedPerMonth: number | null) => void;
}

const UserCard = ({
  user,
  handleMasquerade,
  handleBlock,
  handleDelete,
  handleUpdateUser,
}: UserCardProps) => {
  const showConfirmModal = useModal<ConfirmModalProps>(ConfirmModal);

  const showDeleteConfirm = async () => {
    const confirmResults = await showConfirmModal({
      title: 'Delete User?',
      description: `Are you sure you want to delete ${user.firstName} ${user.lastName}?`,
      confirmText: 'Delete',
    });
    if (confirmResults) {
      handleDelete();
    }
  };

  const showBlockConfirm = async () => {
    const confirmResults = await showConfirmModal({
      title: 'Block User?',
      description: `Are you sure you want to block ${user.firstName} ${user.lastName}?`,
      confirmText: 'Block',
    });
    if (confirmResults) {
      handleBlock();
    }
  };

  const showMaxCsvUploadsModal = useModal<UpdateMaxCsvUploadsModalProps>(
    UpdateMaxCsvUploadsModal,
  );

  const handleSetMaxCsv = async () => {
    await showMaxCsvUploadsModal({
      currentMaxCsvContactsCreatedPerMonth:
        user?.maxCsvContactsCreatedPerMonth === null
          ? ''
          : `${user?.maxCsvContactsCreatedPerMonth}`,
      onSave: (value: string) => {
        const maxCsvContactsCreatedPerMonth =
          value.trim() === '' ? null : parseInt(value, 10);
        handleUpdateUser(maxCsvContactsCreatedPerMonth);
      },
    });
  };

  return (
    <div
      className={`mb-2 p-4 flex-col tablet:flex-row flex tablet:justify-between tablet:items-center ${styles.card_wrapper}`}
    >
      <div>
        <p className='break-all mr-2'>
          <strong>Name:</strong> {user?.firstName} {user?.lastName}
        </p>
        <p>
          <strong>Email:</strong> {user?.email}
        </p>
        <p>
          <strong>Role:</strong> {user?.role}
        </p>
        <p>
          <strong>Org:</strong>{' '}
          <Link href={routeNames.organization({ id: user.organizationId })}>
            {user?.organization?.name}
          </Link>
        </p>
        {user?.blockedAt && (
          <Text css={{ color: '$red-500' }}>
            <strong>Blocked At:</strong> {user?.blockedAt}
          </Text>
        )}
        {user?.maxCsvContactsCreatedPerMonth !== null && (
          <Text css={{ color: '$neutral-blue-700' }}>
            <strong>CSV limit set to:</strong>{' '}
            {user?.maxCsvContactsCreatedPerMonth}
          </Text>
        )}
        {user?.ruptDevices.length > 0 && (
          <Text css={{ color: '$neutral-blue-600' }}>
            <strong>Rupt Device IDs:</strong>{' '}
            {JSON.stringify(user?.ruptDevices)}
          </Text>
        )}
      </div>
      <Flex>
        <Button
          variant='primary'
          onClick={handleMasquerade}
          css={{ ml: '1rem' }}
        >
          Sign in
        </Button>
        <Button
          variant='secondary'
          onClick={handleSetMaxCsv}
          css={{ ml: '1rem' }}
        >
          Set Max CSV
        </Button>
        <Button
          disabled={!!user?.blockedAt}
          variant='darkGray'
          onClick={showBlockConfirm}
          css={{ color: '$red-700', border: '1px solid', ml: '1rem' }}
        >
          Block User
        </Button>
        <Button
          variant='danger'
          onClick={showDeleteConfirm}
          css={{ ml: '1rem' }}
        >
          Delete User
        </Button>
      </Flex>
    </div>
  );
};

export default UserCard;
