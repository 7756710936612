/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateContactEmailDto;
(function (CreateContactEmailDto) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = CreateContactEmailDto.type || (CreateContactEmailDto.type = {}));
})(CreateContactEmailDto || (CreateContactEmailDto = {}));
