/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var InvitationsMetadataDto;
(function (InvitationsMetadataDto) {
    let type;
    (function (type) {
        type["HIRING_MANAGER"] = "hiring-manager";
        type["PROJECT_SHARE"] = "project-share";
        type["LINKED_ORG"] = "linked-org";
        type["TEAMMATE_INVITE"] = "teammate-invite";
    })(type = InvitationsMetadataDto.type || (InvitationsMetadataDto.type = {}));
})(InvitationsMetadataDto || (InvitationsMetadataDto = {}));
