export class InvitationApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns any
     * @throws ApiError
     */
    inviteUser({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/invitations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Invitation
     * @throws ApiError
     */
    getInvitations({ pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invitations',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * @returns CreateManyInvitationsResponseDto
     * @throws ApiError
     */
    inviteUsers({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/invitations/many',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns Invitation
     * @throws ApiError
     */
    getInvitationsForAdmin({ organizationId, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invitations/organization/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * @returns LinkedOrgResponse
     * @throws ApiError
     */
    getLinkedOrganizations({ status, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invitations/linked-orgs',
            query: {
                'status': status,
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * @returns Invitation
     * @throws ApiError
     */
    getInvitation({ id, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/invitations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    removeInvitation({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/invitations/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns boolean
     * @throws ApiError
     */
    acceptInvitation({ id, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/invitations/{id}/accept',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns void
     * @throws ApiError
     */
    deleteLinkedOrganization({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/invitations/linked-org/{id}',
            path: {
                'id': id,
            },
        });
    }
}
