import { showToast, Spinner } from '@betterleap/shared';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import UserCard from '../../elements/User/UserCard';
import { apiClient } from '../../../lib/apiClient';

const UsersTemplate = (): JSX.Element => {
  const queryClient = useQueryClient();

  const [masqueradeUserId, setMasqueradeUserId] = useState<
    string | undefined
  >();
  useQuery(
    ['admin_signin_as_user', { masqueradeUserId }],
    () =>
      apiClient.signup.adminMasqueradeAsUser({
        requestBody: { userId: masqueradeUserId as string },
      }),
    {
      onSettled: () => {
        setMasqueradeUserId(undefined);
      },
      onSuccess: (data) => {
        window.open(data.data, '_blank');
      },
      enabled: !!masqueradeUserId,
    },
  );

  const { data: users, isLoading } = useQuery(['get_users'], () =>
    apiClient.user.getUsers(),
  );

  const adminUpdateUser = useMutation(
    (data: {
      userId: string;
      requestBody: { maxCsvContactsCreatedPerMonth: number | null };
    }) => apiClient.user.adminUpdateUser(data),
    {
      onSuccess: () => {
        showToast('User updated', {}, 'success');
        queryClient.invalidateQueries('get_users');
      },
    },
  );

  const blockUser = useMutation(
    (data: { userId: string }) => apiClient.user.blockUser(data),
    {
      onSuccess: () => {
        showToast('User blocked sucessfully', {}, 'success');
        queryClient.invalidateQueries('get_users');
      },
    },
  );

  const deleteUser = useMutation(
    (data: { id: string }) => apiClient.deleteUser.deleteUserById(data),
    {
      onSuccess: () => {
        showToast('User deleted sucessfully', {}, 'success');
        queryClient.invalidateQueries('get_users');
      },
      onError: (error) => {
        const parsedData = JSON.parse(
          JSON.stringify((error as Record<string, unknown>).body),
        );
        if (parsedData?.error?.name === 'NotPermittedActionError') {
          showToast(
            'This user is the Creator of the Organization, and we don’t allow Admin to do that in production. Contact the Eng team to delete this user and organization.',
            {},
            'error',
          );
        } else if (parsedData?.error?.name === 'BadRequestError') {
          showToast(
            'This user is the Creator of the Organization, and we don’t allow Admin to do that if there are other users in that organization',
            {},
            'error',
          );
        } else {
          showToast('Something went wrong.', {}, 'error');
        }
      },
    },
  );

  const handleMasquerade = (id: string) => {
    setMasqueradeUserId(id);
  };

  const handleBlock = (userId: string) => {
    blockUser.mutate({ userId });
  };

  const handleDelete = (id: string) => {
    deleteUser.mutate({ id });
  };

  return (
    <>
      <h1 className='font-bold text-3xl leading-9 text-gray-900 mb-2'>Users</h1>
      {isLoading && <Spinner variant='blue' />}
      {users?.data?.map((user) => (
        <UserCard
          user={user}
          key={user.id}
          handleMasquerade={() => handleMasquerade(user.id)}
          handleBlock={() => handleBlock(user.id)}
          handleDelete={() => handleDelete(user.id)}
          handleUpdateUser={(maxCsvContactsCreatedPerMonth) =>
            adminUpdateUser.mutate({
              userId: user.id,
              requestBody: { maxCsvContactsCreatedPerMonth },
            })
          }
        />
      ))}
    </>
  );
};

export default UsersTemplate;
