/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Feedback;
(function (Feedback) {
    let type;
    (function (type) {
        type["SOURCING_RESULT"] = "sourcing result";
    })(type = Feedback.type || (Feedback.type = {}));
})(Feedback || (Feedback = {}));
