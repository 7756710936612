import React, { forwardRef } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { keyframes } from '@mpalmerlee/stitches-react';
import { styled } from '../../stitches.config';
import { VIEWPORT_PADDING } from './ToastProvider';

const slideIn = keyframes({
  from: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
  to: { transform: 'translateX(0)' },
});

const slideOut = keyframes({
  from: { transform: 'translateX(0)' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

const swipeOut = keyframes({
  from: { transform: 'translateX(var(--radix-toast-swipe-end-x))' },
  to: { transform: `translateX(calc(100% + ${VIEWPORT_PADDING}px))` },
});

const StyledToast = styled(ToastPrimitive.Root, {
  boxSizing: 'border-box',
  position: 'relative',
  backgroundColor: '$background-component',
  borderRadius: 8,
  boxShadow: '$2xl',
  padding: 18,
  width: 384,
  paddingRight: 56,
  display: 'grid',
  gridTemplateAreas: `
  "icon title"
  ". description"
  ". action"
  `,
  gridTemplateColumns: 'max-content auto',
  columnGap: 16,
  alignItems: 'center',

  '@media (prefers-reduced-motion: no-preference)': {
    '&[data-state="open"]': {
      animation: `${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
    },
    '&[data-state="closed"]': {
      animation: `${slideOut} 100ms ease-in forwards`,
    },
    '&[data-swipe="move"]': {
      transform: 'translateX(var(--radix-toast-swipe-move-x))',
    },
    '&[data-swipe="cancel"]': {
      transform: 'translateX(0)',
      transition: 'transform 200ms ease-out',
    },
    '&[data-swipe="end"]': {
      animation: `${swipeOut} 100ms ease-out forwards`,
    },
  },
  variants: {
    layout: {
      'single-action': {
        gridTemplateAreas: `
        "icon title action"
        ". description action"
        `,
        gridTemplateColumns: 'max-content auto max-content',
      },
    },
  },
});

export interface ToastProps extends React.ComponentProps<typeof StyledToast> {
  as?: React.ElementType;
}

export const Toast = forwardRef<HTMLElement, ToastProps>(({ ...rest }, ref) => (
  <StyledToast ref={ref} {...rest} />
));

Toast.displayName = 'Toast';
