export class ProjectSuggestedContactApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Find project suggested contacts
     * @returns ProjectSuggestedContactDto
     * @throws ApiError
     */
    find({ projectId, pageSize, page, status, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/project-suggested-contact',
            query: {
                'pageSize': pageSize,
                'page': page,
                'projectId': projectId,
                'status': status,
            },
        });
    }
    /**
     * Update multiple project suggested contacts, will also add contacts to the project based on status
     * @returns any
     * @throws ApiError
     */
    update({ requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/project-suggested-contact/many',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Generate more suggested contacts for a project
     * @returns any
     * @throws ApiError
     */
    generateMore({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/project-suggested-contact/generate_more',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
