import { BetterleapHttpRequest, TOKEN } from '@betterleap/shared';
import { ApiClient } from '@betterleap/client';
import config from './config';

export const apiClientConfig = {
  BASE: config.endpoints.api.replace('/api', ''),
  TOKEN: async () => TOKEN.get() ?? '',
};

export const apiClient = new ApiClient(apiClientConfig, BetterleapHttpRequest);
