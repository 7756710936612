export class StepsApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create a step for a user.
     * @returns Step
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/steps',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get a user's steps.
     * @returns Step
     * @throws ApiError
     */
    findAllForUser({ sequenceId, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/steps',
            query: {
                'pageSize': pageSize,
                'page': page,
                'sequenceId': sequenceId,
            },
        });
    }
    /**
     * Get a user's step.
     * @returns Step
     * @throws ApiError
     */
    findOne({ stepId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/steps/{stepId}',
            path: {
                'stepId': stepId,
            },
        });
    }
    /**
     * Update a sequence for a user.
     * @returns Step
     * @throws ApiError
     */
    update({ stepId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/steps/{stepId}',
            path: {
                'stepId': stepId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    remove({ stepId, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/steps/{stepId}',
            path: {
                'stepId': stepId,
            },
        });
    }
    /**
     * Create or update many step overrides. Creates step attachments removing any previous ones
     * @returns any
     * @throws ApiError
     */
    overrideSteps({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/step-override',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
