/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactEmail;
(function (ContactEmail) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = ContactEmail.type || (ContactEmail.type = {}));
    let source;
    (function (source) {
        source["ENRICHMENT"] = "Enrichment";
        source["ATS"] = "ATS";
        source["CSV"] = "CSV";
        source["SEQUENCE"] = "Sequence";
        source["SIGNUP"] = "Signup";
        source["MANUAL"] = "Manual";
    })(source = ContactEmail.source || (ContactEmail.source = {}));
})(ContactEmail || (ContactEmail = {}));
