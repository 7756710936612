import React from 'react';

interface StatusBadgeProps extends React.AllHTMLAttributes<HTMLSpanElement> {
  className?: string;
  children?: React.ReactNode;
  // TODO: Fix eslint
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style: any;
}

const StatusBadge = ({ className, children, ...rest }: StatusBadgeProps) => (
  <span
    className={`inline-flex items-center justify-center text-center text-xs font-medium h-4 w-4 rounded-full ${className}`}
    {...rest}
  >
    {children}
  </span>
);

export default StatusBadge;
