/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ConnectIntegrationDto;
(function (ConnectIntegrationDto) {
    let integrationCategory;
    (function (integrationCategory) {
        integrationCategory["ATS"] = "ats";
        integrationCategory["CRM"] = "crm";
    })(integrationCategory = ConnectIntegrationDto.integrationCategory || (ConnectIntegrationDto.integrationCategory = {}));
})(ConnectIntegrationDto || (ConnectIntegrationDto = {}));
