import { BoxProps, Checkbox } from '@betterleap/ui';
import React, { ChangeEvent } from 'react';
import { Hooks, useRowSelect } from 'react-table';

const useInstance: Hooks['useInstance'][0] = (instance) => {
  const {
    selectedFlatRows,
    state: { selectedRowIds },
    onRowSelect,
  } = instance;

  React.useEffect(() => {
    if (onRowSelect) {
      onRowSelect({ selectedRowIds, selectedFlatRows });
    }
  }, [selectedRowIds, selectedFlatRows]);
};

const visibleColumns: Hooks['visibleColumns'][0] = (columns) => [
  // Let's make a column for selection
  {
    id: 'selection',
    width: 48,
    canResize: false,
    useCell: true,
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }) => {
      const { style, onChange, checked, ...restToggleAllRowsProps } =
        getToggleAllRowsSelectedProps();

      return (
        <Checkbox
          dataCy='Checbox all'
          style={style as BoxProps}
          checked={checked}
          onChange={(newChecked) => {
            onChange?.({ target: newChecked } as unknown as ChangeEvent);
          }}
          {...restToggleAllRowsProps}
        />
      );
    },
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }) => {
      const {
        style,
        onChange,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        indeterminate,
        ...restToggleRowProps
      } = row.getToggleRowSelectedProps();

      return (
        <Checkbox
          dataCy='Checbox 1'
          style={style as BoxProps}
          onChange={(checked) => {
            onChange?.({ target: checked } as unknown as ChangeEvent);
          }}
          {...restToggleRowProps}
        />
      );
    },
  },
  ...columns,
];

const useSelector = (hooks: Hooks) => {
  hooks.useInstance.push(useInstance);
  hooks.visibleColumns.push(visibleColumns);
};

useSelector.pluginName = 'useSelector';

const useRowSelection = [useRowSelect, useSelector];

export default useRowSelection;
