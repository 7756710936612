export function requestToCurl(
  url: string,
  options: {
    headers?: Record<string, string>;
    body?: Record<string, unknown>;
    method?: string;
  } = {},
) {
  let curlCommand = `curl -X ${options.method || 'GET'} "${url}"`;

  if (options.headers) {
    for (const [key, value] of Object.entries(options.headers)) {
      curlCommand += ` -H "${key}: ${value}"`;
    }
  }

  if (options.body) {
    curlCommand += ` -d '${JSON.stringify(options.body)}'`;
  }

  return curlCommand;
}
