import { SHARED_ENDPOINTS } from '@betterleap/shared';

// All endpoints must be in snake case (snake_case)
const ENDPOINTS = {
  ...SHARED_ENDPOINTS,
  // AUTH
  me: {
    uri: '/auth/me',
    method: 'GET',
  },
  signin: {
    uri: '/signup/admin-signup',
    method: 'POST',
  },
  // RECRUITERS
  recruiters: {
    uri: '/recruiter/all',
    method: 'GET',
  },
  inactive_recruiters: {
    uri: '/recruiter/all',
    method: 'GET',
  },
  occupied_recruiters: {
    uri: '/recruiter/all',
    method: 'GET',
  },
  available_recruiters: {
    uri: '/recruiter/all',
    method: 'GET',
  },
  accept_recruiter: {
    uri: '/recruiter/{id}/accept',
    method: 'POST',
  },
  reject_recruiter: {
    uri: '/recruiter/{id}/reject',
    method: 'POST',
  },
  // SEARCH
  organizations_search: {
    uri: '/organization/all',
    method: 'GET',
  },
  // USERS
  get_users: {
    uri: '/user/all',
    method: 'GET',
  },
  delete_user: {
    uri: '/delete-user/{id}',
    method: 'DELETE',
  },
  admin_signin_as_user: {
    uri: '/signup/company-user-signup',
    method: 'POST',
  },
  //  ORGANIZATIONS
  organizations: {
    uri: '/organization/all',
    method: 'GET',
  },
};

export type ApiEndpoint = keyof typeof ENDPOINTS; // TODO: Endpoint defined in shared

export { ENDPOINTS };
