export class SlackApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Send sales rep request
     * @returns any
     * @throws ApiError
     */
    salesRepRequest({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/slack/talk-to-us',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Recruiter shares project
     * @returns any
     * @throws ApiError
     */
    recruiterSharedProject({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/slack/recruiter-shares-project',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
