import { AlertIcon, Flex, FlexProps, styled, Text } from '@betterleap/ui';
import { ContactActivityDto } from '@betterleap/client';
import { format } from 'date-fns';
import { forwardRef } from 'react';

interface ActivityProps extends FlexProps {
  activity: ContactActivityDto;
  lastOfDay?: boolean;
  firstOfDay?: boolean;
  isToday?: boolean;
  onLinkClick?: (path: string) => void;
  compact?: boolean;
}

const TimelineBullet = styled('div', {
  borderRadius: '50%',
  position: 'relative',
  minHeight: 7,
  minWidth: 7,
  left: -4,
  zIndex: 1,
  backgroundColor: '$neutral-blue-200',
  border: '1px solid $neutral-blue-400',
  variants: {
    variant: {
      lastOfDay: {
        backgroundColor: 'black',
        border: '1px solid black',
      },
      today: {
        left: -5,
        minHeight: 9,
        minWidth: 9,
        boxShadow: '$focus',
        backgroundColor: '$primary-base',
        border: '1px solid $primary-base',
      },
    },
  },
});

type TimelineBulletProps = React.ComponentProps<typeof TimelineBullet>;

const ActivityBase = forwardRef<HTMLDivElement, ActivityProps>(
  (
    {
      activity,
      children,
      lastOfDay,
      firstOfDay,
      compact,
      isToday,
      css,
      ...rest
    },
    ref,
  ) => {
    let bulletVariant: TimelineBulletProps['variant'];

    if (lastOfDay) {
      bulletVariant = 'lastOfDay';

      if (isToday) {
        bulletVariant = 'today';
      }
    }

    return (
      <Flex
        ref={ref}
        css={{
          pb: 26,
          gap: 8,
          borderLeft: '1px solid $neutral-blue-400',
          lastChild: {
            borderColor: 'transparent',
          },
          alignItems: 'flex-start',
          '&[data-first-of-day=true]': {
            pb: 56,
          },
          ...css,
        }}
        data-today={isToday}
        data-first-of-day={firstOfDay}
        data-last-of-day={lastOfDay}
        {...rest}
      >
        <TimelineBullet variant={bulletVariant} />
        <Flex align='start' css={{ position: 'relative', top: -4, gap: 8 }}>
          <Text size='xs' css={{ minWidth: 66, color: '$text-tertiary' }}>
            {format(new Date(activity.createdAt), 'h:mm a')}
          </Text>
          <Flex
            style={{ top: compact ? -4 : -8 }}
            align='start'
            css={{ position: 'relative', gap: 8 }}
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

ActivityBase.displayName = 'ActivityBase';

const EmailRepliedActivity = ({
  activity,
  compact,
  ...rest
}: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='bell'
      shape='rounded-square'
      variant='success'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      Replied to {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

const EmailSentActivity = ({ activity, compact, ...rest }: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='mail'
      shape='rounded-square'
      variant='info'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      Messaged by {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

const InMailSentActivity = ({ activity, compact, ...rest }: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='mail'
      shape='rounded-square'
      variant='info'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      InMailed by {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

const InMailRepliedActivity = ({
  activity,
  compact,
  ...rest
}: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='bell'
      shape='rounded-square'
      variant='success'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      Replied on LinkedIn to {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

const ProfileViewActivity = ({ activity, compact, ...rest }: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='eye'
      shape='rounded-square'
      variant='neutral'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      Profile viewed by {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

const AddedToProjectActivity = ({
  activity,
  compact,
  onLinkClick,
  ...rest
}: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='collection'
      shape='rounded-square'
      variant='purple'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      Added to project{' '}
      <Text
        as='span'
        inherit
        variant='link'
        onClick={() =>
          onLinkClick?.(`projects/${activity.metadata?.projectId}`)
        }
      >
        {activity.metadata?.projectName}
      </Text>{' '}
      by {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

const AddedToSequenceActivity = ({
  activity,
  compact,
  onLinkClick,
  ...rest
}: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='plus'
      shape='rounded-square'
      variant='danger'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      Added to sequence{' '}
      <Text
        as='span'
        inherit
        variant='link'
        onClick={() =>
          onLinkClick?.(`sequence/${activity.metadata?.sequenceId}`)
        }
      >
        {activity.metadata?.sequenceName}
      </Text>{' '}
      by {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

const SubmittedActivity = ({
  activity,
  compact,
  onLinkClick,
  ...rest
}: ActivityProps) => (
  <ActivityBase activity={activity} compact={compact} {...rest}>
    <AlertIcon
      css={{ flexShrink: 0 }}
      size={compact ? 'sm' : 'base'}
      name='paper-airplane'
      shape='rounded-square'
      variant='orange'
    />
    <Text size='sm' style={{ paddingTop: compact ? 2 : 6 }}>
      Submitted to{' '}
      <Text
        as='span'
        inherit
        variant='link'
        onClick={() =>
          onLinkClick?.(`projects/${activity.metadata?.projectId}`)
        }
      >
        {activity.metadata?.projectName}
      </Text>{' '}
      by {activity.metadata?.userFullName}
    </Text>
  </ActivityBase>
);

export const ContactActivity = ({ activity, ...rest }: ActivityProps) => {
  switch (activity.event) {
    case ContactActivityDto.event.EMAIL_REPLIED:
      return <EmailRepliedActivity activity={activity} {...rest} />;
    case ContactActivityDto.event.EMAIL_SENT:
      return <EmailSentActivity activity={activity} {...rest} />;
    case ContactActivityDto.event.INMAIL_REPLIED:
      return <InMailRepliedActivity activity={activity} {...rest} />;
    case ContactActivityDto.event.INMAIL_SENT:
      return <InMailSentActivity activity={activity} {...rest} />;
    case ContactActivityDto.event.PROFILE_VIEW:
      return <ProfileViewActivity activity={activity} {...rest} />;
    case ContactActivityDto.event.ADDED_TO_PROJECT:
      return <AddedToProjectActivity activity={activity} {...rest} />;
    case ContactActivityDto.event.ADDED_TO_SEQUENCE:
      return <AddedToSequenceActivity activity={activity} {...rest} />;
    case ContactActivityDto.event.SUBMITTED_APPLICATION:
      return <SubmittedActivity activity={activity} {...rest} />;
    default:
      return null;
  }
};
