/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var TaskStatus;
(function (TaskStatus) {
    let status;
    (function (status) {
        status["WAITING"] = "waiting";
        status["ACTIVE"] = "active";
        status["STALLED"] = "stalled";
        status["COMPLETED"] = "completed";
        status["FAILED"] = "failed";
    })(status = TaskStatus.status || (TaskStatus.status = {}));
})(TaskStatus || (TaskStatus = {}));
