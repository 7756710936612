/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateProjectDto;
(function (CreateProjectDto) {
    let contactEmailEnrichmentMode;
    (function (contactEmailEnrichmentMode) {
        contactEmailEnrichmentMode["PERSONAL"] = "Personal";
        contactEmailEnrichmentMode["PROFESSIONAL"] = "Professional";
    })(contactEmailEnrichmentMode = CreateProjectDto.contactEmailEnrichmentMode || (CreateProjectDto.contactEmailEnrichmentMode = {}));
})(CreateProjectDto || (CreateProjectDto = {}));
