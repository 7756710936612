export class GroupApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Groups in an organization
     * @returns GroupDto
     * @throws ApiError
     */
    getGroups() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/group',
        });
    }
    /**
     * Create a new group
     * @returns any
     * @throws ApiError
     */
    createGroup({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/group',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update a group
     * @returns any
     * @throws ApiError
     */
    updateGroup({ id, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/group/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
