export class SearchApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * /person/search
     * Find people using a natural language query.
     * @returns PublicPersonSearchResult A list of people who matched the natural language query. Contact information is not included. This response will cost 1 search credit for search result returned.
     * @throws ApiError
     */
    search({ query, pageNumber, pageSize, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/public/v1/person/search',
            query: {
                'query': query,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                400: `Invalid request. Please see the message in the response body for details.`,
                401: `API key not provided or invalid. Please provide a valid API key in the X-API-Key header.`,
                402: `No credits available. Credits must be purchased or overdraft credits must be enabled.`,
            },
        });
    }
}
