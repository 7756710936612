import { OrganizationApiKey } from '@betterleap/client';
import { Badge } from '@betterleap/ui';

export const OverdraftColumn = ({ apiKey }: { apiKey: OrganizationApiKey }) => {
  return (
    <Badge variant={apiKey.canOverdraft ? 'success' : 'danger'}>
      {apiKey.canOverdraft ? 'Enabled' : 'Disabled'}
    </Badge>
  );
};
