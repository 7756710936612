export class ChatWorkspaceApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns DefaultWorkspaceResponse
     * @throws ApiError
     */
    getOrgDefaultWorkspace() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat-workspace/org-default',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getWorkspace({ idOrSlug, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat-workspace/{idOrSlug}',
            path: {
                'idOrSlug': idOrSlug,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    createAnnouncement({ idOrSlug, requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/chat-workspace/{idOrSlug}/announcement',
            path: {
                'idOrSlug': idOrSlug,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
