import { forwardRef } from 'react';
import * as TogglePrimitive from '@radix-ui/react-toggle';
import { styled } from '../../stitches.config';

export const StyledToggle = styled(TogglePrimitive.Root, {
  borderRadius: '$xl',
  borderStyle: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  disabled: {
    backgroundColor: '$neutral-blue-200',
    fill: '$neutral-blue-500',
    borderColor: '$background-disabled',
    cursor: 'not-allowed',
    color: 'white',
  },
  focus: {
    boxShadow: '$focus',
  },
  variants: {
    size: {
      xs: {
        fontSize: '$xs',
        px: '$12',
        borderRadius: '$md',
        height: 24,
        minWidth: 24,
        '& svg': {
          height: 14,
          minWidth: 14,
        },
      },
      sm: {
        fontSize: '$sm',
        py: '$8',
        px: '$12',
        borderRadius: '$lg',
        height: 32,
        minWidth: 32,
        '& svg': {
          height: 16,
          minWidth: 16,
        },
      },
      base: {
        fontSize: '$sm',
        py: '$8',
        px: '$16',
        borderRadius: '$xl',
        height: 40,
        minWidth: 40,
        '& svg': {
          height: 18,
          minWidth: 18,
        },
      },
      lg: {
        fontSize: '$sm',
        px: '$16',
        py: '$8',
        borderRadius: '$2xl',
        height: 48,
        minWidth: 48,
        '& svg': {
          height: 20,
          minWidth: 20,
        },
      },
      xl: {
        fontSize: '$base',
        px: '$24',
        py: '$14',
        borderRadius: '$3xl',
        height: 56,
        minWidth: 56,
        '& svg': {
          height: 22,
          minWidth: 22,
        },
      },
    },
    shape: {
      square: {},
    },
    variant: {
      darkGray: {
        borderColor: '$neutral-blue-200',
        backgroundColor: '$neutral-blue-200',
        color: '$neutral-blue-800',
        fill: '$neutral-blue-800',
        hover: {
          backgroundColor: '$neutral-blue-400',
          borderColor: '$neutral-blue-400',
        },
        pressed: {
          backgroundColor: '$neutral-blue-500',
          borderColor: '$neutral-blue-500',
        },
        toggled: {
          backgroundColor: '$neutral-blue-500',
          borderColor: '$neutral-blue-500',
        },
        disabled: {
          cursor: 'not-allowed',
          color: '$neutral-blue-500',
          fill: '$neutral-blue-500',
        },
      },
    },
  },
  compoundVariants: [
    {
      shape: 'square',
      size: 'xs',
      css: {
        width: 24,
      },
    },
    {
      shape: 'square',
      size: 'sm',
      css: {
        width: 32,
      },
    },
    {
      shape: 'square',
      size: 'base',
      css: {
        width: 40,
      },
    },
    {
      shape: 'square',
      size: 'lg',
      css: {
        width: 48,
      },
    },
    {
      shape: 'square',
      size: 'xl',
      css: {
        width: 24,
      },
    },
  ],
  defaultVariants: {
    size: 'base',
    variant: 'darkGray',
  },
});

export interface ToggleProps
  extends Omit<React.ComponentProps<typeof StyledToggle>, 'onChange'> {
  as?: React.ElementType;
  onChange?: TogglePrimitive.ToggleProps['onPressedChange'];
}

export const Toggle = forwardRef<HTMLElement, ToggleProps>(
  ({ onChange, onPressedChange, ...rest }, ref) => (
    <StyledToggle
      ref={ref}
      onPressedChange={(pressed: boolean) => {
        onChange?.(pressed);
        onPressedChange?.(pressed);
      }}
      {...rest}
    />
  ),
);

Toggle.displayName = 'Toggle';
