export class ContactViewApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Upsert contact view.
     * @returns any
     * @throws ApiError
     */
    upsert({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact_view',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get contact view.
     * @returns ContactView
     * @throws ApiError
     */
    get() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact_view',
        });
    }
}
