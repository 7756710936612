import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import { useState } from 'react';

export interface UpdateMaxCsvUploadsModalProps {
  currentMaxCsvContactsCreatedPerMonth: string;
  onSave: (value: string) => void;
}

const UpdateMaxCsvUploadsModal: PromiseModal<UpdateMaxCsvUploadsModalProps> = ({
  onDismiss,
  onSubmit,
  onSave,
  currentMaxCsvContactsCreatedPerMonth,
}) => {
  const [maxPerMonthText, setMaxPerMonthText] = useState<string>(
    currentMaxCsvContactsCreatedPerMonth,
  );

  return (
    <Modal>
      <Flex css={{ alignItems: 'center' }} vertical>
        <Box css={{ py: 16 }}>
          <AlertIcon variant='success' name='beaker' />
        </Box>
        <ModalTitle>Set Max CSV Contacts User can create per month</ModalTitle>
        <Text
          size='sm'
          css={{ color: '$text-lightest', textAlign: 'center', mb: '1rem' }}
        >
          Select the new maximum number of contacts
        </Text>
        <Input
          type='text'
          value={maxPerMonthText}
          onChange={setMaxPerMonthText}
          css={{ width: 150 }}
        />
        <Flex css={{ gap: 8, pt: 24, width: '100%' }}>
          <Button full variant='ghost' onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            full
            onClick={() => {
              onSave(maxPerMonthText);
              onSubmit(maxPerMonthText);
            }}
          >
            Update Max CSV Contacts Per Month
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default UpdateMaxCsvUploadsModal;
