export class PaymentApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns GetUpcomingInvoiceDto
     * @throws ApiError
     */
    getUpcomingInvoice({ quantity, prorationDate, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/payment/upcoming-invoice',
            query: {
                'quantity': quantity,
                'prorationDate': prorationDate,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getDefaultPaymentMethod() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/payment/default-method',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    handleStripeWebhook({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/payment/webhook',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
