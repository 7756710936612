import { keyframes } from '@mpalmerlee/stitches-react';
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible';
import { styled } from '../../stitches.config';

const slideUp = keyframes({
  '0%': { height: 0 },
  '100%': { height: 'var(--radix-collapsible-content-height)' },
});

const slideDown = keyframes({
  '0%': { height: 'var(--radix-collapsible-content-height)' },
  '100%': { height: 0 },
});

export const StyledCollapsibleContent = styled(CollapsiblePrimitive.Content, {
  variants: {
    animation: {
      expand: {
        overflow: 'hidden',
        '&[data-state="open"]': {
          animation: `${slideUp} 300ms ease-out`,
        },
        '&[data-state="closed"]': {
          animation: `${slideDown} 300ms ease-out`,
        },
      },
    },
  },

  defaultVariants: {
    animation: 'expand',
  },
});

export type CollapsibleProps = React.ComponentProps<
  typeof CollapsiblePrimitive.Root
>;
export type CollapsibleTriggerProps = React.ComponentProps<
  typeof CollapsiblePrimitive.Trigger
>;
export type CollapsibleContentProps = React.ComponentProps<
  typeof StyledCollapsibleContent
>;

export const Collapsible = CollapsiblePrimitive.Root;
export const CollapsibleTrigger = CollapsiblePrimitive.Trigger;
export const CollapsibleContent = StyledCollapsibleContent;
