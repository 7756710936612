import * as TabsPrimitive from '@radix-ui/react-tabs';
import { createContext, forwardRef } from 'react';
import { styled } from '../../stitches.config';
import { TabProps } from './Tab';

const StyledTabs = styled(TabsPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  background: 'transparent',
});

export interface TabsContext {
  tabStyle?: TabProps['tabStyle'];
}

export const TabsContext = createContext<TabsContext>({});

export interface TabsProps extends React.ComponentProps<typeof StyledTabs> {
  tabStyle?: TabProps['tabStyle'];
}

export const Tabs = forwardRef<HTMLDivElement, TabsProps>(
  ({ children, defaultValue, tabStyle, ...rest }, ref) => (
    <TabsContext.Provider value={{ tabStyle }}>
      <StyledTabs defaultValue={defaultValue} ref={ref} {...rest}>
        {children}
      </StyledTabs>
    </TabsContext.Provider>
  ),
);

Tabs.displayName = 'Tabs';
