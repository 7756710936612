/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var LinkedOrganization;
(function (LinkedOrganization) {
    let status;
    (function (status) {
        status["PENDING"] = "pending";
        status["JOINED"] = "joined";
    })(status = LinkedOrganization.status || (LinkedOrganization.status = {}));
})(LinkedOrganization || (LinkedOrganization = {}));
