import React from 'react';
import withSidebar from 'components/HOC/withSidebar';
import { ApiLogsTemplate } from '../components/templates/ApiLogs/ApiLogs.template';

const ApiLogs: React.FC = () => (
  <React.StrictMode>
    <ApiLogsTemplate />
  </React.StrictMode>
);

export default withSidebar(ApiLogs, true);
