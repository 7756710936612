import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import downArrow from '../../../assets/images/chevron_down.svg';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import StatusTag from '../StatusTag/StatusTag';
import styles from './AppActionsDropdown.module.scss';

const cn = classNames.bind(styles);

interface AppActionsDropdownProps {
  title: string;
  options: {
    text: string;
    onClick: () => void;
    comingSoon?: boolean;
  }[];
  className?: string;
  wrapperClassName?: string;
  childClassName?: string;
}

const AppActionsDropdown = ({
  title,
  options,
  className,
  wrapperClassName,
  childClassName,
}: AppActionsDropdownProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = () => {
    setOpen(false);
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={className} ref={ref}>
      <div className='relative'>
        <div
          data-cy={`button-dropdown-${title}`}
          className={`flex cursor-pointer border items-center border-solid rounded-md outline-none w-full text-sm leading-5 text-gray-700 h-8 ${styles.actions_wrapper} ${wrapperClassName}`}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
          // data-cy='button-dropdown'
        >
          <span className='mr-3 text-sm leading-5 font-medium text-gray-700'>
            {title}
          </span>
          {open ? (
            <img
              className={cn(styles.arrow, styles.rotated_arrow)}
              src={downArrow}
              alt='up arrow'
            />
          ) : (
            <img src={downArrow} className={styles.arrow} alt='down arrow' />
          )}
        </div>
        {open && (
          <div
            className={`absolute flex flex-col mt-1 rounded-md z-10 right-0 ${styles.open_actions_wrapper} ${childClassName}`}
          >
            {options.map((option) => (
              <div
                key={option.text}
                data-cy={`option-${option.text}`}
                className={`flex justify-between text-sm leading-5 font-medium text-gray-700 cursor-pointer ${styles.dropdown_item}`}
                onClick={(e) => {
                  e.stopPropagation();
                  option.onClick();
                  setOpen(false);
                }}
              >
                {option.text}
                {option.comingSoon && (
                  <StatusTag className='ml-2' tag='coming_soon' />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppActionsDropdown;
