import { OrganizationApiKey } from '@betterleap/client';
import { Flex, IconButton, Text } from '@betterleap/ui';
import { maskString } from 'lib/maskString';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const ApiKeyColumn = ({ apiKey }: { apiKey: OrganizationApiKey }) => {
  const [showKey, setShowKey] = useState(false);
  let apiKeyString = apiKey.value;

  if (!showKey) {
    apiKeyString = maskString(apiKeyString);
  }

  return (
    <Flex>
      <Link to={`/api-logs?apiKeyId=${apiKey.id}`}>
        <Text size='sm' variant='link' flex>
          {apiKeyString}
        </Text>
      </Link>
      <IconButton
        name={showKey ? 'eye-off' : 'eye'}
        size='xs'
        css={{ ml: 2, p: 2 }}
        label='view key'
        onClick={() => setShowKey(!showKey)}
      />
    </Flex>
  );
};
