import { BoxProps } from '../../components/Box';

export interface ConditionStyle {
  match: boolean;
  css: BoxProps['css'];
}

export type ConditionalStyles = ConditionStyle[];

export const reduceConditionalStyles = (
  styles: ConditionalStyles,
): BoxProps['css'] => {
  const css: BoxProps['css'] = {};

  return styles.reduce((acc, curr) => {
    if (curr.match) {
      return {
        ...acc,
        ...curr.css,
      };
    }

    return acc;
  }, css);
};
