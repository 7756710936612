import { atom } from 'jotai';
import { useSetAtom } from 'jotai/react';
import { useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';

export const urlLocationAtom = atom(window.location as unknown as Location);

export const useUrlSync = () => {
  const location = useLocation();
  const setUrlLocation = useSetAtom(urlLocationAtom);

  useEffect(() => {
    setUrlLocation(location);
  }, [location, setUrlLocation]);
};
