import { Badge, Box, IconProps, styled, Text, Tooltip } from '@betterleap/ui';
import React from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import styles from './SidebarLink.module.scss';

const StyledNavLink = styled('div');

type StyledNavLinkProps = React.ComponentProps<typeof StyledNavLink>;

interface SidebarLinkProps {
  title: string;
  route: string;
  beta?: boolean;
  id?: string;
  icon?: string | JSX.Element;
  onClick?: () => void;
  hidden?: boolean;
  css?: StyledNavLinkProps['css'];
  collapsed?: boolean;
  handleNavigate?: NavigateFunction;
  currentLocation?: Location;
}

const SidebarLink = ({
  id,
  title,
  route,
  beta,
  icon,
  onClick,
  hidden,
  css,
  handleNavigate,
  collapsed,
  currentLocation,
}: SidebarLinkProps) => {
  if (hidden) return null;
  return (
    <Tooltip
      content={title}
      side='right'
      className={`${!collapsed ? styles.collapsed : ''}`}
    >
      <StyledNavLink
        id={id}
        className={currentLocation?.pathname.includes(route) ? 'active' : ''}
        key={route}
        css={{
          display: 'flex',
          mt: 8,
          py: 14,
          px: 16,
          borderRadius: '$xl',
          alignItems: 'center',
          cursor: 'pointer',
          color: '$neutral-blue-700',
          justifyContent: collapsed ? 'center' : 'none',
          hover: {
            '& path': {
              fill: '$blue-600',
            },
            color: '$blue-600',
            backgroundColor: '$blue-100',
          },
          '&.active': {
            color: 'white',
            backgroundColor: '$primary',
            boxShadow: '5px 5px 10px rgba(58, 131, 247, 0.3);',
            '& path': {
              fill: 'white',
            },
            hover: {
              color: 'white',
              backgroundColor: '$primary',
            },
          },

          mediaMd: {
            width: !collapsed ? 224 : 48,
            overflow: !collapsed ? 'hidden' : 'unset',
            transition: 'width .4s ease',
          },
          ...css,
        }}
        onClick={() => {
          if (onClick) onClick();
          if (handleNavigate) handleNavigate(route);
        }}
      >
        {icon &&
          (React.isValidElement(icon) ? (
            <Box css={{ mr: collapsed ? 0 : 12 }}>
              {React.cloneElement<IconProps>(icon as JSX.Element)}
            </Box>
          ) : (
            <img
              src={icon as string}
              alt='icon'
              className={`mr-2 ${styles.sidebar_icon}`}
              aria-hidden
            />
          ))}
        {!collapsed && (
          <Text
            css={{ flex: 1, whiteSpace: 'nowrap' }}
            size='sm'
            inherit
            dataCy={title}
          >
            {title}
          </Text>
        )}
        {beta && (
          <Badge
            css={{
              hidden: {
                display: 'none',
              },
              current: {
                backgroundColor: 'white',
                color: '$blue-600',
              },
            }}
            size='sm'
            aria-current={currentLocation?.pathname.includes(route) ?? false}
            aria-hidden={collapsed}
            shape='rectangle'
            variant='info'
          >
            BETA
          </Badge>
        )}
      </StyledNavLink>
    </Tooltip>
  );
};

export default SidebarLink;
