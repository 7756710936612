import {
  Badge,
  CheckboxGroup,
  CheckboxMenuItem,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuArrow,
  MenuContent,
  MenuTrigger,
} from '@betterleap/ui';
import { atom } from 'jotai';
import { useAtomValue } from 'jotai/react';
import { useSearchParams } from 'react-router-dom';
import { urlLocationAtom } from 'hooks/use-url-sync';

enum OrganizationFilterValues {
  HAS_API_KEY = 'hasApiKey',
}

const organizationFilters = atom((get) => {
  const location = get(urlLocationAtom);
  const params = new URLSearchParams(location.search);
  return ([] as OrganizationFilterValues[]).concat(
    params.get(OrganizationFilterValues.HAS_API_KEY) === 'true'
      ? [OrganizationFilterValues.HAS_API_KEY]
      : [],
  );
});

export const AppliedOrganizationFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValues = useAtomValue(organizationFilters);

  const handleRemove = (key: OrganizationFilterValues) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  return (
    <Flex css={{ gap: 8 }}>
      {filterValues.map((filterValue) => {
        switch (filterValue) {
          case OrganizationFilterValues.HAS_API_KEY:
            return (
              <Badge
                key={filterValue}
                shape='rectangle'
                variant='purple'
                onRemove={() => handleRemove(filterValue)}
              >
                <Icon inherit name='filter' size={14} css={{ mr: 4 }} />
                Has API Key
              </Badge>
            );
          default:
            return null;
        }
      })}
    </Flex>
  );
};

export const OrganizationFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterValues = useAtomValue(organizationFilters);

  const handleChange = (value: string[]) => {
    if (value.includes(OrganizationFilterValues.HAS_API_KEY)) {
      searchParams.set('hasApiKey', 'true');
    } else {
      searchParams.delete('hasApiKey');
    }

    setSearchParams(searchParams);
  };

  return (
    <Menu>
      <MenuTrigger asChild>
        <IconButton
          label='filter organizations'
          name='filter'
          variant='darkGray'
        />
      </MenuTrigger>
      <MenuContent align='start' css={{ minWidth: 200 }}>
        <MenuArrow offset={14} />
        <CheckboxGroup onChange={handleChange} value={filterValues}>
          <CheckboxMenuItem value={OrganizationFilterValues.HAS_API_KEY}>
            Has API key
          </CheckboxMenuItem>
        </CheckboxGroup>
      </MenuContent>
    </Menu>
  );
};
