import { Flex, FlexProps, Icon, Link, Text } from '@betterleap/ui';
import { useQuery } from 'react-query';
import { ContactWithAtsDataDto } from '@betterleap/client';
import ashbyLogo from '../../../assets/images/ashby-icon.png';
import clockworkLogo from '../../../assets/images/clockwork-icon.svg';
import leverLogo from '../../../assets/images/lever-icon.png';
import recruiterFlowLogo from '../../../assets/images/recruiterflow-icon.png';
import greenhouseLogo from '../../../assets/images/greenhouse-icon.png';
import bullhornLogo from '../../../assets/images/bullhorn-icon.png';
import taleoLogo from '../../../assets/images/taleo-icon.jpg';
import { getFullName } from '../../../helper';
import { getApiClient } from '../../../lib';

interface AtsProfileLinkProps extends FlexProps {
  linkedInUrl: string;
}

export const atsSourceLogoMap = {
  [ContactWithAtsDataDto.atsSource.ASHBY]: ashbyLogo,
  [ContactWithAtsDataDto.atsSource.CLOCKWORK]: clockworkLogo,
  [ContactWithAtsDataDto.atsSource.LEVER]: leverLogo,
  [ContactWithAtsDataDto.atsSource.RECRUITERFLOW]: recruiterFlowLogo,
  [ContactWithAtsDataDto.atsSource.GREENHOUSE]: greenhouseLogo,
  [ContactWithAtsDataDto.atsSource.BULLHORN]: bullhornLogo,
  [ContactWithAtsDataDto.atsSource.ORACLE_TALEO]: taleoLogo,
};

export const AtsProfileLink = ({
  linkedInUrl,
  css,
  ...rest
}: AtsProfileLinkProps) => {
  const apiClient = getApiClient();

  const { data: contactAtsDataResponse } = useQuery<{
    data: ContactWithAtsDataDto;
  }>(
    ['contactAtsData', { linkedInUrl }],
    () =>
      apiClient.atsCandidate.getContactWithAtsData({
        linkedinUrl: linkedInUrl as string,
      }),
    {
      enabled: !!linkedInUrl,
    },
  );

  const contact = contactAtsDataResponse?.data;

  if (!contact?.atsRemoteId) {
    return null;
  }

  return (
    <Flex
      css={{
        justifyContent: 'space-between',
        p: 8,
        borderRadius: '$lg',
        backgroundColor: '$neutral-blue-100',
        ...css,
      }}
      {...rest}
    >
      <Flex css={{ gap: 8 }}>
        <img
          src={atsSourceLogoMap[contact?.atsSource as string]}
          alt='ats logo'
          style={{ height: 20, width: 20 }}
        />
        <Text size='xs' noBreak>
          {getFullName(contact)} is in {contact.atsSource}
        </Text>
      </Flex>
      {contact.atsProfileUrl && (
        <Link
          href={contact.atsProfileUrl}
          target='_blank'
          rel='noopener noreferrer'
          css={{ display: 'flex', alignItems: 'center', pl: 16 }}
        >
          <Icon name='external-link' color='$blue-600' size={18} />
        </Link>
      )}
    </Flex>
  );
};
