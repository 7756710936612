/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactEmailDto;
(function (ContactEmailDto) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = ContactEmailDto.type || (ContactEmailDto.type = {}));
    let validity;
    (function (validity) {
        validity["VALID"] = "valid";
        validity["INVALID"] = "invalid";
        validity["INCONCLUSIVE"] = "inconclusive";
    })(validity = ContactEmailDto.validity || (ContactEmailDto.validity = {}));
    let rawStatus;
    (function (rawStatus) {
        rawStatus["VALID"] = "valid";
        rawStatus["INVALID"] = "invalid";
        rawStatus["CATCH_ALL"] = "catch-all";
        rawStatus["UNKNOWN"] = "unknown";
        rawStatus["SPAMTRAP"] = "spamtrap";
        rawStatus["ABUSE"] = "abuse";
        rawStatus["DO_NOT_MAIL"] = "do_not_mail";
    })(rawStatus = ContactEmailDto.rawStatus || (ContactEmailDto.rawStatus = {}));
    let rawSubStatus;
    (function (rawSubStatus) {
        rawSubStatus["ANTISPAM_SYSTEM"] = "antispam_system";
        rawSubStatus["GREYLISTED"] = "greylisted";
        rawSubStatus["MAIL_SERVER_TEMPORARY_ERROR"] = "mail_server_temporary_error";
        rawSubStatus["FORCIBLE_DISCONNECT"] = "forcible_disconnect";
        rawSubStatus["MAIL_SERVER_DID_NOT_RESPOND"] = "mail_server_did_not_respond";
        rawSubStatus["TIMEOUT_EXCEEDED"] = "timeout_exceeded";
        rawSubStatus["FAILED_SMTP_CONNECTION"] = "failed_smtp_connection";
        rawSubStatus["MAILBOX_QUOTA_EXCEEDED"] = "mailbox_quota_exceeded";
        rawSubStatus["EXCEPTION_OCCURRED"] = "exception_occurred";
        rawSubStatus["POSSIBLE_TRAP"] = "possible_trap";
        rawSubStatus["ROLE_BASED"] = "role_based";
        rawSubStatus["GLOBAL_SUPPRESSION"] = "global_suppression";
        rawSubStatus["MAILBOX_NOT_FOUND"] = "mailbox_not_found";
        rawSubStatus["NO_DNS_ENTRIES"] = "no_dns_entries";
        rawSubStatus["FAILED_SYNTAX_CHECK"] = "failed_syntax_check";
        rawSubStatus["POSSIBLE_TYPO"] = "possible_typo";
        rawSubStatus["UNROUTABLE_IP_ADDRESS"] = "unroutable_ip_address";
        rawSubStatus["LEADING_PERIOD_REMOVED"] = "leading_period_removed";
        rawSubStatus["DOES_NOT_ACCEPT_MAIL"] = "does_not_accept_mail";
        rawSubStatus["ALIAS_ADDRESS"] = "alias_address";
        rawSubStatus["ROLE_BASED_CATCH_ALL"] = "role_based_catch_all";
        rawSubStatus["DISPOSABLE"] = "disposable";
        rawSubStatus["TOXIC"] = "toxic";
    })(rawSubStatus = ContactEmailDto.rawSubStatus || (ContactEmailDto.rawSubStatus = {}));
})(ContactEmailDto || (ContactEmailDto = {}));
