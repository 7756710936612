import { Box, Divider, Flex, Icon } from '@betterleap/ui';
import { useState } from 'react';
import { Location, NavigateFunction } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import SidebarItem from '../SidebarItem/SidebarItem';
import SidebarLink from '../SidebarLink/SidebarLink';
import styles from './MobileSidebar.module.scss';

interface SidebarProps {
  sidebarRoutes: {
    title: string;
    route?: string;
    disabled?: boolean;
    icon?: string | JSX.Element;
    onClick?: () => void;
    active?: boolean;
    beta?: boolean;
    hidden?: boolean;
  }[];
  sidebarFooterRoutes?: {
    title: string;
    route?: string;
    disabled?: boolean;
    icon?: string | JSX.Element;
    onClick?: () => void;
    active?: boolean;
    beta?: boolean;
    hidden?: boolean;
  }[];
  footerBanner?: React.ReactNode;
  handleLogout?: () => void;
  handleNavigate?: NavigateFunction;
  currentLocation?: Location;
}

const MobileSidebar = ({
  sidebarRoutes,
  sidebarFooterRoutes = [],
  handleLogout,
  handleNavigate,
  footerBanner,
  currentLocation,
}: SidebarProps) => {
  const [open, setOpen] = useState(false);
  return open ? (
    <div className='flex absolute w-full'>
      <div
        className={`fixed h-screen z-20 flex flex-col justify-between desktop:hidden tablet:hidden overflow-y-scroll ${styles.sidebar_wrapper}`}
      >
        <Box css={{ flex: 1 }} onClick={() => setOpen(!open)}>
          <Flex
            css={{
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 16,
              marginInline: 12,
            }}
          >
            <img src={logo} className={styles.logo} alt='logo' />
            <Flex
              css={{
                background: '#F0F1F3',
                height: 40,
                width: 40,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 12,
                cursor: 'pointer',
              }}
            >
              <Icon name='x' size={14} />
            </Flex>
          </Flex>
          {sidebarRoutes.map((item) =>
            item.route ? (
              <SidebarLink
                key={item.title}
                title={item.title}
                route={item.route}
                icon={item.icon}
                hidden={item.hidden}
                handleNavigate={handleNavigate}
                currentLocation={currentLocation}
              />
            ) : (
              <SidebarItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                onClick={item.onClick}
                hidden={item.hidden}
              />
            ),
          )}
        </Box>
        <div>
          <Box css={{ px: 16 }}>
            <Divider css={{ mb: 16, backgroundColor: '$neutral-blue-300' }} />
            {footerBanner}
          </Box>
          {sidebarFooterRoutes.map((item) =>
            item.route ? (
              <SidebarLink
                key={item.title}
                title={item.title}
                route={item.route}
                icon={item.icon}
                hidden={item.hidden}
                handleNavigate={handleNavigate}
                currentLocation={currentLocation}
              />
            ) : (
              <SidebarItem
                key={item.title}
                title={item.title}
                icon={item.icon}
                onClick={item.onClick}
                hidden={item.hidden}
              />
            ),
          )}
          {!!handleLogout && (
            <SidebarItem
              css={{ mb: 4 }}
              icon={
                <Icon name='logout-alt' size={20} color='$neutral-blue-700' />
              }
              title='Logout'
              onClick={handleLogout}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`flex items-center p-5 desktop:hidden tablet:hidden  ${styles.hamburger_container}`}
    >
      <img src={logo} alt='logo' className={styles.logo} />

      <span onClick={() => setOpen(true)}>
        <Flex
          css={{
            background: '#E9F3FF',
            height: 40,
            width: 40,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 12,
            cursor: 'pointer',
          }}
        >
          <Icon name='menu' color='$blue-600' size={14} />
        </Flex>
      </span>
    </div>
  );
};

export default MobileSidebar;
