import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React, { forwardRef, ReactNode } from 'react';
import merge from 'lodash/merge.js';
import { keyframes, styled } from '../../stitches.config';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  borderRadius: 4,
  padding: '8px 12px',
  fontSize: 14,
  lineHeight: 1,
  color: '$text-inverse',
  backgroundColor: '$navy',
  zIndex: '$tooltip',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  userSelect: 'none',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
  variants: {
    variant: {
      light: {
        color: '$text',
        backgroundColor: 'white',
      },
    },
  },
});

const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: '$navy',
  variants: {
    variant: {
      light: {
        fill: 'white',
      },
    },
  },
});

export const TooltipProvider = TooltipPrimitive.Provider;

export interface TooltipProps
  extends Omit<
      React.ComponentProps<typeof StyledContent>,
      'onClick' | 'content'
    >,
    React.ComponentProps<typeof TooltipPrimitive.Root> {
  content: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

export const Tooltip = forwardRef<HTMLButtonElement, TooltipProps>(
  (
    {
      css,
      children,
      content,
      open,
      defaultOpen,
      onOpenChange,
      onClick,
      disabled,
      delayDuration = 100,
      disableHoverableContent,
      variant,
      ...rest
    },
    ref,
  ) => (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      delayDuration={delayDuration}
      disableHoverableContent={disableHoverableContent}
    >
      <TooltipPrimitive.Trigger ref={ref} asChild onClick={onClick}>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal>
        <StyledContent
          data-disabled={disabled}
          css={merge(
            {
              disabled: {
                display: 'none',
              },
            },
            css,
          )}
          variant={variant}
          sideOffset={5}
          {...rest}
        >
          {content}
          <StyledArrow variant={variant} />
        </StyledContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  ),
);

Tooltip.displayName = 'TooltipContent';
