export class ApplicationApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Submit candidate in project
     * @returns any
     * @throws ApiError
     */
    submitCandidate({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/application/project',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Count candidates submitted by this group
     * @returns CountDto
     * @throws ApiError
     */
    countAll() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/application/count',
        });
    }
    /**
     * Count candidates submitted by this group over time
     * @returns DataPoint
     * @throws ApiError
     */
    getSubmissionMetrics({ interval = 'month', start, end, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/application/metrics',
            query: {
                'interval': interval,
                'start': start,
                'end': end,
            },
        });
    }
}
