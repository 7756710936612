export class AuthApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Log in or Sign up as User
     * @returns UserSignupResponseDto
     * @throws ApiError
     */
    userSignup({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/signup/user-signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Sign up as Admin
     * @returns User
     * @throws ApiError
     */
    adminSignup({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/signup/admin-signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Sign up as company
     * @returns string
     * @throws ApiError
     */
    adminMasqueradeAsUser({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/signup/company-user-signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * completes a users profile
     * @returns User
     * @throws ApiError
     */
    onboard({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/signup/onboard',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Sign up as user with email
     * @returns any
     * @throws ApiError
     */
    emailUserSignup({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/signup/email-user-signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Signup from the email finder landing page
     * @returns any
     * @throws ApiError
     */
    emailFinderSignup({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/signup/email-finder-signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Login as User
     * @returns User
     * @throws ApiError
     */
    me() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/me',
        });
    }
    /**
     * Login as User
     * @returns User
     * @throws ApiError
     */
    me1() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/me',
        });
    }
    /**
     * Login as an Existing User
     * @returns User
     * @throws ApiError
     */
    verifyAuthedExtensionUser() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/existing-user-login',
        });
    }
    /**
     * Login as an Existing User
     * @returns User
     * @throws ApiError
     */
    verifyAuthedExtensionUser1() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/auth/existing-user-login',
        });
    }
    /**
     * Action URL used by Firebase email templates
     * @returns any
     * @throws ApiError
     */
    action({ mode, oobCode, apiKey, lang, continueUrl, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/auth/action',
            query: {
                'mode': mode,
                'oobCode': oobCode,
                'apiKey': apiKey,
                'lang': lang,
                'continueUrl': continueUrl,
            },
        });
    }
}
