import { RefinementEffect, z } from 'zod';
import { Step } from '@betterleap/client';
import { requiredString } from '../../../helper/requiredString';

const timeRegExp = /((^(0?[1-9]|[1][0-2]):[0-5][0-9])\s(AM|PM)|\d\d:\d\d:\d\d)/;

const validateEmailStep = (
  step: CreateSequenceFormData['steps'][0],
  index: number,
  ctx: z.RefinementCtx,
) => {
  if (!step.subject) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Subject is required.',
      path: [index, 'subject'],
    });
  }

  if (!step.body) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Body is required.',
      path: [index, 'body'],
    });
  }

  if (index === 0 && !step.sendAfterTime) {
    return;
  }

  if (!step.sendAfterTime) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Send time is required.',
      path: [index, 'sendAfterTime'],
    });
  }

  if (!timeRegExp.test(step.sendAfterTime as string)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Send Time must be in format h:mm.',
      path: [index, 'sendAfterTime'],
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateSteps: RefinementEffect<any[]>['refinement'] = (
  steps,
  ctx,
) => {
  steps.forEach((step, index) => {
    if (step.type === Step.type.EMAIL) {
      validateEmailStep(step, index, ctx);
    }
  });
};

export const SequenceSchema = z.object({
  name: requiredString('Name'),
  sender: z.string().optional(),
  sequenceSchedule: z.array(z.string()),
  useDefaultSignature: z.boolean().optional(),
  sendOnHolidays: z.boolean().optional(),
  steps: z
    .array(
      z.object({
        subject: z.string().optional(),
        body: z.string().optional(),
        sendAfterTime: z.string().optional().nullable(),
        type: z.nativeEnum(Step.type),
        attachments: z
          .array(
            z.object({
              fileId: z.string(),
            }),
          )
          .optional(),
        waitTimeCalendarDays: z
          .number({
            required_error: `Schedule Delay is required.`,
            invalid_type_error: 'Schedule Delay must be a number.',
          })
          .gte(0),
        waitBusinessDaysOnly: z.boolean().optional(),
      }),
    )
    .superRefine(validateSteps),
});

export const UpdateSequenceSchema = z.object({
  name: requiredString('Name'),
  sender: z.string().optional(),
  sequenceSchedule: z.array(z.string()),
  useDefaultSignature: z.boolean().optional(),
  sendOnHolidays: z.boolean().optional(),
  steps: z
    .array(
      z.object({
        id: z.string().optional(),
        subject: z.string().optional(),
        body: z.string().optional(),
        sendAfterTime: z.string().optional().nullable(),
        type: z.nativeEnum(Step.type),
        attachments: z
          .array(
            z.object({
              id: z.string().optional(),
              fileId: z.string(),
            }),
          )
          .optional(),
        waitTimeCalendarDays: z
          .number({
            required_error: `Schedule Delay is required.`,
            invalid_type_error: 'Schedule Delay must be a number',
          })
          .gte(0),
        waitBusinessDaysOnly: z.boolean().optional(),
      }),
    )
    .superRefine(validateSteps),
});

export type CreateSequenceFormData = z.infer<typeof SequenceSchema>;
export type UpdateSequenceFormData = z.infer<typeof UpdateSequenceSchema>;
