import { forwardRef } from 'react';

import { styled } from '../../stitches.config';
import { Icon, IconProps } from '.';

export const StyledIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$full',
  variants: {
    size: {
      sm: {
        height: 24,
        width: 24,
        '& svg': {
          height: 12,
          width: 12,
        },
      },
      base: {
        height: 32,
        width: 32,
        '& svg': {
          height: 16,
          width: 16,
        },
      },
      lg: {
        height: 40,
        width: 40,
        '& svg': {
          height: 20,
          width: 20,
        },
      },
      xl: {
        height: 48,
        width: 48,
        '& svg': {
          height: 20,
          width: 20,
        },
      },
    },
    shape: {
      circle: {
        borderRadius: '$full',
      },
      square: {
        borderRadius: '$lg',
      },
      'rounded-square': {
        borderRadius: '$xl',
      },
    },
    variant: {
      success: {
        backgroundColor: '$success-200',
        fill: '$success-base',
      },
      warning: {
        backgroundColor: '$warning-200',
        fill: '$warning-700',
      },
      danger: {
        backgroundColor: '$danger-200',
        fill: '$danger-800',
      },
      info: {
        backgroundColor: '$blue-100',
        fill: '$blue-600',
      },
      aiBlue: {
        backgroundColor: '$blue-200',
        fill: '$blue-700',
      },
      disabled: {
        backgroundColor: '$neutral-blue-100',
        fill: '$neutral-blue-500',
      },
      neutral: {
        backgroundColor: '$neutral-blue-100',
        fill: '$neutral-blue-700',
      },
      purple: {
        backgroundColor: '$purple-200',
        fill: '$purple-600',
      },
      orange: {
        backgroundColor: '$orange-200',
        fill: '$orange-700',
      },
      gray: {
        backgroundColor: '$neutral-blue-100',
        fill: '$neutral-blue-700',
      },
    },
  },
  compoundVariants: [
    {
      shape: 'rounded-square',
      size: 'sm',
      css: {
        borderRadius: '$lg',
      },
    },
    {
      shape: 'square',
      size: 'sm',
      css: {
        borderRadius: '$md',
      },
    },
  ],
  defaultVariants: {
    variant: 'info',
    shape: 'circle',
    size: 'base',
  },
});

export interface AlertIconProps extends Omit<IconProps, 'size' | 'ref'> {
  variant?: React.ComponentProps<typeof StyledIconContainer>['variant'];
  shape?: React.ComponentProps<typeof StyledIconContainer>['shape'];
  size?: React.ComponentProps<typeof StyledIconContainer>['size'];
}

export const AlertIcon = forwardRef<HTMLElement, AlertIconProps>(
  ({ name, color, variant = 'info', ...rest }, ref) => (
    <StyledIconContainer ref={ref} variant={variant} {...rest}>
      <Icon name={name} color={color} css={{ fill: 'inherit' }} />
    </StyledIconContainer>
  ),
);

AlertIcon.displayName = 'AlertIcon';
