import React from 'react';
import {
  AlertIcon,
  AlertIconProps,
  Flex,
  FlexProps,
  Text,
} from '@betterleap/ui';
import { Step } from '@betterleap/client';

const stepProps: Record<Step.type, AlertIconProps & { label: string }> = {
  [Step.type.EMAIL]: {
    name: 'mail',
    label: 'Email',
    variant: 'info',
  },
  [Step.type.INMAIL]: {
    name: 'mail',
    label: 'InMail',
    variant: 'purple',
  },
  [Step.type.SMS]: {
    name: 'chat',
    label: 'Text',
    variant: 'warning',
  },
  [Step.type.PHONE_CALL]: {
    name: 'phone',
    label: 'Call',
    variant: 'success',
  },
  [Step.type.X_DIRECT_MESSAGE]: {
    name: 'mail',
    label: 'X DM',
    variant: 'neutral',
  },
};

export interface StepTypeIndicatorProps extends FlexProps {
  type: Step.type;
}

export const StepTypeIndicator = ({
  type,
  css,
  ...rest
}: StepTypeIndicatorProps) => {
  const { label, ...props } = stepProps[type];
  return (
    <Flex
      css={{
        gap: 8,
        px: 8,
        ...css,
      }}
      {...rest}
    >
      <AlertIcon shape='rounded-square' {...props} />
      <Text size='sm'>{label}</Text>
    </Flex>
  );
};
