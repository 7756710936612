/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var RecentContactSequenceStatusDto;
(function (RecentContactSequenceStatusDto) {
    let status;
    (function (status) {
        status["IN_PROGRESS"] = "In Progress";
        status["PAUSED"] = "Paused";
        status["FINISHED"] = "Finished";
    })(status = RecentContactSequenceStatusDto.status || (RecentContactSequenceStatusDto.status = {}));
})(RecentContactSequenceStatusDto || (RecentContactSequenceStatusDto = {}));
