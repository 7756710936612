/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactSequence;
(function (ContactSequence) {
    let finishedReason;
    (function (finishedReason) {
        finishedReason["ARCHIVED"] = "Archived";
        finishedReason["SENT_EMAIL"] = "Sent Email";
        finishedReason["CONTACT_REPLIED"] = "Contact Replied";
        finishedReason["ERROR"] = "Error";
        finishedReason["NO_EMAIL_FOUND"] = "No Email Found";
        finishedReason["STEP_REMOVED"] = "Step Removed";
        finishedReason["MARKED_DONE"] = "Marked Done";
        finishedReason["SEQUENCE_COMPLETED"] = "Sequence Completed";
    })(finishedReason = ContactSequence.finishedReason || (ContactSequence.finishedReason = {}));
    let deactivatedReason;
    (function (deactivatedReason) {
        deactivatedReason["ARCHIVED"] = "Archived";
        deactivatedReason["PAUSED"] = "Paused";
        deactivatedReason["MISSING_VARIABLE"] = "Missing Variable";
        deactivatedReason["CONTACT_REPLIED"] = "Contact Replied";
        deactivatedReason["ERROR"] = "Error";
        deactivatedReason["STEP_REMOVED"] = "Step Removed";
        deactivatedReason["MARKED_DONE"] = "Marked Done";
        deactivatedReason["NO_REPLY"] = "No Reply";
    })(deactivatedReason = ContactSequence.deactivatedReason || (ContactSequence.deactivatedReason = {}));
    let status;
    (function (status) {
        status["SOURCED"] = "Sourced";
        status["SCHEDULED"] = "Scheduled";
        status["MESSAGED"] = "Messaged";
        status["OPENED"] = "Opened";
        status["BOUNCED"] = "Bounced";
        status["REPLIED"] = "Replied";
        status["NO_REPLY"] = "No Reply";
        status["NO_EMAIL_FOUND"] = "No Email Found";
        status["DO_NOT_CONTACT"] = "Do Not Contact";
    })(status = ContactSequence.status || (ContactSequence.status = {}));
})(ContactSequence || (ContactSequence = {}));
