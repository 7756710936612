import { MobileSidebar, Sidebar } from '@betterleap/shared';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@betterleap/ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { SidebarContext } from '../../context/SidebarContext';
import sidebarItems from '../../constants/sidebarItems';
import { apiActions } from '../../store/api/actions';
import TOKEN from '../../lib/token';

const withSidebar =
  (WrappedComponent: React.ComponentType, noSpacing?: boolean) =>
  (props: Record<string, unknown>) => {
    const { collapsed, setCollapsed } = useContext(SidebarContext);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
      TOKEN.remove();
      dispatch(
        apiActions.fetch('me', undefined, undefined, { navigate, location }),
      );
      dispatch(apiActions.remove());
    };

    return (
      <>
        <Sidebar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sidebarRoutes={sidebarItems}
          handleLogout={handleLogout}
          handleNavigate={navigate}
          currentLocation={location}
        />
        <MobileSidebar
          sidebarRoutes={sidebarItems}
          handleLogout={handleLogout}
          handleNavigate={navigate}
        />
        <Box
          css={{
            position: 'relative',
            width: '100%',
            minHeight: '100vh',
            background: '#F9FAFB',
            mediaMd: {
              transition: 'width .4s, margin-left .4s',
              marginLeft: collapsed ? 80 : 0,
              width: collapsed ? 'calc(100% - 80px)' : '100%',
            },
            mediaLg: {
              marginLeft: collapsed ? 80 : 255,
              width: collapsed ? 'calc(100% - 80px)' : 'calc(100% - 255px)',
            },
          }}
        >
          <Box css={{ p: noSpacing ? 0 : 32 }}>
            <WrappedComponent {...props} />
          </Box>
        </Box>
      </>
    );
  };
export default withSidebar;
