export class AiDatabaseAgentFeeedbackApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Provide feedback on agent response
     * @returns any
     * @throws ApiError
     */
    saveAgentFeedback({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai-database-agent-feedback',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
