/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var UpdateOrganizationDto;
(function (UpdateOrganizationDto) {
    /**
     * Whether the organization is paid or not.
     */
    let tier;
    (function (tier) {
        tier["PAYWALL"] = "Paywall";
        tier["FREE_TRIAL_REQUESTED"] = "Free Trial Requested";
        tier["FREE_TRIAL"] = "Free Trial";
        tier["PAID"] = "Paid";
    })(tier = UpdateOrganizationDto.tier || (UpdateOrganizationDto.tier = {}));
    let atsSource;
    (function (atsSource) {
        atsSource["LEVER"] = "Lever";
        atsSource["GREENHOUSE"] = "Greenhouse";
        atsSource["ASHBY"] = "Ashby";
        atsSource["RECRUITERFLOW"] = "Recruiterflow";
        atsSource["CLOCKWORK"] = "Clockwork";
        atsSource["BULLHORN"] = "Bullhorn";
        atsSource["ORACLE_TALEO"] = "Oracle Taleo";
    })(atsSource = UpdateOrganizationDto.atsSource || (UpdateOrganizationDto.atsSource = {}));
    let crmSource;
    (function (crmSource) {
        crmSource["SALESFORCE"] = "Salesforce";
    })(crmSource = UpdateOrganizationDto.crmSource || (UpdateOrganizationDto.crmSource = {}));
    let orgType;
    (function (orgType) {
        orgType["ADMIN"] = "Admin";
        orgType["CLIENT_USER"] = "Client User";
        orgType["VC"] = "VC";
        orgType["COMPANY"] = "Company";
        orgType["RECRUITER"] = "Recruiter";
    })(orgType = UpdateOrganizationDto.orgType || (UpdateOrganizationDto.orgType = {}));
})(UpdateOrganizationDto || (UpdateOrganizationDto = {}));
