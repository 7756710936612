import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import {
  useModal as useAriaModal,
  useOverlay,
  usePreventScroll,
} from '@react-aria/overlays';
import { AriaDialogProps } from '@react-types/dialog';
import { keyframes } from '@mpalmerlee/stitches-react';
import {
  forwardRef,
  PropsWithChildren,
  ReactElement,
  RefObject,
  useRef,
} from 'react';
import { mergeProps } from '@react-aria/utils';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { styled } from '../../stitches.config';
import { ModalContext, useModalContext } from './ModalProvider';

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

export const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const StyledOverlay = styled('div', {
  position: 'fixed',
  zIndex: 100,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.4)',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 200ms`,
  },
});

const StyledContent = styled('div', {
  backgroundColor: 'white',
  borderRadius: '$5xl',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'grid',
  gridTemplateAreas: `
  "header"
  "body"
  "footer"
  `,
  gridTemplateRows: 'max-content 1fr max-content',
  width: '90vw',
  maxWidth: '450px',
  maxHeight: '90vh',
  padding: 16,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 700ms cubic-bezier(0.16, 1, 0.3, 1) forwards`,
  },
  '&:focus': { outline: 'none' },
  variants: {
    size: {
      medium: {
        maxWidth: '550px',
      },
      large: {
        maxWidth: '650px',
      },
      xl: {
        maxWidth: '750px',
      },
      xxl: {
        maxWidth: '1024px',
      },
      full: {
        maxWidth: '90vw',
      },
    },
  },
});

export interface PromiseModal<P = Record<string, unknown>, D = unknown> {
  (props: PropsWithChildren<P & PromiseModalProps<D>>): ReactElement;
}

export interface ModalProps extends React.ComponentProps<typeof StyledContent> {
  as?: React.ElementType;
  isDismissable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: (data: any) => void;
  onReject?: (err: any) => void;
  onDismiss?: () => void;
  autoFocus?: boolean;
}

export interface PromiseModalProps<D> extends ModalProps {
  onSubmit: (data?: D) => void;
  onReject: (err?: any) => void;
  onDismiss: () => void;
}

export const Modal = forwardRef<HTMLElement, ModalProps>(
  ({ children, isDismissable = true, autoFocus = true, ...props }, ref) => {
    const { onDismiss, ...context } = useModalContext();
    const internalRef = useRef<HTMLElement>();
    const combinedRef = useMergeRefs(internalRef, ref);

    const { overlayProps, underlayProps } = useOverlay(
      {
        ...props,
        isOpen: true,
        isDismissable,
        shouldCloseOnInteractOutside: (element) => {
          const isDialog =
            element?.closest('[role="dialog"]') ||
            element?.closest('[data-radix-popper-content-wrapper]');

          // clicks in other dialogs like menus don't close modals
          if (isDialog) {
            return false;
          }

          return true;
        },
        onClose: onDismiss,
      },
      internalRef as RefObject<HTMLElement>,
    );

    usePreventScroll();
    const { modalProps } = useAriaModal();
    const { dialogProps, titleProps } = useDialog(
      props as AriaDialogProps,
      internalRef as RefObject<HTMLElement>,
    );

    return (
      <ModalContext.Provider
        value={{
          onDismiss,
          isDismissable,
          titleProps,
          ...context,
        }}
      >
        <StyledOverlay {...underlayProps}>
          <FocusScope contain restoreFocus autoFocus={autoFocus}>
            <StyledContent
              {...mergeProps(props, overlayProps, dialogProps, modalProps)}
              ref={combinedRef}
            >
              {children}
            </StyledContent>
          </FocusScope>
        </StyledOverlay>
      </ModalContext.Provider>
    );
  },
);

Modal.displayName = 'Modal';
