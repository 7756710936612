export class SequenceApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create a sequence for a user.
     * @returns any
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sequences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get an organizations's sequences.
     * @returns Sequence
     * @throws ApiError
     */
    findAllForUser({ pageSize, page, projectId, name, orderProperty, orderDirection, relations, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences',
            query: {
                'pageSize': pageSize,
                'page': page,
                'projectId': projectId,
                'name': name,
                'orderProperty': orderProperty,
                'orderDirection': orderDirection,
                'relations': relations,
            },
        });
    }
    /**
     * Get all recently used sequences for an organization.
     * @returns Sequence
     * @throws ApiError
     */
    findRecentForUser({ pageSize, page, projectId, name, orderProperty, orderDirection, relations, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences/recent',
            query: {
                'pageSize': pageSize,
                'page': page,
                'projectId': projectId,
                'name': name,
                'orderProperty': orderProperty,
                'orderDirection': orderDirection,
                'relations': relations,
            },
        });
    }
    /**
     * Get stats for all sequences for an organization.
     * @returns SequenceStat
     * @throws ApiError
     */
    calculateStats({ search, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences/stats',
            query: {
                'pageSize': pageSize,
                'page': page,
                'search': search,
            },
        });
    }
    /**
     * Count of active sequences in an org.
     * @returns number
     * @throws ApiError
     */
    getActiveSequenceCount({ senderId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences/active-sequence-count',
            query: {
                'senderId': senderId,
            },
        });
    }
    /**
     * Get sequence metrics
     * @returns SequenceMetricsDto
     * @throws ApiError
     */
    getSequenceMetrics({ sequenceId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences/{sequenceId}/stats',
            path: {
                'sequenceId': sequenceId,
            },
        });
    }
    /**
     * Get an organization's sequence.
     * @returns Sequence
     * @throws ApiError
     */
    findOneForUser({ sequenceId, relations, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sequences/{sequenceId}',
            path: {
                'sequenceId': sequenceId,
            },
            query: {
                'relations': relations,
            },
        });
    }
    /**
     * Update a sequence for an organization.
     * @returns Sequence
     * @throws ApiError
     */
    update({ sequenceId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/sequences/{sequenceId}',
            path: {
                'sequenceId': sequenceId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a sequence for an organization.
     * @returns Sequence
     * @throws ApiError
     */
    remove({ sequenceId, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/sequences/{sequenceId}',
            path: {
                'sequenceId': sequenceId,
            },
        });
    }
}
