import { useCallback, useRef, useState } from 'react';

export function useControlledState<T>(
  value?: T,
  defaultValue?: T,
  onChange?: (value: T) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [T | undefined, (value: T, ...args: any[]) => void] {
  const [stateValue, setStateValue] = useState(value || defaultValue);
  const isControlled = value !== undefined;
  const stateRef = useRef(stateValue);

  const setValue = useCallback(
    (newValue: T) => {
      const onChangeCaller = (val: T) => {
        if (onChange) {
          if (!Object.is(stateRef.current, val)) {
            onChange(val);
          }
        }

        if (!isControlled) {
          stateRef.current = val;
        }
      };

      if (!isControlled) {
        setStateValue(newValue);
      }

      onChangeCaller(newValue);
    },
    [isControlled, onChange],
  );

  // If a controlled component's value prop changes, we need to update stateRef
  if (isControlled) {
    stateRef.current = value;
  } else {
    // eslint-disable-next-line no-param-reassign
    value = stateValue;
  }

  return [value, setValue];
}
