/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Flex, FlexProps } from '@betterleap/ui';
import classNames from 'classnames';
import { merge } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import {
  Column,
  PluginHook,
  Row,
  SortingRule,
  TableInstance,
  useFlexLayout,
  usePagination,
  UseRowSelectInstanceProps,
  useSortBy,
  useTable,
} from 'react-table';
import { paginationConfig } from '../../../constants';
import { renderSingleCell } from '../../../helper/renderSingleCell';
import Pagination from '../Pagination/Pagination';
import Spinner from '../Spinner/Spinner';
import styles from './InteractiveTable.module.scss';
import { SortIndicator } from './SortIndicator';
import useRowSelection from './useRowSelection';
import { useSelectRows } from './useSelectRows';

type RowsSelectedEvent = {
  selectedFlatRows: Array<Row<Record<string, unknown>>>;
};

export type PartialTableInstance = Partial<
  TableInstance<Record<string, unknown>> &
    UseRowSelectInstanceProps<Record<string, unknown>>
>;

export interface TableColumn {
  Header: ((props: any) => React.ReactNode) | string;
  accessor: ((props: any) => React.ReactNode) | any;
  slot?: string;
}

const cn = classNames.bind(styles);
interface TableProps {
  columns: TableColumn[];
  tableData: unknown[];
  dataCy?: string;
  loading?: boolean;
  count?: number;
  tableBanner?: ReactNode;
  emptyState?: ReactNode;
  onRowClick?: (commandPressed: boolean, data: unknown) => void;
  onPageChange?: (value: number) => void;
  onRowSelect?: (params: RowsSelectedEvent) => void;
  onSort?: (params: SortingRule<object>) => void;
  disablePagination?: boolean;
  paginationKey?: string;
  paginationBoxStyles?: string;
  onLoad?: (table: PartialTableInstance) => void;
  selectedRows?: Row<Record<string, unknown>>[] | 'all';
  plugins?: Array<PluginHook<Record<string, unknown>>>;
  pageSize?: number;
  css?: FlexProps['css'];
  autoResetSelectedRows?: boolean;
  overlayloader?: ReactNode;
  className?: string;
}

export const InteractiveTable = ({
  columns,
  dataCy,
  tableData,
  loading,
  count,
  onRowClick,
  onRowSelect,
  onPageChange,
  onSort,
  onLoad,
  emptyState,
  tableBanner,
  paginationKey,
  paginationBoxStyles,
  disablePagination,
  pageSize,
  css,
  selectedRows,
  autoResetSelectedRows,
  overlayloader,
  className,
}: TableProps) => {
  const { onRowsSelected, selectRowIds, unselectRowIds } = useSelectRows({
    selectedRows,
    setSelectedRows: (selectedFlatRows) => onRowSelect?.({ selectedFlatRows }),
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleAllRowsSelected,
    toggleRowSelected,
    gotoPage,
    state: { sortBy },
  } = useTable(
    {
      columns: columns as Column[],
      data: tableData as object[],
      disableMultiSort: true,
      manualSortBy: true,
      autoResetSelectedRows: autoResetSelectedRows ?? false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
      manualPagination: true,
      onRowSelect: onRowsSelected,
      initialState: {
        pageSize: pageSize ?? paginationConfig.pageSize,
        selectedRowIds:
          selectedRows !== 'all'
            ? selectedRows
                ?.map((el) => el.original.id as string)
                ?.reduce((a, v) => ({ ...a, [v]: true }), {})
            : Array.from(Array(pageSize).keys()).reduce(
                (a, v) => ({ ...a, [v]: true }),
                {},
              ),
      },
    },
    useFlexLayout,
    useSortBy,
    usePagination,
    ...(onRowSelect ? useRowSelection : []),
  );

  useEffect(() => {
    selectRowIds?.forEach((id) => {
      toggleRowSelected(id, true);
    });
  }, [selectRowIds]);

  useEffect(() => {
    unselectRowIds?.forEach((id) => {
      toggleRowSelected(id, false);
    });
  }, [unselectRowIds]);

  const [commandPressed, setCommandPressed] = useState(false);

  const checkPressedKeysDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Meta') {
      setCommandPressed(true);
    }
  }, []);

  const checkPressedKeysUp = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Meta') {
      setCommandPressed(false);
    }
  }, []);

  const sortRule = sortBy?.[0];

  useEffect(() => {
    onSort?.(sortRule);
  }, [sortRule]);

  useEffect(() => {
    onLoad?.({ toggleAllRowsSelected });

    document.addEventListener('keydown', checkPressedKeysDown, false);
    document.addEventListener('keyup', checkPressedKeysUp, false);

    return () => {
      document.removeEventListener('keydown', checkPressedKeysDown, false);
      document.removeEventListener('keyup', checkPressedKeysUp, false);
    };
  }, []);

  const { role } = getTableProps();

  return (
    <Flex
      css={merge(
        {
          pt: 16,
        },
        css,
      )}
      className={classNames('flex flex-col', className)}
    >
      {loading && !overlayloader ? (
        <div className='flex justify-center mt-5'>
          <Spinner variant='blue' />
        </div>
      ) : (
        <div className={`${styles.table_wrapper}`}>
          <div
            role={role}
            className={`rounded-lg interactive-table ${styles.interactive_table}`}
          >
            <div className={`header_row ${styles.header_row}`}>
              {headerGroups.map((headerGroup: any) => (
                <div
                  {...headerGroup.getHeaderGroupProps()}
                  key={headerGroup.id}
                >
                  {headerGroup.headers.map((column: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { title, ...restSortByProps } =
                      column.getSortByToggleProps();
                    return (
                      <Box
                        {...column.getHeaderProps(
                          column.sortable ? restSortByProps : undefined,
                        )}
                        css={column.headerCss}
                        className={`text-xs font-normal m-0 ${styles.header_cell}`}
                        key={column.id}
                      >
                        <div className='flex items-center justify-between flex-row whitespace-nowrap gap-2'>
                          <div>{column.render('Header')}</div>
                          <SortIndicator column={column} />
                        </div>
                      </Box>
                    );
                  })}
                </div>
              ))}
            </div>
            {tableBanner}
            <div {...getTableBodyProps()} className={`${styles.table_body}`}>
              {loading && overlayloader}
              {!page?.length && !!emptyState
                ? emptyState
                : (page ?? []).map((row: any, index: number) => {
                    prepareRow(row);
                    return (
                      <div
                        {...row.getRowProps()}
                        key={row?.original?.id ?? row.id}
                        data-cy={dataCy}
                        onClick={() => {
                          if (onRowClick)
                            onRowClick(commandPressed, tableData[index]);
                        }}
                        className={cn(`${styles.table_row}`, {
                          [styles.dark_row]: index % 2 !== 0,
                          'cursor-pointer': !!onRowClick,
                        })}
                      >
                        {row.cells.map((cell: any) => (
                          <Box
                            {...cell.column.getCellAttrs?.(row?.original ?? {})}
                            {...cell.getCellProps()}
                            key={cell.id}
                            css={cell.column.css}
                            className={styles.table_cell}
                          >
                            {cell.column?.useCell
                              ? cell.render('Cell')
                              : renderSingleCell(
                                  cell.column.slot,
                                  cell.value,
                                  cell.column.onclick,
                                  cell.column.buttonText,
                                  cell.column.options,
                                  cell.column.hide,
                                )}
                          </Box>
                        ))}
                      </div>
                    );
                  })}
            </div>
          </div>
        </div>
      )}
      {((!disablePagination &&
        count &&
        Math.ceil(count / (pageSize ?? paginationConfig.pageSize))) ||
        0) > 1 && (
        <div className='w-full'>
          <Pagination
            pageSize={pageSize ?? paginationConfig.pageSize}
            hide={loading}
            numberOfPages={
              (count &&
                Math.ceil(count / (pageSize ?? paginationConfig.pageSize))) ||
              0
            }
            handlePages={(value: any) => {
              gotoPage(value);
              onPageChange?.(value);
            }}
            paginationKey={paginationKey}
            paginationBoxStyles={paginationBoxStyles}
          />
        </div>
      )}
    </Flex>
  );
};
