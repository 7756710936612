/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Application;
(function (Application) {
    let status;
    (function (status) {
        status["SUBMITTED"] = "submitted";
        status["APPROVED"] = "approved";
        status["DECLINED"] = "declined";
    })(status = Application.status || (Application.status = {}));
})(Application || (Application = {}));
