import {
  AnalyticsBrowser,
  EventProperties,
  UserTraits,
} from '@segment/analytics-next';

class SegmentAnalyticsHelper {
  analytics: AnalyticsBrowser;

  live = false;

  constructor() {
    this.analytics = new AnalyticsBrowser();
  }

  public load(writeKey: string | undefined) {
    if (writeKey && !this.live) {
      this.analytics.load({ writeKey });
      this.live = true;
    }
  }

  public track(
    enableTrack: boolean,
    eventName: string,
    properties?: EventProperties,
  ) {
    if (this.live && enableTrack) {
      return this.analytics.track(eventName, properties);
    }
    return null;
  }

  public identify(enableTrack: boolean, id?: string, traits?: UserTraits) {
    if (this.live && enableTrack) {
      return this.analytics.identify(id, traits);
    }
    return null;
  }

  public alias(enableTrack: boolean, to: string, from?: string) {
    if (this.live && enableTrack) {
      return this.analytics.alias(to, from);
    }
    return null;
  }

  get instance() {
    if (!this.live) return null;
    return this.analytics;
  }
}

export const SegmentAnalytics = new SegmentAnalyticsHelper();
