/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var User;
(function (User) {
    let role;
    (function (role) {
        role["NONE"] = "None";
        role["RECRUITER"] = "Recruiter";
        role["ADMIN"] = "Admin";
        role["COMPANY_ADMIN"] = "Company Admin";
    })(role = User.role || (User.role = {}));
})(User || (User = {}));
