/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactAtsCandidate;
(function (ContactAtsCandidate) {
    let experienceSyncStatus;
    (function (experienceSyncStatus) {
        experienceSyncStatus["COMPLETE"] = "complete";
        experienceSyncStatus["SKIPPED"] = "skipped";
        experienceSyncStatus["ERROR"] = "error";
    })(experienceSyncStatus = ContactAtsCandidate.experienceSyncStatus || (ContactAtsCandidate.experienceSyncStatus = {}));
    let educationSyncStatus;
    (function (educationSyncStatus) {
        educationSyncStatus["COMPLETE"] = "complete";
        educationSyncStatus["SKIPPED"] = "skipped";
        educationSyncStatus["ERROR"] = "error";
    })(educationSyncStatus = ContactAtsCandidate.educationSyncStatus || (ContactAtsCandidate.educationSyncStatus = {}));
})(ContactAtsCandidate || (ContactAtsCandidate = {}));
