/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Sender;
(function (Sender) {
    let provider;
    (function (provider) {
        provider["GOOGLE"] = "google";
        provider["MICROSOFT"] = "microsoft";
    })(provider = Sender.provider || (Sender.provider = {}));
    let status;
    (function (status) {
        status["ACTIVE"] = "Active";
        status["ERROR"] = "Error";
        status["PAUSED_TEMPORARILY"] = "Paused Temporarily";
    })(status = Sender.status || (Sender.status = {}));
})(Sender || (Sender = {}));
