/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Types } from '@betterleap/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiActions } from '../store/api/actions';
import { selectApiState } from '../store/api/selectors';

const useFetch: Types.UseFetch = <T>(endpoint: Types.Endpoint) => {
  const dispatch = useDispatch();
  const apiState = useSelector(selectApiState);
  const navigate = useNavigate();
  const location = useLocation();
  const doFetch = (data?: Types.EndpointPayload, meta?: Types.IApiMeta) =>
    dispatch(apiActions.fetch(endpoint, data, meta, { navigate, location }));
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const response = apiState[_.camelCase(endpoint)] as unknown as ApiReducer<T>;
  const doRemove = () => dispatch(apiActions.remove(endpoint));

  return [response, doFetch, doRemove];
};

export default useFetch;
