import 'firebase/compat/storage';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import { Types } from '..';
import { randomString } from '../functions/string.functions';
import { getUser } from '../helper';
import { firebase } from '../helper/firebase';

export declare type UseUpload = (
  useFetch: Types.UseFetch,
  refPath: string,
  cacheKey: string,
) => [
  StateOptions,
  (files: File[]) => Promise<StateOptions>,
  () => void,
  Types.ApiReducer<Types.UserFile[]>,
];

declare type StateOptions = {
  loading: boolean;
  data: Record<string, unknown>[] | null;
};

const useUpload: UseUpload = (
  useFetch: Types.UseFetch,
  refPath: string,
  cacheKey: string,
) => {
  const [createdFiles, createFiles, clearCache] =
    useFetch<Types.UserFile[]>(cacheKey);
  const [uploadData, setUploadData] = useState<StateOptions>({
    loading: false,
    data: null,
  });
  const resetUploadData = () => {
    clearCache();
    setUploadData({ loading: false, data: null });
  };
  const upload = async (files: File[]): Promise<StateOptions> => {
    setUploadData({ ...uploadData, loading: true });
    const storage = getStorage(firebase.app());
    const currentUser = await getUser();

    const userUid = currentUser?.uid ?? '';
    const parameterizedPath = refPath
      ? `${refPath.replace('{userUid}', userUid)}/`
      : '';

    const uploads = files.map(async (file) => {
      const path = `${parameterizedPath}${randomString()}-${file.name}`;
      const storageRef = ref(storage, path);

      const fileData = await uploadBytes(storageRef, file);
      const gcsUrl = await getDownloadURL(storageRef);
      const filename = fileData.metadata.fullPath.split('/').slice(-1)[0];
      return {
        ...fileData.metadata,
        path,
        url: gcsUrl,
        name: filename,
      };
    });

    const finishedUploads = await Promise.all(uploads);

    createFiles({
      files: finishedUploads.map(({ name, url }) => ({
        name,
        gcsUrl: url,
      })),
    });

    setUploadData({
      ...uploadData,
      loading: false,
      data: finishedUploads,
    });

    return {
      ...uploadData,
      loading: false,
      data: finishedUploads,
    };
  };

  return [uploadData, upload, resetUploadData, createdFiles];
};

export default useUpload;
