import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-table';

type TableRow = Row<Record<string, unknown>>;
type RowsSelectedEvent = {
  selectedFlatRows: Array<TableRow>;
};

interface HandleSelectRowsProps {
  selectedRowsBefore: TableRow[];
  selectedRowsAfter: TableRow[];
}

interface UseSelectRowsProps {
  selectedRows: 'all' | TableRow[] | undefined;
  setSelectedRows: ((rows: TableRow[]) => void) | undefined;
}

export const useSelectRows = ({
  selectedRows,
  setSelectedRows,
}: UseSelectRowsProps) => {
  const [shiftPressed, setShiftPressed] = useState(false);
  const [lastClickedRowId, setLastClickedRowId] = useState('');
  const [selectRowIds, doSelectRowIds] = useState<string[]>([]);
  const [unselectRowIds, doUnselectRowIds] = useState<string[]>([]);

  const onShiftKeydown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      setShiftPressed(true);
    }
  }, []);
  const onShiftKeyup = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      setShiftPressed(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', onShiftKeydown, false);
    document.addEventListener('keyup', onShiftKeyup, false);
  }, []);

  const except = useCallback(
    (arr1: TableRow[], arr2: TableRow[]) =>
      arr1.filter((x) => !arr2.find((y) => y.id === x.id)),
    [],
  );
  const areEqual = useCallback(
    (arr1: string[], arr2: string[]) =>
      arr1.length === arr2.length && arr1.every((v) => arr2.includes(v)),
    [],
  );
  const getRange = useCallback(
    (start: number, length: number) =>
      Array.from({ length }, (_, i) => start + i).map((n) => `${n}`),
    [],
  );

  const onRowsSelected = ({ selectedFlatRows }: RowsSelectedEvent) => {
    if (selectedRows === 'all') {
      setSelectedRows?.(selectedFlatRows);
    } else {
      const selectedRowsBefore = selectedRows as TableRow[];
      const selectedRowsAfter = selectedFlatRows;
      handleSelectRows({ selectedRowsBefore, selectedRowsAfter });
      setSelectedRows?.(selectedFlatRows);
    }
  };

  function handleSelectRows({
    selectedRowsBefore,
    selectedRowsAfter,
  }: HandleSelectRowsProps) {
    const newSelectedRows = except(selectedRowsAfter, selectedRowsBefore);
    const newUnselectedRows = except(selectedRowsBefore, selectedRowsAfter);
    if (newSelectedRows.length > 0) {
      const calculateRowIds = (startIndex: number, endIndex: number) => {
        const rowIds = getRange(startIndex + 1, endIndex - startIndex - 1);
        // last row clicked may have been unselected, so reselect it
        if (!selectedRowsBefore.find((row) => row.id === lastClickedRowId)) {
          rowIds.push(lastClickedRowId);
        }
        return rowIds;
      };
      handleSelections(
        newSelectedRows,
        selectRowIds,
        calculateRowIds,
        doSelectRowIds,
        doUnselectRowIds,
      );
    } else if (newUnselectedRows.length > 0) {
      const calculateRowIds = (startIndex: number, endIndex: number) => {
        const currentSelections = selectedRows as TableRow[];
        const rowIds = getRange(startIndex, endIndex - startIndex + 1)
          // clicked row will be handled automatically
          .filter((n) => n !== newUnselectedRows[0]?.id)
          // exclude rows that are not currently selected
          .filter((n) => !!currentSelections.find((r) => r.id === n));
        return rowIds;
      };
      handleSelections(
        newUnselectedRows,
        unselectRowIds,
        calculateRowIds,
        doUnselectRowIds,
        doSelectRowIds,
      );
    }
  }

  function handleSelections(
    newSelectedRows: TableRow[],
    rowIdsToSelect: string[],
    calculateRowIds: (s: number, e: number) => string[],
    selectTheseRowIds: (ids: string[]) => void,
    unselectTheseRowIds: (ids: string[]) => void,
  ) {
    const newSelectedRowIds = newSelectedRows.map((row) => row.id);
    const newSelectedRowId = newSelectedRows[0]?.id as string;
    const areArraysEqual = areEqual(newSelectedRowIds, rowIdsToSelect);
    const wasClicked = !areArraysEqual;
    if (!wasClicked) {
      selectTheseRowIds([]);
      unselectTheseRowIds([]);
    }
    if (lastClickedRowId && shiftPressed) {
      const index1 = Number.parseInt(lastClickedRowId, 10);
      const index2 = Number.parseInt(newSelectedRowId, 10);
      const startIndex = Math.min(index1, index2);
      const endIndex = Math.max(index1, index2);

      const rowIds = calculateRowIds(startIndex, endIndex);
      if (rowIds.length) {
        // eslint-disable-next-line no-console
        console.debug('selecting rows', rowIds);
        selectTheseRowIds(rowIds);
      }
    }
    if (wasClicked) {
      // eslint-disable-next-line no-console
      console.debug('setting last clicked row', newSelectedRowId);
      setLastClickedRowId(newSelectedRowId);
    }
  }

  return { onRowsSelected, selectRowIds, unselectRowIds };
};
