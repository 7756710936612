export class AutocompleteApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Autocomplete terms
     * @returns AutocompleteResult
     * @throws ApiError
     */
    autocomplete({ entity, field, value, size, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/autocomplete',
            query: {
                'entity': entity,
                'field': field,
                'value': value,
                'size': size,
            },
        });
    }
}
