import { forwardRef } from 'react';
import { styled } from '../../stitches.config';

export const StyledProgressBarContainer = styled('div', {
  // styles here
  height: 6,
  borderRadius: 6,
  backgroundColor: '$gray-100',
});

export const StyledProgressBar = styled('div', {
  height: 6,
  borderRadius: 6,
  transition: 'width 0.3s ease-in-out',
  variants: {
    variant: {
      info: {
        backgroundColor: '$blue-600',
      },
      purple: {
        backgroundColor: '$purple-600',
      },
      success: {
        backgroundColor: '$success-base',
      },
      danger: {
        backgroundColor: '$danger-base',
      },
    },
  },
  defaultVariants: {
    variant: 'purple',
  },
});

export interface ProgressBarProps
  extends React.ComponentProps<typeof StyledProgressBar> {
  as?: React.ElementType;
  value?: number;
  min?: number;
  max?: number;
}

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(
  ({ variant, min = 0, max = 100, value = 0, ...rest }, ref) => {
    const range = max - min;
    const widthPercentage = Math.round(((value - min) * 100) / range);
    const widthValue = `${widthPercentage}%`;
    return (
      <StyledProgressBarContainer {...rest}>
        <StyledProgressBar
          ref={ref}
          role='progressbar'
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          variant={variant}
          style={{
            width: widthValue,
          }}
        />
      </StyledProgressBarContainer>
    );
  },
);

ProgressBar.displayName = 'ProgressBar';
