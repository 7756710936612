import React, {
  cloneElement,
  createContext,
  forwardRef,
  isValidElement,
  Key,
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Item,
  ItemProps,
  ListProps,
  ListState,
  Section,
  SectionProps,
  useListState,
} from 'react-stately';
import { FocusableElement, Node } from '@react-types/shared';
import innerText from 'react-innertext';
import {
  AriaListBoxOptions,
  useFilter,
  useListBox,
  useListBoxSection,
  useOption,
  useSeparator,
} from 'react-aria';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { styled } from '../../stitches.config';
import { Input, InputProps } from '../Input';
import { useListFocus } from '../../hooks/useListFocus';
import { Icon } from '../Icon';
import { Box, BoxProps } from '../Box';
import { Text, TextProps } from '../Text';
import { Divider } from '../Divider';
import { Checkbox } from '../Checkbox';

const check = `url("data:image/svg+xml,%3Csvg width='24' height='24' fill='%234ade80' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.62 6.046c-.101.051-1.916 1.836-4.89 4.807l-4.729 4.726-1.731-1.725c-1.123-1.12-1.789-1.755-1.898-1.81-.352-.176-.733-.085-1.073.255-.34.34-.431.72-.255 1.073.116.233 4.351 4.468 4.584 4.584.222.11.522.111.744 0 .236-.117 10.467-10.348 10.584-10.584.175-.353.085-.733-.255-1.073-.34-.34-.733-.432-1.081-.253' fill-rule='evenodd' /%3E%3C/svg%3E")`;

export interface ListContextProps {
  options?: Iterable<any>;
  value?: any;
  defaultValue?: any;
  multi?: boolean;
  clearable?: boolean;
  shouldFilter?: boolean;
  filterSelected?: boolean;
  onChange?: (keys: (string | number)[], selected: any[]) => void;
  setListState?: (value: ListState<any>) => void;
  onSetListState?: (value: ListState<any>) => void;
  setFilter?: (value?: string) => void;
  filter?: string;
  async?: boolean;
  handleKeyDown?: (e: KeyboardEvent<FocusableElement>) => void;
  listState?: ListState<any>;
  searchRef?: MutableRefObject<HTMLInputElement | undefined>;
}

export const ListContext = createContext<ListContextProps>({});
export const useList = () => useContext(ListContext);

export interface ListBoxProps
  extends Omit<
    React.ComponentProps<typeof StyledListBox>,
    'children' | 'onChange' | 'autoFocus'
  > {
  children: ListProps<any>['children'];
  async?: boolean;
  options?: Iterable<any>;
  value?: any;
  defaultValue?: any;
  as?: React.ElementType;
  autoFocus?: AriaListBoxOptions<any>['autoFocus'];
  multi?: boolean;
  shouldFilter?: boolean;
  filterSelected?: boolean;
  clearable?: boolean;
  emptyState?: ReactNode;
  onChange?: (keys: (string | number)[], selected: any[]) => void;
  onFocus?: (e: React.FocusEvent<Element, Element>) => void;
  onBlur?: (e: React.FocusEvent<Element, Element>) => void;
  label?: string;
}

export const StyledListBox = styled('ul', {
  padding: 0,
  margin: 0,
  listStyle: 'none',
  overflow: 'auto',
});

export const StyledOption = styled('li', {
  p: 8,
  pl: 16,
  pr: 48,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
  borderRadius: '$xl',
  fontSize: '$sm',
  disabled: {
    color: '$neutral-blue-100',
  },
  focus: {
    backgroundColor: '$neutral-blue-100',
  },
});

export const CheckboxOption = ({
  children,
  css,
  ...props
}: React.ComponentProps<typeof StyledOption> & Partial<ItemProps<any>>) => (
  <StyledOption css={{ py: 10, ...css }} {...props}>
    <Checkbox checked={props['aria-selected'] === true} /> {children}
  </StyledOption>
);

export const Option = ({
  css,
  ...props
}: React.ComponentProps<typeof StyledOption> &
  ItemProps<any> & { component?: JSX.Element | ReactNode }) => (
  <StyledOption
    css={{
      selected: {
        fill: '$success-400',
        after: {
          content: check,
          fill: 'inherit',
          display: 'flex',
          position: 'absolute',
          right: 16,
          top: 0,
          bottom: 0,
          alignItems: 'center',
        },
      },
      ...css,
    }}
    {...props}
  />
);

Option.getCollectionNode = (Item as any).getCollectionNode;

export const ListBox = forwardRef<HTMLUListElement, ListBoxProps>(
  (
    {
      css,
      multi,
      options,
      value,
      defaultValue,
      autoFocus,
      onChange,
      clearable,
      async,
      children,
      emptyState,
      filterSelected,
      ...props
    },
    ref,
  ) => {
    const {
      filter,
      setListState,
      shouldFilter,
      filterSelected: contextFilterSelected,
      value: contextValue,
      defaultValue: contextDefault,
      options: contextOptions,
      async: contextAsync,
      multi: contextMulti,
      clearable: contextClearable,
      onChange: contextOnChange,
      searchRef,
      onSetListState,
    } = useList();
    const defaultValues =
      typeof defaultValue === 'string'
        ? defaultValue === 'all'
          ? contextDefault ?? defaultValue
          : [contextDefault ?? defaultValue]
        : contextDefault ?? defaultValue;
    const values =
      typeof value === 'string'
        ? value === 'all'
          ? contextValue ?? value
          : [contextValue ?? value]
        : contextValue ?? value;
    const onSelectionChange = onChange ?? contextOnChange;
    const shouldFilterSelected = filterSelected ?? contextFilterSelected;
    const isAsync = async ?? contextAsync;
    const isMulti = multi ?? contextMulti;
    const isClearable = clearable ?? contextClearable;

    const selectionRef = useRef<Key[]>([]);
    if (values || defaultValues) {
      selectionRef.current = values ?? defaultValues;
    }

    const internalRef = useRef<HTMLUListElement>(null);
    const combinedRef = useMergeRefs(internalRef, ref);

    const { contains } = useFilter({
      sensitivity: 'base',
    });

    const filterFunction = (nodes: Iterable<Node<any>>) => {
      const topLevelFiltered = Array.from(nodes).filter((node) => {
        if (shouldFilter === false) {
          return true;
        }

        if ([...node.childNodes].length) {
          return true;
        }

        if (shouldFilterSelected && selectionRef.current.includes(node.key)) {
          return false;
        }

        if (isAsync) {
          return true;
        }

        return contains(
          node.textValue || innerText(node.rendered),
          filter ?? '',
        );
      });

      return topLevelFiltered.map((node) => {
        const newNode = { ...node };
        newNode.childNodes = [...newNode.childNodes].length
          ? filterFunction(newNode.childNodes)
          : [];
        return newNode;
      });
    };

    // Create state based on the incoming props
    const state = useListState({
      selectionMode: isMulti ? 'multiple' : 'single',
      selectionBehavior: isMulti ? 'toggle' : 'replace',
      selectedKeys: values,
      defaultSelectedKeys: defaultValues,
      disallowEmptySelection: !isClearable,
      items: contextOptions ?? options,
      children,
      filter: filterFunction,
      onSelectionChange: (change) => {
        const keys = Array.from(change);

        const newValues = [...state.collection]
          .reduce<Node<any>[]>((acc, curr) => {
            if (curr.type === 'section') {
              acc = acc.concat(Array.from(curr.childNodes));
            } else {
              acc = acc.concat([curr]);
            }

            return acc;
          }, [])
          .filter((item) => keys.includes(item.key))
          .map((item) => ({ ...item.value, _section: item.parentKey }));

        selectionRef.current = keys;
        onSelectionChange?.(keys as (string | number)[], newValues);
      },
      ...props,
    });

    useEffect(() => {
      onSetListState?.(state);
      setListState?.(state);
    }, [filter, options, contextOptions]);

    const { handleMouseLeave } = useListFocus(state);

    const { listBoxProps } = useListBox(
      {
        autoFocus,
        shouldUseVirtualFocus: true,
        shouldFocusOnHover: true,
        shouldFocusWrap: true,
        ...props,
      },
      state,
      internalRef,
    );

    const { onKeyDown } = listBoxProps;

    const collection = [...state.collection];

    return (
      <StyledListBox
        {...listBoxProps}
        tabIndex={0}
        onKeyDown={(e: KeyboardEvent<FocusableElement>) => {
          onKeyDown?.(e);

          if (e.key === 'Enter') {
            e.preventDefault();
            state.selectionManager.select(state.selectionManager.focusedKey);
          }
        }}
        onMouseLeave={handleMouseLeave}
        ref={combinedRef}
        css={{
          outline: 'none',
          ...css,
        }}
        {...props}
      >
        {!collection.length && emptyState
          ? emptyState
          : collection.map((item) =>
              item.type === 'section' ? (
                <InternalListSection
                  key={item.key}
                  section={item}
                  state={state}
                  emptyState={emptyState}
                />
              ) : (
                <InternalOption
                  onClick={() => {
                    if (searchRef?.current !== document.activeElement) {
                      internalRef?.current?.focus();
                    }
                  }}
                  key={item.key}
                  item={item}
                  state={state}
                />
              ),
            )}
      </StyledListBox>
    );
  },
);

export interface ListProviderProps {
  children?: React.ReactNode;
  options?: Iterable<any>;
  value?: any;
  defaultValue?: any;
  multi?: boolean;
  async?: boolean;
  clearable?: boolean;
  shouldFilter?: boolean;
  filterSelected?: boolean;
  onChange?: (keys: (string | number)[], selected: any[]) => void;
  onSetListState?: (state: ListState<any>) => void;
}

export const List = ({ children, ...rest }: ListProviderProps) => {
  const [filter, setFilter] = useState<string>();
  const [listState, setListState] = useState<ListState<any>>();
  const searchRef = useRef<HTMLInputElement>();
  const { handleKeyDown } = useListFocus(listState);

  return (
    <ListContext.Provider
      value={{
        filter,
        setFilter,
        listState,
        setListState,
        handleKeyDown,
        searchRef,
        ...rest,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export const ListInput = forwardRef<HTMLInputElement, InputProps>(
  ({ onKeyDown, onChange, ...props }, ref) => {
    const { filter, setFilter, handleKeyDown, searchRef } = useList();
    const combinedRef = useMergeRefs(searchRef, ref);
    return (
      <Input
        ref={combinedRef}
        aria-label='search list'
        css={{ borderRadius: '$xl' }}
        leftIcon={<Icon name='search' />}
        placeholder='Search'
        value={filter}
        onKeyDown={(e) => {
          onKeyDown?.(e);
          handleKeyDown?.(e);
        }}
        onChange={(value: string) => {
          onChange?.(value);
          setFilter?.(value);
        }}
        {...props}
        type='text'
      />
    );
  },
);

ListInput.displayName = 'ListInput';

export const ListTitle = forwardRef<HTMLParagraphElement, TextProps>(
  ({ css, ...props }, ref) => (
    <Text
      ref={ref}
      css={{
        px: 24,
        paddingTop: 16,
        fontSize: '$sm',
        fontWeight: '$bold',
        paddingBottom: 8,
        color: '$text-secondary',
        ...css,
      }}
      {...props}
    />
  ),
);

ListTitle.displayName = 'ListTitle';

/** TODO: Finish section functionality  */
export function InternalListSection({
  section,
  state,
  emptyState,
}: {
  state: ListState<any>;
  section: Node<any>;
  emptyState?: ReactNode;
}) {
  const { itemProps, headingProps, groupProps } = useListBoxSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  });

  const { separatorProps } = useSeparator({
    elementType: 'li',
  });

  const collection = [...section.childNodes];

  // If the section is not the first, add a separator element.
  // The heading is rendered inside an <li> element, which contains
  // a <ul> with the child items.
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <Divider
          css={{ backgroundColor: '$neutral-blue-300' }}
          {...separatorProps}
        />
      )}
      <li {...itemProps}>
        {isValidElement(section.rendered)
          ? cloneElement(section.rendered, {
              ...section.rendered.props,
              ...headingProps,
            })
          : section.rendered && (
              <ListTitle {...headingProps}>{section.rendered}</ListTitle>
            )}
        {!collection.length && emptyState ? (
          emptyState
        ) : (
          <ListSection {...section.props} {...groupProps}>
            {collection.map((node) => (
              <InternalOption key={node.key} item={node} state={state} />
            ))}
          </ListSection>
        )}
      </li>
    </>
  );
}

interface ListSectionProps {
  title?: ReactNode;
  children?: React.ReactNode | ((option: any) => JSX.Element);
}

export const ListSection = ({
  items,
  css,
  children,
  ...rest
}: Omit<BoxProps, 'children'> &
  Omit<SectionProps<any>, 'children'> &
  ListSectionProps) => (
  <Box
    css={{
      padding: 8,
      listStyle: 'none',
      ...css,
    }}
    as='ul'
    {...rest}
  >
    {typeof children === 'function'
      ? Array.from(items ?? []).map((item) =>
          (children as (option: any) => JSX.Element)(item),
        )
      : children}
  </Box>
);

ListSection.getCollectionNode = (Section as any).getCollectionNode;

export const InternalOption = ({
  item,
  state,
  onClick,
}: {
  item: Node<any>;
  state: ListState<any>;
  onClick?: () => void;
}) => {
  // const child = Children.only(children);
  // Get props for the option element
  const ref = useRef<FocusableElement>(null);
  const { optionProps, isSelected, isDisabled, isFocused } = useOption(
    {
      key: item.key,
      shouldSelectOnPressUp: true,
    },
    state,
    ref,
  );

  const {
    onClick: optionOnClick,
    onPointerDown,
    ...restOptionProps
  } = optionProps;

  const handlePointerDown = (e: React.PointerEvent<HTMLLIElement>) => {
    /**
     * The Option element stops propagation of the pointerdown event which breaks menus.
     * So we artificially repropagate it here.
     * */
    const newEvent = new PointerEvent('pointerdown', e.nativeEvent);
    (e.target as HTMLLIElement)?.parentElement?.dispatchEvent(newEvent);
    item.props?.onPointerDown?.(e);
    onPointerDown?.(e);
  };

  if (isValidElement(item.props?.component)) {
    return cloneElement(item.props?.component, {
      ...item.props,
      ...restOptionProps,
      'data-focus': isFocused,
      'aria-selected': isSelected,
      disabled: isDisabled,
      onClick: (e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
        optionOnClick?.(e);
        onClick?.();
      },
      onPointerDown: handlePointerDown,
      children: item.rendered,
    });
  }

  return (
    <Option
      {...item.props}
      {...restOptionProps}
      data-focus={isFocused}
      aria-selected={isSelected}
      disabled={isDisabled}
      onClick={(e) => {
        optionOnClick?.(e);
        onClick?.();
      }}
      onPointerDown={handlePointerDown}
    >
      {item.rendered}
    </Option>
  );
};

ListBox.displayName = 'ListBox';

export type { ListState } from 'react-stately';
