import * as ToastPrimitive from '@radix-ui/react-toast';
import { styled } from '../../stitches.config';

const StyledTitle = styled(ToastPrimitive.Title, {
  gridArea: 'title',
  marginBottom: 0,
  fontWeight: '$medium',
  color: '$text',
  fontSize: '$sm',
});

// Exports
export const ToastTitle = StyledTitle;
