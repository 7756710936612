import { Flex, FlexProps } from '../Flex';

export const LeftInputContext = ({ css, children, ...rest }: FlexProps) => (
  <Flex
    css={{
      backgroundColor: '$background-muted',
      paddingLeft: 18,
      paddingRight: 16,
      height: '100%',
      marginLeft: -8,
      fontSize: '$sm',
      color: '$text-light',
      borderRight: '1px solid $border',
      ...css,
    }}
    aria-hidden
    {...rest}
  >
    {children}
  </Flex>
);
