/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Voucher;
(function (Voucher) {
    let status;
    (function (status) {
        status["ACTIVE"] = "active";
        status["DISABLED"] = "disabled";
    })(status = Voucher.status || (Voucher.status = {}));
})(Voucher || (Voucher = {}));
