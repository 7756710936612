/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateFeedbackDto;
(function (CreateFeedbackDto) {
    let type;
    (function (type) {
        type["SOURCING_RESULT"] = "sourcing result";
    })(type = CreateFeedbackDto.type || (CreateFeedbackDto.type = {}));
})(CreateFeedbackDto || (CreateFeedbackDto = {}));
