import { Location, NavigateFunction } from 'react-router-dom';
import { ActionsUnion, createAction, IAction } from '../action';

enum APP {
  INIT = 'APP_INIT',
}

const appActions = {
  init: ({
    navigate,
    location,
  }: {
    navigate: NavigateFunction;
    location: Location;
  }): IAction<APP> =>
    createAction(APP.INIT, undefined, undefined, { navigate, location }),
};

export type AppAction = ActionsUnion<typeof appActions>;
export { APP, appActions };
