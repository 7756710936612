/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Project;
(function (Project) {
    let contactEmailEnrichmentMode;
    (function (contactEmailEnrichmentMode) {
        contactEmailEnrichmentMode["PERSONAL"] = "Personal";
        contactEmailEnrichmentMode["PROFESSIONAL"] = "Professional";
    })(contactEmailEnrichmentMode = Project.contactEmailEnrichmentMode || (Project.contactEmailEnrichmentMode = {}));
})(Project || (Project = {}));
