/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateSenderDto;
(function (CreateSenderDto) {
    let provider;
    (function (provider) {
        provider["GOOGLE"] = "google";
        provider["MICROSOFT"] = "microsoft";
    })(provider = CreateSenderDto.provider || (CreateSenderDto.provider = {}));
})(CreateSenderDto || (CreateSenderDto = {}));
