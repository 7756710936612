export class OrganizationApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get logged in users organization
     * @returns Organization
     * @throws ApiError
     */
    getOrganization() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organization',
        });
    }
    /**
     * Updates the settings for the organization
     * @returns Organization
     * @throws ApiError
     */
    updateSettings({ requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/organization',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List all organizations.
     * @returns Organization
     * @throws ApiError
     */
    findAll({ pageSize, page, search, hasApiKey, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organization/all',
            query: {
                'pageSize': pageSize,
                'page': page,
                'search': search,
                'hasApiKey': hasApiKey,
            },
        });
    }
    /**
     * Find one organization.
     * @returns Organization
     * @throws ApiError
     */
    findOne({ id, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organization/findOne',
            query: {
                'id': id,
            },
        });
    }
    /**
     * @returns Organization
     * @throws ApiError
     */
    connectIntegration({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/organization/public-token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns LinkTokenResponseDto
     * @throws ApiError
     */
    createLinkToken({ integrationCategory, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organization/link-token',
            query: {
                'integrationCategory': integrationCategory,
            },
        });
    }
    /**
     * Find one organization by id.
     * @returns TeamStatsDto
     * @throws ApiError
     */
    teamStats({ startTime, endTime, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organization/team-stats',
            query: {
                'pageSize': pageSize,
                'page': page,
                'startTime': startTime,
                'endTime': endTime,
            },
        });
    }
    /**
     * Get an organizations total seat count.
     * @returns GetCountOrganizationSeatsDto
     * @throws ApiError
     */
    countOrganizationSeats() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organization/seat-count',
        });
    }
    /**
     * Find one organization by id.
     * @returns Organization
     * @throws ApiError
     */
    findOneById({ id, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/organization/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Updates an organization by Id.
     * @returns Organization
     * @throws ApiError
     */
    update({ id, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/organization/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
