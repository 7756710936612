/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ChatGatewayRefreshBroadcast;
(function (ChatGatewayRefreshBroadcast) {
    let type;
    (function (type) {
        type["CHAT_WORKSPACE_CREATED"] = "chat.workspaceCreated";
        type["CHAT_WORKSPACE_UPDATED"] = "chat.workspaceUpdated";
        type["CHAT_USER_CREATED"] = "chat.userCreated";
        type["CHAT_USER_JOINED_CHAT"] = "chat.userJoinedChat";
        type["CHAT_USER_JOINED_WORKSPACE"] = "chat.userJoinedWorkspace";
        type["CHAT_CHAT_CREATED"] = "chat.chatCreated";
        type["CHAT_MESSAGE_CREATED"] = "chat.messageCreated";
        type["CHAT_MESSAGE_CREATED_NOTIFICATION"] = "chat.messageCreatedNotification";
        type["CHAT_MESSAGE_READ"] = "chat.messageRead";
        type["CHAT_ANNOUNCEMENT_CREATED"] = "chat.announcementCreated";
    })(type = ChatGatewayRefreshBroadcast.type || (ChatGatewayRefreshBroadcast.type = {}));
})(ChatGatewayRefreshBroadcast || (ChatGatewayRefreshBroadcast = {}));
