import * as TabsPrimitive from '@radix-ui/react-tabs';
import { forwardRef } from 'react';
import { styled } from '../../stitches.config';

const StyledContent = styled(TabsPrimitive.Content, {
  flexGrow: 1,
  outline: 'none',
});

export interface TabContentProps
  extends React.ComponentProps<typeof StyledContent> {
  value: string;
}

export const TabContent = forwardRef<HTMLDivElement, TabContentProps>(
  ({ children, value, ...rest }, ref) => (
    <StyledContent ref={ref} value={value} {...rest}>
      {children}
    </StyledContent>
  ),
);

TabContent.displayName = 'TabContent';
