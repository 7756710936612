import React from 'react';
import withSidebar from 'components/HOC/withSidebar';
import UsersTemplate from '../components/templates/Users/Users.template';

const Users: React.FC = () => (
  <React.StrictMode>
    <UsersTemplate />
  </React.StrictMode>
);

export default withSidebar(Users);
