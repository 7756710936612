import React, { useEffect, useState } from 'react';
import {
  Button,
  getLoginRedirectResult,
  signupWithGoogle,
  Spinner,
  Types,
} from '@betterleap/shared';
import config from 'lib/config';
import {
  FIREBASE_USER_TO_USER,
  mapObjectPartialy,
} from '../../../helper/mapper';
import useFetch from '../../../hooks/fetch';
import Header from '../../modules/Header/Header';
import styles from './SignIn.module.scss';

const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => getLoginRedirectResult(setIsLoading, handleSignup), 1000);
  }, [getLoginRedirectResult]);

  const [signup, doFetchSignup] = useFetch('signin');

  const handleSignup = (user: Types.FirebaseAuthCredentials) => {
    doFetchSignup(
      mapObjectPartialy(user, FIREBASE_USER_TO_USER) as Types.EndpointPayload,
    );
  };

  const handleClick = () => {
    signupWithGoogle(
      setIsLoading,
      config.environment === 'development',
      ({ mappedUser }) => handleSignup(mappedUser),
    );
  };

  if (
    (isLoading ||
      Object.keys(window.sessionStorage).filter((it) =>
        it.startsWith('firebase:pendingRedirect'),
      )[0]) &&
    !signup.error
  ) {
    return (
      <div
        className={`flex justify-center items-center h-96 ${styles.wrapper}`}
      >
        <Spinner variant='blue' />
      </div>
    );
  }

  return (
    <div className='w-full'>
      <Header />
      <div
        className={`flex items-center justify-center overflow-hidden p-2 ${styles.screen_height}`}
      >
        <div
          className={`w-full flex flex-col items-center justify-center p-8 ${styles.button_wrapper}`}
        >
          <h1 className='not-italic font-extrabold text-4xl leading-10 text-center tracking-tight text-gray-900 mb-2'>
            Welcome Back to Betterleap!
          </h1>
          <p className='not-italic font-medium text-sm leading-5 text-gray-600 mb-10 text-center'>
            Betterleap connects companies with specialized recruiters.
          </p>
          <Button
            variant='normal'
            onClick={handleClick}
            buttonText='Sign in with Google'
            className='w-full'
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
