import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { forwardRef } from 'react';
import { styled } from '../../stitches.config';
import { Icon } from '../Icon';
import { Label } from '../Label';
import { useCheckboxGroup } from './CheckboxGroup';

export const StyledCheckbox = styled(CheckboxPrimitive.Root, {
  all: 'unset',
  backgroundColor: '$background',
  width: 16,
  height: 16,
  overflow: 'hidden',
  borderRadius: 4,
  border: '1px solid $border',
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  justifyContent: 'center',
  mr: 8,
  lineHeight: '$xs',
  checked: {
    background: '$primary',
    border: '1px solid $primary',
  },
  disabled: {
    background: '$background-disabled-input',
    cursor: 'not-allowed',
    checked: {
      background: '$primary-300',
      border: '1px solid $primary-300',
    },
  },
  invalid: {
    border: '1px solid $border-danger',
    focus: { boxShadow: '$focus-invalid' },
    checked: {
      background: '$danger-base',
      border: '1px solid $danger-base',
    },
  },
  mixed: {
    background: '$primary',
    border: '1px solid $primary',
  },
  hover: {
    backgroundColor: '$neutral-blue-100',
    checked: {
      background: '$primary-dark',
      invalid: {
        background: '$danger-base',
      },
    },
    mixed: {
      background: '$primary-dark',
    },
  },
  focus: { boxShadow: '$focus' },
});

export interface CheckboxProps
  extends Omit<React.ComponentProps<typeof StyledCheckbox>, 'onChange'> {
  as?: React.ElementType;
  /** is invalid */
  error?: boolean;
  /** value of checkbox */
  value?: string;
  /** value of checkbox */
  dataCy?: string;
  onChange?: (checked: boolean, value?: string) => void;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      css,
      onChange,
      error,
      name,
      value,
      defaultChecked,
      checked,
      required,
      disabled,
      children,
      dataCy,
      ...rest
    },
    ref,
  ) => {
    const {
      isSelected,
      disabled: contextDisabled,
      name: contextName,
      onChange: contextOnChange,
    } = useCheckboxGroup();

    const handleOnChange = onChange
      ? (newChecked: boolean) => {
          onChange?.(newChecked, value);
        }
      : () => value && contextOnChange?.(value);

    let isChecked: CheckboxProps['checked'] =
      isSelected && !!value ? isSelected?.(value) : undefined;
    isChecked = checked ?? isChecked;
    const isDisabled = disabled ?? contextDisabled;

    return (
      <Label
        data-checked={isChecked}
        data-disabled={isDisabled}
        css={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: '$text-lightest',
          ...css,
        }}
        {...rest}
      >
        <StyledCheckbox
          ref={ref}
          onCheckedChange={handleOnChange}
          checked={isChecked}
          onClick={(e) => e.stopPropagation()}
          aria-invalid={error}
          name={name ?? contextName}
          defaultChecked={defaultChecked}
          value={value}
          required={required}
          disabled={isDisabled}
          data-cy={dataCy}
        >
          <CheckboxPrimitive.Indicator>
            <Icon
              color='$text-inverse'
              size={14}
              strokeWidth={4}
              name={checked === 'indeterminate' ? 'minus' : 'check'}
              data-cy={dataCy}
            />
          </CheckboxPrimitive.Indicator>
        </StyledCheckbox>
        {children}
      </Label>
    );
  },
);

Checkbox.displayName = 'Checkbox';
