export class FeedbackApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * A endpoint for a user to leave feedback
     * @returns Feedback
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/feedback',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
