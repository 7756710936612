import { createContext, forwardRef, useContext } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { styled } from '../../stitches.config';
import { StyledText } from '../Text';
import { useRadioGroup } from './RadioGroup';
import { StyledIndicator } from './Radio';

const RadioContext = createContext<{
  value?: string | null;
  selectedValue?: string | null;
  disabled?: boolean;
}>({
  selectedValue: null,
});

export const useRadio = () => useContext(RadioContext);

const StyledIndicatorContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$full',
  border: '1px solid $neutral-blue-400',
  focus: { boxShadow: '$focusGray' },
  disabled: {
    background: '$neutral-blue-100',
    selected: {
      backgroundColor: '$blue-200',
      [`& ${StyledIndicator}`]: {
        '&::after': {
          backgroundColor: '$blue-400',
        },
      },
    },
  },
  selected: {
    backgroundColor: '$blue-200',
    borderColor: '$blue-200',
  },
  variants: {
    size: {
      sm: {
        width: 16,
        height: 16,
        [`& ${StyledIndicator}`]: {
          '&::after': {
            width: 6,
            height: 6,
          },
        },
      },
      base: {
        width: 20,
        height: 20,
        [`& ${StyledIndicator}`]: {
          '&::after': {
            width: 8,
            height: 8,
          },
        },
      },
      lg: {
        width: 24,
        height: 24,
        [`& ${StyledIndicator}`]: {
          '&::after': {
            width: 10,
            height: 10,
          },
        },
      },
    },
  },
  defaultVariants: {
    size: 'base',
  },
});

export const StyledRadioCardText = styled(StyledText, {
  color: '$text-secondary',
  fill: '$neutral-blue-600',
  selected: {
    color: '$blue-700',
    fill: '$blue-600',
  },
  variants: {
    variant: {
      title: {
        fontWeight: '$medium',
        color: '$text',
      },
    },
  },
});

export const RadioCardText = forwardRef<
  HTMLParagraphElement,
  React.ComponentProps<typeof StyledRadioCardText>
>((props, ref) => {
  const { selectedValue, value, disabled } = useRadio();
  const isSelected = selectedValue === value;

  return (
    <StyledRadioCardText
      ref={ref}
      data-selected={isSelected}
      data-disabled={disabled}
      {...props}
    />
  );
});

RadioCardText.displayName = 'RadioCardText';

export type RadioIndicatorProps = React.ComponentProps<
  typeof StyledIndicatorContainer
>;

const StyledRadioCard = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  boxSizing: 'border-box',
  cursor: 'pointer',
  fontSize: '$sm',
  width: '100%',
  p: 16,
  border: '1px solid $neutral-blue-300',
  checked: {
    color: '$blue-700',
    fill: '$blue-600',
    zIndex: '$10',
  },
  transition: 'box-shadow 0.3s ease-in-out',
  focus: { boxShadow: '$focus' },
  hover: {
    backgroundColor: '$neutral-blue-100',
  },
  disabled: {
    background: '$background-disabled-input',
    cursor: 'not-allowed',
  },
  variants: {
    variant: {
      list: {
        borderTop: 'none',
        borderBottom: 'none',
        '& + &': {
          borderTop: '1px solid $neutral-blue-300',
        },
        '& + &[aria-checked=true]': {
          borderTop: '1px solid $purple-700',
        },
        '&[aria-checked=true] + &': {
          borderTop: '1px solid $purple-700',
        },
        firstChild: {
          borderTop: '1px solid $neutral-blue-300',
          borderTopLeftRadius: '$lg',
          borderTopRightRadius: '$lg',
        },
        lastChild: {
          borderBottom: '1px solid $neutral-blue-300',
          borderBottomLeftRadius: '$lg',
          borderBottomRightRadius: '$lg',
        },
      },
      card: {
        borderRadius: '$lg',
        border: '1px solid $neutral-blue-300',
      },
    },
  },
  defaultVariants: {
    variant: 'card',
  },
});

export const RadioIndicator = ({
  children = <StyledIndicator />,
  ...props
}: React.ComponentProps<typeof StyledIndicatorContainer>) => {
  const { selectedValue, value, disabled } = useRadio();

  const isSelected = selectedValue === value;

  return (
    <StyledIndicatorContainer
      {...props}
      data-selected={isSelected}
      data-disabled={disabled}
    >
      {isSelected ? children : null}
    </StyledIndicatorContainer>
  );
};

export interface RadioCardProps
  extends React.ComponentProps<typeof StyledRadioCard> {
  as?: React.ElementType;
}

export const RadioCard = forwardRef<HTMLButtonElement, RadioCardProps>(
  ({ children, value, required, disabled, variant, ...rest }, ref) => {
    const {
      selectedValue,
      required: contextRequired,
      disabled: contextDisabled,
      variant: contextVariant,
    } = useRadioGroup();

    const isDisabled = disabled ?? contextDisabled;
    const isSelected = selectedValue === value;

    return (
      <RadioContext.Provider
        value={{ selectedValue, value, disabled: isDisabled }}
      >
        <StyledRadioCard
          ref={ref}
          value={value}
          disabled={isDisabled}
          role='radio'
          required={required ?? contextRequired}
          variant={variant ?? contextVariant}
          aria-checked={isSelected}
          data-selected={isSelected}
          {...rest}
        >
          {children}
        </StyledRadioCard>
      </RadioContext.Provider>
    );
  },
);

RadioCard.displayName = 'RadioCard';
