/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var SourceStatusContact;
(function (SourceStatusContact) {
    let contactEmailEnrichmentMode;
    (function (contactEmailEnrichmentMode) {
        contactEmailEnrichmentMode["PERSONAL"] = "Personal";
        contactEmailEnrichmentMode["PROFESSIONAL"] = "Professional";
    })(contactEmailEnrichmentMode = SourceStatusContact.contactEmailEnrichmentMode || (SourceStatusContact.contactEmailEnrichmentMode = {}));
})(SourceStatusContact || (SourceStatusContact = {}));
