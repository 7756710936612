import { useCheckboxGroupState } from '@react-stately/checkbox';
import { createContext, forwardRef, useContext } from 'react';
import { Box, BoxProps } from '../Box';

const CheckboxGroupContext = createContext<{
  disabled?: boolean;
  name?: string;
  onChange?: (value: string) => void;
  isSelected?: (value: string) => boolean;
}>({});

export const useCheckboxGroup = () => useContext(CheckboxGroupContext);

export interface CheckboxGroupProps extends Omit<BoxProps, 'onChange'> {
  /**
   * when a child checkbox is clicked
   */
  onChange?: (props: string[]) => void;
  /** disables whole group  */
  disabled?: boolean;
  /** The values assigned to the group of checkboxess. */
  value?: string[];
  /** The attribute name assigned to the group of checkboxes. */
  name?: string;
  /** default values */
  dataCy?: string;
  defaultValue?: string[];
}

export const CheckboxGroup = forwardRef<HTMLElement, CheckboxGroupProps>(
  (
    {
      children,
      value,
      defaultValue,
      onChange,
      disabled,
      name,
      dataCy,
      ...rest
    },
    ref,
  ) => {
    const { toggleValue, isSelected } = useCheckboxGroupState({
      isDisabled: disabled,
      onChange,
      value,
      defaultValue,
    });

    const context = {
      isSelected,
      onChange: toggleValue,
      name,
      disabled,
    };

    return (
      <CheckboxGroupContext.Provider value={context}>
        <Box
          ref={ref}
          role='group'
          aria-disabled={disabled}
          {...rest}
          data-cy={dataCy}
        >
          {children}
        </Box>
      </CheckboxGroupContext.Provider>
    );
  },
);

CheckboxGroup.displayName = 'CheckboxGroup';
