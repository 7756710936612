import {
  IconButton,
  Menu,
  MenuArrow,
  MenuContent,
  MenuItem,
  MenuTrigger,
} from '@betterleap/ui';

export interface ActionsDropdownProps<T = string> {
  value: T;
  onClick?: () => void;
  options?: {
    label: string;
    color?: string;
    renderItem?: (value: T) => JSX.Element;
    onClick?: (value: T) => void;
  }[];
}

export const ActionsDropdown = <T,>({
  value,
  options,
  onClick,
}: ActionsDropdownProps<T>) => (
  <Menu>
    <MenuTrigger asChild>
      <IconButton
        label='open menu'
        name='dots-vertical'
        dataCy='Dot Edit Button'
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick();
        }}
      />
    </MenuTrigger>
    <MenuContent align='end'>
      <MenuArrow offset={14} />
      {options?.map((el) =>
        el.renderItem ? (
          el.renderItem(value)
        ) : (
          <MenuItem
            style={{ color: el.color }}
            key={el.label}
            dataCy={`${el.label} Button`}
            onClick={(e) => {
              e.stopPropagation();
              el.onClick?.(value);
            }}
          >
            {el.label}
          </MenuItem>
        ),
      )}
    </MenuContent>
  </Menu>
);
