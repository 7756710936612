/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Invitation;
(function (Invitation) {
    let status;
    (function (status) {
        status["PENDING"] = "pending";
        status["JOINED"] = "joined";
    })(status = Invitation.status || (Invitation.status = {}));
})(Invitation || (Invitation = {}));
