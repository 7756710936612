export class ContactActivityApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get the most recent activity of a contact
     * @returns ContactActivityDto
     * @throws ApiError
     */
    getMostRecentActivity({ profileUrl, contactId, activityTypes, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-activity/most-recent',
            query: {
                'profileUrl': profileUrl,
                'contactId': contactId,
                'activityTypes': activityTypes,
            },
        });
    }
    /**
     * Get the activity of a contact
     * @returns ContactActivityDto
     * @throws ApiError
     */
    get({ profileUrl, contactId, activityTypes, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-activity',
            query: {
                'profileUrl': profileUrl,
                'contactId': contactId,
                'activityTypes': activityTypes,
            },
        });
    }
}
