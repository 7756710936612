export enum ROLE {
  NONE = 'None',
  RECRUITER = 'Recruiter',
  ADMIN = 'Admin',
  COMPANY_ADMIN = 'Company Admin',
}

export enum ROLE_STATUS {
  UNMATCHED = 'Unmatched',
  MATCHED = 'Matched',
  REMATCH = 'Rematch requested',
  OFFERED = 'Offered',
  PAUSED = 'Paused',
  OFFER_APPROVED = 'Offer approved',
  OPEN = 'Open',
  CANCELLED = 'Cancelled',
}

export enum STATUS_OAR {
  ACTIVE = 'active', // Role not accepted by any recruiter
  MISSED = 'missed', // Recruiter didn't accept the role in time
  PAUSED = 'paused', // Company paused the role matching process
  CANCELED = 'canceled', // Company canceled the role matching process
  ACCEPTED = 'accepted', // Recruiter accepted the role
  REMATCH = 'rematch', // Company requested a rematch of the recruiter
  REJECTED = 'rejected', // Recruiter rejected role
  CANCELED_BEFORE_MATCH = 'canceled_before_match', // Role was canceled before anyone accepted it
  RECRUITER_DEACTIVATED = 'recruiter_deactivated', // Recruiter was deactivated
  FINISHED = 'finished', // Role if offered,
  UNMATCHED = 'unmatched', // When admin unmatch role
}

export enum ROLE_URGENCY {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum ROLE_EQUITY {
  XS = '0-0.25%',
  S = '0.25-0.5%',
  M = '0.5-0.75%',
  L = '0.75-1%',
  XL = '1%+',
}

export enum PARTNERS_STATUS {
  INTERESTED = 'interested',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum COMPANY_STATUS {
  ACTIVE = 'active',
  PENDING = 'pending',
}

export enum Feature {
  DEMO_FEATURE = 'demoFeature',
  COMPANY_ONBOARDING = 'companyOnboarding',
  CREATE_ROLE = 'createRole',
  EMAIL_AUTH_LINK = 'emailAuthLink',
  RECRUITER_PROFILE = 'recruiterProfile',
  RECRUITER_ONBOARDING = 'recruiterOnboarding',
  ATS_TWO_WAY_SYNC = 'atsTwoWaySync',
}

export enum COMPANY_ONBOARDING_STEPS {
  COMPANY_ADMIN_DETAILS = 'companyAdminDetails',
  COMPANY_DETAILS = 'companyDetails',
  BILLING_INFO = 'billingInfo',
}

export enum PROFILE_STATUS {
  NOT_COMPLETED = 'notCompleted',
  COMPLETED = 'completed',
}

export enum RECRUITER_STATUS {
  NEW = 'new', // When a recruiter signed up
  PENDING = 'pending', // When a recruiter completed all required profile completion steps (3 steps)
}

export enum COMPANY_VERSION {
  ONE_TO_ONE_MATCHING = '1.0',
  ATS = '2.0',
}

export enum REMOTE_POLICY {
  FRIENDLY = 'Remote Friendly',
  REMOTE = 'Remote Only',
  NO_REMOTE = 'No Remote',
}

export enum MERGE_ROLE_STATUS {
  OPEN = 'Open',
  CLOSED = 'Closed',
  DRAFT = 'Draft',
  ARCHIVED = 'Archived',
  PENDING = 'Pending',
  PUBLISHED = 'Published',
}

export enum CANDIDATE_STATUS {
  NEW = 'new',
  APPROVED = 'approved',
  DECLINED = 'declined',
  OFFER = 'offer',
  CODING_CHALLENGE = 'coding_challenge',
  SUBMITTED = 'submitted',
  PHONE_SCREEN = 'phone_screen',
}

export enum LAST_ROUND_RAISED {
  BOOTSTRAPPED = 'Bootstrapped',
  PRE_SEED = 'Pre-seed',
  SEED = 'Seed',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  PUBLIC = 'PUBLIC',
}

export enum ROLE_SOURCE {
  BETTERLEAP = 'Betterleap',
  MERGE = 'Merge',
}

export enum BULK_ACTION {
  MOVE = 'Move to',
  EXPORT = 'Export as CSV',
  REMOVE = 'Remove',
}

export enum INVITATION_TYPE {
  HIRING_MANAGER = 'hiring-manager',
  PROJECT_SHARE = 'project-share',
  LINKED_ORG = 'linked-org',
}

export enum ANALYTICS_CLIENT_EVENT {
  SIGNUP_STARTED = 'Signup Started',
  LOGIN_STARTED = 'Login Started',
  BUTTON_CLICKED = 'Button Clicked',
  AI_ASSISTANT_PROMPT_SUBMIT = 'AI Assistant Prompt Submit',
  AI_DATABASE_ASSISTANT_PROMPT_SUBMIT = 'AI Database Assistant Prompt Submit',
  EMAIL_FINDER_CSV_UPLOAD = 'Email Finder Csv Uploaded',
}

export enum SEGMENT_EVENTS {
  NAV_CLICKED = 'Nav Clicked',
  KEBAB_MENU_CLICKED = 'Kebab Menu Clicked',
  PAGE_VIEWED = 'Page Viewed',
  BULK_ACTION_ON_CANDIDATES = 'Bulk Action On Candidates',
  EMAIL_PERMISSIONS_GRANTED = 'Email Permissions Granted',
  OPEN_APP_BANNER_CLICKED = 'Open App Banner Clicked',
  RECRUITER_PROFILE_VIEW = 'Recruiter profile view',
  GENERATE_MORE_CONTACTS_CLICKED = 'Request More button click',
  BUTTON_CLICKED = 'Button Clicked',
}

export enum INVITATION_STATUS {
  PENDING = 'pending',
  JOINED = 'joined',
}

export enum VOUCHER_STATUS {
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

export enum ORG_TYPE {
  ADMIN = 'Admin',
  CLIENT_USER = 'Client User',
  VC = 'VC',
}

export enum ORG_TIER {
  BASIC = 'Basic',
  PREMIUM = 'Premium',
  VIEW_ONLY = 'View Only',
}

export enum ORG_CREATION_TYPE {
  REGULAR = 'Regular',
  INVITED = 'Invited',
}

export enum ROLE_PUBLISH_TYPE {
  RECRUITER_NETWORK = 'recruiterNetwork',
  PREFERRED_RECRUITER = 'preferredRecruiter',
}
