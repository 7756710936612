import {
  cloneElement,
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';
import * as Portal from '@radix-ui/react-portal';
import { OverlayProvider } from '@react-aria/overlays';

export const ModalContext = createContext<{
  isDismissable?: boolean;
  isOpen?: boolean;
  onDismiss?: () => void;
  closeModal: () => void;
  openModal: (ModalComponent: ReactElement) => void;
  titleProps?: React.HTMLAttributes<HTMLElement>;
}>({
  closeModal: () => {},
  openModal: () => {},
});

export const useModalContext = () => useContext(ModalContext);

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [Component, setComponent] = useState<ReactElement>();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (ModalComponent: ReactElement) => {
    if (isOpen) return;

    setComponent(ModalComponent);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        isOpen,
      }}
    >
      <Portal.Root>
        <OverlayProvider>
          {isOpen && Component && cloneElement(Component)}
        </OverlayProvider>
      </Portal.Root>
      {children}
    </ModalContext.Provider>
  );
};
