/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CompanySize;
(function (CompanySize) {
    CompanySize["_1_10"] = "1-10";
    CompanySize["_11_50"] = "11-50";
    CompanySize["_51_200"] = "51-200";
    CompanySize["_201_500"] = "201-500";
    CompanySize["_501_1000"] = "501-1000";
    CompanySize["_1001_5000"] = "1001-5000";
    CompanySize["_5001_10000"] = "5001-10000";
    CompanySize["_10001_"] = "10001+";
})(CompanySize || (CompanySize = {}));
