export class DevSupportApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns TestLoggerResponseDto
     * @throws ApiError
     */
    testLogger() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/test-logger',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    testDummyGet({ dummyId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/test-dummy-get',
            path: {
                'dummyId': dummyId,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    testDummyPost() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/test-dummy-post',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    devKeyTest() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/devsupport/common/key-test',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    devGetMany() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/devsupport/common/advisory-lock',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    devTestReleaseToggles() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/devsupport/common/release-toggles-example',
        });
    }
}
