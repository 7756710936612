import {
  BaseHttpRequest,
  CancelablePromise,
  OpenAPIConfig,
  request,
} from '@betterleap/client';
import { ApiRequestOptions } from '@betterleap/client/dist/client/core/ApiRequestOptions';
import axios from 'axios';
import { saveUserToken } from '../helper/firebase';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401) {
      if (!originalRequest.retry) {
        const newToken = await saveUserToken();

        originalRequest.retry = true;
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: `Bearer ${newToken}`,
        };
        return axiosInstance(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export class BetterleapHttpRequest extends BaseHttpRequest {
  constructor(config: OpenAPIConfig) {
    super(config);
  }

  /**
   * Request method
   * @param options The request options from the service
   * @returns CancelablePromise<T>
   * @throws ApiError
   */
  public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
    return request(this.config, options, axiosInstance);
  }
}
