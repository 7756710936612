export class CandidateProfileApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns CandidateProfile
     * @throws ApiError
     */
    getProfile() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/candidate-profile',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    deleteProfile() {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/candidate-profile',
        });
    }
    /**
     * @returns CandidateProfile
     * @throws ApiError
     */
    updateProfile({ requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/candidate-profile/profile',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CandidateProfile
     * @throws ApiError
     */
    updateProfilePicture({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/candidate-profile/picture',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CandidateExperience
     * @throws ApiError
     */
    upsertExperience({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/candidate-profile/experience',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CandidateEducation
     * @throws ApiError
     */
    upsertEducation({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/candidate-profile/education',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CandidateCredential
     * @throws ApiError
     */
    upsertCredential({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/candidate-profile/credential',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CandidateSkill
     * @throws ApiError
     */
    upsertSkill({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/candidate-profile/skill',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns CandidateLanguage
     * @throws ApiError
     */
    upsertLanguage({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/candidate-profile/language',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    deleteExperience({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/candidate-profile/experience/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    deleteEducation({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/candidate-profile/education/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    deleteCredential({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/candidate-profile/credential/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    deleteSkill({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/candidate-profile/skill/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    deleteLanguage({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/candidate-profile/language/{id}',
            path: {
                'id': id,
            },
        });
    }
}
