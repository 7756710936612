import { forwardRef } from 'react';
import { styled } from '../../stitches.config';
import { StyledButton } from '../Button';
import { Icon, IconProps } from '../Icon';

export const StyledIconButton = styled(StyledButton, {
  borderRadius: '$xl',
  borderStyle: 'none',
  disabled: {
    backgroundColor: '$neutral-blue-200',
    fill: '$neutral-blue-500',
  },
  variants: {
    size: {
      xs: {
        borderRadius: '$md',
        height: 24,
        width: 24,
        '& svg': {
          height: 14,
          minWidth: 14,
        },
      },
      sm: {
        borderRadius: '$lg',
        height: 32,
        width: 32,
        '& svg': {
          height: 16,
          minWidth: 16,
        },
      },
      base: {
        borderRadius: '$xl',
        height: 40,
        width: 40,
        '& svg': {
          height: 18,
          minWidth: 18,
        },
      },
      lg: {
        borderRadius: '$2xl',
        height: 48,
        width: 48,
        '& svg': {
          height: 20,
          minWidth: 20,
        },
      },
      xl: {
        borderRadius: '$3xl',
        height: 56,
        width: 56,
        '& svg': {
          height: 22,
          minWidth: 22,
        },
      },
    },
    shape: {
      circle: {
        borderRadius: '$full',
      },
    },
    variant: {
      darkGray: {
        borderColor: '$neutral-blue-200',
        backgroundColor: '$neutral-blue-200',
        color: '$neutral-blue-800',
        fill: '$neutral-blue-800',
        hover: {
          backgroundColor: '$neutral-blue-400',
          borderColor: '$neutral-blue-400',
        },
        pressed: {
          backgroundColor: '$neutral-blue-500',
          borderColor: '$neutral-blue-500',
        },
        disabled: {
          cursor: 'not-allowed',
          color: '$neutral-blue-500',
          fill: '$neutral-blue-500',
        },
      },
      secondary: {
        borderColor: '$primary-50',
        background: '$primary-50',
        color: '$primary-base',
        fill: '$primary-base',
        hover: {
          backgroundColor: '$primary-100',
          borderColor: '$primary-100',
        },
        pressed: {
          backgroundColor: '$primary-200',
          borderColor: '$primary-200',
        },
        disabled: {
          backgroundColor: '$primary-50',
          borderColor: '$primary-50',
          cursor: 'not-allowed',
          color: '$primary-300',
        },
      },
      danger: {
        borderColor: '$danger-100',
        background: '$danger-100',
        color: '$danger-700',
        fill: '$danger-700',
        hover: {
          backgroundColor: '$danger-200',
          borderColor: '$danger-200',
        },
        pressed: {
          backgroundColor: '$danger-300',
          borderColor: '$danger-300',
        },
        disabled: {
          cursor: 'not-allowed',
          color: '$danger-500',
          fill: '$danger-500',
        },
      },
      ghost: {
        color: '$text',
        fill: '$text',
        background: 'transparent',
        hover: {
          backgroundColor: '$background-muted',
        },
        expanded: {
          backgroundColor: '$background-dark',
        },
        pressed: {
          backgroundColor: '$background-dark',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'ghost',
    size: 'base',
  },
});

export interface IconButtonProps
  extends React.ComponentProps<typeof StyledIconButton> {
  as?: React.ElementType;
  name?: IconProps['name'];
  color?: IconProps['color'];
  label: string;
  dataCy?: string;
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, name, disabled, label, color, css, dataCy, ...rest }, ref) => (
    <StyledIconButton
      data-cy={dataCy}
      aria-label={label}
      disabled={disabled}
      ref={ref}
      css={css}
      {...rest}
    >
      {name ? (
        <Icon color={color} css={{ fill: 'inherit' }} name={name} />
      ) : (
        children
      )}
    </StyledIconButton>
  ),
);

IconButton.displayName = 'IconButton';
