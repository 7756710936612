import { Organization } from '@betterleap/client';
import { Button, useModal } from '@betterleap/ui';
import { useMemo } from 'react';
import ExtendTrialModal, {
  ExtendTrialModalProps,
} from '../Modals/ExtendTrialModal/ExtendTrialModal';
import UpgradeModal from '../Modals/UpgradeModal/UpgradeModal';
import ConfirmModal, {
  ConfirmModalProps,
} from '../Modals/ConfirmModal/ConfirmModal';

interface OrganizationTierManagementProps {
  organization?: Organization;
  membersCount: number;
  refetchOrganization: () => void;
  updateOrganization: (data: {
    organizationId: string;
    trialStartedAt: string;
    tier: Organization.tier;
  }) => void;
}

const OrganizationTierManagement = ({
  organization,
  membersCount,
  refetchOrganization,
  updateOrganization,
}: OrganizationTierManagementProps) => {
  if (!organization) {
    return null;
  }

  const openConfigmActivateTrialModal =
    useModal<ConfirmModalProps>(ConfirmModal);

  const handleOnActivate = async () => {
    const confirmation = await openConfigmActivateTrialModal({
      title: 'Activate free trial',
      description: `Are you sure you want to activate trial for ${organization.name}?`,
      confirmText: 'Activate',
    });

    if (!confirmation) {
      return;
    }

    updateOrganization({
      organizationId: organization.id,
      trialStartedAt: new Date().toISOString(),
      tier: Organization.tier.FREE_TRIAL,
    });
  };

  const showExtendTrialModal =
    useModal<ExtendTrialModalProps>(ExtendTrialModal);

  const handleExtendTrial = async () => {
    const extendTrial = await showExtendTrialModal({
      currentTrialStartDate: organization?.trialStartedAt as string,
      onExtend: (value: string) => {
        updateOrganization({
          organizationId: organization.id,
          trialStartedAt: value,
          tier: Organization.tier.FREE_TRIAL,
        });
      },
    });
    if (extendTrial) {
      refetchOrganization();
    }
  };

  const showUpgradeModal = useModal<{
    type: string;
    organization?: Organization;
    title: string;
    subtitle: string;
    numberOfMembers: number;
    icon: string;
    buttonConfirmationText: string;
    buttonConfirmationVariant: string;
  }>(UpgradeModal);

  const updateModalProps = useMemo(
    () => ({
      upgrade: {
        title: 'How many seats to add for this account?',
        subtitle: 'Paid Betterleap features will be available for all members',
        icon: 'success',
        buttonConfirmationText: 'Upgrade Now',
        buttonConfirmationVariant: 'green',
      },
      manage_seats: {
        title: 'Manage seats',
        subtitle: `Update the total number of seats for ${organization?.name}`,
        icon: 'info',
        buttonConfirmationText: 'Update',
        buttonConfirmationVariant: 'primary',
      },
      downgrade: {
        title: `Downgrade ${organization?.name} to Paywall Tier?`,
        subtitle: 'Paid Betterleap features will be lost for all members',
        icon: 'danger',
        buttonConfirmationText: 'Downgrade Now',
        buttonConfirmationVariant: 'danger',
      },
    }),
    [organization],
  );

  const showUpgradeModalWithOptions = async (
    type: keyof typeof updateModalProps,
  ) => {
    const resultOfModal = await showUpgradeModal({
      type,
      organization,
      numberOfMembers: membersCount,
      ...updateModalProps[type],
    });
    if (resultOfModal) {
      refetchOrganization();
    }
  };

  if (
    organization.tier === Organization.tier.PAYWALL ||
    organization.tier === Organization.tier.FREE_TRIAL
  ) {
    return (
      <>
        <Button
          dataCy='Upgrade-button'
          css={{
            width: '100%',
            maxWidth: 300,
            mediaMd: { mb: 0, mt: 0, ml: 0, width: 'unset' },
          }}
          variant='success'
          onClick={() => showUpgradeModalWithOptions('upgrade')}
        >
          Upgrade
        </Button>
        <Button
          dataCy='extend-trial-button'
          css={{
            width: '100%',
            maxWidth: 300,
            mediaMd: { mb: 0, mt: 0, ml: 0, width: 'unset' },
          }}
          variant='primary'
          onClick={handleExtendTrial}
        >
          Extend Trial
        </Button>
      </>
    );
  } else if (organization.tier === Organization.tier.FREE_TRIAL_REQUESTED) {
    return (
      <>
        <Button
          dataCy='Activate-button'
          css={{
            width: '100%',
            maxWidth: 300,
            mediaMd: { mb: 0, mt: 0, ml: 0, width: 'unset' },
          }}
          variant='success'
          onClick={handleOnActivate}
        >
          Activate
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          dataCy='Manage seats-button'
          css={{
            width: '100%',
            maxWidth: 300,
            mediaMd: { mb: 0, mt: 0, ml: 0, width: 'unset' },
          }}
          variant='primary'
          onClick={() => showUpgradeModalWithOptions('manage_seats')}
        >
          Manage seats
        </Button>
        <Button
          dataCy='Downgrade-button'
          css={{
            width: '100%',
            maxWidth: 300,
            mediaMd: { mb: 0, mt: 0, ml: 0, width: 'unset' },
          }}
          variant='danger'
          onClick={() => showUpgradeModalWithOptions('downgrade')}
        >
          Downgrade
        </Button>
      </>
    );
  }
};

export default OrganizationTierManagement;
