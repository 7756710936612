import debounce from 'lodash/debounce.js';
import { RefObject, useCallback, useLayoutEffect, useState } from 'react';

export interface useIsOverflowOptions {
  type?: 'vertical' | 'horizontal';
  debounceDuration?: number;
}

export const useIsOverflow = (
  ref: RefObject<HTMLElement | undefined>,
  callback: (isOverflow: boolean) => void,
  options: useIsOverflowOptions = { type: 'vertical', debounceDuration: 100 },
) => {
  const [isOverflow, setIsOverflow] = useState(false);

  const debouncedCallback = useCallback(
    debounce((overflow: boolean) => {
      callback(overflow);
    }, options.debounceDuration),
    [callback],
  );

  useLayoutEffect(() => {
    const { current } = ref;

    if (current) {
      const trigger = () => {
        const hasOverflow =
          options.type === 'vertical'
            ? current.scrollHeight > current.clientHeight
            : current.scrollWidth > current.clientWidth;

        setIsOverflow(hasOverflow);

        debouncedCallback(hasOverflow);
      };

      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};
