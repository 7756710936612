// All endpoints must be in snake case (snake_case)
const SHARED_ENDPOINTS = {
  example: {
    uri: '/example',
    method: 'GET',
  },
  save_profile_pic: {
    uri: '/file/many',
    method: 'POST',
  },
  save_files: {
    uri: '/file/many',
    method: 'POST',
  },
  save_resume: {
    uri: '/file/many',
    method: 'POST',
  },
  save_sequence_photo: {
    uri: '/file/many',
    method: 'POST',
  },
  create_invite: {
    uri: '/invitations',
    method: 'POST',
  },
  recruiter_by_id: {
    uri: '/recruiter/{id}',
    method: 'GET',
  },
  update_recruiter: {
    uri: '/recruiter/{id}',
    method: 'POST',
  },
  feature_config: {
    uri: '/feature-config',
    method: 'GET',
  },
  get_organization: {
    uri: '/organization/{organizationId}',
    method: 'GET',
  },
  update_organization: {
    uri: '/organization/{organizationId}',
    method: 'PATCH',
  },
  get_members: {
    uri: '/user/organization-members/{organizationId}',
    method: 'GET',
  },
};

export { SHARED_ENDPOINTS };
