import { forwardRef } from 'react';
import { styled } from '../../stitches.config';
import { BoxProps } from '../Box';

export const StyledText = styled('p', {
  margin: 0,
  color: '$text',
  fontSize: '$base',
  fontWeight: '$normal',
  fontFamily: '$body',

  variants: {
    flex: {
      true: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    truncate: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    inherit: {
      true: {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: 'inherit',
      },
    },
    inline: {
      true: {
        display: 'inline-block',
      },
    },
    noBreak: {
      true: {
        whiteSpace: 'nowrap',
      },
    },
    align: {
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
      center: {
        textAlign: 'center',
      },
    },
    size: {
      xs: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
      },
      sm: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
      base: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
      },
      lg: {
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
      },
      xl: {
        fontSize: '1.25rem',
        lineHeight: '1rem',
      },
      '2xl': {
        fontSize: '1.5rem',
        lineHeight: '2rem',
      },
      '3xl': {
        fontSize: '1.875rem',
        lineHeight: '2.25rem',
      },
      '4xl': {
        fontSize: '2.25rem',
        lineHeight: '2.5rem',
      },
      '5xl': {
        fontSize: '3rem',
        lineHeight: '3rem',
      },
      '6xl': {
        fontSize: '3.75rem',
        lineHeight: '3.75rem',
      },
      '7xl': {
        fontSize: '4.5rem',
        lineHeight: '4.5rem',
      },
      '8xl': {
        fontSize: '6rem',
        lineHeight: '6rem',
      },
      '9xl': {
        fontSize: '8rem',
        lineHeight: '8rem',
      },
    },
    variant: {
      'heading-1': {
        fontWeight: '$bold',
        fontSize: '$3xl',
        lineHeight: '2.25rem',
      },
      'heading-2': {
        fontWeight: '$bold',
        fontSize: '$2xl',
        lineHeight: '2rem',
      },
      'heading-3': {
        fontWeight: '$medium',
        fontSize: '1.25rem',
        lineHeight: '1rem',
      },
      h6: {
        fontWeight: '$medium',
        fontSize: '1.25rem',
        lineHeight: '2rem',
      },
      link: {
        fontSize: '$sm',
        color: '$primary-600',
        fill: '$primary-600',
        cursor: 'pointer',
        hover: {
          textDecoration: 'underline',
        },
      },
    },
  },
});

export interface TextProps extends React.ComponentProps<typeof StyledText> {
  as?: React.ElementType;
  rel?: BoxProps['rel'];
  target?: BoxProps['target'];
  href?: BoxProps['href'];
  dataCy?: string;
}

export const Text = forwardRef<HTMLElement, TextProps>(
  ({ dataCy, ...rest }, ref) => (
    <StyledText ref={ref} {...rest} data-cy={dataCy} />
  ),
);

Text.displayName = 'Text';

export const InlineText = forwardRef<HTMLElement, TextProps>(
  ({ ...rest }, ref) => <Text ref={ref} as='span' inline inherit {...rest} />,
);

InlineText.displayName = 'InlineText';
