export class UserSettingsApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Update a user's settings
     * @returns UserSettings
     * @throws ApiError
     */
    update({ requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user-settings',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get an authenticated user's settings
     * @returns UserSettings
     * @throws ApiError
     */
    get() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user-settings',
        });
    }
}
