export class ProjectRoleApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Update a project role.
     * @returns any
     * @throws ApiError
     */
    update({ id, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/project-role/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
