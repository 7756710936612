/** gets env var in a webpack and vite environment */
export const getEnvVar = (key: string): string | undefined => {
  // Check if the code is running in a Vite environment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (typeof import.meta !== 'undefined' && (import.meta as any).env) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (import.meta as any).env[key];
  }

  // Fallback for Create React App (CRA) or other Node.js environments
  return process.env[key];
};
