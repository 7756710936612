/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactActivityDto;
(function (ContactActivityDto) {
    let event;
    (function (event) {
        event["PROFILE_VIEW"] = "PROFILE_VIEW";
        event["EMAIL_SENT"] = "EMAIL_SENT";
        event["EMAIL_REPLIED"] = "EMAIL_REPLIED";
        event["INMAIL_SENT"] = "INMAIL_SENT";
        event["INMAIL_REPLIED"] = "INMAIL_REPLIED";
        event["ADDED_TO_PROJECT"] = "ADDED_TO_PROJECT";
        event["ADDED_TO_SEQUENCE"] = "ADDED_TO_SEQUENCE";
        event["SUBMITTED_APPLICATION"] = "SUBMITTED_APPLICATION";
    })(event = ContactActivityDto.event || (ContactActivityDto.event = {}));
})(ContactActivityDto || (ContactActivityDto = {}));
