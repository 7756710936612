/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ProjectWithSharedDataDto;
(function (ProjectWithSharedDataDto) {
    let contactEmailEnrichmentMode;
    (function (contactEmailEnrichmentMode) {
        contactEmailEnrichmentMode["PERSONAL"] = "Personal";
        contactEmailEnrichmentMode["PROFESSIONAL"] = "Professional";
    })(contactEmailEnrichmentMode = ProjectWithSharedDataDto.contactEmailEnrichmentMode || (ProjectWithSharedDataDto.contactEmailEnrichmentMode = {}));
})(ProjectWithSharedDataDto || (ProjectWithSharedDataDto = {}));
