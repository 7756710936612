import { Key, KeyboardEvent } from 'react';
import { ListState } from 'react-stately';

export const useListFocus = (state?: ListState<any>) => {
  const handleMouseLeave = () => {
    state?.selectionManager?.setFocusedKey('');
    state?.selectionManager?.setFocused(false);
  };

  const handleBlur = () => {
    state?.selectionManager?.setFocusedKey('');
    state?.selectionManager?.setFocused(false);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    const currentkey = state?.selectionManager?.focusedKey;

    let nextKey;
    let prevKey;
    let nextItem;
    let prevItem;
    // eslint-disable-next-line default-case
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        // if no key is focused set to first
        nextKey = currentkey
          ? state.collection.getKeyAfter(currentkey)
          : state?.collection.getFirstKey();

        // if there is no next key we are at the end of the list. go back to first.
        nextKey = nextKey ?? state?.collection.getFirstKey();

        nextItem = state?.collection.getItem(nextKey as Key);

        if (nextItem?.type === 'section') {
          nextKey =
            state?.collection?.getKeyAfter(nextKey as Key) ??
            state?.collection.getFirstKey();
        }

        // set focus to next key
        state?.selectionManager?.setFocusedKey(nextKey as Key);
        break;
      case 'ArrowUp':
        e.preventDefault();

        // if no key is focused and up arrow is pressed send focus to end of list.
        prevKey = currentkey
          ? state?.collection.getKeyBefore(currentkey)
          : state?.collection.getLastKey();

        // if there is no prev key we are at the beginning of the list. go back to last.
        prevKey = prevKey ?? state?.collection.getLastKey();

        prevItem = state?.collection.getItem(prevKey as Key);

        if (prevItem?.type === 'section') {
          prevKey =
            state?.collection?.getKeyBefore(prevKey as Key) ??
            state?.collection.getLastKey();
        }

        state?.selectionManager?.setFocusedKey(prevKey as Key);
        break;
      case 'Home':
        state?.selectionManager?.setFocusedKey(
          state?.collection.getFirstKey() as Key,
        );
        break;
      case 'End':
        state?.selectionManager?.setFocusedKey(
          state?.collection.getLastKey() as Key,
        );
        break;
      case 'Enter':
        if (currentkey) {
          e.preventDefault();
          state?.selectionManager?.select(currentkey);
        }
        break;
    }
  };

  return {
    handleKeyDown,
    handleMouseLeave,
    handleBlur,
  };
};
