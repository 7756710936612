type ToggleConfiguration = {
  field: string;
  label: string;
};

export const organizationToggles: ToggleConfiguration[] = [
  {
    field: 'test',
    label: 'Test',
  },
  {
    field: 'features.enabledSourcing',
    label: 'Sourcing Enabled',
  },
  {
    field: 'features.enabledExportEmails',
    label: 'Email Export Enabled',
  },
  {
    field: 'features.enabledNaturalLanguageSourcing',
    label: 'Natural Language Sourcing Enabled',
  },
  {
    field: 'features.enabledNaturalLanguageSourcingBooleans',
    label: 'Natural Language Sourcing Boolean Queries Enabled',
  },
  {
    field: 'features.enabledNaturalLanguageFilteringProjectContacts',
    label: 'Natural Language Sourcing Filter Project Contacts',
  },
  {
    field: 'features.enabledSalesProjects',
    label: 'Sales Projects',
  },
  {
    field: 'features.enabledExcludeViewedFilter',
    label: 'Can Exclude Viewed Candidates',
  },
  {
    field: 'features.enabledGenderFilter',
    label: 'Gender Filter Enabled',
  },
  {
    field: 'features.enabledAddToProjectByLinkedIn',
    label: 'Add to project by LinkedIn URL',
  },
  {
    field: 'features.enabledEnrichByElasticSearchOnly',
    label: 'Restrict enrichments to PDL',
  },
];
