import React from 'react';
import styles from './Specialization.module.scss';

interface SpecializationProps {
  value: string[];
}

const Specialization = ({ value }: SpecializationProps) => {
  const renderTags = () => {
    const tags: JSX.Element[] = [];
    let index = 0;
    let wordLength = 0;
    for (let i = 0; i < value.length; i++) {
      if (!value[i]) continue;
      if (wordLength + value[i].length < 16) {
        tags.push(
          <p
            className={`bg-blue-50 text-blue-500 text-xs leading-4 font-medium ${styles.tag}`}
          >
            {value[i]}
          </p>,
        );
        index += 1;
        wordLength += value[i].length;
      }
    }
    if (index < value.length) {
      tags.push(
        <p
          className={`bg-blue-50 text-blue-500 text-xs leading-4 font-medium ${styles.tag}`}
        >{`+${value.length - index}`}</p>,
      );
    }

    return tags;
  };

  return <div className='flex'>{renderTags()}</div>;
};

export default Specialization;
