const generateRoute = (
  unsafeRoute: string,
  params: Record<string, string | null> = {},
) => {
  let route = unsafeRoute;
  Object.keys(params).forEach((key) => {
    if (!params[key]) route = route.replace(`/:${key}`, '');
    else route = route.replace(`:${key}`, params[key] as string);
  });

  return route;
};
const routeNames = {
  home: (params?: Record<string, string>): string => generateRoute('/', params),
  signin: (params?: Record<string, string>): string =>
    generateRoute('/signin', params),
  organizations: (params?: Record<string, string>): string =>
    generateRoute('/organizations', params),
  search: (params?: Record<string, string>): string =>
    generateRoute('/search', params),
  organization: (params?: Record<string, string>): string =>
    generateRoute('/organization/:id', params),
  chats: (params?: Record<string, string>): string =>
    generateRoute('/chat', params),
  chatThread: (params?: Record<string, string>): string =>
    generateRoute('/chat/:chatId', params),
  users: (params?: Record<string, string>): string =>
    generateRoute('/users', params),
  apiLogs: (params?: Record<string, string>): string =>
    generateRoute('/api-logs', params),
};

export default routeNames;
