import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { globalStyles } from '../../globalStyles';
import { darkTheme } from '../../stitches.config';
import { BoxProps } from '../Box';
import { ModalProvider } from '../Modal';
import { ToastProvider } from '../Toast';
import { TooltipProvider } from '../Tooltip';

type Themes = 'light' | 'dark';

export const THEMES = {
  light: undefined,
  dark: darkTheme,
};

export interface ThemeProviderProps {
  theme?: Themes;
  children: ReactNode;
  toastContainerCss?: BoxProps['css'];
}

interface ThemeContextProps {
  theme?: Themes;
  themes: {
    light: undefined;
    dark: typeof darkTheme;
  };
  setTheme: (mode: Themes) => void;
}

const initialThemeContext: ThemeContextProps = {
  themes: THEMES,
  setTheme: () => undefined,
};

export const ThemeContext = createContext(initialThemeContext);
export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({
  children,
  theme,
  toastContainerCss,
}: ThemeProviderProps) => {
  const previousTheme = useRef<Themes>();
  const themes = THEMES;
  const [themeMode, setThemeMode] = useState<Themes>(theme ?? 'light');

  const setTheme = (newTheme: Themes) => {
    previousTheme.current = themeMode;
    setThemeMode(newTheme);
  };

  useEffect(() => {
    if (theme) {
      setTheme(theme);
    }
  }, [theme]);

  useEffect(() => {
    applyTheme(themeMode);
  }, [themeMode]);

  const applyTheme = (newTheme: Themes) => {
    const body = document?.body;
    if (body) {
      if (previousTheme.current) {
        body.classList.remove(previousTheme.current);
      }

      body.classList.add(themes[newTheme] ?? 'light');
    }
  };

  // apply global styles
  globalStyles();

  return (
    <ThemeContext.Provider
      value={{
        themes,
        theme: themeMode,
        setTheme,
      }}
    >
      <ModalProvider>
        <TooltipProvider>
          <ToastProvider css={toastContainerCss}>{children}</ToastProvider>
        </TooltipProvider>
      </ModalProvider>
    </ThemeContext.Provider>
  );
};
