import { forwardRef } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { styled } from '../../stitches.config';
import { StyledLabel } from '../Label';
import { Box } from '../Box';
import { useRadioGroup } from './RadioGroup';

const StyledRadio = styled(RadioGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: '$background',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '100%',
  border: '1px solid $neutral-blue-300',
  focus: { boxShadow: '$focusGray' },
  transition: 'box-shadow 0.3s ease-in-out',
  checked: {
    background: '$blue-200',
    border: '1px solid $blue-200',
  },
  disabled: {
    background: '$neutral-blue-100',
    cursor: 'not-allowed',
    checked: {
      background: '$blue-200',
      border: '1px solid $blue-200',
    },
  },
  hover: {
    backgroundColor: '$neutral-blue-100',
    checked: {
      background: '$primary-dark',
    },
    mixed: {
      background: '$primary-dark',
    },
  },
  variants: {
    size: {
      sm: {
        width: 16,
        height: 16,
      },
      base: {
        width: 20,
        height: 20,
      },
      lg: {
        width: 24,
        height: 24,
      },
    },
  },
  defaultVariants: {
    size: 'base',
  },
});

export const StyledIndicator = styled(RadioGroupPrimitive.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  position: 'relative',
  disabled: {
    '&::after': {
      backgroundColor: '$blue-400',
    },
  },
  '&::after': {
    content: '""',
    display: 'block',
    borderRadius: '50%',
    backgroundColor: '$blue-600',
  },
  variants: {
    size: {
      sm: {
        '&::after': {
          width: 6,
          height: 6,
        },
      },
      base: {
        '&::after': {
          width: 8,
          height: 8,
        },
      },
      lg: {
        '&::after': {
          width: 10,
          height: 10,
        },
      },
    },
  },
  defaultVariants: {
    size: 'base',
  },
});

const RadioLabel = styled(StyledLabel, {
  variants: {
    size: {
      sm: {
        fontSize: '$xs',
      },
      base: {
        fontSize: '$sm',
      },
      lg: {
        fontSize: '$base',
      },
    },
  },
});

export interface RadioProps extends React.ComponentProps<typeof StyledRadio> {
  as?: React.ElementType;
}

export const Radio = forwardRef<HTMLButtonElement, RadioProps>(
  ({ css, id, value, disabled, size, required, children, ...rest }, ref) => {
    const { required: contextRequired, disabled: contextDisabled } =
      useRadioGroup();

    return (
      <RadioLabel
        htmlFor={id}
        size={size}
        css={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          fontWeight: '$normal',
          ...css,
        }}
        {...rest}
      >
        <StyledRadio
          ref={ref}
          id={id}
          value={value}
          size={size}
          disabled={disabled ?? contextDisabled}
          required={required ?? contextRequired}
        >
          <StyledIndicator
            aria-disabled={disabled ?? contextDisabled}
            size={size}
          />
        </StyledRadio>
        {children && <Box css={{ pl: 8 }}>{children}</Box>}
      </RadioLabel>
    );
  },
);

Radio.displayName = 'Radio';
