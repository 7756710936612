import { Icon, IconProps } from '@betterleap/ui';

const iconProps: Omit<IconProps, 'name'> = {
  color: '$neutral-blue-700',
  size: 20,
};

const sidebarItems = [
  {
    title: 'Users',
    route: '/users',
    icon: <Icon name='user-add' {...iconProps} />,
  },
  {
    title: 'Organizations',
    route: '/organizations',
    icon: <Icon name='office-building' {...iconProps} />,
  },
  {
    title: 'Chat',
    route: '/chat',
    icon: <Icon name='speakerphone' {...iconProps} />,
  },
  {
    title: 'API Logs',
    route: '/api-logs',
    icon: <Icon name='database' {...iconProps} />,
  },
];

export default sidebarItems;
