export class QueueTaskApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get users active tasks
     * @returns QueueTask
     * @throws ApiError
     */
    find({ pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/queue-task',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * Get status and progress for a task by jobId
     * @returns TaskStatus
     * @throws ApiError
     */
    getJob({ jobId, queueName, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/queue-task/queue/{queueName}/job/{jobId}',
            path: {
                'jobId': jobId,
                'queueName': queueName,
            },
        });
    }
    /**
     * Update a queue task.
     * @returns QueueTask
     * @throws ApiError
     */
    update({ queueTaskId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/queue-task/{queueTaskId}',
            path: {
                'queueTaskId': queueTaskId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
