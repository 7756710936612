export class CompanyBillingInfoApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get company billing info
     * @returns CompanyBillingInfo
     * @throws ApiError
     */
    getCompanyBillingInfo({ xTestUser, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-billing-info',
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
    /**
     * Create or update company billing info
     * @returns any
     * @throws ApiError
     */
    updateCompanyBillingInfo({ requestBody, xTestUser, }) {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/company-billing-info',
            headers: {
                'x-test-user': xTestUser,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
