import routeNames from 'constants/routeNames';
import {
  ChatAppContextProvider,
  RecruiterChatView,
} from '@betterleap/chat-shared';
import { getUser, showToast } from '@betterleap/shared';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { apiClient } from 'lib/apiClient';
import logo from '../../../assets/images/betterleap-logo.png';
import config from '../../../lib/config';

export default function OrganizationAdminChatTemplate() {
  const { data: chatWorkspace, isLoading: workspaceLoading } = useQuery(
    ['org_chat_workspace'],
    () => apiClient.chatWorkspace.getOrgDefaultWorkspace(),
  );
  const navigate = useNavigate();

  if (!chatWorkspace?.data || workspaceLoading) {
    return (
      <div className='h-[calc(100vh-86px)] flex items-center justify-center'>
        <img src={logo} className='h-10 w-10 mx-auto' />
      </div>
    );
  }

  const chatConfig = {
    apiBaseUrl: config.endpoints.api,
    apiNamespace: 'admin',
  } as const;

  return (
    <ChatAppContextProvider
      getUserToken={() => getUser().then((u) => u!.getIdToken())}
      showGlobalError={(message) => showToast(message, undefined, 'error')}
      showToast={(message) => showToast(message, undefined, 'success')}
      initialState={{
        config: chatConfig,
        user: chatWorkspace.data.user,
        workspaces: chatWorkspace.data.workspaces,
        authState: 'signedIn',
      }}
    >
      <RecruiterChatView
        url={chatWorkspace.data.url}
        emptyState={
          <div className='grid place-items-center h-full'>
            <div className='text-center flex flex-col gap-2'>
              <img src={logo} className='h-20 w-20 mx-auto' />
              <div className='text-3xl font-bold'>Select a chat</div>
              <div className='text-gray-500 text-lg'>
                Start a new chat or select an existing one
              </div>
            </div>
          </div>
        }
        onChatClick={(chatUrl) => {
          navigate(chatUrl);
        }}
        onClickBack={() => {
          navigate(routeNames.chats());
        }}
      />
    </ChatAppContextProvider>
  );
}
