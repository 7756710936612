import { Text } from '@betterleap/ui';
import React, { useRef, useState } from 'react';
import styles from './Avatar.module.scss';

interface AvatarProps {
  name: string;
  color: string;
  image?: string;
  size?: number;
}

const Avatar = ({ name, color, image, size }: AvatarProps) => {
  const avatarImageRef = useRef<HTMLImageElement>(null);
  const [hideImage, setHideImage] = useState(false);
  return (
    <div
      className={`flex items-center justify-center ${image && 'relative'} ${
        image && hideImage ? styles.recruiter_wrapper : styles.company_wrapper
      }`}
      style={
        // eslint-disable-next-line no-nested-ternary
        image && !hideImage
          ? size
            ? {
                minWidth: `${size}px`,
                maxWidth: `${size}px`,
                minHeight: `${size}px`,
                maxHeight: `${size}px`,
                color: color === '#FBF2E6' ? '#0C1B3A' : '#FFF',
              }
            : {
                color: color === '#FBF2E6' ? '#0C1B3A' : '#FFF',
              }
          : size
          ? {
              minWidth: `${size}px`,
              maxWidth: `${size}px`,
              minHeight: `${size}px`,
              maxHeight: `${size}px`,
              backgroundColor: color,
            }
          : { backgroundColor: color }
      }
    >
      {image && !hideImage ? (
        <img
          ref={avatarImageRef}
          className={styles.image}
          src={image}
          alt=''
          onError={(event) => {
            if (avatarImageRef.current) {
              avatarImageRef.current?.setAttribute(
                'data-content',
                ((name || '').toUpperCase().match(/\b(\w)/g) || [])
                  .slice(0, 2)
                  ?.join(''),
              );
              setHideImage(true);
              avatarImageRef.current.setAttribute('style', 'display: none;');
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            event.onerror = null;
          }}
        />
      ) : (
        <Text
          className={`leading-7 font-semibold ${
            hideImage ? styles.history_size : ''
          }`}
          style={{ color: color === '#FBF2E6' ? '#0C1B3A' : '#FFF' }}
          css={{ fontSize: (size ?? 40) / 2 }}
        >
          {((name || '').toUpperCase().match(/\b(\w)/g) || [])
            .slice(0, 2)
            ?.join('')}
        </Text>
      )}
    </div>
  );
};

export default Avatar;
