import { AriaTextFieldOptions, useTextField } from '@react-aria/textfield';
import omit from 'lodash/omit.js';
import {
  cloneElement,
  forwardRef,
  MouseEvent,
  RefObject,
  useRef,
  useState,
} from 'react';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { styled } from '../../stitches.config';
import { IconProps } from '../Icon';

export const InputContainer = styled('div', {
  border: '1px solid $border-light',
  height: 38,
  borderRadius: '$2xl',
  display: 'flex',
  alignItems: 'center',
  px: '$8',
  transition: 'box-shadow 0.3s ease-in-out',
  overflow: 'hidden',
  backgroundColor: '$background-component',

  disabled: {
    backgroundColor: '$background-disabled-input',
    color: '$text-disabled',
    cursor: 'not-allowed',
  },
  focus: {
    boxShadow: '$focus',
    borderColor: '$border-light',
    invalid: {
      boxShadow: '$focus-invalid',
      borderColor: '$border-danger',
    },
  },
  invalid: {
    borderColor: '$border-danger',
  },

  variants: {
    size: {
      lg: {
        height: 48,
      },
      xl: {
        height: 56,
      },
    },
  },
});

export const StyledInput = styled('input', {
  flex: 1,
  outline: 'none',
  borderStyle: 'none',
  fontSize: '$sm',
  px: '$8',
  lineHeight: '2rem',
  background: 'transparent',
  width: '100%',

  placeholder: {
    fontSize: '$sm',
    color: '$text-placeholder',
  },

  disabled: {
    backgroundColor: '$background-disabled-input',
    color: '$text-disabled',
    cursor: 'not-allowed',
  },

  variants: {
    size: {
      lg: {
        height: 48,
      },
      xl: {
        height: 56,
        fontSize: '$base',
        placeholder: {
          fontSize: '$base',
        },
      },
    },
  },
});

export interface InputProps
  extends Omit<React.ComponentProps<typeof StyledInput>, 'onChange' | 'size'> {
  as?: React.ElementType;
  /** Is input invalid */
  error?: string;
  /** Content to left of input */
  leftIcon?: React.ReactElement<IconProps>;
  /** Content to right of input */
  rightIcon?: React.ReactElement<IconProps>;
  size?: React.ComponentProps<typeof InputContainer>['size'];
  onChange?: (value: string) => void;
  dataCy?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      readOnly,
      required,
      leftIcon,
      rightIcon,
      error,
      dataCy,
      onClick,
      size,
      ...rest
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLInputElement>();
    const combinedRef = useMergeRefs(internalRef, ref);
    const [focused, setFocused] = useState<boolean>();

    const handleContainerClick = () => {
      internalRef?.current?.focus();
    };

    const { inputProps } = useTextField(
      {
        isDisabled: disabled,
        isReadOnly: readOnly,
        isRequired: required,
        validationState: error ? 'invalid' : 'valid',
        ...(rest as AriaTextFieldOptions<'input'>),
      },
      internalRef as RefObject<HTMLInputElement>,
    );

    const { onBlur, onFocus, ...restInputProps } = inputProps;

    return (
      <InputContainer
        data-disabled={disabled}
        data-invalid={!!error}
        data-focus={focused}
        data-readonly={readOnly}
        size={size}
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          handleContainerClick();
          onClick?.(e);
        }}
        {...omit<Omit<InputProps, keyof typeof inputProps>>(
          rest,
          Object.keys(inputProps),
        )}
      >
        {leftIcon &&
          cloneElement(leftIcon, {
            color: leftIcon.props.color ?? '$neutral-blue-400',
            size: leftIcon.props.size ?? 18,
            ...leftIcon.props,
          })}
        <StyledInput
          ref={combinedRef}
          onFocus={(e) => {
            setFocused(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocused(false);
            onBlur?.(e);
          }}
          {...restInputProps}
          size={size}
          data-cy={dataCy}
        />
        {rightIcon &&
          cloneElement(rightIcon, {
            color: rightIcon.props.color ?? '$neutral-blue-400',
            size: rightIcon.props.size ?? 18,
            ...rightIcon.props,
          })}
      </InputContainer>
    );
  },
);

Input.displayName = 'Input';
