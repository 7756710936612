/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Recruiter;
(function (Recruiter) {
    let yearsOfExperience;
    (function (yearsOfExperience) {
        yearsOfExperience["_0_5"] = "0-5";
        yearsOfExperience["_5_10"] = "5-10";
        yearsOfExperience["_10_15"] = "10-15";
        yearsOfExperience["_15_"] = "15+";
    })(yearsOfExperience = Recruiter.yearsOfExperience || (Recruiter.yearsOfExperience = {}));
    let status;
    (function (status) {
        status["NEW"] = "new";
        status["PENDING"] = "pending";
    })(status = Recruiter.status || (Recruiter.status = {}));
})(Recruiter || (Recruiter = {}));
