/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var PhoneNumberDto;
(function (PhoneNumberDto) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = PhoneNumberDto.type || (PhoneNumberDto.type = {}));
})(PhoneNumberDto || (PhoneNumberDto = {}));
