export class SavedSearchApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get saved searches.
     * @returns SavedSearch
     * @throws ApiError
     */
    get({ pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/saved-search',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * Create a saved search.
     * @returns SavedSearch
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/saved-search',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates a saved search.
     * @returns any
     * @throws ApiError
     */
    update({ savedSearchId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/saved-search/{savedSearchId}',
            path: {
                'savedSearchId': savedSearchId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Deletes a saved search.
     * @returns any
     * @throws ApiError
     */
    delete({ savedSearchId, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/saved-search/{savedSearchId}',
            path: {
                'savedSearchId': savedSearchId,
            },
        });
    }
}
