import React, { forwardRef } from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import { ChartProps, Line } from 'react-chartjs-2';
import { Box, BoxProps } from '../Box';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

export const areaChartOptions: ChartProps<'line'>['options'] = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  layout: {
    padding: {
      top: 20,
    },
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 4,
      backgroundColor: '#3B82F6',
      hoverBackgroundColor: '#3B82F6',
      hoverBorderWidth: 15,
      hoverBorderColor: 'rgb(59, 130, 246, 0.1)',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      display: false,
      grace: '20%',
    },
  },
  plugins: {
    tooltip: {
      displayColors: false,
      bodyAlign: 'center',
      position: 'nearest',
      xAlign: 'center',
      yAlign: 'bottom',
      caretPadding: 16,
      mode: 'nearest',
      backgroundColor: '#1A1C20',
      callbacks: {
        label: (context) => context?.parsed?.y.toString(),
        title: () => '',
      },
      animation: {
        duration: 0,
      },
    },
  },
};

export interface Datum {
  label: string;
  value: number;
}

export interface AreaChartStyleOptions {
  lineColor?: string;
  gradientFunc?: (c: any) => CanvasGradient;
}

export const styleAreaChartData = (
  data: Datum[],
  options: AreaChartStyleOptions = {},
) => ({
  labels: data.map((datum) => datum.label),
  datasets: [
    {
      fill: true,
      data: data.map((datum) => datum.value),
      borderColor: options?.lineColor ?? '#3B82F6',
      tension: 0.3,
      backgroundColor:
        options.gradientFunc ??
        createGradient(['rgb(90, 154, 252, 0.075)', 'rgba(90, 154, 252, 0)']),
    },
  ],
});

export const createGradient =
  (colorStops: string[]) =>
  (context: any): CanvasGradient => {
    const { chart } = context;
    const { ctx } = chart;

    // TODO: be smarter about calculating the gradient's coordinates. i.e. from top to bottom of chart. or from top of highest y value.
    const gradientStroke = (
      ctx as CanvasRenderingContext2D
    ).createLinearGradient(0, 0, 0, 300);

    colorStops.forEach((colorStop) => {
      gradientStroke.addColorStop(0, colorStop);
      gradientStroke.addColorStop(1, colorStop);
    });

    return gradientStroke;
  };

export interface AreaChartProps extends Omit<BoxProps, 'data'> {
  options: ChartProps<'line'>['options'];
  data: ChartProps<'line'>['data'];
}

export const AreaChart = forwardRef<HTMLElement, AreaChartProps>(
  ({ options, data, style, ...rest }, ref) => (
    <Box
      ref={ref}
      className='chart-container'
      style={{ position: 'relative', width: '100%', height: '100%', ...style }}
      {...rest}
    >
      <Line options={options} data={data} />
    </Box>
  ),
);

AreaChart.displayName = 'AreaChart';
