/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateManyContactProjectsDto;
(function (CreateManyContactProjectsDto) {
    let source;
    (function (source) {
        source["EXTENSION"] = "Extension";
        source["CSV_UPLOAD"] = "CSV Upload";
        source["COPIED_FROM_PROJECT"] = "Copied from project";
        source["MOVED_FROM_PROJECT"] = "Moved from project";
        source["SOURCING_COPILOT"] = "Sourcing Copilot";
        source["WORLD_TALENT_POOL"] = "World Talent Pool";
        source["MY_TALENT_POOL"] = "My Talent Pool";
        source["INTERNAL_ACCOUNT_MANAGER"] = "Internal Account Manager";
    })(source = CreateManyContactProjectsDto.source || (CreateManyContactProjectsDto.source = {}));
})(CreateManyContactProjectsDto || (CreateManyContactProjectsDto = {}));
