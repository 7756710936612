import React, { forwardRef, useContext } from 'react';
import { useController } from 'react-hook-form';
import { CheckboxGroup, CheckboxGroupProps } from '../Checkbox';

import { useMergeRefs } from '../../hooks/useMergeRefs';
import { BoundComponentProps } from './types';
import { FormContext } from './Form';

export type BoundCheckboxGroupProps = CheckboxGroupProps & BoundComponentProps;

export const BoundCheckboxGroup = forwardRef<
  HTMLDivElement,
  BoundCheckboxGroupProps
>(
  (
    {
      id,
      control,
      name,
      defaultValue,
      rules,
      required,
      onChange: externalOnChange,
      ...rest
    },
    ref,
  ) => {
    const { control: contextControl } = useContext(FormContext);

    const {
      field: { ref: internalRef, value, onChange, ...checkboxProps },
    } = useController({
      name,
      control: control ?? contextControl,
      defaultValue,
      rules: {
        required: required && 'This field is required',
        ...rules,
      },
    });

    const combinedRef = useMergeRefs(ref, internalRef);

    return (
      <CheckboxGroup
        ref={combinedRef}
        id={id}
        value={value}
        onChange={(values: string[]) => {
          onChange(values);
          externalOnChange?.(values);
        }}
        {...checkboxProps}
        {...rest}
      />
    );
  },
);

BoundCheckboxGroup.displayName = 'BoundCheckboxGroup';
