/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var MergeSyncDto;
(function (MergeSyncDto) {
    let atsSource;
    (function (atsSource) {
        atsSource["LEVER"] = "Lever";
        atsSource["GREENHOUSE"] = "Greenhouse";
        atsSource["ASHBY"] = "Ashby";
        atsSource["RECRUITERFLOW"] = "Recruiterflow";
        atsSource["CLOCKWORK"] = "Clockwork";
        atsSource["BULLHORN"] = "Bullhorn";
        atsSource["ORACLE_TALEO"] = "Oracle Taleo";
    })(atsSource = MergeSyncDto.atsSource || (MergeSyncDto.atsSource = {}));
})(MergeSyncDto || (MergeSyncDto = {}));
