export class ContactSequenceApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Add Contact to Sequence
     * @returns ContactSequence
     * @throws ApiError
     */
    createContactSequence({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-sequence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Contact Sequence by id
     * @returns ContactSequence
     * @throws ApiError
     */
    getCandidateSequenceById({ id, relations, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-sequence/{id}',
            path: {
                'id': id,
            },
            query: {
                'relations': relations,
            },
        });
    }
    /**
     * Get most recent contact sequence for contact
     * @returns RecentContactSequenceStatusDto
     * @throws ApiError
     */
    getRecentSequenceStatus({ contactId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-sequence/recent-sequence-status/{contactId}',
            path: {
                'contactId': contactId,
            },
        });
    }
}
