import { AbilityBuilder, createMongoAbility, subject as forceSubject, } from '@casl/ability';
import { AUTH_ROLE } from './types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const subject = (tags, sub) => forceSubject(tags, sub);
const defineUserRole = (user, can) => {
    // User
    can('update', 'User', [
        'emailOptionIncludeUnsubscribeLink',
        'emailOptionMaxEmailsPerDay',
        'emailOptionMinSecondsBetweenEmails',
    ], { id: user.id });
    // Contact
    can('read', 'Contact', { organizationId: user.organizationId });
    can('update', 'Contact', { organizationId: user.organizationId });
    // ContactSequence
    can('update', 'ContactSequence', {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['sequence.organizationId']: user.organizationId,
    });
    // ContactSequenceStep
    can('update', 'ContactSequenceStep', {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['contactSequence.sequence.organizationId']: user.organizationId,
    });
    // Sender
    can('delete', 'Sender', { userId: user.id });
    // SavedSearch
    can('update', 'SavedSearch', { userId: user.id });
    can('delete', 'SavedSearch', { userId: user.id });
};
const defineAdminRole = (user, can) => {
    defineUserRole(user, can);
    // Invitation
    can('delete', 'Invitation');
    can('create', 'Invitation');
    // User
    can('delete', 'User', { organizationId: user.organizationId });
    can('update', 'User', ['roles', 'groupId'], {
        organizationId: user.organizationId,
    });
    // Organization
    can('update', 'Organization', [
        'atsSource',
        'crmSource',
        'accountToken',
        'crmAccountToken',
        'usePersonalEmailFallback',
        'useBusinessEmailFallback',
        'enabledEmailFooterChatLink',
    ], { id: user.organizationId });
    // Contact
    can('export', 'Contact');
    // Group
    can('create', 'Group');
    can('update', 'Group', ['name'], { organizationId: user.organizationId });
    // Misc
    can('manage', 'Billing');
};
const defineSuperAdminRole = (user, can) => {
    can('manage', 'all');
};
export const defineUserAbility = (user) => {
    var _a;
    const { can, build } = new AbilityBuilder(createMongoAbility);
    if (!user) {
        return build();
    }
    const roles = (_a = user === null || user === void 0 ? void 0 : user.roles) !== null && _a !== void 0 ? _a : [];
    if (roles.includes(AUTH_ROLE.User)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        defineUserRole(user, can);
    }
    if (roles.includes(AUTH_ROLE.Admin)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        defineAdminRole(user, can);
    }
    if (roles.includes(AUTH_ROLE.SuperAdmin)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        defineSuperAdminRole(user, can);
    }
    return build();
};
