import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';

export interface ConfirmModalProps {
  title: string;
  description: string;
  confirmText: string;
}

const ConfirmModal: PromiseModal<ConfirmModalProps> = ({
  onDismiss,
  onSubmit,
  title,
  description,
  confirmText,
}) => (
  <Modal>
    <Flex css={{ alignItems: 'center' }} vertical>
      <Box css={{ py: 16 }}>
        <AlertIcon variant='warning' name='speakerphone' />
      </Box>
      <ModalTitle>{title}</ModalTitle>
      <Text size='sm' css={{ color: '$text-lightest', textAlign: 'center' }}>
        {description}
      </Text>
      <Flex css={{ gap: 8, pt: 24, width: '100%' }}>
        <Button full variant='ghost' onClick={onDismiss}>
          Cancel
        </Button>
        <Button full onClick={onSubmit}>
          {confirmText}
        </Button>
      </Flex>
    </Flex>
  </Modal>
);

export default ConfirmModal;
