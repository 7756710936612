import { Box, Flex, Icon, IconButton } from '@betterleap/ui';
import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

export const JsonCodeBlock = ({
  json,
  onCopy,
}: {
  json: Record<string, unknown>;
  onCopy: (text: string) => void;
}) => {
  const [copied, setCopied] = useState(false);
  const formattedJson = JSON.stringify(json, null, 2);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
    onCopy?.(formattedJson);
  };

  return (
    <Box>
      <Flex
        css={{
          p: 8,
          gap: 8,
          background: '$neutral-blue-1000',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
        justify='end'
      >
        {copied && <Icon name='check' color='$success-base' size={18} />}
        <IconButton
          onClick={handleCopy}
          css={{
            hover: {
              backgroundColor: '$neutral-blue-900',
            },
          }}
          color='$white'
          size='sm'
          label='copy query'
          name='duplicate'
        />
      </Flex>
      <SyntaxHighlighter
        customStyle={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: 16,
          borderBottomRightRadius: 16,
          margin: 0,
        }}
        language='json'
        style={coldarkDark}
        showLineNumbers
      >
        {formattedJson}
      </SyntaxHighlighter>
    </Box>
  );
};
