import { saveUserToken, Types } from '@betterleap/shared';
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  Method,
} from 'axios';
import { get, omit } from 'lodash';
import qs from 'qs';
import { ApiEndpoint, ENDPOINTS } from '../constants/endpoints';
import config from './config';
import TOKEN from './token';

interface ApiHeaders {
  Authorization?: string;
  'x-test-user'?: string;
  'Content-Type'?: string;
}

interface ApiRequest extends AxiosRequestConfig {
  retry?: boolean;
}

class ApiFactory {
  headers: ApiHeaders;

  client: AxiosInstance;

  endpoints: typeof ENDPOINTS;

  constructor(endpoints: typeof ENDPOINTS) {
    this.headers = {};
    this.endpoints = endpoints;
    this.client = axios.create();

    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest: ApiRequest = error.config;

        if (error.response?.status === 401) {
          if (!originalRequest.retry) {
            const newToken = await saveUserToken();
            originalRequest.retry = true;
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${newToken}`,
            };
            return this.client(originalRequest);
          }
        }

        return Promise.reject(error);
      },
    );
  }

  fetch = <T = Types.IApiRequest>(
    endpoint: ApiEndpoint,
    data: Types.EndpointPayload = {},
    sendInParams?: boolean,
  ): AxiosPromise<T> => {
    const response = this.generateFullUri(this.endpoints[endpoint].uri, data);
    let { url } = response;
    const { removed } = response;
    const method = this.endpoints[endpoint].method as Method;
    const body: Types.EndpointPayload = omit(data, removed);
    const token = TOKEN.get();
    const formDataBody = new FormData();

    if (token) {
      this.headers = { Authorization: `Bearer ${token}` };
    }

    if (config.test_user) {
      this.headers['x-test-user'] = config.test_user;
    }
    if (get(this.endpoints[endpoint], 'isMultipart', false)) {
      this.headers['Content-Type'] = 'multipart/form-data';
      Object.keys(body).forEach((key) =>
        formDataBody.append(key, body[key] as Blob),
      );
    }
    if (method === 'GET' && !sendInParams) {
      const queryString = qs.stringify(body, { arrayFormat: 'repeat' });
      if (queryString) {
        url += `?${queryString}`;
      }
    }

    return this.client({
      url,
      method,
      data: get(this.endpoints[endpoint], 'isMultipart', false)
        ? formDataBody
        : body,
      headers: {
        ...this.headers,
      },
    });
  };

  private generateFullUri = (
    endpoint: string,
    data: Types.EndpointPayload = {},
  ) => {
    const removed: string[] = [];

    const url = `${config.endpoints.api}${endpoint}`.replace(
      /\{(.*?)\}/g,
      (token, name) => {
        let value = token;
        if (data && data[name]) {
          removed.push(name);
          value = (data[name] as string).toString();
        }
        return value;
      },
    );

    return { url, removed };
  };

  /* Tests */
  public reinitializeEndpoints = (endpoints: typeof ENDPOINTS) => {
    this.endpoints = endpoints;
  };
}

const api = new ApiFactory(ENDPOINTS);

export default api;
