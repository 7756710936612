import { cloneElement, forwardRef } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { styled } from '../../stitches.config';
import { Icon, IconProps } from '../Icon';
import { Box } from '../Box';

const StyledTrigger = styled(SelectPrimitive.SelectTrigger, {
  all: 'unset',
  display: 'inline-flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  border: '1px solid $border-light',
  width: '100%',
  borderRadius: '$2xl',
  cursor: 'pointer',
  padding: '0 16px',
  fontSize: 14,
  lineHeight: 1,
  height: 38,
  gap: 5,
  backgroundColor: 'white',
  color: '$text',
  '&:focus': { boxShadow: `$focus` },
  disabled: {
    backgroundColor: '$background-disabled-input',
    cursor: 'not-allowed',
  },
  invalid: {
    borderColor: '$border-danger',
  },
  variants: {
    variant: {
      headless: {
        border: 'none',
        backgroundColor: 'transparent',
        disabled: {
          backgroundColor: 'transparent',
          '& svg': {
            display: 'none',
          },
        },
      },
    },
    size: {
      xs: {
        height: 24,
        fontSize: '$xs',
      },
      sm: {
        height: 32,
        fontSize: '$sm',
      },
      lg: {
        height: 56,
        fontSize: '$md',
      },
    },
  },
});

const StyledContent = styled(SelectPrimitive.Content, {
  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: '$2xl',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
  margin: '8px 0',
  zIndex: 10,
});

const StyledViewport = styled(SelectPrimitive.Viewport, {
  padding: 5,
});

const StyledItem = styled(SelectPrimitive.Item, {
  all: 'unset',
  fontSize: 14,
  lineHeight: 1,
  color: '$text',
  borderRadius: '$2xl',
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '10px 35px 10px 15px',
  position: 'relative',
  userSelect: 'none',

  '&[data-disabled]': {
    color: '$text-disabled',
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: '$neutral-blue-100',
    color: '$text',
  },
  variants: {
    size: {
      xs: {
        fontSize: '$xs',
        padding: '2px 35px 2px 15px',
      },
      sm: {
        fontSize: '$sm',
        padding: '4px 35px 4px 15px',
      },
      lg: {
        fontSize: '$lg',
        padding: '12px 35px 12px 15px',
      },
    },
    variant: {
      placeholder: {
        color: '$text-placeholder',
        hover: {
          color: '$text-placeholder',
          backgroundColor: '$background-component',
        },
        '& .select-item-selected-indicator': { visibility: 'hidden' },
      },
    },
  },
});

const StyledLabel = styled(SelectPrimitive.Label, {
  padding: '0 25px',
  fontSize: 12,
  lineHeight: '25px',
  color: '$text',
});

const StyledSeparator = styled(SelectPrimitive.Separator, {
  height: 1,
  backgroundColor: '$background-dark',
  margin: 5,
});

const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator, {
  position: 'absolute',
  right: 0,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    size: {
      xs: {
        '& svg': {
          height: 12,
          width: 12,
        },
      },
    },
  },
});

const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 25,
  backgroundColor: 'white',
  color: '$text',
  cursor: 'default',
};

const StyledScrollUpButton = styled(
  SelectPrimitive.ScrollUpButton,
  scrollButtonStyles,
);

const StyledScrollDownButton = styled(
  SelectPrimitive.ScrollDownButton,
  scrollButtonStyles,
);

const StyledValue = styled(SelectPrimitive.Value, {
  flex: 1,
  color: 'inherit',
});

const StyledSelectIcon = styled(SelectPrimitive.Icon, {
  '& svg': {
    height: 16,
    width: 16,
  },
  variants: {
    size: {
      xs: {
        '& svg': {
          height: 12,
          width: 12,
        },
      },
      sm: {
        '& svg': {
          height: 14,
          width: 14,
        },
      },
      lg: {
        '& svg': {
          height: 20,
          width: 20,
        },
      },
    },
  },
});

// Exports
export const SelectTrigger = StyledTrigger;
export const SelectValue = SelectPrimitive.Value;
export const SelectIcon = StyledSelectIcon;
export const SelectContent = StyledContent;
export const SelectViewport = StyledViewport;
export const SelectGroup = SelectPrimitive.Group;

export const SelectItem = ({
  children,
  ...rest
}: React.ComponentProps<typeof StyledItem>) => (
  <StyledItem {...rest}>
    <SelectItemText>{children}</SelectItemText>
    <SelectItemIndicator className='select-item-selected-indicator'>
      <Icon size={16} color='$success-base' name='check' />
    </SelectItemIndicator>
  </StyledItem>
);

export const SelectItemText = SelectPrimitive.ItemText;
export const SelectItemIndicator = StyledItemIndicator;
export const SelectGroupLabel = StyledLabel;
export const SelectSeparator = StyledSeparator;
export const SelectScrollUpButton = StyledScrollUpButton;
export const SelectScrollDownButton = StyledScrollDownButton;

interface Props
  extends Omit<
    React.ComponentProps<typeof SelectTrigger>,
    'onChange' | 'children'
  > {
  as?: React.ElementType;
  options?: any[];
  /** Content to left of select trigger */
  leftIcon?: React.ReactElement<IconProps>;
  /** Is invalid */
  error?: string;

  readOnly?: boolean;

  placeholder?: string;

  onChange?: SelectPrimitive.SelectProps['onValueChange'];

  children?: React.ReactNode | ((option: any) => JSX.Element);
}

export type SelectProps = Omit<SelectPrimitive.SelectProps, 'children'> & Props;

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      children,
      options = [],
      onChange,
      readOnly,
      leftIcon,
      error,
      css,
      value,
      defaultValue,
      defaultOpen,
      open,
      onOpenChange,
      dir,
      name,
      size,
      placeholder = 'Choose option',
      ...rest
    },
    ref,
  ) => (
    <SelectPrimitive.Root
      onValueChange={onChange}
      value={value}
      defaultValue={defaultValue}
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      dir={dir}
      name={name}
    >
      <SelectTrigger
        ref={ref}
        css={css}
        disabled={readOnly}
        aria-invalid={!!error}
        size={size}
        {...rest}
      >
        {leftIcon &&
          cloneElement(leftIcon, {
            size: 18,
            color: '$neutral-blue-600',
            ...leftIcon.props,
          })}
        <Box css={{ flex: 1 }}>
          <StyledValue placeholder={placeholder} />
        </Box>
        <SelectIcon size={size}>
          <Icon name='chevron-down' />
        </SelectIcon>
      </SelectTrigger>
      <SelectContent onCloseAutoFocus={(e) => e.preventDefault()}>
        <SelectScrollUpButton>
          <Icon size={16} name='chevron-up' />
        </SelectScrollUpButton>
        <SelectViewport>
          {typeof children === 'function'
            ? options.map((option) =>
                (children as (option: any) => JSX.Element)(option),
              )
            : children}
        </SelectViewport>
        <SelectScrollDownButton>
          <Icon size={16} name='chevron-down' />
        </SelectScrollDownButton>
      </SelectContent>
    </SelectPrimitive.Root>
  ),
);

Select.displayName = 'Select';
