/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactPhoneNumber;
(function (ContactPhoneNumber) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = ContactPhoneNumber.type || (ContactPhoneNumber.type = {}));
})(ContactPhoneNumber || (ContactPhoneNumber = {}));
