export class CompanyApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create or update company
     * @returns any
     * @throws ApiError
     */
    createOrUpdateCompany({ requestBody, xTestUser, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/company',
            headers: {
                'x-test-user': xTestUser,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update company
     * @returns any
     * @throws ApiError
     */
    updateCompany({ requestBody, xTestUser, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/company',
            headers: {
                'x-test-user': xTestUser,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get logged in users company
     * @returns Company
     * @throws ApiError
     */
    getCompany({ xTestUser, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company',
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
    /**
     * Create or update company
     * @returns Company
     * @throws ApiError
     */
    createCompanyAdminAndCompanyForUser({ requestBody, xTestUser, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/company/new',
            headers: {
                'x-test-user': xTestUser,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Complete onboarding
     * @returns any
     * @throws ApiError
     */
    completeOnboarding({ xTestUser, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/company/complete-onboarding',
            headers: {
                'x-test-user': xTestUser,
            },
        });
    }
}
