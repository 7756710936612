/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Role;
(function (Role) {
    let urgency;
    (function (urgency) {
        urgency["LOW"] = "Low";
        urgency["MEDIUM"] = "Medium";
        urgency["HIGH"] = "High";
    })(urgency = Role.urgency || (Role.urgency = {}));
    let equity;
    (function (equity) {
        equity["_0_0_25_"] = "0-0.25%";
        equity["_0_25_0_5_"] = "0.25-0.5%";
        equity["_0_5_0_75_"] = "0.5-0.75%";
        equity["_0_75_1_"] = "0.75-1%";
        equity["_1_"] = "1%+";
    })(equity = Role.equity || (Role.equity = {}));
    let status;
    (function (status) {
        status["INACTIVE"] = "Inactive";
        status["UNMATCHED"] = "Unmatched";
        status["OFFERED"] = "Offered";
        status["REMATCH_REQUESTED"] = "Rematch requested";
        status["PAUSED"] = "Paused";
        status["MATCHED"] = "Matched";
        status["OFFER_APPROVED"] = "Offer approved";
        status["OPEN"] = "Open";
        status["CANCELLED"] = "Cancelled";
    })(status = Role.status || (Role.status = {}));
    let source;
    (function (source) {
        source["BETTERLEAP"] = "Betterleap";
        source["MERGE"] = "Merge";
    })(source = Role.source || (Role.source = {}));
})(Role || (Role = {}));
