/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { Action } from 'redux';
import { put, takeEvery } from 'redux-saga/effects';
import { Types } from '@betterleap/shared';
import { ApiEndpoint } from '../../constants/endpoints';
import routeNames from '../../constants/routeNames';
import api from '../../lib/api';
import { API, ApiAction, apiActions } from './actions';

export function* onApiLoad({ payload, type, meta, helpers }: ApiAction) {
  const actionType = type
    .replace(API.FETCH_START, '')
    .toLowerCase() as ApiEndpoint;
  try {
    const response: AxiosResponse<Types.ApiReducer<Types.ApiResponse>> =
      yield api.fetch(actionType, payload as Types.EndpointPayload | undefined);

    yield put(
      apiActions.fetchSuccess(
        actionType,
        response.data.data,
        {
          ...response.data.meta,
          debounce: {
            time: _.get(meta, 'debounce.time', 0) + 100,
            key: `${actionType}Success`,
          },
        },
        helpers ?? undefined,
      ),
    );
  } catch (e: any) {
    if (e.response?.status === 401) {
      yield put(apiActions.remove());
      yield helpers?.navigate(routeNames.signin());
    }

    yield put(
      apiActions.fetchFailure(
        actionType,
        e.response,
        {
          debounce: {
            time: _.get(meta, 'debounce.time', 0) + 100,
            key: `${actionType}Failure`,
          },
        },
        helpers ?? undefined,
      ),
    );
  }
}

export function* watchApiLoad() {
  yield takeEvery(
    (action: Action) => /^FETCH_START_/.test(action.type),
    onApiLoad,
  );
}

export default [watchApiLoad];
