import { FocusOutsideEvent } from '@radix-ui/react-dismissable-layer';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { keyframes } from '@mpalmerlee/stitches-react';
import { createContext, forwardRef, useContext } from 'react';
import { styled } from '../../stitches.config';
import { Icon } from '../Icon';
import { useCheckboxGroup } from '../Checkbox/CheckboxGroup';
import { CheckboxProps } from '../Checkbox';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

export interface MenuContextProps {
  onDismiss?: () => void;
  closeOnSelect?: boolean;
}

export const MenuContext = createContext<MenuContextProps>({});
export const useMenu = () => useContext(MenuContext);

const StyledMenuContent = styled(DropdownMenuPrimitive.Content, {
  minWidth: 150,
  backgroundColor: '$background-component',
  borderRadius: '$lg',
  py: 6,
  zIndex: 51,
  boxShadow: '$lg',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export interface MenuContentProps
  extends React.ComponentProps<typeof StyledMenuContent> {
  /** Element type to render */
  as?: React.ElementType;
}

export const MenuContent = forwardRef<HTMLElement, MenuContentProps>(
  ({ as, onEscapeKeyDown, onInteractOutside, ...rest }, ref) => {
    const { onDismiss } = useMenu();

    return (
      <StyledMenuContent
        as={as}
        ref={ref}
        sideOffset={6}
        onEscapeKeyDown={(e: KeyboardEvent) => {
          onEscapeKeyDown?.(e);
          onDismiss?.();
        }}
        onInteractOutside={(e: FocusOutsideEvent) => {
          onInteractOutside?.(e);
          onDismiss?.();
        }}
        collisionTolerance={16}
        loop
        {...rest}
      />
    );
  },
);

MenuContent.displayName = 'MenuContent';

export type MenuTriggerProps = React.ComponentProps<
  typeof DropdownMenuPrimitive.Trigger
>;

export const MenuTrigger = forwardRef<HTMLButtonElement, MenuTriggerProps>(
  ({ ...rest }, ref) => (
    <DropdownMenuPrimitive.Trigger ref={ref} asChild {...rest} />
  ),
);

MenuTrigger.displayName = 'MenuTrigger';

const StyledMenuItemSeparator = styled(DropdownMenuPrimitive.Separator, {
  height: 1,
  backgroundColor: '$border',
});

const StyledMenuArrow = styled(DropdownMenuPrimitive.Arrow, {
  fill: '$background-component',
  filter: 'drop-shadow(rgba(0, 0, 0, 0.1) 0px 1px 0.5px)',
});

const StyledMenuItem = styled(DropdownMenuPrimitive.Item, {
  all: 'unset',
  fontSize: '$sm',
  lineHeight: 1,
  color: '$text-light',
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 5px',
  position: 'relative',
  py: 8,
  px: 16,
  userSelect: 'none',
  cursor: 'pointer',

  '&[data-disabled]': {
    cursor: 'not-allowed',
    color: '$text-placeholder',
    pointerEvents: 'none',
  },

  '&:focus': {
    backgroundColor: '$background-muted',
  },

  variants: {
    variant: {
      danger: {
        color: '$danger-800',
      },
    },
  },
});

const StyledCheckboxMenuItem = styled(DropdownMenuPrimitive.CheckboxItem, {
  all: 'unset',
  fontSize: '$sm',
  lineHeight: 1,
  color: '$text-light',
  display: 'flex',
  alignItems: 'center',
  height: 25,
  padding: '0 5px',
  position: 'relative',
  py: 8,
  px: 16,
  userSelect: 'none',
  cursor: 'pointer',

  '&[data-disabled]': {
    cursor: 'not-allowed',
    color: '$text-placeholder',
    pointerEvents: 'none',
  },

  focus: {
    backgroundColor: '$background-muted',
  },

  variants: {
    variant: {
      danger: {
        color: '$danger-800',
      },
    },
  },
});

export const StyledMenuCheckbox = styled('div', {
  backgroundColor: '$background',
  width: 16,
  height: 16,
  overflow: 'hidden',
  borderRadius: 4,
  border: '1px solid $border',
  display: 'flex',
  alignItems: 'center',
  padding: 0,
  justifyContent: 'center',
  mr: 8,
  lineHeight: '$xs',
  checked: {
    background: '$primary',
    border: '1px solid $primary',
  },
  disabled: {
    background: '$background-disabled-input',
    cursor: 'not-allowed',
    checked: {
      background: '$primary-300',
      border: '1px solid $primary-300',
    },
  },
});

export const Menu = ({
  children,
  onDismiss,
  closeOnSelect = true,
  ...rest
}: MenuProps & MenuContextProps) => (
  <MenuContext.Provider
    value={{
      onDismiss,
      closeOnSelect,
    }}
  >
    <DropdownMenuPrimitive.Root {...rest}>
      {children}
    </DropdownMenuPrimitive.Root>
  </MenuContext.Provider>
);

export const MenuItem = forwardRef<HTMLDivElement, MenuItemProps>(
  ({ onSelect, dataCy, ...rest }, ref) => {
    const { onDismiss, closeOnSelect } = useMenu();

    return (
      <StyledMenuItem
        data-cy={dataCy}
        ref={ref}
        onSelect={(e) => {
          if (!closeOnSelect) {
            e.preventDefault();
          } else {
            onDismiss?.();
          }

          onSelect?.(e);
        }}
        {...rest}
      />
    );
  },
);

MenuItem.displayName = 'MenuItem';

export const CheckboxMenuItem = forwardRef<
  HTMLDivElement,
  CheckboxMenuItemProps
>(
  (
    {
      children,
      onChange,
      value,
      checked,
      defaultChecked,
      onSelect,
      disabled,
      name,
      ...rest
    },
    ref,
  ) => {
    const { onDismiss, closeOnSelect } = useMenu();
    const {
      isSelected,
      disabled: contextDisabled,
      name: contextName,
      onChange: contextOnChange,
    } = useCheckboxGroup();

    const handleOnChange = onChange
      ? (newChecked: boolean) => {
          onChange?.(newChecked, value);
        }
      : () => value && contextOnChange?.(value);

    let isChecked: CheckboxProps['checked'] =
      isSelected && !!value ? isSelected?.(value) : undefined;
    isChecked = checked ?? isChecked;
    const isDisabled = disabled ?? contextDisabled;

    return (
      <StyledCheckboxMenuItem
        ref={ref}
        checked={isChecked}
        defaultChecked={defaultChecked}
        disabled={isDisabled}
        onCheckedChange={handleOnChange}
        data-name={name ?? contextName}
        onSelect={(e) => {
          if (!closeOnSelect) {
            e.preventDefault();
          } else {
            onDismiss?.();
          }

          onSelect?.(e);
        }}
        {...rest}
      >
        <StyledMenuCheckbox data-checked={isChecked} data-disabled={isDisabled}>
          <DropdownMenuPrimitive.ItemIndicator>
            <Icon
              color='$text-inverse'
              size={14}
              strokeWidth={4}
              name='check'
            />
          </DropdownMenuPrimitive.ItemIndicator>
        </StyledMenuCheckbox>
        {children}
      </StyledCheckboxMenuItem>
    );
  },
);

CheckboxMenuItem.displayName = 'CheckboxMenuItem';

export type MenuProps = React.ComponentProps<typeof DropdownMenuPrimitive.Root>;
export type MenuItemDivider = React.ComponentProps<
  typeof StyledMenuItemSeparator
>;
export type MenuItemProps = React.ComponentProps<typeof StyledMenuItem> & {
  dataCy?: string;
};
export type CheckboxMenuItemProps = Omit<
  React.ComponentProps<typeof StyledCheckboxMenuItem>,
  'onChange'
> & {
  onChange?: (checked: boolean, value?: string) => void;
  value?: string;
  name?: string;
};
export type MenuArrowProps = React.ComponentProps<typeof StyledMenuArrow>;

export const MenuItemDivider = StyledMenuItemSeparator;
export const MenuArrow = StyledMenuArrow;
