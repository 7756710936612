import React from 'react';
import SignInTemplate from '../components/templates/SignIn/SignIn.template';

const SignIn: React.FC = () => (
  <React.StrictMode>
    <SignInTemplate />
  </React.StrictMode>
);

export default SignIn;
