import { apiClient } from 'lib/apiClient';
import { useInfiniteQuery } from 'react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Row,
  Spinner,
  useIntersectionObserver,
} from '@betterleap/ui';
import { useSearchParams } from 'react-router-dom';
import { LoadingArea } from '@betterleap/shared';
import { omit } from 'lodash';
import { ApiLogsLayout } from './ApiLogs.layout';
import { LogItem } from './LogItem';
import { LogDetail } from './LogDetail';
import { ApiLogSearch } from './ApiLogSearch';
import { AppliedApiLogFilters } from './AppliedApiLogFilters';
import { LivePauseButton } from './LivePauseButton';

export const ApiLogsTemplate = () => {
  const loadMoreDivRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [isPaused, setIsPaused] = useState(false);

  const logQuery = useMemo<{
    organizationId?: string;
    apiKeyId?: string;
    requestId?: string;
    pageSize?: number;
  }>(() => {
    return {
      take: 30,
      organizationId: searchParams.get('organizationId') ?? undefined,
      requestId:
        searchParams.get('filterByRequestId') === 'true'
          ? searchParams.get('requestId') ?? undefined
          : undefined,
      apiKeyId: searchParams.get('apiKeyId') ?? undefined,
    };
  }, [searchParams]);

  const {
    data: apiLogsResponse,
    isLoading: isApiLogsLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(
    ['api-logs', logQuery],
    ({ pageParam = null }) => {
      return apiClient.apiKeyManagement.getApiKeyRequestLogs({
        ...logQuery,
        cursor: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        const lastLog = lastPage.data?.[lastPage.data.length - 1];
        return lastLog
          ? `${lastLog.requestTime}_${lastLog.requestId}`
          : undefined;
      },
      refetchInterval: 5000,
      enabled: !isPaused,
    },
  );

  const handleIntersect = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useIntersectionObserver({
    target: loadMoreDivRef,
    onIntersect: handleIntersect,
    enabled: hasNextPage,
  });

  const pages = apiLogsResponse?.pages;

  return (
    <ApiLogsLayout>
      <ApiLogsLayout.Header css={{ p: 16 }}>
        <h1 className='font-bold text-3xl leading-9 text-gray-900'>API Logs</h1>
      </ApiLogsLayout.Header>
      <ApiLogsLayout.FilterBar
        css={{ borderBottom: '1px solid $border', p: 16 }}
      >
        <Row css={{ gridTemplateColumns: '1.25fr 1fr' }}>
          <Box>
            <Flex justify={'between'}>
              <LivePauseButton
                isPaused={isPaused}
                onClick={(paused) => setIsPaused(paused)}
              />
              <Flex justify='end' css={{ gap: 16, flex: 1 }}>
                <AppliedApiLogFilters
                  filters={
                    omit(logQuery, ['pageSize']) as Record<string, string>
                  }
                />
              </Flex>
            </Flex>
          </Box>
          <Box>
            <ApiLogSearch />
          </Box>
        </Row>
      </ApiLogsLayout.FilterBar>
      <ApiLogsLayout.LogViewer css={{ borderRight: '1px solid $border' }}>
        <LoadingArea isLoading={isApiLogsLoading}>
          <AnimatePresence>
            {pages?.map((page) =>
              page.data.map((apiLog) => (
                <motion.div
                  key={apiLog.requestId}
                  style={{ borderBottom: '1px solid #D6D9DE' }}
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.25 }}
                >
                  <LogItem style={{ height: 50 }} log={apiLog} />
                </motion.div>
              )),
            )}
          </AnimatePresence>
          <Flex ref={loadMoreDivRef} justify='center' css={{ height: 40 }}>
            {hasNextPage && isFetching && <Spinner />}
          </Flex>
        </LoadingArea>
      </ApiLogsLayout.LogViewer>
      <ApiLogsLayout.LogDetail>
        <LogDetail />
      </ApiLogsLayout.LogDetail>
    </ApiLogsLayout>
  );
};
