export class AttachmentApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get the file attachments for a contact
     * @returns Attachment
     * @throws ApiError
     */
    getAttachments({ contactId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/attachment/{contactId}',
            path: {
                'contactId': contactId,
            },
        });
    }
    /**
     * Attach a file to a contact
     * @returns Attachment
     * @throws ApiError
     */
    createAttachment({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/attachment',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove a file attachment from a contact
     * @returns any
     * @throws ApiError
     */
    deleteAttachment({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/attachment/{id}',
            path: {
                'id': id,
            },
        });
    }
}
