import { forwardRef } from 'react';
import { styled } from '../../stitches.config';

export const StyledRow = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  gap: '1em',
  alignItems: 'center',
});

export const StyledCol = styled('div', {
  gridColumn: 'span 12',

  variants: {
    span: {
      1: {
        gridColumn: 'span 1',
      },
      2: {
        gridColumn: 'span 2',
      },
      3: {
        gridColumn: 'span 3',
      },
      4: {
        gridColumn: 'span 4',
      },
      5: {
        gridColumn: 'span 5',
      },
      6: {
        gridColumn: 'span 6',
      },
      7: {
        gridColumn: 'span 7',
      },
      8: {
        gridColumn: 'span 8',
      },
      9: {
        gridColumn: 'span 9',
      },
      10: {
        gridColumn: 'span 10',
      },
      11: {
        gridColumn: 'span 11',
      },
      12: {
        gridColumn: 'span 12',
      },
    },
  },
});

export interface RowProps extends React.ComponentProps<typeof StyledRow> {
  as?: React.ElementType;
}

export interface ColProps extends React.ComponentProps<typeof StyledCol> {
  as?: React.ElementType;
}

export const Row = forwardRef<HTMLElement, RowProps>(({ ...rest }, ref) => (
  <StyledRow ref={ref} {...rest} />
));

Row.displayName = 'Row';

export const Col = forwardRef<HTMLElement, ColProps>(({ ...rest }, ref) => (
  <StyledCol ref={ref} {...rest} />
));

Col.displayName = 'Col';
