export class ContactApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get contacts in an organization
     * @returns OrganizationCandidate
     * @throws ApiError
     */
    getContacts({ search, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact',
            query: {
                'pageSize': pageSize,
                'page': page,
                'search': search,
            },
        });
    }
    /**
     * Lookup LinkedIn profile, create new contact and optionally add to a sequence
     * @returns any
     * @throws ApiError
     */
    sourceContact({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact/source',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update an existing contact
     * @returns Contact
     * @throws ApiError
     */
    updateContact({ contactId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/contact/{contactId}',
            path: {
                'contactId': contactId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Set the externally-contacted status of a contact
     * @returns any
     * @throws ApiError
     */
    setExternallyContactedStatus({ contactId, status, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/contact/{contactId}/externally-contacted',
            path: {
                'contactId': contactId,
            },
            query: {
                'status': status,
            },
        });
    }
    /**
     * @returns Contact
     * @throws ApiError
     */
    getAllContacts({ pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/all',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    exportAsCsv({ projectId, contactIds, statuses, activityTypes, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/export/csv',
            query: {
                'projectId': projectId,
                'contactIds': contactIds,
                'statuses': statuses,
                'activityTypes': activityTypes,
            },
        });
    }
    /**
     * Count contacts sourced into projects by this organization
     * @returns CountDto
     * @throws ApiError
     */
    countAll() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/count',
        });
    }
    /**
     * Count contacts in this group by time period
     * @returns DataPoint
     * @throws ApiError
     */
    getContactMetrics({ interval = 'month', start, end, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/metrics',
            query: {
                'interval': interval,
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * Find matching values based on a particular search field
     * @returns PersonSearchAutocompleteResult
     * @throws ApiError
     */
    discoverContactsFieldAutocomplete({ field, text, size, minMatchCount, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/discovery/autocomplete',
            query: {
                'field': field,
                'text': text,
                'size': size,
                'minMatchCount': minMatchCount,
            },
        });
    }
    /**
     * Retrieve an existing contact
     * @returns GetContactEnhancedDto
     * @throws ApiError
     */
    getContact({ id, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Delete an existing contact
     * @returns any
     * @throws ApiError
     */
    deleteContact({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/contact/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Returns any projects and sequences the candidate is currently in
     * @returns CandidateSourceStatus
     * @throws ApiError
     */
    getSourceStatus({ linkedinUrl, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/source/status',
            query: {
                'linkedinUrl': linkedinUrl,
            },
        });
    }
    /**
     * Removes contact from project and sequence.
     * @returns any
     * @throws ApiError
     */
    removeFromProjectSequence({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact/remove-from-project-sequence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Removes contacts from project and sequence.
     * @returns any
     * @throws ApiError
     */
    bulkRemoveFromProjectSequence({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact/bulk-remove-from-project-sequence',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Removes contacts from project and sequence.
     * @returns any
     * @throws ApiError
     */
    bulkPauseOrResumeContactSequences({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact/bulk-pause-or-resume-contact-sequences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add many contacts from CSV
     * @returns HandleCsvUploadResultDto
     * @throws ApiError
     */
    insertManyContacts({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact/many',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Return if CSV Upload is enabled for user
     * @returns any
     * @throws ApiError
     */
    canUserUploadCsvContacts() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact/many/allowed',
        });
    }
    /**
     * Add many contacts from Enrichment Provider data
     * @returns SourceManContactsResult
     * @throws ApiError
     */
    sourceManyContacts({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact/source/many',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Unsubscribe a contact from sequence emails
     * @returns any
     * @throws ApiError
     */
    unsubscribeContact({ id, unsubscribeToken, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/unsubscribe/{unsubscribeToken}/contact/{id}',
            path: {
                'id': id,
                'unsubscribeToken': unsubscribeToken,
            },
        });
    }
}
