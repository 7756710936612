export class SenderApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get sender data for a user
     * @returns Sender
     * @throws ApiError
     */
    getSender() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sender',
        });
    }
    /**
     * Get all sender data for a user
     * @returns Sender
     * @throws ApiError
     */
    getAllSenders() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sender/all',
        });
    }
    /**
     * Get all senders for a user's organization
     * @returns any
     * @throws ApiError
     */
    getSendersByOrganization() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sender/my-organization',
        });
    }
    /**
     * Redirect to oauth
     * @returns any
     * @throws ApiError
     */
    getAuthUrlRedirect({ provider, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sender/oauth2url/redirect',
            query: {
                'provider': provider,
            },
        });
    }
    /**
     * OAuth Endpoint for Creating an authorized sequence sending account
     * @returns any
     * @throws ApiError
     */
    connectSender({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sender/oauth2complete',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Listens for change notifications from subscribed Outlook mailboxes
     * @returns any
     * @throws ApiError
     */
    notifyMicrosoft({ validationToken, requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sender/notify/microsoft',
            query: {
                'validationToken': validationToken,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    watch({ senderId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sender/watch/{senderId}',
            path: {
                'senderId': senderId,
            },
        });
    }
    /**
     * Handles push notifications from monitored Gmail inboxes
     * @returns void
     * @throws ApiError
     */
    notify({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sender/notify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Send a test email for a sequence step
     * @returns any
     * @throws ApiError
     */
    sendTestEmail({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sender/send-test-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get sender data for a user
     * @returns Sender
     * @throws ApiError
     */
    getSenderById({ senderId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sender/{senderId}',
            path: {
                'senderId': senderId,
            },
        });
    }
    /**
     * Delete sender for a user
     * @returns Sender
     * @throws ApiError
     */
    deleteSender({ senderId, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/sender/{senderId}',
            path: {
                'senderId': senderId,
            },
        });
    }
    /**
     * Set a signature for a sender
     * @returns any
     * @throws ApiError
     */
    setSignature({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/sender/signature',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
