import { ReactElement, useEffect, useState } from 'react';
import { getClearbitLogoUri } from '../../../helper';

export interface ClearbitLogoImageProps {
  domain?: string;
  requestedSize?: string;
  renderSize: number;
  fallbackElm: ReactElement;
}

const ClearbitLogoImage = (props: ClearbitLogoImageProps) => {
  const { domain, requestedSize, renderSize, fallbackElm } = props;

  const [imgValid, setImgValid] = useState(false);

  const logoUri = domain
    ? getClearbitLogoUri(domain, requestedSize)
    : undefined;

  useEffect(() => {
    const checkImage = (path: string) => {
      const img = new Image();
      img.onload = () => {
        // only show square images
        setImgValid(img.naturalHeight === img.naturalWidth);
      };
      img.onerror = () => {
        setImgValid(false);
      };

      img.src = path;
    };
    if (logoUri) {
      checkImage(logoUri);
    }
  }, []);

  if (!logoUri || !imgValid) {
    return fallbackElm;
  }
  return (
    <img src={logoUri} width={renderSize} height={renderSize} alt={domain} />
  );
};

export default ClearbitLogoImage;
