import { Types } from '@betterleap/shared';
import { Location, NavigateFunction } from 'react-router-dom';
import { ActionsUnion, createAction, IActionWithPayload } from '../action';

enum API {
  FETCH_START = 'FETCH_START_',
  FETCH_SUCCESS = 'FETCH_SUCCESS_',
  FETCH_FAILURE = 'FETCH_FAILURE_',
  REMOVE = 'REMOVE_',
  UPDATE = 'UPDATE_',
}

const apiActions = {
  fetch: (
    endpoint: Types.Endpoint,
    data: Types.EndpointPayload = {},
    meta?: Types.IApiMeta,
    helpers?: { navigate: NavigateFunction; location: Location } | null,
  ): IActionWithPayload<
    string,
    Types.EndpointPayload,
    Types.IApiMeta,
    { navigate: NavigateFunction; location: Location }
  > => {
    const actionType = `${API.FETCH_START}${endpoint.toUpperCase()}`;

    return createAction(actionType, data, meta, helpers);
  },
  fetchSuccess: (
    endpoint: Types.Endpoint,
    data?: Types.ApiResponse,
    meta?: Types.ApiMetaResponse,
    helpers?: { navigate: NavigateFunction; location: Location },
  ): IActionWithPayload<
    string,
    Types.ApiResponse | undefined,
    Types.ApiMetaResponse,
    { navigate: NavigateFunction; location: Location }
  > => {
    const actionType = `${API.FETCH_SUCCESS}${endpoint.toUpperCase()}`;
    return createAction(actionType, data, meta, helpers);
  },
  fetchFailure: (
    endpoint: Types.Endpoint,
    data: Types.ApiError,
    meta?: Types.IApiMeta,
    helpers?: { navigate: NavigateFunction; location: Location },
  ): IActionWithPayload<
    string,
    Types.ApiError,
    Types.IApiMeta,
    { navigate: NavigateFunction; location: Location }
  > => {
    const actionType = `${API.FETCH_FAILURE}${endpoint.toUpperCase()}`;
    return createAction(actionType, data, meta, helpers);
  },
  remove: (
    endpoint?: Types.Endpoint,
  ): IActionWithPayload<string, null, unknown, null> => {
    const actionType = endpoint
      ? `${API.REMOVE}${endpoint?.toUpperCase()}`
      : `${API.REMOVE}ALL`;
    return createAction(actionType, null, {});
  },
  update: (
    endpoint: Types.Endpoint,
    data?: Types.ApiResponse,
    meta?: Types.ApiMetaResponse,
  ): IActionWithPayload<
    string,
    Types.ApiResponse | undefined,
    Types.ApiMetaResponse,
    { navigate: NavigateFunction; location: Location }
  > => {
    const actionType = `${API.UPDATE}${endpoint.toUpperCase()}`;
    return createAction(actionType, data, meta);
  },
};

export type ApiAction = ActionsUnion<typeof apiActions>;
export { API, apiActions };
