import { useButton } from '@react-aria/button';
import { mergeProps } from '@react-aria/utils';
import { AriaButtonProps, LinkButtonProps } from '@react-types/button';
import { forwardRef, RefObject, useRef } from 'react';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { styled } from '../../stitches.config';

export const StyledButton = styled('button', {
  boxSizing: 'border-box',
  cursor: 'pointer',
  outline: 0,
  display: 'inline-flex',
  alignItems: 'center',
  color: 'white',
  fill: 'white',
  borderStyle: 'solid',
  borderWidth: '$thin',
  width: 'fit-content',
  transition: 'box-shadow 0.3s ease-in-out',
  textDecoration: 'none',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  focus: {
    boxShadow: '$focus',
  },
  disabled: {
    backgroundColor: '$background-disabled',
    borderColor: '$background-disabled',
    cursor: 'not-allowed',
    color: 'white',
  },
  variants: {
    full: {
      true: {
        width: '100%',
      },
    },
    size: {
      xs: {
        fontSize: '$xs',
        height: 24,
        px: '$12',
        borderRadius: 8,
      },
      sm: {
        fontSize: '$sm',
        height: 32,
        py: '$8',
        px: '$12',
        borderRadius: 10,
      },
      base: {
        fontSize: '$sm',
        height: 40,
        py: '$8',
        px: '$16',
        borderRadius: 12,
      },
      lg: {
        fontSize: '$sm',
        height: 48,
        px: '$16',
        py: '$8',
        borderRadius: 14,
      },
      xl: {
        fontSize: '$base',
        height: 56,
        px: '$24',
        py: '$14',
        borderRadius: 16,
      },
    },
    variant: {
      ghost: {
        backgroundColor: '$background',
        borderColor: '$border',
        borderWidth: '$thin',
        color: '$text-light',
        fill: '$text-light',
        hover: {
          backgroundColor: '$background-muted',
        },
        pressed: {
          backgroundColor: '$background-dark',
        },
      },
      'empty-primary': {
        backgroundColor: 'transparent',
        borderColor: '$primary-base',
        borderWidth: '$thin',
        color: '$primary-base',
        fill: '$primary-base',
        hover: {
          color: '$primary-700',
          fill: '$primary-700',
          borderColor: '$primary-700',
        },
        pressed: {
          backgroundColor: '$blue-200',
        },
        disabled: {
          backgroundColor: '$background',
          borderColor: '$primary-300',
          cursor: 'not-allowed',
          color: '$primary-300',
          fill: '$primary-300',
        },
      },
      'empty-purple': {
        backgroundColor: 'transparent',
        borderColor: '$purple-600',
        borderWidth: '$thin',
        color: '$purple-600',
        fill: '$purple-600',
        hover: {
          color: '$purple-700',
          fill: '$purple-700',
          borderColor: '$purple-700',
        },
        pressed: {
          backgroundColor: '$background-dark',
        },
        focus: {
          boxShadow: '$focusPurple',
        },
        disabled: {
          backgroundColor: '$background',
          borderColor: '$purple-300',
          cursor: 'not-allowed',
          color: '$purple-300',
          fill: '$purple-300',
        },
      },
      'empty-success': {
        backgroundColor: 'transparent',
        borderColor: '$green-600',
        borderWidth: '$thin',
        color: '$green-600',
        fill: '$green-600',
        hover: {
          color: '$green-700',
          fill: '$green-700',
          borderColor: '$green-700',
        },
        pressed: {
          backgroundColor: '$background-dark',
        },
        focus: {
          boxShadow: '$focusSuccess',
        },
        disabled: {
          backgroundColor: '$background',
          borderColor: '$green-300',
          cursor: 'not-allowed',
          color: '$green-300',
          fill: '$green-300',
        },
      },
      'empty-warning': {
        backgroundColor: 'transparent',
        borderColor: '$yellow-600',
        borderWidth: '$thin',
        color: '$yellow-600',
        fill: '$yellow-600',
        hover: {
          color: '$yellow-700',
          fill: '$yellow-700',
          borderColor: '$yellow-700',
        },
        pressed: {
          backgroundColor: '$background-dark',
        },
        focus: {
          boxShadow: '$focusGray',
        },
        disabled: {
          backgroundColor: '$background',
          borderColor: '$yellow-300',
          cursor: 'not-allowed',
          color: '$yellow-300',
          fill: '$yellow-300',
        },
      },
      'empty-danger': {
        backgroundColor: 'transparent',
        borderColor: '$red-600',
        borderWidth: '$thin',
        color: '$red-600',
        fill: '$red-600',
        hover: {
          color: '$red-700',
          fill: '$red-700',
          borderColor: '$red-700',
        },
        pressed: {
          backgroundColor: '$background-dark',
        },
        focus: {
          boxShadow: '$focusDanger',
        },
        disabled: {
          backgroundColor: '$background',
          borderColor: '$red-300',
          cursor: 'not-allowed',
          color: '$red-300',
          fill: '$red-300',
        },
      },
      secondary: {
        borderColor: '$primary-100',
        background: '$primary-100',
        color: '$blue-600',
        fill: '$blue-600',
        hover: {
          backgroundColor: '$primary-200',
          borderColor: '$primary-200',
        },
        pressed: {
          backgroundColor: '$primary-300',
          borderColor: '$primary-300',
        },
        disabled: {
          backgroundColor: '$primary-100',
          borderColor: '$primary-100',
          cursor: 'not-allowed',
          color: '$primary-300',
          fill: '$primary-300',
        },
      },
      gray: {
        borderColor: '$neutral-blue-200',
        background: '$neutral-blue-200',
        color: '$neutral-blue-800',
        fill: '$neutral-blue-800',
        hover: {
          backgroundColor: '$neutral-blue-300',
          borderColor: '$neutral-blue-300',
        },
        pressed: {
          backgroundColor: '$neutral-blue-400',
          borderColor: '$neutral-blue-400',
        },
        disabled: {
          cursor: 'not-allowed',
          color: '$neutral-blue-500',
          fill: '$neutral-blue-500',
        },
        focus: {
          boxShadow: '$focusGray',
        },
      },
      primary: {
        borderColor: '$primary-base',
        background: '$primary-base',
        hover: {
          backgroundColor: '$primary-600',
          borderColor: '$primary-600',
        },
        pressed: {
          backgroundColor: '$primary-700',
          borderColor: '$primary-700',
        },
        disabled: {
          backgroundColor: '$blue-300',
          borderColor: '$blue-300',
          cursor: 'not-allowed',
        },
      },
      danger: {
        borderColor: '$danger-base',
        background: '$danger-base',
        hover: {
          backgroundColor: '$danger-500',
          borderColor: '$danger-500',
        },
        focus: {
          boxShadow: '$focusDanger',
        },
        pressed: {
          backgroundColor: '$danger-800',
          borderColor: '$danger-800',
        },
        disabled: {
          backgroundColor: '$red-400',
          borderColor: '$red-400',
          cursor: 'not-allowed',
        },
      },
      'danger-secondary': {
        color: '$red-600',
        borderColor: '$coral-100',
        background: '$coral-100',
        hover: {
          backgroundColor: '$coral-200',
          borderColor: '$coral-200',
        },
        focus: {
          boxShadow: '$focusDanger',
        },
        pressed: {
          backgroundColor: '$coral-500',
          borderColor: '$coral-500',
        },
        disabled: {
          backgroundColor: '$coral-100',
          borderColor: '$coral-100',
          color: '$red-300',
          cursor: 'not-allowed',
        },
      },
      link: {
        backgroundColor: 'transparent',
        border: 'none',
        color: '$primary',
        fill: '$primary',
        fontWeight: '$medium',
        fontSize: '$sm',
        p: 0,
        height: 'auto',
        borderRadius: '$none',
        hover: {
          textDecoration: 'underline',
        },
        pressed: {
          color: '$primary-700',
          fill: '$primary-700',
          borderColor: '$primary-700',
        },
      },
      'link-secondary': {
        backgroundColor: 'transparent',
        border: 'none',
        color: '$neutral-blue-700',
        fill: '$neutral-blue-700',
        fontWeight: '$medium',
        fontSize: '$sm',
        height: 'auto',
        p: 0,
        borderRadius: '$none',
        hover: {
          textDecoration: 'underline',
        },
        pressed: {
          color: '$neutral-blue-900',
          fill: '$neutral-blue-900',
          borderColor: '$primary-700',
        },
      },
      headless: {
        background: 'transparent',
        border: 'none',
        color: 'inherit',
        fill: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        p: 0,
        height: 'auto',
        borderRadius: '$none',
      },
      success: {
        borderColor: '$success-800',
        background: '$success-800',
        hover: {
          backgroundColor: '$success-800',
          borderColor: '$success-800',
        },
        focus: {
          boxShadow: '$focusSuccess',
        },
        pressed: {
          backgroundColor: '$success-800',
          borderColor: '$success-800',
        },
        disabled: {
          backgroundColor: '$success-400',
          borderColor: '$success-400',
          cursor: 'not-allowed',
        },
      },
      purple: {
        background: '$purple-600',
        hover: {
          backgroundColor: '$purple-700',
          borderColor: '$purple-700',
        },
        focus: {
          boxShadow: '$focusPurple',
        },
        pressed: {
          backgroundColor: '$purple-800',
          borderColor: '$purple-800',
        },
        disabled: {
          backgroundColor: '$purple-300',
          borderColor: '$purple-300',
          cursor: 'not-allowed',
        },
      },
      darkGray: {
        borderColor: '$neutral-blue-200',
        backgroundColor: '$neutral-blue-200',
        color: '$neutral-blue-800',
        fill: '$neutral-blue-800',
        hover: {
          backgroundColor: '$neutral-blue-400',
          borderColor: '$neutral-blue-400',
        },
        pressed: {
          backgroundColor: '$neutral-blue-500',
          borderColor: '$neutral-blue-500',
        },
        disabled: {
          cursor: 'not-allowed',
          color: '$neutral-blue-500',
          fill: '$neutral-blue-500',
        },
      },
    },
  },
  defaultVariants: {
    size: 'base',
    variant: 'primary',
  },
});

interface Props extends React.ComponentProps<typeof StyledButton> {
  /** Element type to render */
  as?: React.ElementType;
  dataCy?: string;
}

export type ButtonProps = Props & LinkButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ as, onClick, dataCy, ...rest }, ref) => {
    const internalRef = useRef<HTMLButtonElement>();
    const combinedRef = useMergeRefs(internalRef, ref);

    const { buttonProps, isPressed } = useButton(
      {
        ...(rest as AriaButtonProps),
        isDisabled: rest.disabled,
        onPress: onClick as AriaButtonProps['onPress'],
        elementType: typeof as === 'string' ? as : 'button',
      },
      internalRef as RefObject<HTMLButtonElement>,
    );

    return (
      <StyledButton
        as={as}
        ref={combinedRef}
        data-pressed={isPressed}
        data-cy={dataCy}
        {...mergeProps(rest, buttonProps)}
      />
    );
  },
);

Button.displayName = 'Button';
