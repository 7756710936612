/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateUserDto;
(function (CreateUserDto) {
    let role;
    (function (role) {
        role["NONE"] = "None";
        role["RECRUITER"] = "Recruiter";
        role["ADMIN"] = "Admin";
        role["COMPANY_ADMIN"] = "Company Admin";
    })(role = CreateUserDto.role || (CreateUserDto.role = {}));
})(CreateUserDto || (CreateUserDto = {}));
