export class AiAssistantApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Subscribe to suggestion response
     * @returns any
     * @throws ApiError
     */
    subscribe({ userId, sessionId, prompt, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai-text-suggestion',
            query: {
                'userId': userId,
                'sessionId': sessionId,
                'prompt': prompt,
            },
        });
    }
}
