import { Toastify } from '@betterleap/shared';
import { ThemeProvider } from '@betterleap/ui';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './pages';
import { SidebarProvider } from './context/SidebarContext';
import { store } from './store';
import './styles/_main.scss';

const queryClient = new QueryClient();

const App: React.FC = () => (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <SidebarProvider>
              <Toastify />
              <AppRouter />
            </SidebarProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

export default App;
