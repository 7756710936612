import React from 'react';
import withSidebar from 'components/HOC/withSidebar';
import OrganizationsTemplate from '../components/templates/Organizations/Organizations.template';

const Organizations: React.FC = () => (
  <React.StrictMode>
    <OrganizationsTemplate />
  </React.StrictMode>
);

export default withSidebar(Organizations);
