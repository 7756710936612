export const colors = {
  // 'slate-50': '#f8fafc',
  // 'slate-100': '#f1f5f9',
  // 'slate-200': '#e2e8f0',
  // 'slate-300': '#cbd5e1',
  // 'slate-400': '#94a3b8',
  // 'slate-500': '#64748b',
  // 'slate-600': '#475569',
  // 'slate-700': '#334155',
  // 'slate-800': '#1e293b',
  // 'slate-900': '#0f172a',
  // 'gray-50': '#f9fafb',
  // 'gray-200': '#e5e7eb',
  'gray-300': '#d1d5db',
  // 'gray-400': '#9ca3af',
  'gray-100': '#F3F4F6',
  'gray-500': '#6b7280',
  // 'gray-600': '#4b5563',
  // 'gray-700': '#374151',
  // 'gray-800': '#1f2937',
  // 'gray-900': '#111827',
  'red-100': '#fef2f2',
  'red-200': '#fee2e2',
  'red-300': '#fecaca',
  'red-400': '#fca5a5',
  'red-500': '#f87171',
  'red-600': '#ef4444',
  'red-700': '#dc2626',
  'red-800': '#b91c1c',
  'red-900': '#991b1b',
  'red-1000': '#7f1d1d',
  'orange-100': '#FFF7ED',
  'orange-200': '#FFEDD5',
  'orange-300': '#FED7AA',
  'orange-400': '#FDBA74',
  'orange-500': '#FB923C',
  'orange-600': '#F97316',
  'orange-700': '#EA580C',
  'orange-800': '#C2410C',
  'orange-900': '#9A3412',
  'orange-1000': '#7C2D12',
  'yellow-100': '#fefce8',
  'yellow-200': '#fef9c3',
  'yellow-300': '#fef08a',
  'yellow-400': '#fde047',
  'yellow-500': '#facc15',
  'yellow-600': '#F5BD49',
  'yellow-700': '#ca8a04',
  'yellow-800': '#a16207',
  'yellow-900': '#854d0e',
  'yellow-1000': '#713f12',
  'green-50': '#ECFDF5',
  'green-100': '#F0FDF4',
  'green-200': '#DCFCE7',
  'green-300': '#BBF7D0',
  'green-400': '#86EFAC',
  'green-500': '#4ADE80',
  'green-600': '#22C55E',
  'green-700': '#16A34A',
  'green-800': '#15803d',
  'green-900': '#166534',
  'green-1000': '#14532d',
  'teal-100': '#f0fdfa',
  'teal-200': '#ccfbf1',
  'teal-300': '#99f6e4',
  'teal-400': '#5eead4',
  'teal-500': '#2dd4bf',
  'teal-600': '#14b8a6',
  'teal-700': '#0d9488',
  'teal-800': '#0f766e',
  'teal-900': '#115e59',
  'teal-1000': '#134e4a',
  'cyan-100': '#ecfeff',
  'cyan-200': '#cffafe',
  'cyan-300': '#a5f3fc',
  'cyan-400': '#67e8f9',
  'cyan-500': '#22d3ee',
  'cyan-600': '#06b6d4',
  'cyan-700': '#0891b2',
  'cyan-800': '#0e7490',
  'cyan-900': '#155e75',
  'cyan-1000': '#164e63',
  'blue-50': '#F4F9FF',
  'blue-100': '#E9F3FF',
  'blue-200': '#C3DEFF',
  'blue-300': '#9EC8FF',
  'blue-400': '#7BB1FF',
  'blue-500': '#5A9AFC',
  'blue-600': '#3A83F7',
  'blue-700': '#2B6BD1',
  'blue-800': '#1E53A6',
  'blue-900': '#133C79',
  'blue-1000': '#0A264D',
  'neutral-blue-50': '#F9FAFB',
  'neutral-blue-100': '#F7F8F9',
  'neutral-blue-200': '#F0F1F3',
  'neutral-blue-300': '#E8EAED',
  'neutral-blue-400': '#D6D9DE',
  'neutral-blue-500': '#ADB3BD',
  'neutral-blue-600': '#87909E',
  'neutral-blue-700': '#656F7D',
  'neutral-blue-800': '#4F5762',
  'neutral-blue-900': '#3C414A',
  'neutral-blue-1000': '#2A2E34',
  'neutral-blue-1100': '#1A1C20',
  'light-blue-100': '#F0F9FF',
  'light-blue-200': '#E0F2FE',
  'light-blue-300': '#BAE6FD',
  'light-blue-400': '#7DD3FC',
  'light-blue-500': '#38BDF8',
  'light-blue-600': '#0EA5E9',
  'light-blue-700': '#0284C7',
  'light-blue-800': '#0369A1',
  'light-blue-900': '#075985',
  'light-blue-1000': '#0C4A6E',
  'violet-100': '#f5f3ff',
  'violet-200': '#ede9fe',
  'violet-300': '#ddd6fe',
  'violet-400': '#c4b5fd',
  'violet-500': '#a78bfa',
  'violet-600': '#8b5cf6',
  'violet-700': '#7c3aed',
  'violet-800': '#6d28d9',
  'violet-900': '#5b21b6',
  'violet-1000': '#4c1d95',
  'purple-100': '#f5f3ff',
  'purple-200': '#ede9fe',
  'purple-300': '#ddd6fe',
  'purple-400': '#c4b5fd',
  'purple-500': '#a78bfa',
  'purple-600': '#8b5cf6',
  'purple-700': '#7c3aed',
  'purple-800': '#6d28d9',
  'purple-900': '#5b21b6',
  'purple-1000': '#4c1d95',
  'pink-100': '#FDF2F8',
  'pink-200': '#FCE7F3',
  'pink-300': '#FBCFE8',
  'pink-400': '#F9A8D4',
  'pink-500': '#F472B6',
  'pink-600': '#EC4899',
  'pink-700': '#DB2777',
  'pink-800': '#BE185D',
  'pink-900': '#9D174D',
  'pink-1000': '#831843',
  'rose-100': '#fff1f2',
  'rose-200': '#ffe4e6',
  'rose-300': '#fecdd3',
  'rose-400': '#fda4af',
  'rose-500': '#fb7185',
  'rose-600': '#f43f5e',
  'rose-700': '#e11d48',
  'rose-800': '#be123c',
  'rose-900': '#9f1239',
  'rose-1000': '#881337',
  'indigo-100': '#EEF2FF',
  'indigo-200': '#E0E7FF',
  'indigo-300': '#C7D2FE',
  'indigo-400': '#A5B4FC',
  'indigo-500': '#818CF8',
  'indigo-600': '#6366F1',
  'indigo-700': '#4F46E5',
  'indigo-800': '#4338CA',
  'indigo-900': '#3730A3',
  'indigo-1000': '#312E81',
  'coral-100': '#FFEDED',
  'coral-200': '#FFD1D1',
  'coral-300': '#FFB9B9',
  'coral-400': '#FFA5A5',
  'coral-500': '#FF9696',
  'coral-600': '#FF8A8A',
  'coral-700': '#E07070',
  'coral-800': '#C15858',
  'coral-900': '#A14343',
  'coral-1000': '#823030',
  navy: '#061B3A',
  white: '#FFFFFF',
};
