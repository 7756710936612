/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var EmailUserSignupDto;
(function (EmailUserSignupDto) {
    let role;
    (function (role) {
        role["NONE"] = "None";
        role["RECRUITER"] = "Recruiter";
        role["ADMIN"] = "Admin";
        role["COMPANY_ADMIN"] = "Company Admin";
    })(role = EmailUserSignupDto.role || (EmailUserSignupDto.role = {}));
})(EmailUserSignupDto || (EmailUserSignupDto = {}));
