import { EnvironmentInfoFactory } from './environmentInfo';

class TransientReleaseToggleFactory extends EnvironmentInfoFactory {
  public isDemoFeatureEnabledByZone = () => !this.isProductionZone;

  public signInWithRedirect = () => true;

  public disablePayment = () => true;

  public atsTwoWaySyncFeature = () => this.isNonProductionZone;
}

const toggles = new TransientReleaseToggleFactory();

export { toggles };
