export class ContactEnrichmentApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get Experience data for a contact
     * @returns EnrichmentJobExperience
     * @throws ApiError
     */
    getContactExperience({ contactId, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-enrichment/experience',
            query: {
                'pageSize': pageSize,
                'page': page,
                'contactId': contactId,
            },
        });
    }
    /**
     * Get Education data for a contact
     * @returns EnrichmentEducation
     * @throws ApiError
     */
    getContactEducation({ contactId, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-enrichment/education',
            query: {
                'pageSize': pageSize,
                'page': page,
                'contactId': contactId,
            },
        });
    }
    /**
     * Get skills data for a contact
     * @returns string
     * @throws ApiError
     */
    getContactSkills({ contactId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-enrichment/skills',
            query: {
                'contactId': contactId,
            },
        });
    }
    /**
     * Get certifications for a contact
     * @returns EnrichmentCertification
     * @throws ApiError
     */
    getContactCertifications({ contactId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-enrichment/certifications',
            query: {
                'contactId': contactId,
            },
        });
    }
}
