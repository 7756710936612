import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const constructError = (axiosError: AxiosError<any>) => {
  let response: { description: string };
  if (axiosError.response?.data?.error?.name?.includes('BadRequestError')) {
    response = {
      description: 'Invalid value for total seats!',
    };
  } else {
    response = {
      description: 'Something went wrong!',
    };
  }
  return response;
};
