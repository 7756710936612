/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { get } from 'lodash';
import { showToast } from '../components/elements/Toastify/Toastify';
import { FirebaseAuthCredentials, FirebaseError } from '../types';
import { toggles } from './releaseToggles';
import { getEnvVar } from './getEnvVar';

export const FIREBASE_CONFIG = {
  apiKey: getEnvVar('REACT_APP_FIREBASE_API_KEY'),
  authDomain: getEnvVar('REACT_APP_FIREBASE_AUTH_DOMAIN'),
  projectId: getEnvVar('REACT_APP_FIREBASE_PROJECT_ID'),
  storageBucket: getEnvVar('REACT_APP_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnvVar('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
  appId: getEnvVar('REACT_APP_FIREBASE_APP_ID'),
  measurementId: getEnvVar('REACT_APP_FIREBASE_MEASUREMENT_ID'),
};

export const initFirebase = (): firebase.app.App =>
  firebase.initializeApp(FIREBASE_CONFIG);

export const getUser = async (): Promise<firebase.User | null> => {
  const user = firebase.auth().currentUser;

  if (user) return user;

  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(
      (firebaseUser) => resolve(firebaseUser),
      (error) => reject(error),
    );
  });
};

export const signInWithEmailLink = async (
  email: string,
): Promise<FirebaseAuthCredentials | null> => {
  if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
    try {
      const result = await firebase
        .auth()
        .signInWithEmailLink(email, window.location.href);
      window.localStorage.removeItem('emailForSignIn');
      const { additionalUserInfo, user } = result;
      const nameParts = user?.displayName?.split(' ');
      const name = nameParts
        ? {
            firstName: nameParts[0],
            lastName: nameParts[1],
          }
        : {};
      return {
        email: user?.email as string,
        ...name,
        isNewUser: additionalUserInfo?.isNewUser,
        firebaseId: user?.uid,
      };
    } catch (e) {
      showToast('Email is not correct!', {}, 'error');
    }
  } else {
    showToast('Email link is not correct!', {}, 'error');
  }
  return null;
};

export const saveUserToken = async (): Promise<string | undefined> => {
  const user = await getUser();
  const token = await user?.getIdToken();
  if (token) localStorage.setItem('token', token);
  return token;
};

export async function signUpWithEmailPassword(email: string, password: string) {
  const credential = await createUserWithEmailAndPassword(
    firebase.auth(),
    email,
    password,
  );
  await sendEmailVerification(credential.user);
  return credential;
}

export async function applyActionCode(code: string) {
  return firebase.auth().applyActionCode(code);
}

export function signInWithEmailPassword(email: string, password: string) {
  return signInWithEmailAndPassword(firebase.auth(), email, password);
}

export interface SignupCallback {
  credential: firebase.auth.UserCredential;
  mappedUser: FirebaseAuthCredentials;
}

export const signupWithGoogle = async (
  showLoader: (loading: boolean) => void,
  usePopup: boolean,
  onAuthenticated: (user: SignupCallback) => void,
) => {
  if (!usePopup) {
    await loginWithGoogle(showLoader);
  } else {
    const result = await loginWithGoogle(showLoader, true);
    await saveUserToken();
    const credential = result as firebase.auth.UserCredential;
    const mappedUser = mapGoogleUser(credential);
    onAuthenticated({ credential, mappedUser });
  }
};

export const loginWithGoogle = (
  showLoader: (loading: boolean) => void,
  usePopup?: boolean,
): Promise<void | firebase.auth.UserCredential> => {
  const provider = new firebase.auth.GoogleAuthProvider();

  provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
  provider.addScope('https://www.googleapis.com/auth/userinfo.email');
  provider.addScope('openid');

  return login(provider, showLoader, usePopup);
};

export const signupWithMicrosoft = async (
  showLoader: (loading: boolean) => void,
  usePopup: boolean,
  onAuthenticated: (user: SignupCallback) => void,
) => {
  if (!usePopup) {
    await loginWithMicrosoft(showLoader);
  } else {
    const result = await loginWithMicrosoft(showLoader, true);
    await saveUserToken();
    const credential = result as firebase.auth.UserCredential;
    const mappedUser = mapMicrosoftUser(credential);
    onAuthenticated({ credential, mappedUser });
  }
};

export const loginWithMicrosoft = async (
  showLoader: (loading: boolean) => void,
  usePopup?: boolean,
): Promise<void | firebase.auth.UserCredential> => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');

  return login(provider, showLoader, usePopup);
};

function login(
  provider: firebase.auth.AuthProvider,
  showLoader: (loading: boolean) => void,
  usePopup?: boolean,
) {
  showLoader(true);
  if (!usePopup && toggles.signInWithRedirect()) {
    return firebase.auth().signInWithRedirect(provider);
  }
  return firebase.auth().signInWithPopup(provider);
}

export const getLoginRedirectResult = (
  showLoader: (loading: boolean) => void,
  callback: (user: FirebaseAuthCredentials) => void,
) => {
  firebase
    .auth()
    .getRedirectResult()
    .then((result: firebase.auth.UserCredential) => {
      if (!result.user) return;
      showLoader(true);

      firebase
        .auth()
        .currentUser?.getIdToken()
        .then((token) => {
          if (token) localStorage.setItem('token', token);
          if (result.credential?.signInMethod === 'microsoft.com') {
            callback(mapMicrosoftUser(result));
          } else {
            callback(mapGoogleUser(result));
          }
        })
        .catch(() => {
          showLoader(true);
        });
    })
    .catch(async (e) => {
      handleLoginError(e, callback, () => {
        showToast('Something went wrong', {}, 'error');
      });
    });
};

function mapMicrosoftUser(credential: firebase.auth.UserCredential) {
  const { additionalUserInfo, user } = credential;
  const result: FirebaseAuthCredentials = {
    ...user,
    ...additionalUserInfo?.profile,
    isNewUser: additionalUserInfo?.isNewUser,
    firebaseId: user?.uid,
    email: user?.email ?? undefined,
    given_name: get(additionalUserInfo, 'profile.givenName', ''),
    family_name: get(additionalUserInfo, 'profile.surname', ''),
  };
  return result;
}

function mapGoogleUser(credential: firebase.auth.UserCredential) {
  const { additionalUserInfo, user } = credential;
  const result: FirebaseAuthCredentials = {
    ...additionalUserInfo?.profile,
    isNewUser: additionalUserInfo?.isNewUser,
    firebaseId: user?.uid,
  };
  return result;
}

export const handleLoginError = (
  error: any,
  callback: (user: any) => void,
  handleError: (error: any) => void,
) => {
  if (error.code === 'auth/account-exists-with-different-credential') {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        const microsoftProvider = new firebase.auth.OAuthProvider(
          'microsoft.com',
        );
        firebase
          .auth()
          .signInWithCredential(res.credential as firebase.auth.AuthCredential)
          .then(async (result) => {
            if (result.user?.getIdToken()) {
              const token = await result.user.getIdToken();
              if (token) localStorage.setItem('token', token);
            }

            callback({
              ...result.user,
              ...result.additionalUserInfo?.profile,
              isNewUser: result.additionalUserInfo?.isNewUser,
              firebaseId: result.user?.uid,
              email: result.user?.email,
            } as any);
            result.user?.linkWithPopup(microsoftProvider);
          })
          .catch((e) => {
            handleError(e);
          });
      });
  } else {
    handleError(error);
  }
};

export const signInWithEmail = async (
  token: string,
  email: string,
): Promise<FirebaseAuthCredentials | null> => {
  try {
    const result = await firebase.auth().signInWithCustomToken(token);
    if (email !== result.user?.email) {
      showToast('Email is not correct!', {}, 'error');
      return null;
    }
    window.localStorage.removeItem('emailForSignIn');
    const { additionalUserInfo, user } = result;
    const nameParts = user?.displayName?.split(' ');
    const name = nameParts
      ? {
          firstName: nameParts[0],
          lastName: nameParts[1],
        }
      : {};
    return {
      email: user?.email as string,
      ...name,
      isNewUser: additionalUserInfo?.isNewUser,
      firebaseId: user?.uid,
    };
  } catch (e) {
    if ((e as FirebaseError).code === 'auth/invalid-custom-token')
      showToast('The login link you attempted to use has expired', {}, 'error');
    else showToast('Something went wrong', {}, 'error');
  }

  return null;
};

export const signInWithCustomToken = async (
  token: string,
): Promise<FirebaseAuthCredentials | null> => {
  try {
    const result = await firebase.auth().signInWithCustomToken(token);
    const { additionalUserInfo, user } = result;

    const customToken = await firebase.auth().currentUser?.getIdToken();

    return {
      customToken,
      email: user?.email as string,
      isNewUser: additionalUserInfo?.isNewUser,
      firebaseId: user?.uid,
    };
  } catch (e: unknown) {
    if ((e as FirebaseError).code === 'auth/invalid-custom-token')
      showToast('The login link you attempted to use has expired', {}, 'error');
    else showToast('Something went wrong', {}, 'error');
  }

  return null;
};

export const logout = () => {
  firebase.auth().signOut();
};

export { firebase };
