export class ProjectApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * List all projects.
     * @returns Project
     * @throws ApiError
     */
    findAll({ pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects/all',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * List all projects for an organization.
     * @returns Project
     * @throws ApiError
     */
    findAllByUser({ search, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects',
            query: {
                'pageSize': pageSize,
                'page': page,
                'search': search,
            },
        });
    }
    /**
     * Create new project.
     * @returns Project
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get projects by user ordered by contact addition descending.
     * @returns Project
     * @throws ApiError
     */
    findByUserRecentlyOrdered({ pageSize, page, search, tags, onlyMine, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects/recent',
            query: {
                'pageSize': pageSize,
                'page': page,
                'search': search,
                'tags': tags,
                'onlyMine': onlyMine,
            },
        });
    }
    /**
     * List all projects for an organization with first sequence stats.
     * @returns ProjectWithSharedDataDto
     * @throws ApiError
     */
    findAllWithSequenceStats({ pageSize, page, sortOrder, sortBy, search, tags, onlyMine, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects/stats',
            query: {
                'pageSize': pageSize,
                'page': page,
                'sortOrder': sortOrder,
                'sortBy': sortBy,
                'search': search,
                'tags': tags,
                'onlyMine': onlyMine,
            },
        });
    }
    /**
     * Get project with sequence stats.
     * @returns ProjectWithStatsDto
     * @throws ApiError
     */
    findOneWithSequenceStats({ projectId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects/{projectId}/stats',
            path: {
                'projectId': projectId,
            },
        });
    }
    /**
     * Get an organizations's project.
     * @returns Project
     * @throws ApiError
     */
    findOneByUser({ projectId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects/{projectId}',
            path: {
                'projectId': projectId,
            },
        });
    }
    /**
     * Update a project.
     * @returns Project
     * @throws ApiError
     */
    update({ id, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/projects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a project.
     * @returns any
     * @throws ApiError
     */
    delete({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/projects/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Link project with role.
     * @returns any
     * @throws ApiError
     */
    linkRole({ id, requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/{id}/role',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Move contacts from one project to another
     * @returns any
     * @throws ApiError
     */
    moveContacts({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/move',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Share project by email.
     * @returns any
     * @throws ApiError
     */
    shareProject({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/share',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * adds specific candidates to be auto-synced with an ATS.
     * @returns any
     * @throws ApiError
     */
    syncWithAts({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/auto-sync',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Check to see if a start sequence request would result in candidates being added to more than one active sequence.
     * @returns CheckStartSequenceResult
     * @throws ApiError
     */
    checkStartSequenceRequest({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/sequence/start/check',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Start a sequence for candidates.
     * @returns CandidateImportResult
     * @throws ApiError
     */
    startSequence({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/sequence/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Start a sequence for candidates.
     * @returns SendFirstStepResponse
     * @throws ApiError
     */
    sendFirstStep({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/sequence/send-first',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Restart a sequence for candidates that were Bounced.
     * @returns RestartSequenceResult
     * @throws ApiError
     */
    restartSequence({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/sequence/restart',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
