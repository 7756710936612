import { forwardRef } from 'react';
import { styled } from '../../stitches.config';

export const StyledFlex = styled('div', {
  display: 'flex',
  alignItems: 'center',

  variants: {
    align: {
      start: {
        alignItems: 'flex-start',
      },
      center: {
        alignItems: 'center',
      },
      end: {
        alignItems: 'flex-end',
      },
    },
    justify: {
      around: {
        justifyContent: 'space-around',
      },
      between: {
        justifyContent: 'space-between',
      },
      start: {
        justifyContent: 'flex-start',
      },
      center: {
        justifyContent: 'center',
      },
      end: {
        justifyContent: 'flex-end',
      },
    },
    vertical: {
      true: {
        alignItems: 'normal',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      },
    },
    centered: {
      true: {
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    wrap: {
      true: {
        flexWrap: 'wrap',
      },
    },
  },
});

export interface FlexProps extends React.ComponentProps<typeof StyledFlex> {
  as?: React.ElementType;
  dataCy?: string;
}

export const Flex = forwardRef<HTMLElement, FlexProps>(
  ({ dataCy, ...rest }, ref) => (
    <StyledFlex ref={ref} {...rest} data-cy={dataCy} />
  ),
);

Flex.displayName = 'Flex';
