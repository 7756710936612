import { Flex, Input, InputProps, Label } from '@betterleap/ui';
import { forwardRef } from 'react';

interface StepInputProps extends InputProps {
  label: string;
  dataCy?: string;
}

export const StepInput = forwardRef<HTMLInputElement, StepInputProps>(
  ({ id, css, label, dataCy, ...props }, ref) => (
    <Flex css={css}>
      <Label
        id={`${id}-label`}
        data-cy={`${id}`}
        css={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'normal',
          height: 38,
          color: '$text-lightest',
          borderTop: '1px solid $border-light',
          zIndex: 0,
          fontSize: 16,
          px: 12,
        }}
        htmlFor={id}
      >
        {label}
      </Label>
      <Input
        ref={ref}
        id={id}
        data-cy={dataCy}
        aria-labelledby={`${id}-label`}
        css={{
          borderRadius: 0,
          flex: 1,
          borderLeft: 'none',
          borderBottom: 'none',
          zIndex: 1,
          invalid: {
            borderBottom: '1px solid $danger-base',
          },
          focus: {
            zIndex: '$10',
          },
          readOnly: {
            color: '$neutral-blue-400',
          },
        }}
        {...props}
      />
    </Flex>
  ),
);

StepInput.displayName = 'StepInput';
