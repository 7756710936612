export class ContactSearchApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Search Contacts
     * @returns ContactSearchResultDto
     * @throws ApiError
     */
    searchContacts({ requestBody, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-search',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Search for contacts matching the requested filters
     * @returns ExtendedContactProject
     * @throws ApiError
     */
    searchProject({ requestBody, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-search/project',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
