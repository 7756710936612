export class ProjectShareApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * List all shared projects with organization
     * @returns GetProjectShareDto
     * @throws ApiError
     */
    getAllSharedProjectsWithOrganization({ pageSize, page, search, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/project-share',
            query: {
                'pageSize': pageSize,
                'page': page,
                'search': search,
            },
        });
    }
    /**
     * Get project shared with organization
     * @returns ProjectShare
     * @throws ApiError
     */
    getProjectSharedWithOrganization({ projectId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/project-share/{projectId}',
            path: {
                'projectId': projectId,
            },
        });
    }
    /**
     * Update a project share.
     * @returns ProjectShare
     * @throws ApiError
     */
    updateProjectShare({ projectShareId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/project-share/{projectShareId}',
            path: {
                'projectShareId': projectShareId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
