import moment from 'moment';
import React from 'react';
import { ActionsDropdown } from '../components/elements/ActionsDropdown/ActionsDropdown';
import Avatar from '../components/elements/Avatar/Avatar';
import styles from '../components/elements/InteractiveTable/Slots.module.scss';
import Specialization from '../components/elements/Specialization/Specialization';
import StatusTag from '../components/elements/StatusTag/StatusTag';
import { Tag } from '../constants/statusTags';
import { Company } from '../types/company';
import { Variable } from '../types/variable';
import { getCompanyLogo } from './companyLogo';
import { truncateString } from './truncateString';

/* eslint-disable @typescript-eslint/no-explicit-any */

export const renderSingleCell = (
  slot: string,
  value: Record<string, Variable> | Variable,
  onClick?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: Record<string, Variable> | Variable,
  ) => void,
  buttonText?: string,
  options?: {
    label: string;
    color?: string;
    onClick?: (value: Record<string, Variable> | string) => void;
  }[],
  hide?: (value: Record<string, Variable> | Variable) => boolean,
) => {
  switch (slot) {
    case 'bold': {
      return <p className='font-medium text-sm'>{(value as any) ?? 'N/A'}</p>;
    }
    case 'truncate': {
      return (
        <p className='font-medium text-sm'>
          {value ? truncateString((value as string).toString(), 31) : 'N/A'}
        </p>
      );
    }
    case 'boolean': {
      if (value) return <StatusTag tag='active' className={styles.tag} />;
      return <StatusTag tag='inactive' className={styles.tag} />;
    }
    case 'button': {
      if (hide && hide(value)) return null;
      return (
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClick?.(e, value as Variable);
          }}
          className={`text-sm font-medium rounded-md ${styles.assign_button}`}
        >
          {buttonText}
        </button>
      );
    }
    case 'actions': {
      return (
        <ActionsDropdown
          value={value as string}
          options={options}
          onClick={() => {
            if (onClick) onClick();
          }}
        />
      );
    }
    case 'date': {
      if (!!value && value !== 'N/A') {
        return (
          <p className='text-gray-500'>{moment(value as Date).format('L')}</p>
        );
      }
      return <p className='text-gray-500'>N/A</p>;
    }
    case 'specialization': {
      return <Specialization value={value as string[]} />;
    }
    case 'tag': {
      return <StatusTag tag={value as Tag} className={styles.tag} />;
    }
    case 'vc': {
      return (
        <p className='font-medium text-gray-500 capitalize'>
          {(value as any) ?? 'N/A'}
        </p>
      );
    }
    case 'company': {
      const company = value as Company;
      return (
        <div className='flex items-center'>
          <Avatar
            image={getCompanyLogo(company?.companyUrl, company?.companyName)}
            name={company?.companyName || ''}
            color='#3A83F7'
            size={16}
          />
          <p className='font-medium text-sm ml-3'>
            {company?.companyName ?? 'N/A'}
          </p>
        </div>
      );
    }
    case 'custom':
      return <div style={{ maxWidth: '100%' }}>{(value as any) ?? 'N/A'}</div>;
    default:
      return <p className='text-gray-500'>{(value as any) ?? 'N/A'}</p>;
  }
};
