export class UserApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * User accepts the terms of service
     * @returns any
     * @throws ApiError
     */
    acceptTermsOfService() {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user/accept-terms-of-service',
        });
    }
    /**
     * User accepts the privacy policy
     * @returns any
     * @throws ApiError
     */
    acceptPrivacyPolicy() {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user/accept-privacy-policy',
        });
    }
    /**
     * Get all users
     * @returns User
     * @throws ApiError
     */
    getUsers() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user/all',
        });
    }
    /**
     * Get all org members
     * @returns any
     * @throws ApiError
     */
    getOrgMembers({ pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user/organization-members',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * Get all org members
     * @returns any
     * @throws ApiError
     */
    getOrgMembersForAdmin({ organizationId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user/organization-members/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Create user
     * @returns any
     * @throws ApiError
     */
    createUser({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Block user
     * @returns User
     * @throws ApiError
     */
    blockUser({ userId, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user/block/{userId}',
            path: {
                'userId': userId,
            },
        });
    }
    /**
     * Update user as an admin
     * @returns any
     * @throws ApiError
     */
    adminUpdateUser({ userId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user/admin/{userId}',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * User downloaded extension
     * @returns any
     * @throws ApiError
     */
    userDownloadedExtension() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/user/extension-download',
        });
    }
    /**
     * Get logged in user
     * @returns User
     * @throws ApiError
     */
    getMyUser() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user/me',
        });
    }
    /**
     * Updates the logged in user
     * @returns any
     * @throws ApiError
     */
    updateMe({ requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user/me',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates a user by Id
     * @returns any
     * @throws ApiError
     */
    update({ id, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Updates the logged in user
     * @returns any
     * @throws ApiError
     */
    updateMyDevices({ requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/user/me/devices',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
