import { debounce, merge } from 'lodash';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useResizeObserver } from '../../hooks/useResizeObserver';
import { styled } from '../../stitches.config';
import { Flex, FlexProps } from '../Flex';
import { Tooltip, TooltipProps } from '../Tooltip';

export const StyledClippedList = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

function isXOverflow(
  root?: Element | null,
  child?: HTMLDivElement | null,
  index?: number,
) {
  if (!child || !root || index === 0) return false;

  const rootRect = root.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();

  return childRect.right > rootRect.right;
}

export interface ClippedListProps<T extends { id: string }>
  extends Omit<React.ComponentProps<typeof StyledClippedList>, 'children'> {
  as?: React.ElementType;
  items: T[];
  children: (arg: T, isOverflow: boolean) => JSX.Element;
  indicatorContent?: (items: T[]) => JSX.Element;
  tooltipProps?: Partial<Omit<TooltipProps, 'ref'>>;
}

export function ClippedList<T extends { id: string }>({
  css,
  children,
  items,
  indicatorContent,
  tooltipProps,
  ...rest
}: ClippedListProps<T>) {
  const childrenRef = useRef(new Map());
  const [rootEntry, setRootEntry] = useState<ResizeObserverEntry>();
  const [childrenMap, setChildrenMap] = useState(new Map());
  const rootRef = useRef(null);

  let renderIndicator = (overflowItems: T[]) => (
    <OverflowIndicator>+ {overflowItems.length}</OverflowIndicator>
  );
  renderIndicator = indicatorContent ?? renderIndicator;

  const childrenItems = Array.from<HTMLDivElement>(childrenMap?.values() ?? []);
  const overflowItems = childrenItems.filter((el, index) => {
    const isOverflow =
      index !== 0 &&
      rootEntry?.target &&
      isXOverflow(rootEntry?.target, el as HTMLDivElement);

    return isOverflow;
  });

  const debouncedCallback = useCallback(
    debounce((entry) => {
      setRootEntry?.(entry);
    }, 5),
    [items],
  );

  useResizeObserver(rootRef, debouncedCallback);

  useEffect(() => {
    setChildrenMap(new Map());
  }, [items]);

  return (
    <StyledClippedList
      ref={rootRef}
      css={{ width: '100%', overflowX: 'hidden', ...css }}
      {...rest}
    >
      {items.map((item, index) => {
        const elId = `${item.id}-${index}`;
        const isOverflow = isXOverflow(
          rootEntry?.target,
          childrenMap.get(elId),
          index,
        );

        const isLast =
          index === items.length - (overflowItems.length + 1) && !isOverflow;

        return (
          <div
            id={elId}
            ref={(el) => {
              if (el) {
                childrenRef.current.set(el.id, el);
                setChildrenMap(childrenRef.current);
              }
            }}
            key={elId}
            style={{
              display: 'flex',
              visibility: isOverflow ? 'hidden' : 'visible',
              maxWidth: '100%',
            }}
          >
            {children(item, false)}
            {isLast && overflowItems.length > 0 && (
              <Tooltip
                {...tooltipProps}
                content={
                  <>
                    {items
                      .slice(items.length - overflowItems.length)
                      .map((oveflowItem) => (
                        <>{children(oveflowItem, true)}</>
                      ))}
                  </>
                }
              >
                {renderIndicator(
                  items.slice(items.length - overflowItems.length),
                )}
              </Tooltip>
            )}
          </div>
        );
      })}
    </StyledClippedList>
  );
}

export const OverflowIndicator = forwardRef<HTMLDivElement, FlexProps>(
  ({ css, ...rest }, ref) => (
    <Flex
      ref={ref}
      css={merge(
        {
          whiteSpace: 'nowrap',
          borderRadius: '$md',
          px: 6,
          py: 4,
          color: '$neutral-blue-700',
          fontSize: '$sm',
          hover: {
            cursor: 'pointer',
            backgroundColor: '$neutral-blue-200',
          },
        },
        css,
      )}
      {...rest}
    />
  ),
);

OverflowIndicator.displayName = 'OverflowIndicator';
