export class ContactProjectApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create a Contact Project
     * @returns ContactProject
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-project',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create many Contact Projects
     * @returns any
     * @throws ApiError
     */
    createMany({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-project/many',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update a Contact Project
     * @returns ContactProject
     * @throws ApiError
     */
    update({ contactProjectId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/contact-project/{contactProjectId}',
            path: {
                'contactProjectId': contactProjectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Count contacts sourced into projects by this group by time period
     * @returns DataPoint
     * @throws ApiError
     */
    getSourceMetrics({ interval = 'month', start, end, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-project/metrics',
            query: {
                'interval': interval,
                'start': start,
                'end': end,
            },
        });
    }
}
