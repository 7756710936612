import { forwardRef, ReactNode, useRef } from 'react';
import { useLabel } from 'react-aria';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { Box } from '../Box';
import { Label } from '../Label';
import { Text } from '../Text';
import { Select, SelectItem, SelectProps } from '.';

export interface SelectFieldProps extends SelectProps {
  /** Select helper text */
  description?: string;
  /** Select label text */
  label?: ReactNode;
  /** Select error text */
  error?: string;

  required?: boolean;
}

export const SelectField = forwardRef<HTMLButtonElement, SelectFieldProps>(
  (
    {
      id,
      value,
      options,
      readOnly,
      required,
      disabled,
      error,
      description,
      label,
      css,
      ...rest
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLButtonElement>();
    const combinedRef = useMergeRefs(internalRef, ref);

    const { labelProps } = useLabel({ label });

    return (
      <Box
        css={{
          flexGrow: 1,
          ...css,
        }}
      >
        {label && typeof label === 'string' ? (
          <Label
            css={{
              mb: 8,
            }}
            {...labelProps}
            required={required}
          >
            {label}
          </Label>
        ) : (
          label
        )}
        <Select
          ref={combinedRef}
          id={id}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          error={error}
          {...rest}
        >
          {options?.map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
        </Select>
        <Box css={{ minHeight: 20 }}>
          {description && !error && (
            <Text
              css={{
                color: '$text-lightest',
                fontSize: '$sm',
              }}
              noBreak
            >
              {description}
            </Text>
          )}
          {error && (
            <Text
              css={{
                color: '$text-danger',
                fontSize: '$sm',
              }}
              noBreak
            >
              {error}
            </Text>
          )}
        </Box>
      </Box>
    );
  },
);

SelectField.displayName = 'InputField';
