import { forwardRef } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { keyframes, styled } from '../../stitches.config';
import { IconButton } from '../IconButton';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(PopoverPrimitive.Content, {
  borderRadius: '$2xl',
  padding: 16,
  backgroundColor: '$background-component',
  fill: '$background-component',
  zIndex: '$50',
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
  focus: {
    outline: 'none',
  },
});

const StyledArrow = styled(PopoverPrimitive.Arrow, {
  zIndex: '$50',
  fill: '$background-component',
});

const StyledPopoverTrigger = styled(PopoverPrimitive.Trigger, {});

const StyledClose = styled(PopoverPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$text',
  position: 'absolute',
  top: 5,
  right: 5,

  '&:hover': { backgroundColor: '$background-muted' },
  '&:focus': { boxShadow: `$focus` },
});

// Exports
export const Popover = PopoverPrimitive.Root;

export const PopoverClose = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof PopoverPrimitive.Trigger>
>(({ ...rest }, ref) => (
  <StyledClose asChild ref={ref as any} {...rest}>
    <IconButton ref={ref} size='sm' label='close' name='x' />
  </StyledClose>
));

PopoverClose.displayName = 'PopoverClose';

export const PopoverTrigger = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof StyledPopoverTrigger>
>(({ children, ...rest }, ref) => (
  <StyledPopoverTrigger ref={ref} asChild {...rest}>
    {children}
  </StyledPopoverTrigger>
));

PopoverTrigger.displayName = 'PopoverTrigger';

export interface PopoverContentProps
  extends React.ComponentProps<typeof StyledContent> {
  arrow?: boolean;
}

export const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(
  ({ children, arrow = true, css, ...rest }, ref) => (
    <PopoverPrimitive.Portal>
      <StyledContent
        ref={ref}
        sideOffset={5}
        collisionPadding={16}
        css={css}
        {...rest}
      >
        {children}
        {arrow && (
          <StyledArrow
            css={{ fill: css?.background ?? css?.backgroundColor }}
          />
        )}
      </StyledContent>
    </PopoverPrimitive.Portal>
  ),
);

PopoverContent.displayName = 'PopoverContent';
