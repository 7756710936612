import React from 'react';

interface SpinnerProps {
  variant: 'blue' | 'white';
}

const Spinner = ({ variant, ...rest }: SpinnerProps) => (
  <svg
    className='animate-spin h-6 w-6 mr-3'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...rest}
  >
    <circle
      className='opacity-25'
      cx='12'
      cy='12'
      r='10'
      stroke='white'
      strokeWidth='4'
      fill={`${variant === 'blue' ? 'white' : '#3A83F7'}`}
    />
    <path
      className='opacity-75'
      fill={`${variant === 'blue' ? '#3A83F7' : 'white'} `}
      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
    />
  </svg>
);

export default Spinner;
