import { ContactProject } from '@betterleap/client';
import { Recruiter } from './recruiter';
import { Resume } from './resume';
import { Role } from './role';
import { User } from './user';

export declare interface SenderOauth2Url {
  url: string;
}

export declare interface SenderCreateResponse {
  email: string;
}

export declare interface Sender {
  id: string;
  user: User;
  userId: string;
  email: string;
  status: SENDER_STATUS;
  statusDescription?: string;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
  signature?: string;
}

export enum SENDER_STATUS {
  ACTIVE = 'Active',
  ERROR = 'Error',
  PAUSED_TEMPORARILY = 'Paused Temporarily',
}

export declare interface Project {
  id: string;
  recruiter: Recruiter;
  recruiterId: string;
  name: string;
  role?: Role;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
  organizationId: string;
}

export declare interface Step {
  id: string;
  sequence: Sequence;
  prevStep?: Step;
  nextStep?: Step;
  subject: string;
  body: string;
  waitTimeCalendarDays: number;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
}

export declare interface Sequence {
  id: string;
  project: Project;
  projectId: string;
  sender?: Sender;
  steps: Step[];
  name: string;
  sendOnHolidays: boolean;
  enabled: boolean;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
}

export declare interface CompanyEnrichment {
  name: string;
  domain: string;
  logo: string;
}

export declare interface Contact {
  id: string;
  recruiter: Recruiter;
  firstName?: string;
  lastName?: string;
  primaryEmail?: string;
  linkedinUrl?: string;
  githubUrl?: string;
  twitterUrl?: string;
  additionalLink?: string;
  phoneNumber?: string;
  locations?: string[];
  currentCompanyEnrichment?: CompanyEnrichment;
  currentTitle?: string;
  currentCompany?: string;
  currentCompanyDomain?: string;
  notes?: string;
  user?: User;
  resumes?: Resume[];
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
}

export declare interface ContactProjectNote {
  id: string;
  note: string;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
  user: User;
  contactProject: ContactProject;
}

export declare interface ContactSequence {
  id: string;
  contact: Contact;
  sequence: Sequence;
  externalThreadId?: string;
  threadInReplyToMessageId?: string;
  activatedAt?: Date;
  finishedAt?: Date;
  deactivatedAt?: Date;
  deactivatedReason?: string;
  status?: string;
  createdAt: Date;
  deletedAt: Date;
  updatedAt: Date;
  emailOpenedCount?: string;
  contactProjectNote?: ContactProjectNote[];
  contactProject?: ContactProject;
  isInCompanyAts?: boolean;
  isCandidateSubmitted?: boolean;
}

export declare interface ProjectWithSequenceStats {
  id: string;
  name: string;
  projectId: string;
  sequenceId: string;
  senderId: string;
  enabled: boolean;
  stepCount: number;
  contactsTotalCount: number;
  contactsEmailedCount: number;
  contactsScheduledCount: number;
  contactsFinishedCount: number;
  contactsOpenedEmailCount: number;
  contactsRepliedCount: number;
  contactsErrorCount: number;
  contactsNoReplyCount: number;
  contactsPausedCount: number;
  sequenceName?: string;
}
