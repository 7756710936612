export var ProjectContactStatus;
(function (ProjectContactStatus) {
    ProjectContactStatus["NOT_IN_SEQUENCE"] = "not in sequence";
    ProjectContactStatus["SCHEDULED"] = "scheduled";
    ProjectContactStatus["FINISHED"] = "finished";
    ProjectContactStatus["BOUNCED"] = "bounced";
    ProjectContactStatus["UNCONTACTED"] = "uncontacted";
    ProjectContactStatus["CONTACTED"] = "contacted";
    ProjectContactStatus["REPLIED"] = "replied";
    ProjectContactStatus["OPENED_EMAIL"] = "opened email";
    ProjectContactStatus["NOT_OPENED_EMAIL"] = "not opened email";
    ProjectContactStatus["NO_PERSONAL_EMAIL"] = "no personal email";
})(ProjectContactStatus || (ProjectContactStatus = {}));
