import { createContext, FormEventHandler, forwardRef } from 'react';
import { Control } from 'react-hook-form';

import { styled } from '../../stitches.config';
import { BoxProps } from '../Box';

export interface FormContext {
  control?: Control;
}

export interface FormProps extends Omit<BoxProps, 'onSubmit'> {
  control: any;
  /** receives handleSubmit from react-hook-form's useForm */
  onSubmit: FormEventHandler<HTMLFormElement>;
  submitOnEnter?: boolean;
}

export const StyledForm = styled('form', {});

export const FormContext = createContext<FormContext>({});

// eslint-disable-next-line prefer-arrow-callback
export const Form = forwardRef<HTMLFormElement, FormProps>(function Form(
  { control, submitOnEnter = true, ...rest }: any,
  ref: any,
) {
  return (
    <FormContext.Provider value={{ control }}>
      <StyledForm
        ref={ref}
        onKeyDown={(e: { key: string; preventDefault: () => any }) =>
          !submitOnEnter && e.key === 'Enter' && e.preventDefault()
        }
        {...rest}
      />
    </FormContext.Provider>
  );
}) as (
  props: FormProps & { ref?: React.ForwardedRef<HTMLUListElement> },
) => JSX.Element;
