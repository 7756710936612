/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var FirebaseUserSignupDto;
(function (FirebaseUserSignupDto) {
    let role;
    (function (role) {
        role["NONE"] = "None";
        role["RECRUITER"] = "Recruiter";
        role["ADMIN"] = "Admin";
        role["COMPANY_ADMIN"] = "Company Admin";
    })(role = FirebaseUserSignupDto.role || (FirebaseUserSignupDto.role = {}));
})(FirebaseUserSignupDto || (FirebaseUserSignupDto = {}));
