export class RestrictedDomainApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Replaces the restricted domains of an organization
     * @returns void
     * @throws ApiError
     */
    replaceDomains({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/restricted-domain/replace',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the restricted domains of an organization
     * @returns string
     * @throws ApiError
     */
    getDomains() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/restricted-domain',
        });
    }
}
