import React, { forwardRef } from 'react';
import { Icon, IconProps } from '../Icon';

export interface ToastIconProps extends Partial<IconProps> {
  variant?: 'success' | 'danger' | 'info' | 'warning';
}

const variantToIcon: Record<string, Pick<IconProps, 'name' | 'color'>> = {
  success: {
    color: '$success-base',
    name: 'check-circle',
  },
  info: {
    color: '$primary',
    name: 'exclamation-circle',
  },
  danger: {
    color: '$danger-base',
    name: 'exclamation-circle',
  },
  warning: {
    color: '$warning-base',
    name: 'exclamation-circle',
  },
};

export const ToastIcon = forwardRef<SVGElement, ToastIconProps>(
  ({ variant, css, ...rest }, ref) => {
    const variantProps = variantToIcon[variant ?? 'success'];
    return (
      <Icon
        ref={ref}
        css={{ gridArea: 'icon', ...css }}
        {...variantProps}
        {...rest}
      />
    );
  },
);

ToastIcon.displayName = 'ToastIcon';
