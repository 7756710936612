import _ from 'lodash';
import { ENDPOINTS } from '../../../constants/endpoints';

const initApi = (): ApiState => {
  const reducers = {};
  Object.keys(ENDPOINTS).forEach((endpointKey) => {
    const reducer = {
      data: null,
      loading: false,
      error: null,
      firedCount: 0,
    };

    Object.assign(reducers, { [_.camelCase(endpointKey)]: reducer });
  });

  return reducers as ApiState;
};

export default initApi;
