import { AriaTextFieldOptions, useTextField } from '@react-aria/textfield';
import { forwardRef, ReactNode, Ref, RefObject, useRef } from 'react';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { Label } from '../Label';
import { Text } from '../Text';
import { Combobox, ComboboxProps } from '.';

export interface ComboboxFieldProps extends ComboboxProps {
  /** Input helper text */
  description?: string;
  /** Input label text */
  label?: ReactNode;
  /** Input element css */
  inputCss?: ComboboxProps['css'];
}

export const ComboboxField = forwardRef<HTMLInputElement, ComboboxFieldProps>(
  (
    {
      disabled,
      required,
      readOnly,
      error,
      description,
      label,
      css,
      size,
      inputCss,
      ...rest
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLInputElement>(null);
    const combinedRef = useMergeRefs(internalRef, ref);

    const { labelProps, errorMessageProps, descriptionProps } = useTextField(
      {
        label,
        isDisabled: disabled,
        isReadOnly: readOnly,
        isRequired: required,
        validationState: error ? 'invalid' : 'valid',
        ...(rest as AriaTextFieldOptions<'input'>),
      },
      internalRef as RefObject<HTMLInputElement>,
    );

    return (
      <Box css={css}>
        {label && typeof label === 'string' ? (
          <Label
            css={{
              mb: 8,
            }}
            {...labelProps}
            required={required}
          >
            {label}
          </Label>
        ) : (
          label
        )}
        <Combobox
          aria-labelledby={labelProps?.id}
          css={inputCss}
          ref={combinedRef as Ref<HTMLInputElement>}
          size={size}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          error={error}
          {...rest}
        />
        <Flex css={{ minHeight: 20 }} align='end'>
          {description && !error && (
            <Text
              css={{
                color: '$text-lightest',
                fontSize: '$sm',
              }}
              noBreak
              {...descriptionProps}
            >
              {description}
            </Text>
          )}
          {error && (
            <Text
              css={{
                color: '$text-danger',
                fontSize: '$sm',
              }}
              noBreak
              {...errorMessageProps}
            >
              {error}
            </Text>
          )}
        </Flex>
      </Box>
    );
  },
);

ComboboxField.displayName = 'ComboboxField';
