import React from 'react';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToast = (
  message: string,
  options: ToastOptions = {},
  type?: 'error' | 'success',
) => {
  switch (type) {
    case 'error':
      return toast.error(message, options);
    case 'success':
      return toast.success(message, options);
    default:
      return toast(message, options);
  }
};

const Toastify = (): JSX.Element => (
  <ToastContainer
    position='top-right'
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export default Toastify;
