/* eslint-disable @typescript-eslint/no-explicit-any */
import { InteractiveTable, Types } from '@betterleap/shared';
import { Badge, Box, Flex, Text } from '@betterleap/ui';
import qs from 'qs';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCustomerType } from '../../../helper/getCustomerType';
import routeNames from '../../../constants/routeNames';
import api from '../../../lib/api';
import SearchContainer from '../../modules/SearchContainer/SearchContainer';
import {
  AppliedOrganizationFilters,
  OrganizationFilter,
} from './OrganizationFilter';

const organizationColumns = {
  columns: [
    {
      Header: 'NAME',
      accessor: (value: Types.Organization) => (
        <div style={{ display: 'flex' }}>
          <Text css={{ fontSize: '$sm', fontWeight: '$medium' }}>
            {value.name}
          </Text>
          {value.test && (
            <Badge
              className='ml-2'
              css={{ fontWeight: '$medium' }}
              size='sm'
              variant='info'
            >
              Test
            </Badge>
          )}
        </div>
      ),
      slot: 'custom',
    },
    {
      Header: 'TYPE',
      accessor: (value: Types.Organization) => (
        <Text css={{ fontSize: '$sm', fontWeight: '$medium' }}>
          {getCustomerType(value)}
        </Text>
      ),
    },
    {
      Header: 'TIER',
      accessor: 'tier',
      slot: 'tag',
    },
    {
      Header: 'CREATOR EMAIL',
      accessor: 'createdByUser.email',
      slot: 'bold',
    },
    {
      Header: 'DATE JOINED',
      accessor: 'createdAt',
      slot: 'date',
    },
  ],
};

const OrganizationsTemplate = (): JSX.Element => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = qs.parse(search.slice(1));
  const {
    data: organizations,
    isLoading,
    refetch,
  } = useQuery(['organizations', queryParams], () =>
    api.fetch<{
      data: Types.Organization[];
      meta: Types.ApiPaginationResponse;
    }>('organizations', {
      ...queryParams,
      sortBy: ['createdAt', 'id'],
    }),
  );

  useEffect(() => {
    refetch();
  }, [search]);

  return (
    <div>
      <SearchContainer title='Organizations' dataCy='Organizations-title' />
      <Flex justify='between'>
        <Box>
          <OrganizationFilter />
        </Box>
        <Box>
          <AppliedOrganizationFilters />
        </Box>
      </Flex>
      <InteractiveTable
        tableData={organizations?.data?.data || [{}]}
        columns={organizationColumns.columns}
        count={organizations?.data?.meta?.count ?? 0}
        loading={isLoading}
        onRowClick={(commandPressed: boolean, data: any) => {
          if (commandPressed)
            window.open(routeNames.organization({ id: data.id }), '_blank');
          else navigate(routeNames.organization({ id: data.id }));
        }}
      />
    </div>
  );
};

export default OrganizationsTemplate;
