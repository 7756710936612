import { showToast } from '@betterleap/shared';
import { get } from 'lodash';
import { ReactText } from 'react';
import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import routeNames from '../../constants/routeNames';
import TOKEN from '../../lib/token';
import { ApiAction, apiActions } from '../api/actions';

export function* onSigninSuccess({ payload, helpers }: ApiAction) {
  yield put(apiActions.fetch('me', undefined, {}, helpers));
  if (get(payload, 'role', '') === 'Admin') {
    helpers?.navigate(routeNames.users());
  } else {
    helpers?.navigate(routeNames.signin());
  }
}
export function* watchOnSigninSuccess(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_SUCCESS_SIGNIN', onSigninSuccess);
}

export function* onSigninFailure(): Generator<ReactText> {
  yield showToast("User with this email doesn't exist", {}, 'error');
  TOKEN.remove();
}

export function* watchOnSigninFailure(): Generator<
  ForkEffect<never>,
  void,
  unknown
> {
  yield takeLatest('FETCH_FAILURE_SIGNIN', onSigninFailure);
}

export default [watchOnSigninSuccess, watchOnSigninFailure];
