import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconProps,
  Menu,
  MenuContent,
  MenuTrigger,
  styled,
  Text,
} from '@betterleap/ui';
import { useNavigate } from 'react-router-dom';

const StyledNavigationOption = styled('div');

const NavigationOptionItem = ({ option }) => {
  const navigate = useNavigate();
  const { id, icon, route, title, onClick, children, disabled } = option;
  const [open, setOpen] = useState(false);

  const handleOptionClick = (e, path, handleClick) => {
    if (!id) {
      e.stopPropagation();
    }
    if (path) {
      navigate(route);
    } else if (handleClick) {
      handleClick();
    }
  };
  const isDropdown = children && children.length > 0;

  if (isDropdown) {
    return (
      <Menu key={id} open={open} onDismiss={() => setOpen(false)}>
        <React.Fragment key='.0'>
          {icon &&
            (React.isValidElement(icon) ? (
              <MenuTrigger asChild onClick={() => setOpen(!open)}>
                <Box
                  css={{
                    mr: 12,
                    padding: '6px',
                    hover: {
                      cursor: 'pointer',
                      '& svg': {
                        fill: '$blue-600',
                      },
                    },
                    '& svg': {
                      fill: open ? '$blue-600' : '$neutral-blue-700',
                      stroke: '$white',
                      strokeWidth: '0.3px',
                    },
                    backgroundColor: open ? '$blue-50' : 'white',
                    borderRadius: '8px',
                  }}
                >
                  {React.cloneElement<IconProps>(icon as JSX.Element)}
                </Box>
              </MenuTrigger>
            ) : (
              <img
                src={icon as string}
                alt='icon'
                className='mr-2'
                aria-hidden
              />
            ))}
        </React.Fragment>
        <MenuContent align='end'>
          <Box
            style={{
              minWidth: '224px',
              backgroundColor: 'white',
              padding: '12px 16px 0px',
              borderRadius: '6px',
            }}
          >
            <Text
              css={{ color: '#87909E', marginBottom: 12 }}
              size='sm'
              inherit
              dataCy={title}
            >
              Settings
            </Text>

            {children &&
              children.map(
                (child) =>
                  !child.hidden && (
                    <StyledNavigationOption
                      css={{
                        hover: {
                          color: '$blue-600',
                          cursor: 'pointer',
                          '& svg': {
                            fill: '$blue-600',
                          },
                        },
                        '&:last-child': {
                          margin: '0 -16px',
                          padding: '8px 16px',
                          borderTop: '1px solid $gray-100',
                        },
                        padding: '8px 0px',
                        cursor: 'pointer',
                      }}
                    >
                      <NavigationOptionItem option={child} />
                    </StyledNavigationOption>
                  ),
              )}
          </Box>
        </MenuContent>
      </Menu>
    );
  }

  return (
    <Box
      key={id}
      id={id}
      onClick={(e) => !disabled && handleOptionClick(e, route, onClick)}
    >
      <Flex>
        {icon &&
          (React.isValidElement(icon) ? (
            <Box
              css={{
                mr: 12,
                padding: '6px',
                hover: {
                  cursor: 'pointer',
                  '& svg': {
                    fill: '$blue-600',
                  },
                },
                '& svg': {
                  fill: '$neutral-blue-700',
                  stroke: '$white',
                  strokeWidth: '0.3px',
                },
                backgroundColor: 'white',
                borderRadius: '8px',
              }}
            >
              {React.cloneElement<IconProps>(icon as JSX.Element)}
            </Box>
          ) : (
            <img src={icon as string} alt='icon' className='mr-2' aria-hidden />
          ))}
        <Text
          css={{ flex: 1, whiteSpace: 'nowrap' }}
          size='sm'
          inherit
          dataCy={title}
        >
          {title}
        </Text>
      </Flex>
    </Box>
  );
};

export default NavigationOptionItem;
