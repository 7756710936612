import { forwardRef } from 'react';
import { keyframes, styled } from '../../stitches.config';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' },
});

const AnimatedSvg = styled('svg', {
  height: 24,
  width: 24,
  opacity: 0.75,
  animation: `${spin} linear 1s infinite`,

  variants: {
    variant: {
      blue: {
        fill: '$blue-600',
      },
      white: {
        fill: 'white',
      },
    },
  },

  defaultVariants: {
    variant: 'blue',
  },
});

export interface SpinnerProps
  extends Omit<React.ComponentProps<typeof AnimatedSvg>, 'fr'> {
  as?: React.ElementType;
}

export const Spinner = forwardRef<SVGElement, SpinnerProps>(
  ({ ...rest }, ref) => (
    <AnimatedSvg
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
      {...rest}
    >
      <path d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z' />
    </AnimatedSvg>
  ),
);

Spinner.displayName = 'Spinner';
