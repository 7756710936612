import { Flex, FlexProps } from '../Flex';

export const RightInputContext = ({ css, children, ...rest }: FlexProps) => (
  <Flex
    css={{
      backgroundColor: '$background-muted',
      paddingLeft: 16,
      paddingRight: 18,
      height: '100%',
      marginRight: -8,
      fontSize: '$sm',
      color: '$text-light',
      borderLeft: '1px solid $border',
      ...css,
    }}
    aria-hidden
    {...rest}
  >
    {children}
  </Flex>
);
