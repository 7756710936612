import {
  Box,
  BoxProps,
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  Divider,
  Flex,
  Icon,
  IconButton,
  Spinner,
  StyledIcon,
  Tab,
  TabContent,
  Tabs,
  TabsList,
  Text,
  Tooltip,
} from '@betterleap/ui';
import { apiClient } from 'lib/apiClient';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { requestToCurl } from 'helper/requestToCurl';
import config from '../../../lib/config';
import { JsonCodeBlock } from './CodeBlock';
import { HeaderDetail } from './HeaderDetail';

const EmptyState = () => (
  <Flex justify='center' align='center' css={{ height: '100%' }}>
    Select a log to view details.
  </Flex>
);

const NotFound = () => (
  <Flex justify='center' align='center' css={{ height: '100%' }}>
    Log not found
  </Flex>
);

const LoadingState = () => (
  <Flex justify='center' align='center' css={{ height: '100%' }}>
    <Spinner />
  </Flex>
);

export interface RequestDetailsGroupProps extends BoxProps {
  title: string;
  defaultIsOpen?: boolean;
}

const RequestDetailsGroup = ({
  title,
  children,
  defaultIsOpen = true,
  ...rest
}: RequestDetailsGroupProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <Box {...rest}>
      <Collapsible open={isOpen} onOpenChange={setIsOpen} {...rest}>
        <Divider />
        <CollapsibleTrigger asChild>
          <Button
            full
            variant='headless'
            css={{
              focus: {
                boxShadow: '$base',
                [`& ${StyledIcon}`]: {
                  boxShadow: '$focus',
                },
              },
              p: 16,
              gap: 16,
              height: 50,
              fontSize: '$base',
              fontWeight: '$medium',
              boxShadow: '$base',
              justifyContent: 'space-between',
            }}
          >
            <Text inherit truncate flex>
              {title}
            </Text>
            <Icon
              css={{
                open: { transform: 'rotate(180deg)' },
                borderRadius: '$lg',
              }}
              name='chevron-down'
              data-open={isOpen}
            />
          </Button>
        </CollapsibleTrigger>
        <CollapsibleContent css={{ px: 16, py: 16 }}>
          {children}
        </CollapsibleContent>
      </Collapsible>
    </Box>
  );
};

export const LogDetail = () => {
  const [searchParams] = useSearchParams();
  const requestId = searchParams.get('requestId');
  const { data: apiLogsResponse, isLoading } = useQuery(
    ['api-log', requestId],
    () => {
      return apiClient.apiKeyManagement.getApiKeyRequestLogs({
        requestId: requestId as string,
      });
    },
    {
      enabled: !!requestId,
    },
  );

  if (!requestId) {
    return <EmptyState />;
  }

  if (isLoading) {
    return <LoadingState />;
  }

  const log = apiLogsResponse?.data?.[0];

  if (!log) {
    return <NotFound />;
  }

  return (
    <Tabs defaultValue='request' tabStyle='underline'>
      <TabsList css={{ borderBottom: '1px solid $border-light', height: 50 }}>
        <Tab value='request' css={{ px: 24, py: 12 }}>
          <Text inherit size='base' css={{ fontWeight: '$medium' }}>
            Request
          </Text>
        </Tab>
        <Tab value='response' css={{ px: 24, py: 12 }}>
          <Text inherit size='base' css={{ fontWeight: '$medium' }}>
            Response
          </Text>
        </Tab>
        <Flex justify='end' css={{ flex: 1, px: 16, gap: 8 }}>
          <Tooltip content='Copy log link'>
            <IconButton
              label='copy link'
              name='link'
              size='sm'
              onClick={() => {
                navigator.clipboard.writeText(
                  `${config.url}/api-logs?requestId=${requestId}&filterByRequestId=true`,
                );
              }}
            />
          </Tooltip>
          <Tooltip
            content='Copy CURL'
            onClick={() => {
              const curl = requestToCurl(`{{api_url}}${log.requestPath}`, {
                headers: log?.requestHeaders,
                body: !isEmpty(log.requestBody) ? log.requestBody : undefined,
                method: log?.requestMethod,
              });

              navigator.clipboard.writeText(curl);
            }}
          >
            <IconButton label='copy curl' name='duplicate' size='sm' />
          </Tooltip>
        </Flex>
      </TabsList>
      <TabContent value='request'>
        <RequestDetailsGroup title='Headers' defaultIsOpen={false}>
          <HeaderDetail headers={log?.requestHeaders} />
        </RequestDetailsGroup>
        <RequestDetailsGroup title='Query'>
          <JsonCodeBlock
            json={log?.requestQuery}
            onCopy={(text) => {
              const queryString = qs.stringify(JSON.parse(text), {
                arrayFormat: 'repeat',
              });
              navigator.clipboard.writeText(`?${queryString}`);
            }}
          />
        </RequestDetailsGroup>
        <RequestDetailsGroup title='Body'>
          <JsonCodeBlock
            json={log?.requestBody}
            onCopy={(text) => {
              navigator.clipboard.writeText(text);
            }}
          />
        </RequestDetailsGroup>
      </TabContent>
      <TabContent value='response'>
        <RequestDetailsGroup title='Headers' defaultIsOpen={false}>
          <HeaderDetail headers={log?.responseHeaders} />
        </RequestDetailsGroup>
        <RequestDetailsGroup title='Body'>
          <JsonCodeBlock
            json={log?.responseBody}
            onCopy={(text) => {
              navigator.clipboard.writeText(text);
            }}
          />
        </RequestDetailsGroup>
        {!isEmpty(log.responseMeta) && (
          <RequestDetailsGroup title='Meta'>
            <JsonCodeBlock
              json={log.responseMeta}
              onCopy={(text) => {
                navigator.clipboard.writeText(text);
              }}
            />
          </RequestDetailsGroup>
        )}
      </TabContent>
    </Tabs>
  );
};
