import classNames from 'classnames';
import React from 'react';
import Spinner from '../Spinner/Spinner';
import StatusTag from '../StatusTag/StatusTag';
import styles from './Button.module.scss';

const cn = classNames.bind(styles);

interface ButtonProps {
  variant:
    | 'normal'
    | 'ghost'
    | 'gray'
    | 'reject'
    | 'yellow'
    | 'blue'
    | 'green'
    | 'red';
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  disabled?: boolean;
  buttonText: string;
  image?: string;
  type?: 'submit' | 'button' | undefined;
  loading?: boolean;
  comingSoon?: boolean;
  textOverflow?: boolean;
}

const Button = ({
  variant,
  onClick,
  className,
  buttonText,
  disabled,
  image,
  type,
  loading,
  comingSoon,
  textOverflow,
}: ButtonProps): JSX.Element | null => {
  const buttonStyles = (v: string) => {
    const stylesByVariant = {
      normal: () =>
        cn(styles.normal_button, {
          'bg-blue-500 hover:bg-blue-600 active:bg-blue-900': !disabled,
        }),
      ghost: () =>
        cn(styles.ghost_button, 'bg-white', {
          'hover:bg-gray-100 active:bg-gray-200': !disabled,
        }),
      yellow: () =>
        cn(styles.normal_button, {
          'bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-600': !disabled,
        }),
      reject: () =>
        cn(styles.reject_button, {
          'bg-red-500 hover:bg-red-600 active:bg-red-900': !disabled,
        }),
      green: () =>
        cn(styles.green_button, {
          'hover:bg-gray-100 active:bg-gray-200': !disabled,
        }),
      red: () =>
        cn(styles.red_button, {
          'hover:bg-gray-100 active:bg-gray-200': !disabled,
        }),
      blue: () => styles.blue_button,
      gray: () =>
        cn(styles.gray_button, {
          [styles.gray_accepted_button]: disabled,
        }),
    };
    return v in stylesByVariant ? stylesByVariant[v]() : stylesByVariant.blue();
  };

  return (
    <button
      onClick={(e) => onClick && onClick(e)}
      type={type}
      disabled={disabled || loading}
      className={cn(
        `flex justify-center items-center rounded-md ${
          disabled && 'cursor-not-allowed'
        } ${buttonStyles(variant)} ${className}`,
        {
          [styles.disabled_button]: disabled && variant !== 'gray',
        },
      )}
      data-cy={`button-${buttonText}`}
    >
      <div className='flex items-center justify-center w-full break-all'>
        {loading && (
          <Spinner
            variant={
              variant === 'ghost' || variant === 'blue' ? 'blue' : 'white'
            }
          />
        )}
        {image && (
          <img className={`mr-2 ${styles.icon}`} src={image} alt='icon' />
        )}
        <span
          className={`not-italic font-medium text-sm leading-5 flex ${
            textOverflow && 'overflow-auto'
          } ${styles.button_text}`}
        >
          {buttonText}
        </span>
        {comingSoon && <StatusTag className='ml-2' tag='coming_soon' />}
      </div>
    </button>
  );
};

export default Button;
