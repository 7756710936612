/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var RecruiterDto;
(function (RecruiterDto) {
    let yearsOfExperience;
    (function (yearsOfExperience) {
        yearsOfExperience["_0_5"] = "0-5";
        yearsOfExperience["_5_10"] = "5-10";
        yearsOfExperience["_10_15"] = "10-15";
        yearsOfExperience["_15_"] = "15+";
    })(yearsOfExperience = RecruiterDto.yearsOfExperience || (RecruiterDto.yearsOfExperience = {}));
})(RecruiterDto || (RecruiterDto = {}));
