export class ContactEmailApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get All Emails for a contact
     * @returns ContactEmailDto
     * @throws ApiError
     */
    getContactEmails({ contactId, pageSize, page, type, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-email',
            query: {
                'pageSize': pageSize,
                'page': page,
                'contactId': contactId,
                'type': type,
            },
        });
    }
    /**
     * Create new contact email.
     * @returns any
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an existing contact email
     * @returns any
     * @throws ApiError
     */
    deleteContactEmail({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/contact-email/{id}',
            path: {
                'id': id,
            },
        });
    }
}
