import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AdminRoute from '../components/elements/ProtectedRoutes/AdminRoute';
import routeNames from '../constants/routeNames';
import { useUrlSync } from '../hooks/use-url-sync';
import { appActions } from '../store/app/actions';
import ApiLogs from './ApiLogs';
import Home from './Home';
import Organization from './Organization';
import Organizations from './Organizations';
import SearchPage from './SearchPage';
import SignIn from './SignIn';
import Users from './Users';
import OrganizationChat from './OrganizationChat';

const AppRouter = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useUrlSync();

  useEffect(() => {
    dispatch(appActions.init({ navigate, location }));
  }, []);

  return (
    <>
      <Routes>
        <Route path={routeNames.signin()} element={<SignIn />} />
        <Route
          path={routeNames.home()}
          element={<AdminRoute element={<Home />} />}
        />

        <Route
          path={routeNames.organizations()}
          element={<AdminRoute element={<Organizations />} />}
        />

        <Route
          path={routeNames.search()}
          element={<AdminRoute element={<SearchPage />} />}
        />

        <Route
          path={routeNames.users()}
          element={<AdminRoute element={<Users />} />}
        />

        <Route
          path={routeNames.organization()}
          element={<AdminRoute element={<Organization />} />}
        />

        <Route
          path={routeNames.chats()}
          element={<AdminRoute element={<OrganizationChat />} />}
        />

        <Route
          path={routeNames.chatThread()}
          element={<AdminRoute element={<OrganizationChat />} />}
        />

        <Route
          path={routeNames.apiLogs()}
          element={<AdminRoute element={<ApiLogs />} />}
        />
      </Routes>
    </>
  );
};

export default AppRouter;
