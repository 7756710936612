import { Badge, BadgeProps, Box, Flex, Icon, Text } from '@betterleap/ui';
import { useSearchParams } from 'react-router-dom';

const AppliedFilterBadge = ({
  title,
  value,
  ...rest
}: { title: string; value: string } & BadgeProps) => {
  return (
    <Badge shape='oval' variant='purple' {...rest}>
      <Icon inherit name='filter' size={14} css={{ mr: 6 }} />
      <Box css={{ maxWidth: 100, pb: 4 }}>
        <Text inherit css={{ fontWeight: '$bold', fontSize: 10 }}>
          {title}
        </Text>
        <Text truncate inherit css={{ fontSize: 10, lineHeight: 1 }}>
          {value}
        </Text>
      </Box>
    </Badge>
  );
};

export const AppliedApiLogFilters = ({
  filters,
}: {
  filters: Record<string, string>;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRemove = (key: string) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  const filterValues = Object.entries(filters).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, filterValue]) => !!filterValue,
  );

  return (
    <Flex css={{ gap: 8 }}>
      {filterValues.map(([key, filterValue]) => {
        switch (key) {
          case 'organizationId':
            return (
              <AppliedFilterBadge
                key={filterValue}
                onRemove={() => handleRemove(key)}
                title='Organization'
                value={filterValue}
              />
            );
          case 'apiKeyId':
            return (
              <AppliedFilterBadge
                key={filterValue}
                onRemove={() => handleRemove(key)}
                title='Api Key'
                value={filterValue}
              />
            );
          case 'requestId':
            return (
              <AppliedFilterBadge
                key={filterValue}
                onRemove={() => {
                  handleRemove(key);
                  handleRemove('filterByRequestId');
                }}
                title='Request Id'
                value={filterValue}
              />
            );
          default:
            return null;
        }
      })}
    </Flex>
  );
};
