import { IconButton, IconButtonProps } from '../IconButton';
import { useModalContext } from './ModalProvider';

export const ModalClose = ({
  css,
  ...rest
}: Omit<IconButtonProps, 'label'>) => {
  const { onDismiss } = useModalContext();

  return (
    <IconButton
      css={{
        position: 'absolute',
        right: 8,
        top: 8,
        ...css,
      }}
      onClick={onDismiss}
      label='close label'
      name='x'
      {...rest}
    />
  );
};
