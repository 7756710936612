export class AiDatabaseAgentApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Query database agent
     * @returns any
     * @throws ApiError
     */
    queryAgent() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai-database-agent',
        });
    }
    /**
     * Generate visualization data with agent response
     * @returns string
     * @throws ApiError
     */
    generateVisualizationData({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai-database-agent/visualization',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
