import { OrganizationApiKey } from '@betterleap/client';
import { InteractiveTable } from '@betterleap/shared';
import { Box, useModal } from '@betterleap/ui';
import EditApiKeyModal from 'components/modules/Modals/EditApiKeyModal/EditApiKeyModal';
import * as columns from '../../../constants/columns';

interface OrganizationApiKeysProps {
  data: OrganizationApiKey[];
  onEdit?: () => void;
}

const OrganizationApiKeys = ({ data, onEdit }: OrganizationApiKeysProps) => {
  const openEditApiKeyModal = useModal(EditApiKeyModal);

  const handleEditApiKey = async (apiKey: OrganizationApiKey) => {
    await openEditApiKeyModal({
      apiKey,
    });

    onEdit?.();
  };

  return (
    <Box css={{ mt: 40, mb: 40 }}>
      <h2 className='not-italic font-medium text-lg leading-6 text-gray-900 mb-1'>
        API Keys
      </h2>
      <InteractiveTable
        tableData={data || []}
        columns={
          columns.organizationApiKeys({
            onEdit: handleEditApiKey,
          }).columns
        }
      />
    </Box>
  );
};

export default OrganizationApiKeys;
