/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactProject;
(function (ContactProject) {
    let status;
    (function (status) {
        status["SOURCED"] = "Sourced";
        status["NO_EMAIL_FOUND"] = "No Email Found";
    })(status = ContactProject.status || (ContactProject.status = {}));
})(ContactProject || (ContactProject = {}));
