import { Box, BoxProps, IconProps, Text, Tooltip } from '@betterleap/ui';
import React from 'react';
import styles from './SidebarItem.module.scss';

interface SidebarItemProps extends BoxProps {
  title: string;
  icon?: string | JSX.Element;
  onClick?: () => void;
  hidden?: boolean;
  collapsed?: boolean;
  dataCy?: string;
}

const SidebarItem = ({
  title,
  icon,
  onClick,
  hidden,
  css,
  collapsed,
  dataCy,
  ...rest
}: SidebarItemProps) => {
  if (hidden) return null;
  return (
    <Tooltip
      content={title}
      side='right'
      className={`${!collapsed ? styles.collapsed : ''}`}
    >
      <Box
        as='nav'
        css={{
          display: 'flex',
          mt: 8,
          py: 14,
          px: 16,
          borderRadius: '$xl',
          alignItems: 'center',
          color: '$neutral-blue-700',
          cursor: 'pointer',
          justifyContent: collapsed ? 'center' : 'none',
          hover: {
            '& path': {
              fill: '$blue-600',
            },
            color: '$blue-600',
            backgroundColor: '$blue-100',
          },
          mediaMd: {
            width: !collapsed ? 224 : 'unset',
            overflow: !collapsed ? 'hidden' : 'unset',
          },
          ...css,
        }}
        key={title}
        onClick={onClick}
        data-cy={dataCy}
        {...rest}
      >
        {icon &&
          (React.isValidElement(icon) ? (
            <Box css={{ mr: collapsed ? 0 : 12 }}>
              {React.cloneElement<IconProps>(icon as JSX.Element)}
            </Box>
          ) : (
            <img
              src={icon as string}
              alt='icon'
              className={`mr-2 ${styles.sidebar_icon}`}
              aria-hidden
            />
          ))}
        {!collapsed && (
          <Text size='sm' inherit>
            {title}
          </Text>
        )}
      </Box>
    </Tooltip>
  );
};

export default SidebarItem;
