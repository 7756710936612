/* eslint-disable @typescript-eslint/no-explicit-any */

import { InteractiveTable, Types } from '@betterleap/shared';
import React from 'react';
import * as columns from '../../../constants/columns';

interface Member {
  name?: string;
  email?: string;
  status?: Types.INVITATION_STATUS;
  acceptedDate?: Date;
}
interface OrganizationMembersProps {
  data: Member[];
  countNumber: number;
}

const OrganizationMembers = ({
  data,
  countNumber,
}: OrganizationMembersProps) => (
  <div className='mt-5'>
    <h2 className='not-italic font-medium text-lg leading-6 text-gray-900 mb-1'>
      Members
    </h2>
    <InteractiveTable
      tableData={data || []}
      columns={columns.organizationMembers().columns}
      count={countNumber ?? 0}
    />
  </div>
);

export default OrganizationMembers;
