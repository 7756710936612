import {
  createStitches,
  createTheme,
  CSS,
  ScaleValue,
} from '@mpalmerlee/stitches-react';
import { colors } from './colors';

const themeConfig = {
  media: {
    sm: '(min-width: 640px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
  },
  theme: {
    colors: {
      ...colors,

      // semantic colors
      'success-100': '$green-100',
      'success-200': '$green-200',
      'success-300': '$green-300',
      'success-400': '$green-400',
      'success-500': '$green-500',
      'success-base': '$green-600',
      'success-700': '$green-700',
      'success-800': '$green-800',
      'success-900': '$green-900',
      'success-1000': '$green-1000',
      'warning-100': '$yellow-100',
      'warning-200': '$yellow-200',
      'warning-300': '$yellow-300',
      'warning-400': '$yellow-400',
      'warning-500': '$yellow-500',
      'warning-base': '$yellow-600',
      'warning-700': '$yellow-700',
      'warning-800': '$yellow-800',
      'warning-900': '$yellow-900',
      'warning-1000': '$yellow-1000',
      'danger-100': '$red-100',
      'danger-200': '$red-200',
      'danger-300': '$red-300',
      'danger-400': '$red-400',
      'danger-500': '$red-500',
      'danger-base': '$red-600',
      'danger-700': '$red-700',
      'danger-800': '$red-800',
      'danger-900': '$red-900',
      'danger-1000': '$red-1000',
      'primary-50': '$blue-50',
      'primary-100': '$blue-100',
      'primary-200': '$blue-200',
      'primary-300': '$blue-300',
      'primary-400': '$blue-400',
      'primary-base': '$blue-500',
      'primary-600': '$blue-600',
      'primary-700': '$blue-700',
      'primary-800': '$blue-800',
      'primary-900': '$blue-900',
      'primary-1000': '$blue-1000',

      // Primary brand color for links, buttons, etc.
      'primary-darkest': '$blue-700',
      'primary-dark': '$blue-600',
      primary: '$blue-500',
      'primary-light': '$blue-400',
      // A secondary brand color for alternative styling
      secondary: '#0C1B3A',
      // accent	A contrast color for emphasizing UI
      accent: '$pink-600',
      // darker than body. for active styles.
      'background-dark': '$neutral-blue-400',
      // Body background color
      background: 'white',
      // Background of components i.e. Inputs.
      'background-component': 'white',
      // A contrasting background color
      'background-muted': '$neutral-blue-100',
      // A background color for highlighting text
      'background-highlight': '#FBF2E6',
      // A background color for disabled elements
      'background-disabled': '$neutral-blue-100',
      // A background color for disabled inputs
      'background-disabled-input': '$neutral-blue-100',
      // A border for elements
      border: '$neutral-blue-500',
      // A lighter border for elements
      'border-light': '$neutral-blue-400',
      // A border for elements containing errors
      'border-danger': '$red-400',
      // Main body text color
      text: '$neutral-blue-1000',
      // Info text color
      'info-text': '$neutral-blue-800',
      // text on dark background
      'text-inverse': 'white',
      // mid tone text.
      'text-light': '$neutral-blue-800',
      // Secondary information text color
      'text-lightest': '$neutral-blue-700',
      // Text color for form placeholders
      'text-placeholder': '$neutral-blue-600',
      // Text color for disabled form components
      'text-disabled': '$neutral-blue-500',
      // Text representing erros.
      'text-danger': '$red-500',
      'text-tertiary': '$neutral-blue-600',
      'text-secondary': '$neutral-blue-700',
    },
    space: {
      2: '0.125rem',
      4: '0.25rem',
      8: '0.50rem',
      12: '0.75rem',
      14: '0.875rem',
      16: '1rem',
      20: '1.25rem',
      24: '1.5rem',
      28: '1.75rem',
      32: '2rem',
      36: '2.25rem',
      40: '2.5rem',
      44: '2.75rem',
      48: '3rem',
      56: '3.5rem',
      64: '4rem',
      80: '5rem',
      96: '6rem',
      112: '7rem',
      128: '8rem',
      144: '9rem',
      160: '10rem',
      176: '11rem',
      192: '12rem',
      208: '13rem',
      224: '14rem',
      240: '15rem',
      256: '16rem',
      288: '18rem',
      320: '20rem',
      384: '24rem',
    },
    fontSizes: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '3.75rem',
      '7xl': '4.5rem',
      '8xl': '6rem',
      '9xl': '8rem',
    },
    fonts: {
      body: 'Inter, sans-serif',
      gilroy: 'Gilroy, sans-serif',
    },
    fontWeights: {
      thin: '100',
      extralight: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    lineHeights: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
    },
    borderWidths: {
      thin: '1px',
      thick: '2px',
    },
    radii: {
      none: '0px',
      sm: '0.125rem',
      base: '0.25rem',
      md: '0.375rem',
      lg: '0.5rem',
      xl: '0.625rem',
      '2xl': '0.75rem',
      '3xl': '1rem',
      '4xl': '1.5rem',
      '5xl': '2rem',
      full: '9999px',
    },
    shadows: {
      sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
      base: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      md: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
      lg: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
      '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
      inner: 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
      none: 'none',
      focus: '0px 0px 0px 4px rgba(58, 131, 247, 0.2)',
      'focus-invalid': '0 0 0 3px var(--colors-pink-200)',
      focusSuccess: '0px 0px 0px 4px rgba(5, 150, 105, 0.2)',
      focusDanger: '0px 0px 0px 4px rgba(239, 68, 68, 0.2)',
      focusPurple: '0px 0px 0px 4px rgba(124, 58, 237, 0.2)',
      focusGray: '0px 0px 0px 4px rgba(232,234,237)',
      whiteOutline: '0px 0px 0px 2px #FFFFFF',
      doublefocus:
        '0 0 0 3px var(--colors-background), 0 0 0 6px var(--colors-blue-500)',
      breadcrumbfocus:
        '0 0 0 3px var(--colors-violet-200), inset 0 0 0 2px var(--colors-violet-500)',
    },
    zIndices: {
      10: '10',
      20: '20',
      30: '30',
      40: '40',
      50: '50',
      overlay: 2,
      modal: '50',
      tooltip: '1000',
      'combo-menu': '3000000000',
    },
    transitions: {
      // TBD
    },
  },
};

// psuedo class helpers
const hover = '&:hover, &[data-hover=true]';
const active = '&:active:enabled, &[data-active=true]:enabled';
const focus = '&:focus, &[data-focus=true]';
const visited = '&:visited';
const even = '&:nth-of-type(even)';
const odd = '&:nth-of-type(odd)';
const disabled =
  '&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover, &[data-disabled=true], &[data-disabled=true]:focus, &[data-disabled=true]:hover';
const checked = '&[aria-checked=true], &[data-checked=true]';
const mixed = '&[aria-checked=mixed]';
const current = '&[aria-current=true]';
const selected = '&[aria-selected=true], &[data-selected=true]';
const invalid = '&[aria-invalid=true], &[data-invalid=true]';
const toggled = '&[aria-pressed=true]';
const pressed = '&[data-pressed=true]';
const readOnly = '&[aria-readonly=true], &[readonly], &[data-readonly=true]';
const first = '&:first-of-type';
const last = '&:last-of-type';
const expanded = '&[aria-expanded=true]';
const grabbed = '&[aria-grabbed=true]';
const notFirst = '&:not(:first-of-type)';
const notLast = '&:not(:last-of-type)';
const before = '&:before';
const after = '&:after';
const focusWithin = '&:focus-within';
const placeholder = '&::placeholder';
const hidden = '&[aria-hidden=true]';
const firstChild = '&:first-child';
const lastChild = '&:last-child';
const onlyChild = '&:only-child';
const open = '&[data-open=true]';
const dragHover = '&[data-drag-hover=true]';

// shorthand
const m = 'margin';
const mt = 'marginTop';
const mr = 'marginRight';
const mb = 'marginBottom';
const ml = 'marginLeft';
const mx = ['marginLeft', 'marginRight'];
const my = ['marginTop', 'marginBottom'];
const p = 'padding';
const pt = 'paddingTop';
const pr = 'paddingRight';
const pb = 'paddingBottom';
const pl = 'paddingLeft';
const px = ['paddingLeft', 'paddingRight'];
const py = ['paddingTop', 'paddingBottom'];

// compute config type without utils to avoid circular reference.
const { config: baseConfig } = createStitches({ ...themeConfig });

export const { styled, css, config, theme, keyframes } = createStitches({
  ...themeConfig,
  prefix: 'bl-ui',
  utils: {
    // shorthand utils
    m: (value: ScaleValue<'space'> | CSS['margin']) => ({
      [m]: value,
    }),
    mt: (value: ScaleValue<'space'> | CSS['margin']) => ({
      [mt]: value,
    }),
    mr: (value: ScaleValue<'space'> | CSS['margin']) => ({
      [mr]: value,
    }),
    ml: (value: ScaleValue<'space'> | CSS['margin']) => ({
      [ml]: value,
    }),
    mb: (value: ScaleValue<'space'> | CSS['margin']) => ({
      [mb]: value,
    }),
    mx: (value: ScaleValue<'space'> | CSS['margin']) => ({
      [mx[0]]: value,
      [mx[1]]: value,
    }),
    my: (value: ScaleValue<'space'> | CSS['margin']) => ({
      [my[0]]: value,
      [my[1]]: value,
    }),
    p: (value: ScaleValue<'space'> | CSS['padding']) => ({
      [p]: value,
    }),
    pt: (value: ScaleValue<'space'> | CSS['padding']) => ({
      [pt]: value,
    }),
    pr: (value: ScaleValue<'space'> | CSS['padding']) => ({
      [pr]: value,
    }),
    pl: (value: ScaleValue<'space'> | CSS['padding']) => ({
      [pl]: value,
    }),
    pb: (value: ScaleValue<'space'> | CSS['padding']) => ({
      [pb]: value,
    }),
    px: (value: ScaleValue<'space'> | CSS['padding']) => ({
      [px[0]]: value,
      [px[1]]: value,
    }),
    py: (value: ScaleValue<'space'> | CSS['padding']) => ({
      [py[0]]: value,
      [py[1]]: value,
    }),

    // media utils
    mediaSm: (value: CSS<typeof baseConfig>) => ({
      '@sm': value,
    }),
    mediaMd: (value: CSS<typeof baseConfig>) => ({
      '@md': value,
    }),
    mediaLg: (value: CSS<typeof baseConfig>) => ({
      '@lg': value,
    }),

    // psuedo class utils
    hover: (value: CSS<typeof baseConfig>) => ({
      [hover]: value,
    }),
    active: (value: CSS<typeof baseConfig>) => ({
      [active]: value,
    }),
    visited: (value: CSS<typeof baseConfig>) => ({
      [visited]: value,
    }),
    focus: (value: CSS<typeof baseConfig>) => ({
      [focus]: value,
    }),
    even: (value: CSS<typeof baseConfig>) => ({
      [even]: value,
    }),
    odd: (value: CSS<typeof baseConfig>) => ({
      [odd]: value,
    }),
    disabled: (value: CSS<typeof baseConfig>) => ({
      [disabled]: value,
    }),
    mixed: (value: CSS<typeof baseConfig>) => ({
      [mixed]: value,
    }),
    checked: (value: CSS<typeof baseConfig>) => ({
      [checked]: value,
    }),
    current: (value: CSS<typeof baseConfig>) => ({
      [current]: value,
    }),
    selected: (value: CSS<typeof baseConfig>) => ({
      [selected]: value,
    }),
    invalid: (value: CSS<typeof baseConfig>) => ({
      [invalid]: value,
    }),
    toggled: (value: CSS<typeof baseConfig>) => ({
      [toggled]: value,
    }),
    pressed: (value: CSS<typeof baseConfig>) => ({
      [pressed]: value,
    }),
    readOnly: (value: CSS<typeof baseConfig>) => ({
      [readOnly]: value,
    }),
    first: (value: CSS<typeof baseConfig>) => ({
      [first]: value,
    }),
    last: (value: CSS<typeof baseConfig>) => ({
      [last]: value,
    }),
    expanded: (value: CSS<typeof baseConfig>) => ({
      [expanded]: value,
    }),
    grabbed: (value: CSS<typeof baseConfig>) => ({
      [grabbed]: value,
    }),
    notFirst: (value: CSS<typeof baseConfig>) => ({
      [notFirst]: value,
    }),
    notLast: (value: CSS<typeof baseConfig>) => ({
      [notLast]: value,
    }),
    before: (value: CSS<typeof baseConfig>) => ({
      [before]: value,
    }),
    after: (value: CSS<typeof baseConfig>) => ({
      [after]: value,
    }),
    focusWithin: (value: CSS<typeof baseConfig>) => ({
      [focusWithin]: value,
    }),
    placeholder: (value: CSS<typeof baseConfig>) => ({
      [placeholder]: value,
    }),
    hidden: (value: CSS<typeof baseConfig>) => ({
      [hidden]: value,
    }),
    firstChild: (value: CSS<typeof baseConfig>) => ({
      [firstChild]: value,
    }),
    lastChild: (value: CSS<typeof baseConfig>) => ({
      [lastChild]: value,
    }),
    onlyChild: (value: CSS<typeof baseConfig>) => ({
      [onlyChild]: value,
    }),
    dragHover: (value: CSS<typeof baseConfig>) => ({
      [dragHover]: value,
    }),
    open: (value: CSS<typeof baseConfig>) => ({
      [open]: value,
    }),
  },
});

export const darkTheme = createTheme('dark', {
  colors: {
    primary: '$blue-500',
    secondary: '#0C1B3A',
    accent: '$pink-500',
    'background-dark': '$neutral-blue-900',
    background: '$neutral-blue-800',
    'background-muted': '$neutral-blue-700',
    'background-highlight': '#FBF2E6',
    'background-disabled': '$neutral-blue-300',
    border: '$neutral-blue-200',
    'boder-light': '$neutral-blue-100',
    'border-danger': '$red-300',
    text: 'white',
    'text-light': '$grary-100',
    'text-lightest': '$neutral-blue-50',
    'text-placeholder': '$neutral-blue-500',
  },
});
