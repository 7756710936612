import * as ToastPrimitive from '@radix-ui/react-toast';
import React, { forwardRef } from 'react';
import { styled } from '../../stitches.config';

const StyledAction = styled(ToastPrimitive.Action, {
  padding: 0,
});

export const ToastAction = forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof ToastPrimitive.Action>
>(({ ...rest }, ref) => <StyledAction ref={ref as any} asChild {...rest} />);

ToastAction.displayName = 'ToastAction';
