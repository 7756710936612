/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ChartData;
(function (ChartData) {
    let chartType;
    (function (chartType) {
        chartType["BAR"] = "Bar";
        chartType["LINE"] = "Line";
        chartType["PIE"] = "Pie";
        chartType["AREA"] = "Area";
        chartType["RADAR"] = "Radar";
    })(chartType = ChartData.chartType || (ChartData.chartType = {}));
})(ChartData || (ChartData = {}));
