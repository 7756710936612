/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { InteractiveTable } from '../InteractiveTable/InteractiveTable';

interface MiniTableProps {
  title: string;
  data: unknown[];
  columns: {
    Header: string;
    accessor: string | ((props: any) => React.ReactNode | string | string[]);
    slot?: string | undefined;
  }[];
  count: number;
  paginationKey?: string;
  loading?: boolean;
  onRowClick?: (commandPressed: boolean, data: unknown) => void;
}

const MiniTable = ({
  title,
  data,
  columns,
  count,
  paginationKey,
  loading,
  onRowClick,
}: MiniTableProps) =>
  data.length ? (
    <div className='mobile:mt-5 tablet:mt-10'>
      <h3 className='not-italic font-semibold text-lg leading-5 text-black'>
        {title}
      </h3>
      <InteractiveTable
        tableData={data}
        columns={columns}
        count={count}
        paginationKey={paginationKey}
        loading={loading}
        onRowClick={onRowClick}
      />
    </div>
  ) : (
    <></>
  );

export default MiniTable;
