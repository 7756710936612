import React from 'react';
import withSidebar from 'components/HOC/withSidebar';
import OrganizationAdminChatTemplate from '../components/templates/OrganizationChat/OrganizationAdminChat.template';

const OrganizationChat: React.FC = () => (
  <React.StrictMode>
    <OrganizationAdminChatTemplate />
  </React.StrictMode>
);

export default withSidebar(OrganizationChat);
