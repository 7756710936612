/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var OnboardDto;
(function (OnboardDto) {
    let orgType;
    (function (orgType) {
        orgType["VC"] = "VC";
        orgType["COMPANY"] = "Company";
        orgType["RECRUITER"] = "Recruiter";
    })(orgType = OnboardDto.orgType || (OnboardDto.orgType = {}));
    let goal;
    (function (goal) {
        goal["REDUCE_COSTS"] = "REDUCE_COSTS";
        goal["INCREASE_EMAIL_COVERAGE"] = "INCREASE_EMAIL_COVERAGE";
        goal["IMPROVE_EFFICIENCY"] = "IMPROVE_EFFICIENCY";
        goal["AI"] = "AI";
        goal["OTHER"] = "OTHER";
    })(goal = OnboardDto.goal || (OnboardDto.goal = {}));
})(OnboardDto || (OnboardDto = {}));
