import { Box, Flex, Icon, Tooltip } from '@betterleap/ui';
import { Location, NavigateFunction } from 'react-router-dom';
import logo from '../../../assets/images/betterleap-logo.svg';
import SidebarItem from '../../elements/SidebarItem/SidebarItem';
import SidebarLink from '../../elements/SidebarLink/SidebarLink';

interface SidebarProps {
  sidebarRoutes: {
    title: string;
    route?: string;
    disabled?: boolean;
    icon?: string | JSX.Element;
    onClick?: () => void;
    active?: boolean;
    beta?: boolean;
    hidden?: boolean;
    id?: string;
  }[];
  sidebarFooterRoutes?: {
    title: string;
    route?: string;
    disabled?: boolean;
    icon?: string | JSX.Element;
    onClick?: () => void;
    active?: boolean;
    beta?: boolean;
    hidden?: boolean;
    id?: string;
  }[];
  footerBanner?: React.ReactNode;
  handleLogout?: () => void;
  handleNavigate?: NavigateFunction;
  collapsed?: boolean;
  setCollapsed?: () => void;
  currentLocation?: Location;
}

const Sidebar = ({
  sidebarRoutes,
  sidebarFooterRoutes = [],
  handleLogout,
  handleNavigate,
  collapsed,
  footerBanner,
  setCollapsed,
  currentLocation,
}: SidebarProps) => (
  <Box
    css={{
      background: '$background',
      height: '100vh',
      py: 24,
      px: 16,
      width: '100%',
      position: 'fixed',
      display: 'none',
      overflowX: 'hidden',
      '& .launchnotes-unread-indicator': {
        visibility: collapsed ? 'hidden' : 'visible',
        opacity: collapsed ? 0 : 1,
        transition: 'opacity 0.8s',
      },
      mediaMd: {
        display: 'flex',
        flexDirection: 'column',
        width: !collapsed ? 256 : 80,
        justifyContent: 'space-between',
        transition: 'width .4s',
        boxShadow: !collapsed ? '0 0 0 100vmax rgba(0, 0, 0, .3)' : 'none',
      },

      mediaLg: {
        boxShadow: 'none',
      },
      zIndex: 11,
    }}
  >
    <Box css={{ flex: 1 }}>
      <Flex
        css={{
          mb: 24,
          minHeight: 30,
          justifyContent: collapsed ? 'center' : 'space-between',
        }}
      >
        {!collapsed && (
          <Box
            as='img'
            src={logo}
            css={{ height: 30, width: 30 }}
            alt='logo'
            aria-hidden
          />
        )}
        <Tooltip
          content={collapsed ? 'Expand Menu' : 'Collapse Menu'}
          side='right'
        >
          <Flex
            onClick={() => {
              setCollapsed?.();
            }}
            css={{
              background: '#F0F1F3',
              height: 24,
              width: 24,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 8,
              cursor: 'pointer',
            }}
          >
            <Icon
              name={collapsed ? 'chevron-double-right' : 'chevron-double-left'}
              size={10}
            />
          </Flex>
        </Tooltip>
      </Flex>
      {sidebarRoutes.map((item) =>
        item.route ? (
          <SidebarLink
            id={item.id}
            key={item.title}
            title={item.title}
            route={item.route}
            beta={item.beta}
            icon={item.icon}
            onClick={item.onClick}
            hidden={item.hidden}
            handleNavigate={handleNavigate}
            collapsed={collapsed}
            currentLocation={currentLocation}
          />
        ) : (
          <SidebarItem
            id={item.id}
            key={item.title}
            title={item.title}
            icon={item.icon}
            onClick={item.onClick}
            hidden={item.hidden}
            collapsed={collapsed}
            dataCy={item.title}
          />
        ),
      )}
    </Box>
    <div>
      {!collapsed && footerBanner}
      {sidebarFooterRoutes.map((item) =>
        item.route ? (
          <SidebarLink
            id={item.id}
            key={item.title}
            title={item.title}
            route={item.route}
            icon={item.icon}
            onClick={item.onClick}
            hidden={item.hidden}
            handleNavigate={handleNavigate}
            collapsed={collapsed}
            currentLocation={currentLocation}
          />
        ) : (
          <SidebarItem
            id={item.id}
            key={item.title}
            title={item.title}
            icon={item.icon}
            onClick={item.onClick}
            hidden={item.hidden}
            collapsed={collapsed}
            dataCy={item.title}
          />
        ),
      )}
      {!!handleLogout && (
        <SidebarItem
          css={{ mb: 4 }}
          icon={<Icon name='logout-alt' size={20} color='$neutral-blue-700' />}
          title='Logout'
          onClick={handleLogout}
          collapsed={collapsed}
        />
      )}
    </div>
  </Box>
);

export default Sidebar;
