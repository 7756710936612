import { forwardRef } from 'react';
import { styled } from '../../stitches.config';

export const StyledLink = styled('a', {
  // styles here
  color: '$primary-base',
  textDecoration: 'none',
  textDecorationColor: '$primary',
  fontSize: '$sm',
  cursor: 'pointer',

  hover: {
    textDecoration: 'underline',
  },

  variants: {
    truncate: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    inline: {
      true: {
        display: 'inline-block',
      },
    },
    noBreak: {
      true: {
        whiteSpace: 'nowrap',
      },
    },
    inherit: {
      true: {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: 'inherit',
      },
    },
    variant: {
      purple: {
        color: '$purple-700',
        textDecorationColor: '$purple-700',
      },
    },
  },
});

export interface LinkProps extends React.ComponentProps<typeof StyledLink> {
  as?: React.ElementType;
}

export const Link = forwardRef<HTMLElement, LinkProps>(({ ...rest }, ref) => (
  <StyledLink ref={ref} {...rest} />
));

Link.displayName = 'Link';
