/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactWithAtsDataDto;
(function (ContactWithAtsDataDto) {
    let atsSource;
    (function (atsSource) {
        atsSource["LEVER"] = "Lever";
        atsSource["GREENHOUSE"] = "Greenhouse";
        atsSource["ASHBY"] = "Ashby";
        atsSource["RECRUITERFLOW"] = "Recruiterflow";
        atsSource["CLOCKWORK"] = "Clockwork";
        atsSource["BULLHORN"] = "Bullhorn";
        atsSource["ORACLE_TALEO"] = "Oracle Taleo";
    })(atsSource = ContactWithAtsDataDto.atsSource || (ContactWithAtsDataDto.atsSource = {}));
})(ContactWithAtsDataDto || (ContactWithAtsDataDto = {}));
