import * as ToastPrimitive from '@radix-ui/react-toast';
import { styled } from '../../stitches.config';

const StyledDescription = styled(ToastPrimitive.Description, {
  gridArea: 'description',
  margin: 0,
  color: '$text-lightest',
  fontSize: '$sm',
  lineHeight: 1.3,
  paddingTop: 4,
});

export const ToastDescription = StyledDescription;
