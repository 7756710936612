export class CompanyAdminApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Create or update company admin
     * @returns any
     * @throws ApiError
     */
    createCompanyAdmin({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/company-admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Update company admin
     * @returns any
     * @throws ApiError
     */
    updateCompanyAdmin({ requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/company-admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get logged in company admin
     * @returns CompanyAdmin
     * @throws ApiError
     */
    getCompanyAdmin() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-admin',
        });
    }
    /**
     * Get logged in company admin
     * @returns CompanyAdmin
     * @throws ApiError
     */
    getCompanyAdmins() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/company-admin/all',
        });
    }
}
