import Search from '../../elements/Search/Search';

interface SearchContainerProps {
  title: string;
  dataCy?: string;
}

const SearchContainer = ({ title, dataCy }: SearchContainerProps) => (
  <div
    className='w-full flex mobile:flex-col tablet:flex-row justify-between tablet:items-center mobile:mb-4 desktop:mb-14'
    data-cy={dataCy}
  >
    <h1
      className='font-bold text-3xl leading-9 text-gray-900 mobile:mb-2 tablet:mb-0 tablet:mr-2'
      data-cy={dataCy}
    >
      {title}
    </h1>
    <Search />
  </div>
);

export default SearchContainer;
