import React, { forwardRef, useContext } from 'react';
import { useController } from 'react-hook-form';

import { useMergeRefs } from '../../hooks/useMergeRefs';
import { TextArea, TextAreaProps } from '../TextArea';
import { BoundComponentProps } from './types';
import { FormContext } from './Form';

export type BoundTextAreaProps = TextAreaProps & BoundComponentProps;

export const BoundTextArea = forwardRef<
  HTMLTextAreaElement,
  BoundTextAreaProps
>(
  (
    { id, control, name, defaultValue, rules, onChange, required, ...rest },
    ref,
  ) => {
    const { control: contextControl } = useContext(FormContext);

    // TODO: incorporate errors
    const {
      field: {
        ref: internalRef,
        value,
        onChange: internalOnChange,
        ...inputProps
      },
      // fieldState: { error },
    } = useController({
      name,
      control: control ?? contextControl,
      defaultValue,
      rules: {
        required: required && 'This field is required',
        ...rules,
      },
    });

    const combinedRef = useMergeRefs(ref, internalRef);

    return (
      <TextArea
        ref={combinedRef}
        id={id}
        value={value}
        required={required}
        onChange={(newValue) => {
          onChange?.(newValue);
          internalOnChange(newValue);
        }}
        {...inputProps}
        {...rest}
      />
    );
  },
);

BoundTextArea.displayName = 'BoundTextArea';
