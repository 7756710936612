export const STATUS_TAG = {
  low: {
    title: 'Low',
    color: '#065F46',
    background: '#D1FAE5',
  },
  medium: {
    title: 'Medium',
    color: '#92400E',
    background: '#FEF3C7',
  },
  high: {
    title: 'High',
    color: '#991B1B',
    background: '#FEE2E2',
  },
  unmatched: {
    title: 'Matching',
    color: '#1E40AF',
    background: '#DBEAFE',
  },
  in_progress: {
    title: 'Actively Recruiting',
    color: '#92400E',
    background: '#FEF3C7',
  },
  rematch_requested: {
    title: 'Rematching',
    color: '#1E40AF',
    background: '#DBEAFE',
  },
  matched: {
    title: 'Actively Recruiting',
    color: '#065F46',
    background: '#D1FAE5',
  },
  offered: {
    title: 'Offered',
    color: '#5B21B6',
    background: '#EDE9FE',
  },
  paused: {
    title: 'Paused',
    color: '#9D174D',
    background: '#FCE7F3',
  },
  coming_soon: {
    title: 'Coming soon',
    color: '#92400E',
    background: '#FEF3C7',
  },
  pending: {
    title: 'Pending',
    color: '#991B1B',
    background: '#FEE2E2',
  },
  joined: {
    title: 'Joined',
    color: '#059669',
    background: '#D1FAE5',
  },
  notable_investor: {
    title: 'Dynamic',
    color: '#4F46E5',
    background: '#EEF2FF',
  },
  perk: {
    title: 'Dynamic',
    color: '#3A83F7',
    background: '#EFF6FF',
  },
  betterleap_client: {
    title: 'Betterleap Client',
    color: '#065F46',
    background: '#D1FAE5',
  },
  enabled: {
    title: 'Enabled',
    color: '#991B1B',
    background: '#FEE2E2',
  },
  disabled: {
    title: 'Disabled',
    color: '#065F46',
    background: '#D1FAE5',
  },
  interested: {
    title: 'Interested',
    color: '#92400E',
    background: '#FEF3C7',
  },
  paid: {
    title: 'Paid',
    color: '#065F46',
    background: '#D1FAE5',
  },
  deleted: {
    title: 'Deleted',
    color: '#92400E',
    background: '#FEF3C7',
  },
  open: {
    title: 'Open',
    color: '#9D174D',
    background: '#FCE7F3',
  },
  draft: {
    title: 'Draft',
    color: '#1E40AF',
    background: '#DBEAFE',
  },
  uncollectible: {
    title: 'Uncollectible',
    color: '#4F46E5',
    background: '#EEF2FF',
  },
  void: {
    title: 'Void',
    color: '#3A83F7',
    background: '#EFF6FF',
  },
  more_partners_soon: {
    title: 'More partners coming soon!',
    color: '#3A83F7',
    background: '#E0F2FE',
  },
  matching_in_progress: {
    title: 'Matching in progress',
    color: '#0C1B3A',
    background: '#FBF2E6',
  },
  rematching_in_progress: {
    title: 'Rematching in progress',
    color: '#0C1B3A',
    background: '#FBF2E6',
  },
  active: {
    title: 'Active',
    color: '#059669',
    background: '#D1FAE5',
  },
  inactive: {
    title: 'Inactive',
    color: '#FFFFFF',
    background: '#FF8A8A',
  },
  example: {
    title: 'Example',
    color: '#065F46',
    background: '#D1FAE5',
  },
  coming_soon_services: {
    title: 'COMING SOON',
    color: '#FFFFFF',
    background: '#FF8A8A',
  },
  open_role: {
    title: 'Open',
    color: '#1E40AF',
    background: '#DBEAFE',
  },
  // CANDIDATE STATUS
  new: {
    title: 'New',
    color: '#FFFFFF',
    background: '#3A83F7',
  },
  offer: {
    title: 'Offer',
    color: '#FFFFFF',
    background: '#059669',
  },
  coding_challenge: {
    title: 'Coding challenge',
    color: '#FFFFFF',
    background: '#3A83F7',
  },
  submitted: {
    title: 'Submitted',
    color: '#FFFFFF',
    background: '#3A83F7',
  },
  approved: {
    title: 'Approved',
    color: '#FFFFFF',
    background: '#3A83F7',
  },
  declined: {
    title: 'Declined',
    color: '#FFFFFF',
    background: '#FF8A8A',
  },
  rejected: {
    title: 'Rejected',
    color: '#FFFFFF',
    background: '#FF8A8A',
  },
  phone_screen: {
    title: 'Phone screen',
    color: '#FFFFFF',
    background: '#3A83F7',
  },
  Paywall: {
    title: 'Paywall',
    color: '#1F2937',
    background: '#F3F4F6',
  },
  Paid: {
    title: 'Paid',
    color: '#065F46',
    background: '#D1FAE5',
  },
  'View Only': {
    title: 'View Only',
    color: '#6D28D9',
    background: '#F5F3FF',
  },
  joined_invitation: {
    title: 'Active',
    color: '#065F46',
    background: '#D1FAE5',
  },
  pending_invitation: {
    title: 'Invite sent',
    color: '#1E40AF',
    background: '#DBEAFE',
  },
  free: {
    title: 'FREE',
    color: '#065F46',
    background: '#D1FAE5',
  },
  placement_fee: {
    title: '20% Placement Fee',
    color: '#5B21B6',
    background: '#EDE9FE',
  },
  test: {
    title: 'Test',
    color: '#1E40AF',
    background: '#DBEAFE',
  },
};

// | 'uncollectible'
// | 'void';

export type Tag = keyof typeof STATUS_TAG;
