import { ForkEffect, put, takeLatest } from 'redux-saga/effects';
import { initFirebase } from '@betterleap/shared';
import { Location, NavigateFunction } from 'react-router-dom';
import { TakeableChannel } from 'redux-saga';
import { apiActions } from '../api/actions';
import { APP } from './actions';

export function* onAppInit({
  navigate,
  location,
}: {
  navigate: NavigateFunction;
  location: Location;
}) {
  yield initFirebase();
  yield put(
    apiActions.fetch('feature_config', undefined, {}, { navigate, location }),
  );
}

export function* watchAppInit(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(APP.INIT as unknown as TakeableChannel<unknown>, onAppInit);
}

export default [watchAppInit];
