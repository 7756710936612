import React, { createContext, useEffect, useState } from 'react';

interface SidebarContextArgs {
  setCollapsed: () => void;
  collapsed?: boolean;
}
export const SidebarContext = createContext<SidebarContextArgs>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCollapsed: () => {},
  collapsed: false,
});

interface Props {
  children: React.ReactNode;
}

export const SidebarProvider: React.FC<Props> = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const setCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    if (window.innerWidth < 1024) setIsCollapsed(true);
  }, []);

  return (
    <SidebarContext.Provider
      value={{
        setCollapsed,
        collapsed: isCollapsed,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
