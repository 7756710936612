export class DeleteUserApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Delete user by id
     * @returns User
     * @throws ApiError
     */
    deleteUserById({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/delete-user/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Delete team member by id
     * @returns User
     * @throws ApiError
     */
    deleteTeamMemberById({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/delete-user/team-member/{id}',
            path: {
                'id': id,
            },
        });
    }
}
