import { Text, TextProps } from '../Text';
import { useModalContext } from '.';

export const ModalTitle = ({ css, ...rest }: TextProps) => {
  const { titleProps } = useModalContext();

  return (
    <Text
      css={{
        textAlign: 'center',
        lineHeight: '1.5rem',
        fontWeight: '$medium',
        py: 10,
        ...css,
      }}
      as='h1'
      size='lg'
      {...titleProps}
      {...rest}
    />
  );
};
