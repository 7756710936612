/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var EmailDto;
(function (EmailDto) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = EmailDto.type || (EmailDto.type = {}));
})(EmailDto || (EmailDto = {}));
