/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var UpdateProjectSuggestedContactDto;
(function (UpdateProjectSuggestedContactDto) {
    let status;
    (function (status) {
        status["ACTIVE"] = "Active";
        status["ADDED"] = "Added";
        status["REMOVED"] = "Removed";
    })(status = UpdateProjectSuggestedContactDto.status || (UpdateProjectSuggestedContactDto.status = {}));
})(UpdateProjectSuggestedContactDto || (UpdateProjectSuggestedContactDto = {}));
