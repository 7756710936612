export class PersonSearchApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Search for people
     * @returns BetterleapPersonSearchResponse
     * @throws ApiError
     */
    searchPeople({ requestBody, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-search',
            query: {
                'pageSize': pageSize,
                'page': page,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Generate parameters for person search using AI
     * @returns any
     * @throws ApiError
     */
    generateParams({ query, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/person-search/generate-person-search-params',
            query: {
                'query': query,
            },
        });
    }
    /**
     * Generate person search result profile summary using AI
     * @returns string
     * @throws ApiError
     */
    generateProfileSummary({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-search/generate-profile-summary',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get autocomplete suggestions for a given "text" indexed field
     * @returns any
     * @throws ApiError
     */
    autocomplete({ field, value, size, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/person-search/autocomplete',
            query: {
                'field': field,
                'value': value,
                'size': size,
            },
        });
    }
}
