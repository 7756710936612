import { Box, Text } from '@betterleap/ui';

export const HeaderDetail = ({
  headers,
}: {
  headers: Record<string, string>;
}) => {
  return (
    <>
      {Object.entries(headers).map(([key, value]) => (
        <Box css={{ mb: 12 }} key={key}>
          <Text size='sm' css={{ fontWeight: '$medium', mb: 2 }}>
            {key}
          </Text>
          <Text size='sm' css={{ color: '$text-secondary' }}>
            {value}
          </Text>
        </Box>
      ))}
    </>
  );
};
