/* eslint-disable @typescript-eslint/ban-ts-comment */
import { all, AllEffect, call, CallEffect } from 'redux-saga/effects';
import apiSaga from './api/saga';
import { AppAction } from './app/actions';
import appSaga from './app/saga';
import authSaga from './auth/saga';

const sagas = [...appSaga, ...apiSaga, ...authSaga];

function* root(): Generator<AllEffect<CallEffect<void>>, void, unknown> {
  yield all(sagas.map((saga) => call(saga)));
}

export type ReduxAction = AppAction;

export { root };
