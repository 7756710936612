import { ScaleValue } from '@mpalmerlee/stitches-react';
import get from 'lodash/get.js';

import { THEMES } from '../../components/ThemeProvider';
import { config, theme } from '../../stitches.config';

const resolveColorToken = (
  color?: ScaleValue<'colors', typeof config>,
  themeMode?: keyof typeof THEMES,
): string | undefined => {
  if (!color) return undefined;

  const tokenName = color.startsWith('$') ? color.slice(1) : color;
  const currentTheme = THEMES[themeMode ?? 'light'] ?? theme;
  const resolvedColor = get(currentTheme, `colors.${tokenName}.value`, color);
  return resolvedColor;
};

export default resolveColorToken;
