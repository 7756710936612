import classNames from 'classnames';
import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import leftArrow from '../../../assets/images/left-arrow.png';
import { paginationConfig } from '../../../constants';
import { getPaginationParams, triggerPage } from '../../../helper/pagination';
import styles from './Pagination.module.scss';

const cn = classNames.bind(styles);

interface PaginationProps {
  numberOfPages: number;
  handlePages: (value: number) => void;
  paginationKey?: string;
  hide?: boolean;
  pageSize?: number;
  paginationBoxStyles?: string;
}

const Pagination = ({
  numberOfPages,
  handlePages,
  paginationKey = '',
  hide,
  paginationBoxStyles,
  pageSize,
}: PaginationProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchKey = qs.parse(location.search.slice(1));

  const [page, setPage] = useState(
    (getPaginationParams([paginationKey || ''])?.[
      `${paginationKey || ''}page`
    ] || 1) as number,
  );
  const [arrayOfPages, setArrayOfPages] = useState<Array<string | number>>([]);

  const setInParamsPage = useCallback(() => {
    const params: string = triggerPage(
      getPaginationParams([paginationKey || '']),
      page,
      pageSize ?? paginationConfig.pageSize,
      paginationKey,
    );
    if (location.search !== params)
      navigate(
        {
          pathname: window.location.pathname,
          search: params,
        },
        { replace: true },
      );
  }, [navigate, pageSize, paginationConfig.pageSize, page]);

  const setArray = (item: number) => {
    if (item <= 3 && numberOfPages > 6) {
      setArrayOfPages([1, 2, 3, 4, '...', numberOfPages]);
    } else if (item >= numberOfPages - 2 && numberOfPages > 6) {
      setArrayOfPages([
        1,
        '...',
        numberOfPages - 3,
        numberOfPages - 2,
        numberOfPages - 1,
        numberOfPages,
      ]);
    } else if (numberOfPages > 6) {
      setArrayOfPages([
        1,
        '...',
        item - 1,
        item,
        item + 1,
        '...',
        numberOfPages,
      ]);
    } else {
      setArrayOfPages(Array.from({ length: numberOfPages }, (_, i) => i + 1));
    }
  };

  const handlePrevious = () => {
    setArray(page - 1);
    if (page > 1) {
      setPage(page - 1);
      handlePages(page - 1);
    }
  };

  const handleNext = () => {
    setArray(page + 1);
    if (page < numberOfPages) {
      handlePages(page + 1);
      setPage(page + 1);
    }
  };

  const handleClick = (item: string | number) => {
    if (typeof item !== 'string') {
      setArray(item as number);
      if (item >= 1 && item <= numberOfPages) setPage(item as number);
      handlePages(page - 1);
    }
  };

  useEffect(() => {
    if (searchKey[`${paginationKey}page`] !== page.toString()) {
      setPage(
        parseInt(
          (searchKey[`${paginationKey || ''}page`] as string) || '1',
          10,
        ),
      );
    }
  }, [searchKey[`${paginationKey}page`]]);

  useEffect(() => {
    setInParamsPage();
  }, [page]);

  useEffect(() => {
    setArray(page);
    if (!page) setPage(1);
  }, [numberOfPages]);

  if (numberOfPages < 2) return null;

  return (
    <div
      className={`w-full flex items-center justify-between flex-row  ${
        styles.pagination_wrapper
      } ${hide && 'hidden'}`}
    >
      <div
        className='flex items-center cursor-pointer'
        onClick={handlePrevious}
      >
        <img
          src={leftArrow}
          alt='left arrow'
          className={`mr-3 ${styles.arrow}`}
        />
        <span className='hidden tablet:block font-medium text-sm leading-5 text-gray-600 hover:text-gray-900'>
          Previous
        </span>
      </div>
      <div className='flex'>
        {arrayOfPages.map((item) => (
          <div
            key={`${item}-${uuid()}`}
            onClick={() => {
              handleClick(item);
            }}
            className={`mr-2 tablet:mr-5 font-medium text-sm flex items-center justify-center cursor-pointer ${cn(
              styles.page_square,
              { [styles.active]: item === page },
            )} ${paginationBoxStyles}`}
          >
            {item}
          </div>
        ))}
      </div>
      <div className='flex items-center cursor-pointer' onClick={handleNext}>
        <span className='hidden tablet:block font-medium text-sm leading-5 text-gray-600 hover:text-gray-900'>
          Next
        </span>
        <img
          src={leftArrow}
          alt='right arrow'
          className={`ml-3 ${styles.arrow} ${styles.right_arrow}`}
        />
      </div>
    </div>
  );
};

export default Pagination;
