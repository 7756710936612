import * as TabsPrimitive from '@radix-ui/react-tabs';
import { forwardRef, useContext } from 'react';
import { styled } from '../../stitches.config';
import { StyledBadge } from '../Badge';
import { TabsContext } from './Tabs';

const StyledTrigger = styled(TabsPrimitive.Trigger, {
  all: 'unset',
  fontFamily: 'inherit',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  lineHeight: '$base',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  color: '$neutral-blue-800',
  fontSize: '$xs',
  fontWeight: '$normal',
  px: 12,
  py: 10,
  gap: 8,

  variants: {
    tabStyle: {
      swatch: {
        borderRadius: '$xl',
        '&[data-state="active"]': {
          color: '$purple-600',
          backgroundColor: '$purple-200',
          [`& ${StyledBadge}`]: {
            backgroundColor: 'white',
          },
          minWidth: 'fit-content',
        },
        hover: {
          backgroundColor: '$neutral-blue-200',
          color: '$neutral-blue-700',
        },
      },
      underline: {
        color: '$text-tertiary',
        borderBottom: '1px solid transparent',
        hover: {
          color: '$text-secondary',
          '&[data-state="active"]': {
            color: '$blue-800',
            borderBottom: '1px solid $blue-800',
          },
        },
        '&[data-state="active"]': {
          color: '$blue-600',
          borderBottom: '1px solid $blue-600',
        },
        minWidth: 'fit-content',
      },
      pill: {
        borderRadius: '$xl',
        flex: 1,
        justifyContent: 'center',
        py: 6,
        hover: {
          backgroundColor: '$neutral-blue-200',
          color: '$neutral-blue-700',
        },
        '&[data-state="active"]': {
          color: '$blue-600',
          backgroundColor: '$blue-100',
        },
      },
      text: {
        color: '$text-tertiary',
        hover: {
          color: '$text',
        },
        '&[data-state="active"]': {
          color: '$text',
        },
      },
    },
    variant: {
      'light-primary': {
        '&[data-state="active"]': {
          color: '$primary-base',
          backgroundColor: '$primary-50',
        },
      },
      'light-blue': {
        '&[data-state="active"]': {
          color: '$light-blue-900',
          backgroundColor: '$light-blue-400',
        },
      },
      blue: {
        '&[data-state="active"]': {
          color: '$blue-800',
          backgroundColor: '$blue-300',
        },
      },
      orange: {
        '&[data-state="active"]': {
          color: '$orange-900',
          backgroundColor: '$orange-400',
        },
      },
      pink: {
        '&[data-state="active"]': {
          color: '$pink-900',
          backgroundColor: '$pink-400',
        },
      },
      yellow: {
        '&[data-state="active"]': {
          color: '$yellow-900',
          backgroundColor: '$yellow-400',
        },
      },
      green: {
        '&[data-state="active"]': {
          color: '$green-900',
          backgroundColor: '$green-400',
        },
      },
      red: {
        '&[data-state="active"]': {
          color: '$red-900',
          backgroundColor: '$red-400',
        },
      },
    },
  },
  defaultVariants: {
    tabStyle: 'swatch',
  },
});

export interface TabProps extends React.ComponentProps<typeof StyledTrigger> {
  onClick?: () => void;
  value: string;
}

export const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ children, onClick, variant, value, ...rest }, ref) => {
    const { tabStyle } = useContext(TabsContext);

    return (
      <StyledTrigger
        ref={ref}
        value={value}
        onClick={onClick}
        variant={variant}
        tabStyle={tabStyle}
        {...rest}
      >
        {children}
      </StyledTrigger>
    );
  },
);

Tab.displayName = 'Tab';
