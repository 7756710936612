import { Children, isValidElement, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { v4 as uuid } from 'uuid';
import { ToastProviderProps } from '@radix-ui/react-toast';
import { Flex } from '../Flex';
import { styled } from '../../stitches.config';
import { BoxProps } from '../Box';
import { Toast, ToastProps } from './Toast';
import { ToastTitle } from './ToastTitle';
import { ToastDescription } from './ToastDescription';
import { ToastClose } from './ToastClose';
import { ToastIcon } from './ToastIcon';

export interface ShowToastArgs extends ToastProps {
  variant?: 'danger' | 'success' | 'info' | 'warning';
  title?: string;
  description?: string;
  icon?: React.ReactNode;
  actions?: React.ReactNode;
  toastDuration?: number;
  id?: string;
}

type ToastValue = ShowToastArgs | string;

const ToastStream = new Subject<ToastValue>();

const observable = ToastStream.pipe(
  map((toastValue) => {
    const withId = {
      id: uuid(),
    };

    if (typeof toastValue === 'string') {
      return {
        title: toastValue,
        ...withId,
      };
    }

    return {
      id: uuid(),
      ...toastValue,
    };
  }),
);

export const showToast = (toastValue: ToastValue) => {
  ToastStream.next(toastValue);
};

export const useShowToast = () => {
  return showToast;
};

export const VIEWPORT_PADDING = 18;

const StyledViewport = styled(ToastPrimitive.Viewport, {
  position: 'fixed',
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  padding: VIEWPORT_PADDING,
  gap: 10,
  width: 434,
  maxWidth: '100vw',
  margin: 0,
  listStyle: 'none',
  zIndex: 10000000,
  outline: 'none',
});

export const ToastProvider = ({
  children,
  css,
}: ToastProviderProps & BoxProps) => {
  const [toasts, setToast] = useState<ShowToastArgs[]>([]);

  useEffect(() => {
    const subscription = observable.subscribe((value) => {
      setToast([...toasts, value]);
    });

    return () => subscription.unsubscribe();
  }, [toasts]);

  return (
    <ToastPrimitive.Provider swipeDirection='right'>
      <StyledViewport css={css} />
      {children}

      {toasts.map((toast) => {
        const actions = isValidElement(toast.actions)
          ? Children.toArray(toast.actions?.props.children)
          : Children.toArray(toast.actions);
        const numActions = actions.length;

        return (
          <Toast
            onOpenChange={(open) => {
              if (!open) {
                // remove toast from array after animation completes
                setTimeout(() => {
                  setToast(toasts.filter((t) => toast.id !== t.id));
                }, 110);
              }
            }}
            key={toast.id}
            layout={numActions < 2 ? 'single-action' : undefined}
            duration={toast.toastDuration || 3000}
          >
            <ToastIcon variant={toast.variant} />
            {toast.title && <ToastTitle>{toast.title}</ToastTitle>}
            {toast.description && (
              <ToastDescription>{toast.description}</ToastDescription>
            )}
            {toast.actions && (
              <Flex
                style={{ paddingTop: numActions < 2 ? 0 : 12 }}
                css={{ gridArea: 'action', gap: 16 }}
              >
                {toast.actions}
              </Flex>
            )}
            <ToastClose />
          </Toast>
        );
      })}
    </ToastPrimitive.Provider>
  );
};
