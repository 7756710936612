import { triggerPage } from '@betterleap/shared';
import { get } from 'lodash';
import qs from 'qs';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import searchIcon from '../../../assets/images/search-icon.png';
import routeNames from '../../../constants/routeNames';
import styles from './Search.module.scss';

const Search = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = qs.parse(location.search.slice(1));
  const [value, setValue] = useState(get(query, 'search', '') as string);

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && value !== '') {
      triggerPage({}, 1, 6);
      navigate({
        pathname: routeNames.search(),
        search: qs.stringify({
          search: value,
        }),
      });
    }
  };
  return (
    <div
      className={`w-full bg-white rounded-md flex items-center ${styles.search_wrapper}`}
    >
      <img
        className={`w-full mr-2 cursor-pointer ${styles.search_icon}`}
        src={searchIcon}
        alt='search icon'
        onClick={() => navigate(routeNames.search({ value }))}
        role='presentation'
      />
      <input
        className='w-full outline-none'
        placeholder='Search'
        onKeyDown={(e) => handleSubmit(e)}
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
    </div>
  );
};

export default Search;
