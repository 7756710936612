export class ApiKeyManagementApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get organizations with at least one API key
     * @returns ApiKeyOrganization
     * @throws ApiError
     */
    getOrganizationsWithApiKeys() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/api-key-management/organizations',
        });
    }
    /**
     * Get the API keys belonging to an organization
     * @returns OrganizationApiKey
     * @throws ApiError
     */
    getOrganizationApiKeys({ organizationId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/api-key-management/organizations/{organizationId}/api-keys',
            path: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Issue or revoke credits for an API key
     * @returns any
     * @throws ApiError
     */
    updateApiKeyCredits({ apiKeyId, requestBody, }) {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/api-key-management/api-keys/{apiKeyId}',
            path: {
                'apiKeyId': apiKeyId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Search the request logs of the public API
     * @returns ApiKeyRequestLog
     * @throws ApiError
     */
    getApiKeyRequestLogs({ organizationId, apiKeyId, requestId, cursor, take, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/api-key-management/request-logs',
            query: {
                'organizationId': organizationId,
                'apiKeyId': apiKeyId,
                'requestId': requestId,
                'cursor': cursor,
                'take': take,
            },
        });
    }
}
