export class SentEmailLogApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Count sequence sent emails in this group by time period
     * @returns DataPoint
     * @throws ApiError
     */
    getSentEmailsByTimePeriod({ interval = 'month', start, end, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sent-email-log/outreach-metrics',
            query: {
                'interval': interval,
                'start': start,
                'end': end,
            },
        });
    }
    /**
     * Count contacts sourced into projects by this organization
     * @returns CountDto
     * @throws ApiError
     */
    countAllSentEmails() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/sent-email-log/outreach-metrics/count',
        });
    }
}
