import { Badge, Box, IconProps, styled, Text } from '@betterleap/ui';
import React from 'react';
import { Location, NavigateFunction } from 'react-router-dom';

const StyledNavLink = styled('a');

type StyledNavLinkProps = React.ComponentProps<typeof StyledNavLink>;

interface NavigationLinkProps {
  title?: string;
  route: string;
  beta?: boolean;
  id?: string;
  icon?: string | JSX.Element;
  indicator?: React.ReactNode;
  onClick?: () => void;
  hidden?: boolean;
  css?: StyledNavLinkProps['css'];
  collapsed?: boolean;
  handleNavigate?: NavigateFunction;
  currentLocation?: Location;
}

const NavigationLink = ({
  id,
  title,
  route,
  beta,
  icon,
  onClick,
  indicator,
  hidden,
  css,
  handleNavigate,
  currentLocation,
}: NavigationLinkProps) => {
  if (hidden) return null;
  const active = currentLocation?.pathname.includes(route);
  return (
    <li>
      <StyledNavLink
        aria-current={active && 'page'}
        id={id}
        key={route}
        css={{
          display: 'flex',
          px: 16,
          gap: 12,
          height: '100%',
          alignItems: 'center',
          cursor: 'pointer',
          color: '$neutral-blue-700',
          justifyContent: 'none',
          position: 'relative',
          hover: {
            '& path': {
              fill: '$blue-600',
            },
            color: '$blue-600',
          },
          '&[aria-current="page"]': {
            color: '$blue-600',
            '& path': {
              fill: '$blue-600',
            },
            '&:after': {
              content: '""',
              position: 'absolute',
              height: 4,
              width: '100%',
              left: 0,
              bottom: 0,
              backgroundColor: '$blue-400',
            },
          },
          ...css,
        }}
        onClick={(e) => {
          e.preventDefault();
          if (onClick) onClick();
          if (handleNavigate) handleNavigate(route);
        }}
      >
        {icon &&
          (React.isValidElement(icon) ? (
            <Box
              css={{
                '& svg': {
                  fill: '$neutral-blue-700',
                  stroke: '$white',
                  strokeWidth: '0.3px',
                },
              }}
            >
              {React.cloneElement<IconProps>(icon as JSX.Element)}
            </Box>
          ) : (
            <img
              src={icon as string}
              alt='icon'
              aria-hidden
              style={{
                maxWidth: '24px',
              }}
            />
          ))}
        <Text
          inherit
          dataCy={title}
          css={{
            flex: 1,
            whiteSpace: 'nowrap',
            xs: {
              display: 'none',
            },
            fontSize: '1rem',
          }}
        >
          {title}
        </Text>
        {indicator}
        {beta && (
          <Badge
            css={{
              hidden: {
                display: 'none',
              },
              current: {
                color: '$blue-600',
              },
            }}
            size='sm'
            aria-current={currentLocation?.pathname.includes(route) ?? false}
            shape='rectangle'
            variant='info'
          >
            BETA
          </Badge>
        )}
      </StyledNavLink>
    </li>
  );
};

export default NavigationLink;
