/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Contact;
(function (Contact) {
    let lastUpdatedBySource;
    (function (lastUpdatedBySource) {
        lastUpdatedBySource["USER"] = "USER";
        lastUpdatedBySource["ATS"] = "ATS";
        lastUpdatedBySource["ENRICHMENT_PROVIDER"] = "ENRICHMENT_PROVIDER";
        lastUpdatedBySource["BETTERLEAP"] = "BETTERLEAP";
    })(lastUpdatedBySource = Contact.lastUpdatedBySource || (Contact.lastUpdatedBySource = {}));
    let contactEmailEnrichmentMode;
    (function (contactEmailEnrichmentMode) {
        contactEmailEnrichmentMode["PERSONAL"] = "Personal";
        contactEmailEnrichmentMode["PROFESSIONAL"] = "Professional";
    })(contactEmailEnrichmentMode = Contact.contactEmailEnrichmentMode || (Contact.contactEmailEnrichmentMode = {}));
})(Contact || (Contact = {}));
