import { Types } from '@betterleap/shared';
import { Organization } from '@betterleap/client';
import {
  Alert,
  AlertHeading,
  AlertIcon,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useMutation } from 'react-query';
import api from '../../../../lib/api';
import { constructError } from './constructError';

interface UpgradeModalProps {
  organization: Types.Organization;
  type: 'upgrade' | 'downgrade' | 'manage_seats';
  title: string;
  subtitle: string;
  numberOfMembers: number | 0;
  icon: 'success' | 'danger' | 'info';
  buttonConfirmationText: string;
  buttonConfirmationVariant: 'success' | 'danger' | 'primary';
}

const UpgradeModal: PromiseModal<UpgradeModalProps> = ({
  onDismiss,
  onSubmit,
  type,
  title,
  subtitle,
  organization,
  numberOfMembers,
  icon,
  buttonConfirmationText,
  buttonConfirmationVariant,
}) => {
  const updateOrganization = useMutation(
    (data: {
      organizationId: string;
      tier: string;
      seatsGranted: number;
      name: string;
    }) => api.fetch<{ data: Types.Organization }>('update_organization', data),
    {
      onSuccess: () => {
        onSubmit();
      },
      onError: (axiosError: AxiosError) => {
        const { description } = constructError(axiosError);
        setErrorDescription(description);
        setError(true);
      },
    },
  );
  const [seatValue, setSeatValue] = useState<number>(
    organization?.seatsGranted ? organization?.seatsGranted : 6,
  );
  const [error, setError] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>('');
  const handleSubmit = () => {
    if (type === 'upgrade' || type === 'manage_seats') {
      if (numberOfMembers > seatValue) {
        setErrorDescription('Total seat count below total invited members');
        setError(true);
      } else {
        updateOrganization.mutate({
          organizationId: organization.id,
          tier: Organization.tier.PAID,
          seatsGranted: seatValue,
          name: organization.name ? organization.name : '',
        });
      }
    } else {
      updateOrganization.mutate({
        organizationId: organization.id,
        tier: Organization.tier.PAYWALL,
        seatsGranted: 0,
        name: organization.name ? organization.name : '',
      });
    }
  };
  return (
    <Modal isDismissable={false}>
      <Flex vertical css={{ alignItems: 'center' }}>
        <AlertIcon variant={icon} name='exclamation-circle' css={{ mb: 24 }} />
        <Text as='h3' css={{ fontWeight: '$medium', fontSize: '$lg', mb: 8 }}>
          {title}
        </Text>
        <Text
          as='p'
          css={{
            color: '$neutral-blue-500',
            fontSize: '$sm',
            mb: 24,
          }}
          data-cy={title}
        >
          {subtitle}
        </Text>
        {error && (
          <Alert css={{ mb: 16, p: 16, width: '100%' }} variant='danger'>
            <Icon css={{ mt: 2 }} size={24} name='x-icon' color='$red-500' />
            <AlertHeading css={{ mb: 0, mt: 0 }}>
              {errorDescription}
            </AlertHeading>
          </Alert>
        )}
        {(type === 'upgrade' || type === 'manage_seats') && (
          <Flex justify='center' css={{ mb: 24, gap: 10 }}>
            <Text as='p' css={{ color: '$neutral-blue-500' }}>
              Total seats:
            </Text>
            <Input
              defaultValue={type === 'upgrade' ? 6 : seatValue}
              css={{ maxWidth: 80 }}
              onChange={(value: unknown) => setSeatValue(value as number)}
              type='number'
              dataCy='Seat numbet-input'
            />
          </Flex>
        )}
        <Flex justify='center' css={{ gap: 12, width: '100%' }}>
          <Button
            variant='ghost'
            onClick={onDismiss}
            css={{ maxWidth: 146, width: '100%' }}
          >
            Cancel
          </Button>

          <Button
            variant={buttonConfirmationVariant}
            onClick={handleSubmit}
            css={{
              maxWidth: 146,
              width: '100%',
            }}
          >
            {buttonConfirmationText}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
export default UpgradeModal;
