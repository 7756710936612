import { globalCss } from '@mpalmerlee/stitches-react';

export const globalStyles = globalCss({
  body: {
    backgroundColor: '$background',
    margin: 0,
  },
  '*': {
    boxSizing: 'border-box',
    fontFamily: '$body',
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
  },
});
