export class ContactSequenceStepsApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get contact sequence steps that are ready to be completed
     * @returns ContactSequenceStep
     * @throws ApiError
     */
    getDueSteps({ pageSize, page, includeCompleted, includeFinished, contactSequenceStepId, contactSequenceId, contactId, stepTypes, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-sequence-steps/due',
            query: {
                'pageSize': pageSize,
                'page': page,
                'includeCompleted': includeCompleted,
                'includeFinished': includeFinished,
                'contactSequenceStepId': contactSequenceStepId,
                'contactSequenceId': contactSequenceId,
                'contactId': contactId,
                'stepTypes': stepTypes,
            },
        });
    }
    /**
     * Marks a contact sequence step as completed
     * @returns ContactSequenceStep
     * @throws ApiError
     */
    markComplete({ id, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-sequence-steps/{id}/complete',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Marks a contact sequence step as replied and ends a sequence
     * @returns ContactSequenceStep
     * @throws ApiError
     */
    markReplied({ id, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-sequence-steps/{id}/replied',
            path: {
                'id': id,
            },
        });
    }
}
