export class FileApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns FileEntity
     * @throws ApiError
     */
    saveFile({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/file',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns FileEntity
     * @throws ApiError
     */
    saveFiles() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/file/many',
        });
    }
}
