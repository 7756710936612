/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var QueueTask;
(function (QueueTask) {
    let status;
    (function (status) {
        status["WAITING"] = "waiting";
        status["ACTIVE"] = "active";
        status["STALLED"] = "stalled";
        status["COMPLETED"] = "completed";
        status["FAILED"] = "failed";
    })(status = QueueTask.status || (QueueTask.status = {}));
    let queueName;
    (function (queueName) {
        queueName["CONTACT_CSV"] = "contact-csv";
        queueName["CONTACT_SEARCH"] = "contact-search";
        queueName["PERSON_INGESTION_MATCHING"] = "person-ingestion-matching";
        queueName["PERSON_INGESTION_PLANNING"] = "person-ingestion-planning";
        queueName["PERSON_INGESTION_EXECUTION"] = "person-ingestion-execution";
        queueName["CONTACT_EMAIL_VALIDATION"] = "contact-email-validation";
        queueName["CHAT_NOTIFICATION"] = "chat-notification";
    })(queueName = QueueTask.queueName || (QueueTask.queueName = {}));
    let jobName;
    (function (jobName) {
        jobName["CONTACT_CSV_IMPORT_CONTACTS"] = "contact-csv.import-contacts";
    })(jobName = QueueTask.jobName || (QueueTask.jobName = {}));
})(QueueTask || (QueueTask = {}));
