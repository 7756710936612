import React from 'react';
import betterleapLogo from '../../../assets/images/betterleap-logo.png';
import styles from './Header.module.scss';

const Header = () => (
  <div
    className={`w-full flex justify-center items-center fixed left-0 right-0 top-0 z-10 ${styles.header_wrapper}`}
  >
    <img src={betterleapLogo} alt='logo' className={` ${styles.logo}`} />
  </div>
);

export default Header;
