import { AriaTextFieldOptions, useTextField } from '@react-aria/textfield';
import {
  forwardRef,
  MutableRefObject,
  RefObject,
  useRef,
  useState,
} from 'react';
import { mergeProps } from '@react-aria/utils';

import { useMergeRefs } from '../../hooks/useMergeRefs';
import { styled } from '../../stitches.config';

export const StyledTextArea = styled('textarea', {
  width: '100%',
  resize: 'vertical',
  p: 8,
  outline: 'none',
  borderRadius: '$md',
  border: '1px solid $border',
  fontSize: '$base',
  transition: 'box-shadow 0.3s ease-in-out',
  focus: {
    boxShadow: '$focus',
    borderColor: '$border',
  },

  invalid: {
    focus: {
      boxShadow: '$focus-invalid',
    },
    borderColor: '$border-danger',
  },

  placeholder: {
    fontSize: '$sm',
    color: '$text-placeholder',
  },

  disabled: {
    backgroundColor: '$background-disabled-input',
    color: '$text-disabled',
    cursor: 'not-allowed',
  },
});

export interface TextAreaProps
  extends Omit<React.ComponentProps<typeof StyledTextArea>, 'onChange'> {
  as?: React.ElementType;
  /** Is input invalid */
  error?: string;

  onChange?: (val: string) => void;
}

export const useExpandingTextArea = (
  ref: MutableRefObject<HTMLTextAreaElement> | RefObject<HTMLTextAreaElement>,
  {
    minHeight,
    maxHeight,
    onChange,
  }: {
    minHeight: number;
    maxHeight: number;
    onChange?: TextAreaProps['onChange'];
  },
) => {
  const [styles, setStyles] = useState<TextAreaProps['style']>({
    resize: 'none',
    height: minHeight,
  });

  const handleOnChange: TextAreaProps['onChange'] = (val) => {
    let newHeight;

    if (ref.current && ref.current?.scrollHeight > ref.current?.clientHeight) {
      newHeight = Math.min(ref.current?.scrollHeight, maxHeight);
    }

    if (newHeight) {
      setStyles({
        ...styles,
        height: newHeight,
      });
    }

    onChange?.(val);
  };

  return {
    style: styles,
    onChange: handleOnChange,
  };
};

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ disabled, readOnly, required, onChange, error, ...rest }, ref) => {
    const internalRef = useRef<HTMLTextAreaElement>();
    const combinedRef = useMergeRefs(internalRef, ref);

    const { inputProps } = useTextField(
      {
        isDisabled: disabled,
        isReadOnly: readOnly,
        isRequired: required,
        onChange: (val) => onChange?.(val),
        validationState: error ? 'invalid' : 'valid',
        inputElementType: 'textarea',
        ...(rest as AriaTextFieldOptions<'textarea'>),
      },
      internalRef as RefObject<HTMLTextAreaElement>,
    );

    return (
      <StyledTextArea
        ref={combinedRef}
        rows={5}
        {...mergeProps(rest, inputProps)}
      />
    );
  },
);

TextArea.displayName = 'TextArea';
