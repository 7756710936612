/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateContactPhoneNumberDto;
(function (CreateContactPhoneNumberDto) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = CreateContactPhoneNumberDto.type || (CreateContactPhoneNumberDto.type = {}));
})(CreateContactPhoneNumberDto || (CreateContactPhoneNumberDto = {}));
