import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { useRadioGroupState } from '@react-stately/radio';
import { createContext, forwardRef, useContext } from 'react';
import { styled } from '../../stitches.config';

const RadioGroupContext = createContext<{
  required?: boolean;
  disabled?: boolean;
  selectedValue: string | null;
  variant?: 'card' | 'list';
}>({
  selectedValue: null,
});

export const useRadioGroup = () => useContext(RadioGroupContext);

export const StyledRadioGroup = styled(RadioGroupPrimitive.Root, {});

export interface RadioGroupProps
  extends Omit<React.ComponentProps<typeof StyledRadioGroup>, 'onChange'> {
  as?: React.ElementType;
  disabled?: boolean;
  onChange?: (val: string) => void;
  required?: boolean;
  /** applies variant to children. only applies to RadioCard */
  variant?: 'card' | 'list';
}

export const RadioGroup = forwardRef<HTMLElement, RadioGroupProps>(
  (
    { disabled, required, value, defaultValue, variant, onChange, ...rest },
    ref,
  ) => {
    const { selectedValue, setSelectedValue } = useRadioGroupState({
      isDisabled: disabled,
      onChange,
      value,
      defaultValue,
    });

    return (
      <RadioGroupContext.Provider
        value={{
          selectedValue,
          disabled,
          variant,
          required,
        }}
      >
        <StyledRadioGroup
          ref={ref}
          value={selectedValue}
          onValueChange={(val: string) => setSelectedValue(val)}
          {...rest}
        />
      </RadioGroupContext.Provider>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';
