import React, { forwardRef, useContext } from 'react';
import { useController } from 'react-hook-form';
import { useMergeRefs } from '../../hooks/useMergeRefs';
import { RadioGroup, RadioGroupProps } from '../RadioGroup';
import { FormContext } from './Form';
import { BoundComponentProps } from './types';

export type BoundRadioGroupProps = RadioGroupProps &
  Omit<BoundComponentProps, 'label'>;

export const BoundRadioGroup = forwardRef<
  HTMLButtonElement,
  BoundRadioGroupProps
>(
  (
    {
      id,
      control,
      name,
      defaultValue,
      rules,
      required,
      disabled,
      onChange: outerOnChange,
      ...rest
    },
    ref,
  ) => {
    const { control: contextControl } = useContext(FormContext);

    const {
      field: { ref: internalRef, value, onChange, ...props },
    } = useController({
      name,
      control: control ?? contextControl,
      defaultValue,
      rules: {
        required: required && 'This field is required',
        ...rules,
      },
    });

    const combinedRef = useMergeRefs(ref, internalRef);

    return (
      <RadioGroup
        ref={combinedRef}
        id={id}
        value={value}
        required={required}
        disabled={disabled}
        onChange={(newValue: string) => {
          onChange(newValue);
          outerOnChange?.(newValue);
        }}
        {...props}
        {...rest}
      />
    );
  },
);

BoundRadioGroup.displayName = 'BoundRadioGroup';
