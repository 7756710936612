export var ContactActivityType;
(function (ContactActivityType) {
    ContactActivityType["EMAIL_SENT"] = "EMAIL_SENT";
    ContactActivityType["EMAIL_REPLIED"] = "EMAIL_REPLIED";
    ContactActivityType["INMAIL_SENT"] = "INMAIL_SENT";
    ContactActivityType["INMAIL_REPLIED"] = "INMAIL_REPLIED";
    ContactActivityType["ADDED_TO_PROJECT"] = "ADDED_TO_PROJECT";
    ContactActivityType["ADDED_TO_SEQUENCE"] = "ADDED_TO_SEQUENCE";
    ContactActivityType["SUBMITTED_APPLICATION"] = "SUBMITTED_APPLICATION";
})(ContactActivityType || (ContactActivityType = {}));
