import { forwardRef } from 'react';
import { ScaleValue } from '@mpalmerlee/stitches-react';
import { config, styled } from '../../stitches.config';
import resolveColorToken from '../../utils/resolveColorToken';
import { useTheme } from '../ThemeProvider';
import { ICONS } from './icon-tokens';
import icons from './icons';

export type IconToken = keyof typeof ICONS;

export const StyledIcon = styled('svg', {
  fill: '$text',

  variants: {
    inherit: {
      true: {
        fill: 'inherit',
      },
    },
  },
});

export interface IconProps
  extends Omit<React.ComponentProps<typeof StyledIcon>, 'color' | 'fr'> {
  as?: React.ElementType;
  /** Name of icon to render. */
  name: IconToken;
  /** The icon color. */
  color?: ScaleValue<'colors', typeof config>;
  /** Height and width of icon. */
  size?: number;
}

export const Icon = forwardRef<SVGElement, IconProps>(
  ({ name, color, size = 24, ...rest }, ref) => {
    const { theme } = useTheme();

    const iconPaths = icons[name] as IconType[];

    const iconColor = resolveColorToken(color, theme);
    if (!iconPaths) {
      throw new Error('Invalid icon name passed to Icon.');
    }

    return (
      <StyledIcon
        style={{ fill: iconColor }}
        ref={ref}
        viewBox='0 0 24 24'
        width={size}
        height={size}
        role='img'
        aria-hidden
        {...rest}
      >
        {iconPaths.map((pathAttrs, index) => (
          <path key={index} {...pathAttrs} />
        ))}
      </StyledIcon>
    );
  },
);

Icon.displayName = 'Icon';

export * from './AlertIcon';
