/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateSequenceStepDto;
(function (CreateSequenceStepDto) {
    let type;
    (function (type) {
        type["X_DIRECT_MESSAGE"] = "X direct message";
        type["SMS"] = "sms";
        type["PHONE_CALL"] = "phone call";
        type["EMAIL"] = "email";
        type["INMAIL"] = "inmail";
    })(type = CreateSequenceStepDto.type || (CreateSequenceStepDto.type = {}));
})(CreateSequenceStepDto || (CreateSequenceStepDto = {}));
