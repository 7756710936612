import { Flex, FlexProps } from '@betterleap/ui';
import { ReactNode } from 'react';
import Spinner from '../Spinner/Spinner';

export interface LoadingAreaProps extends FlexProps {
  isLoading?: boolean;
  loader?: ReactNode;
}

export const LoadingArea = ({
  isLoading,
  loader,
  css,
  children,
  ...rest
}: LoadingAreaProps) => (
  <>
    {isLoading ? (
      loader ? (
        loader
      ) : (
        <Flex
          centered
          css={{
            minHeight: 300,
            ...css,
          }}
          {...rest}
        >
          <Spinner variant='blue' />
        </Flex>
      )
    ) : (
      children
    )}
  </>
);

export default LoadingArea;
