/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var RoleActionsNotes;
(function (RoleActionsNotes) {
    let type;
    (function (type) {
        type["REMATCH"] = "rematch";
        type["PAUSE"] = "pause";
        type["DELETE"] = "delete";
    })(type = RoleActionsNotes.type || (RoleActionsNotes.type = {}));
})(RoleActionsNotes || (RoleActionsNotes = {}));
