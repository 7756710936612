import { COMPANY_LOGOS } from '../constants';

export const getCompanyLogo = (companyUrl: string, companyName: string) => {
  let logo;
  if (COMPANY_LOGOS[companyName]) logo = COMPANY_LOGOS[companyName];
  else
    logo = `https://logo.clearbit.com/${companyUrl?.replace(
      /http:\/\/|https:\/\/|www./,
      '',
    )}`;

  return logo;
};

export const getClearbitLogoUri = (domain: string, size?: string) => {
  let logoUri = `https://logo.clearbit.com/${domain}`;
  if (size) {
    logoUri += `?size=${size}`;
  }
  return logoUri;
};
