/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ProjectWithStatsDto;
(function (ProjectWithStatsDto) {
    let contactEmailEnrichmentMode;
    (function (contactEmailEnrichmentMode) {
        contactEmailEnrichmentMode["PERSONAL"] = "Personal";
        contactEmailEnrichmentMode["PROFESSIONAL"] = "Professional";
    })(contactEmailEnrichmentMode = ProjectWithStatsDto.contactEmailEnrichmentMode || (ProjectWithStatsDto.contactEmailEnrichmentMode = {}));
})(ProjectWithStatsDto || (ProjectWithStatsDto = {}));
