/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactSequenceStep;
(function (ContactSequenceStep) {
    let result;
    (function (result) {
        result["ARCHIVED"] = "Archived";
        result["SENT_EMAIL"] = "Sent Email";
        result["CONTACT_REPLIED"] = "Contact Replied";
        result["ERROR"] = "Error";
        result["NO_EMAIL_FOUND"] = "No Email Found";
        result["STEP_REMOVED"] = "Step Removed";
        result["MARKED_DONE"] = "Marked Done";
        result["SEQUENCE_COMPLETED"] = "Sequence Completed";
    })(result = ContactSequenceStep.result || (ContactSequenceStep.result = {}));
})(ContactSequenceStep || (ContactSequenceStep = {}));
