/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ChatMessage;
(function (ChatMessage) {
    let type;
    (function (type) {
        type["DEFAULT"] = "default";
        type["ANNOUNCEMENT"] = "announcement";
    })(type = ChatMessage.type || (ChatMessage.type = {}));
})(ChatMessage || (ChatMessage = {}));
