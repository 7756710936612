/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var SequenceMetricsDto;
(function (SequenceMetricsDto) {
    let status;
    (function (status) {
        status["ACTIVE"] = "Active";
        status["PAUSED"] = "Paused";
        status["NEW"] = "New";
        status["COMPLETED"] = "Completed";
        status["IN_ACTIVE"] = "InActive";
    })(status = SequenceMetricsDto.status || (SequenceMetricsDto.status = {}));
})(SequenceMetricsDto || (SequenceMetricsDto = {}));
