import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';

import { styled } from '../../stitches.config';

export const StyledAspectRatio = styled(AspectRatioPrimitive.Root, {});

export interface AspectRatioProps
  extends React.ComponentProps<typeof StyledAspectRatio> {
  as?: React.ElementType;
}

export const AspectRatio = StyledAspectRatio;

AspectRatio.displayName = 'AspectRatio';
