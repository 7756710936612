import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AiAssistantApi } from './services/AiAssistantApi';
import { AiDatabaseAgentApi } from './services/AiDatabaseAgentApi';
import { AiDatabaseAgentFeeedbackApi } from './services/AiDatabaseAgentFeeedbackApi';
import { ApiKeyManagementApi } from './services/ApiKeyManagementApi';
import { ApplicationApi } from './services/ApplicationApi';
import { AtsCandidateApi } from './services/AtsCandidateApi';
import { AttachmentApi } from './services/AttachmentApi';
import { AuthApi } from './services/AuthApi';
import { AutocompleteApi } from './services/AutocompleteApi';
import { CandidateProfileApi } from './services/CandidateProfileApi';
import { ChatApi } from './services/ChatApi';
import { ChatWorkspaceApi } from './services/ChatWorkspaceApi';
import { CompanyApi } from './services/CompanyApi';
import { CompanyAdminApi } from './services/CompanyAdminApi';
import { CompanyBillingInfoApi } from './services/CompanyBillingInfoApi';
import { ContactApi } from './services/ContactApi';
import { ContactActivityApi } from './services/ContactActivityApi';
import { ContactEmailApi } from './services/ContactEmailApi';
import { ContactEnrichmentApi } from './services/ContactEnrichmentApi';
import { ContactNoteApi } from './services/ContactNoteApi';
import { ContactPhoneNumberApi } from './services/ContactPhoneNumberApi';
import { ContactProjectApi } from './services/ContactProjectApi';
import { ContactProjectNoteApi } from './services/ContactProjectNoteApi';
import { ContactSearchApi } from './services/ContactSearchApi';
import { ContactSequenceApi } from './services/ContactSequenceApi';
import { ContactSequenceStepsApi } from './services/ContactSequenceStepsApi';
import { ContactViewApi } from './services/ContactViewApi';
import { DefaultApi } from './services/DefaultApi';
import { DeleteUserApi } from './services/DeleteUserApi';
import { DevSupportApi } from './services/DevSupportApi';
import { DoNotContactDomainApi } from './services/DoNotContactDomainApi';
import { EnrichmentApi } from './services/EnrichmentApi';
import { FeatureConfigApi } from './services/FeatureConfigApi';
import { FeedbackApi } from './services/FeedbackApi';
import { FileApi } from './services/FileApi';
import { GroupApi } from './services/GroupApi';
import { InvitationApi } from './services/InvitationApi';
import { MergeApi } from './services/MergeApi';
import { OrganizationApi } from './services/OrganizationApi';
import { PaymentApi } from './services/PaymentApi';
import { PersonalizationApi } from './services/PersonalizationApi';
import { PersonIngestionApi } from './services/PersonIngestionApi';
import { PersonSearchApi } from './services/PersonSearchApi';
import { ProfileViewApi } from './services/ProfileViewApi';
import { ProjectApi } from './services/ProjectApi';
import { ProjectRoleApi } from './services/ProjectRoleApi';
import { ProjectShareApi } from './services/ProjectShareApi';
import { ProjectSuggestedContactApi } from './services/ProjectSuggestedContactApi';
import { ProjectTagApi } from './services/ProjectTagApi';
import { QueueTaskApi } from './services/QueueTaskApi';
import { RecruiterApi } from './services/RecruiterApi';
import { ReportingApi } from './services/ReportingApi';
import { RestrictedDomainApi } from './services/RestrictedDomainApi';
import { RoleApi } from './services/RoleApi';
import { SavedSearchApi } from './services/SavedSearchApi';
import { SearchApi } from './services/SearchApi';
import { SenderApi } from './services/SenderApi';
import { SentEmailLogApi } from './services/SentEmailLogApi';
import { SequenceApi } from './services/SequenceApi';
import { SignupApi } from './services/SignupApi';
import { SlackApi } from './services/SlackApi';
import { StepsApi } from './services/StepsApi';
import { UserApi } from './services/UserApi';
import { UserSettingsApi } from './services/UserSettingsApi';
export class ApiClient {
    constructor(config, HttpRequest = AxiosHttpRequest) {
        var _a, _b, _c, _d;
        this.request = new HttpRequest({
            BASE: (_a = config === null || config === void 0 ? void 0 : config.BASE) !== null && _a !== void 0 ? _a : '',
            VERSION: (_b = config === null || config === void 0 ? void 0 : config.VERSION) !== null && _b !== void 0 ? _b : '1.1.14',
            WITH_CREDENTIALS: (_c = config === null || config === void 0 ? void 0 : config.WITH_CREDENTIALS) !== null && _c !== void 0 ? _c : false,
            CREDENTIALS: (_d = config === null || config === void 0 ? void 0 : config.CREDENTIALS) !== null && _d !== void 0 ? _d : 'include',
            TOKEN: config === null || config === void 0 ? void 0 : config.TOKEN,
            USERNAME: config === null || config === void 0 ? void 0 : config.USERNAME,
            PASSWORD: config === null || config === void 0 ? void 0 : config.PASSWORD,
            HEADERS: config === null || config === void 0 ? void 0 : config.HEADERS,
            ENCODE_PATH: config === null || config === void 0 ? void 0 : config.ENCODE_PATH,
        });
        this.aiAssistant = new AiAssistantApi(this.request);
        this.aiDatabaseAgent = new AiDatabaseAgentApi(this.request);
        this.aiDatabaseAgentFeeedback = new AiDatabaseAgentFeeedbackApi(this.request);
        this.apiKeyManagement = new ApiKeyManagementApi(this.request);
        this.application = new ApplicationApi(this.request);
        this.atsCandidate = new AtsCandidateApi(this.request);
        this.attachment = new AttachmentApi(this.request);
        this.auth = new AuthApi(this.request);
        this.autocomplete = new AutocompleteApi(this.request);
        this.candidateProfile = new CandidateProfileApi(this.request);
        this.chat = new ChatApi(this.request);
        this.chatWorkspace = new ChatWorkspaceApi(this.request);
        this.company = new CompanyApi(this.request);
        this.companyAdmin = new CompanyAdminApi(this.request);
        this.companyBillingInfo = new CompanyBillingInfoApi(this.request);
        this.contact = new ContactApi(this.request);
        this.contactActivity = new ContactActivityApi(this.request);
        this.contactEmail = new ContactEmailApi(this.request);
        this.contactEnrichment = new ContactEnrichmentApi(this.request);
        this.contactNote = new ContactNoteApi(this.request);
        this.contactPhoneNumber = new ContactPhoneNumberApi(this.request);
        this.contactProject = new ContactProjectApi(this.request);
        this.contactProjectNote = new ContactProjectNoteApi(this.request);
        this.contactSearch = new ContactSearchApi(this.request);
        this.contactSequence = new ContactSequenceApi(this.request);
        this.contactSequenceSteps = new ContactSequenceStepsApi(this.request);
        this.contactView = new ContactViewApi(this.request);
        this.default = new DefaultApi(this.request);
        this.deleteUser = new DeleteUserApi(this.request);
        this.devSupport = new DevSupportApi(this.request);
        this.doNotContactDomain = new DoNotContactDomainApi(this.request);
        this.enrichment = new EnrichmentApi(this.request);
        this.featureConfig = new FeatureConfigApi(this.request);
        this.feedback = new FeedbackApi(this.request);
        this.file = new FileApi(this.request);
        this.group = new GroupApi(this.request);
        this.invitation = new InvitationApi(this.request);
        this.merge = new MergeApi(this.request);
        this.organization = new OrganizationApi(this.request);
        this.payment = new PaymentApi(this.request);
        this.personalization = new PersonalizationApi(this.request);
        this.personIngestion = new PersonIngestionApi(this.request);
        this.personSearch = new PersonSearchApi(this.request);
        this.profileView = new ProfileViewApi(this.request);
        this.project = new ProjectApi(this.request);
        this.projectRole = new ProjectRoleApi(this.request);
        this.projectShare = new ProjectShareApi(this.request);
        this.projectSuggestedContact = new ProjectSuggestedContactApi(this.request);
        this.projectTag = new ProjectTagApi(this.request);
        this.queueTask = new QueueTaskApi(this.request);
        this.recruiter = new RecruiterApi(this.request);
        this.reporting = new ReportingApi(this.request);
        this.restrictedDomain = new RestrictedDomainApi(this.request);
        this.role = new RoleApi(this.request);
        this.savedSearch = new SavedSearchApi(this.request);
        this.search = new SearchApi(this.request);
        this.sender = new SenderApi(this.request);
        this.sentEmailLog = new SentEmailLogApi(this.request);
        this.sequence = new SequenceApi(this.request);
        this.signup = new SignupApi(this.request);
        this.slack = new SlackApi(this.request);
        this.steps = new StepsApi(this.request);
        this.user = new UserApi(this.request);
        this.userSettings = new UserSettingsApi(this.request);
    }
}
