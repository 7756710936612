import { OrganizationApiKey } from '@betterleap/client';
import { Types } from '@betterleap/shared';
import { ApiKeyColumn } from 'components/templates/Organization/ApiKeyColumn';
import { CreditColumn } from 'components/templates/Organization/CreditColumn';
import { OverdraftColumn } from 'components/templates/Organization/OverdraftColumn';

export const organizationMembers = () => ({
  columns: [
    {
      Header: 'NAME',
      accessor: (d: { name: string }) => `${d?.name || ''} `,
      slot: 'bold',
    },
    {
      Header: 'EMAIL',
      accessor: (d: { email: string }) => `${d?.email || ''}`,
      slot: 'bold',
    },
    {
      Header: 'STATUS',
      accessor: (d: { status: Types.INVITATION_STATUS }) =>
        `${d?.status}_invitation`,
      slot: 'tag',
    },
    {
      Header: 'JOINED DATE',
      accessor: 'acceptedAt',
      slot: 'date',
      width: 130,
    },
  ],
});

export const organizationApiKeys = ({
  onEdit,
}: {
  onEdit: (apiKey: OrganizationApiKey) => void;
}) => ({
  columns: [
    {
      Header: 'KEY',
      accessor: (d: OrganizationApiKey) => <ApiKeyColumn apiKey={d} />,
      slot: 'bold',
    },
    {
      Header: 'AVAILABLE CREDITS',
      accessor: (d: OrganizationApiKey) => <CreditColumn apiKey={d} />,
      slot: 'text',
    },
    {
      Header: 'OVERDRAFT',
      accessor: (d: OrganizationApiKey) => <OverdraftColumn apiKey={d} />,
      slot: 'custom',
    },
    {
      Header: 'CREATED DATE',
      accessor: 'createdAt',
      slot: 'date',
      width: 130,
    },
    {
      Header: '',
      id: 'action-menu',
      slot: 'actions',
      width: 50,
      headerCss: {
        maxWidth: 84,
      },
      css: {
        display: 'flex',
        maxWidth: 84,
        justifyContent: 'flex-end',
        '& button': {
          fill: '$neutral-blue-600',
        },
      },
      accessor: (apiKey: OrganizationApiKey) => apiKey,
      options: [
        {
          label: 'Edit',
          onClick: (apiKey: OrganizationApiKey) => {
            onEdit(apiKey);
          },
        },
      ],
    },
  ],
});
