import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { createContext, useContext } from 'react';
import { Presence } from '@radix-ui/react-presence';
import { keyframes, styled } from '../../stitches.config';
import { useControlledState } from '../../hooks/useControlledState';

const fadeOut = keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
});

const fadeIn = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const slideInRight = keyframes({
  '0%': { transform: 'translateX(100%)' },
  '100%': { transform: 'translateX(0)' },
});

const slideOutRight = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(100%)' },
});

const slideInLeft = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(0)' },
});

const slideOutLeft = keyframes({
  '0%': { transform: 'translateX(0)' },
  '100%': { transform: 'translateX(-100%)' },
});

export const DrawerContext = createContext<{
  open?: boolean;
  modal?: boolean;
  setIsOpen: (open: boolean) => void;
}>({
  setIsOpen: () => {},
});

// radix doesn't show overlay in modal false mode, so we use this to manually add if using in that mode.
export const ManualOverlay = styled('div', {
  position: 'fixed',
  inset: 0,
  zIndex: 50,
  background: 'rgba(0, 0, 0, 0.20);',
  '&[data-state="open"]': {
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${fadeIn} 500ms`,
    },
  },
  '&[data-state="closed"]': {
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${fadeOut} 500ms`,
    },
  },
});

const StyledDrawerOverlay = styled(DialogPrimitive.Overlay, {
  position: 'fixed',
  inset: 0,
  zIndex: '$modal',
  background: 'rgba(0, 0, 0, 0.20);',
  '&[data-state="open"]': {
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${fadeIn} 500ms`,
    },
  },
  '&[data-state="closed"]': {
    '@media (prefers-reduced-motion: no-preference)': {
      animation: `${fadeOut} 500ms`,
    },
  },
});

export type DrawerProps = DialogPrimitive.DialogProps;
export const DrawerTrigger = DialogPrimitive.Trigger;
export const DrawerClose = DialogPrimitive.Close;
export const DrawerPortal = DialogPrimitive.Portal;

export const Drawer = ({
  modal,
  open,
  defaultOpen,
  onOpenChange,
  ...rest
}: DrawerProps) => {
  const [isOpen, setIsOpen] = useControlledState<boolean>(
    open,
    defaultOpen,
    onOpenChange,
  );

  const handleOnOpenChange = (nextOpen: boolean) => {
    setIsOpen(nextOpen);
  };

  return (
    <DrawerContext.Provider value={{ modal, open: open ?? isOpen, setIsOpen }}>
      <DialogPrimitive.Root
        modal={modal}
        open={isOpen}
        onOpenChange={handleOnOpenChange}
        {...rest}
      />
    </DrawerContext.Provider>
  );
};

const DrawerOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ ...props }, ref) => {
  const { modal, open, setIsOpen } = useContext(DrawerContext);

  if (!modal) {
    return (
      <Presence present={!!open}>
        <ManualOverlay
          data-state={open ? 'open' : 'closed'}
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </Presence>
    );
  }

  return <StyledDrawerOverlay {...props} ref={ref} />;
});

DrawerOverlay.displayName = DialogPrimitive.Overlay.displayName;

export type DrawerContentProps = React.ComponentPropsWithoutRef<
  typeof StyledDrawerContent
>;

const StyledDrawerContent = styled(DialogPrimitive.Content, {
  position: 'fixed',
  zIndex: 50,
  boxShadow: '$lg',
  background: 'rgba(0, 0, 0, 0.20);',
  backgroundColor: '$background-component',
  outline: 'none',
  top: 0,
  bottom: 0,
  height: '100vh',
  width: '75%',
  variants: {
    side: {
      left: {
        left: 0,
        '&[data-state="open"]': {
          '@media (prefers-reduced-motion: no-preference)': {
            animation: `${slideInLeft} 500ms`,
          },
        },
        '&[data-state="closed"]': {
          '@media (prefers-reduced-motion: no-preference)': {
            animation: `${slideOutLeft} 500ms`,
          },
        },
      },
      right: {
        right: 0,
        '&[data-state="open"]': {
          '@media (prefers-reduced-motion: no-preference)': {
            animation: `${slideInRight} 500ms`,
          },
        },
        '&[data-state="closed"]': {
          '@media (prefers-reduced-motion: no-preference)': {
            animation: `${slideOutRight} 500ms`,
          },
        },
      },
    },
  },

  defaultVariants: {
    side: 'right',
  },
});

export const DrawerContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DrawerContentProps
>(({ side = 'right', children, onInteractOutside, ...props }, ref) => (
  <DrawerPortal>
    <DrawerOverlay />
    <StyledDrawerContent
      ref={ref}
      side={side}
      onInteractOutside={(e) => {
        // prevent interacting with dialogs from closing drawer
        const isEventFromDialog = (e.target as HTMLElement)?.closest(
          '[role="dialog"]',
        );

        if (isEventFromDialog) {
          e.preventDefault();
        }

        onInteractOutside?.(e);
      }}
      {...props}
    >
      {children}
    </StyledDrawerContent>
  </DrawerPortal>
));

DrawerContent.displayName = DialogPrimitive.Content.displayName;
