export class PersonalizationApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get prompt for personalizing the first step of a sequence for a candidate
     * @returns string
     * @throws ApiError
     */
    generateEmailPrompt({ contactId, sequenceId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/personalization/email-prompt',
            query: {
                'contactId': contactId,
                'sequenceId': sequenceId,
            },
        });
    }
}
