import {
  AlertIcon,
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalTitle,
  PromiseModal,
  Text,
} from '@betterleap/ui';
import { useState } from 'react';

export interface ExtendTrialModalProps {
  currentTrialStartDate: string;
  onExtend: (value: string) => void;
}

const ExtendTrialModal: PromiseModal<ExtendTrialModalProps> = ({
  onDismiss,
  onSubmit,
  onExtend,
  currentTrialStartDate,
}) => {
  const [trialStartDateText, setTrialStartDateText] = useState<string>(
    currentTrialStartDate,
  );

  return (
    <Modal>
      <Flex css={{ alignItems: 'center' }} vertical>
        <Box css={{ py: 16 }}>
          <AlertIcon variant='success' name='beaker' />
        </Box>
        <ModalTitle>Extend Trial</ModalTitle>
        <Text
          size='sm'
          css={{ color: '$text-lightest', textAlign: 'center', mb: '1rem' }}
        >
          Select the new start date of the trial.
        </Text>
        <Input
          type='text'
          value={trialStartDateText}
          onChange={setTrialStartDateText}
          css={{ width: 250 }}
        />
        <Flex css={{ gap: 8, pt: 24, width: '100%' }}>
          <Button full variant='ghost' onClick={onDismiss}>
            Cancel
          </Button>
          <Button
            full
            onClick={() => {
              onExtend(trialStartDateText);
              onSubmit(trialStartDateText);
            }}
          >
            Extend Trial
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ExtendTrialModal;
