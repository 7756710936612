export class FeatureConfigApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get feature configuration
     * @returns FeatureConfig
     * @throws ApiError
     */
    getFeatureConfiguration() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/feature-config',
        });
    }
    /**
     * Get a feature by name
     * @returns any
     * @throws ApiError
     */
    getFeatureByName({ name, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/feature-config/{name}',
            path: {
                'name': name,
            },
        });
    }
}
