import { Box, BoxProps } from '@betterleap/ui';

export const ApiLogsLayout = ({ css, ...props }: BoxProps) => (
  <Box
    css={{
      display: 'grid',
      gridTemplateAreas: `
        "header header"
        "filter filter"
        "logs detail"
      `,
      gridTemplateRows: 'auto auto 1fr',
      gridTemplateColumns: '1.25fr 1fr',
      height: '100vh',
      ...css,
    }}
    {...props}
  />
);

ApiLogsLayout.Header = function Header({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'header', ...css }} {...props} />;
};

ApiLogsLayout.FilterBar = function FilterBar({ css, ...props }: BoxProps) {
  return <Box css={{ gridArea: 'filter', ...css }} {...props} />;
};

ApiLogsLayout.LogViewer = function LogViewer({ css, ...props }: BoxProps) {
  return (
    <Box css={{ gridArea: 'logs', overflow: 'auto', ...css }} {...props} />
  );
};

ApiLogsLayout.LogDetail = function LogDetail({ css, ...props }: BoxProps) {
  return (
    <Box css={{ gridArea: 'detail', overflow: 'auto', ...css }} {...props} />
  );
};
