import React from 'react';
import { STATUS_TAG, Tag } from '../../../constants/statusTags';
import styles from './StatusTag.module.scss';

interface StatusTagProps {
  tag: Tag;
  className?: string;
  content?: React.ReactNode;
}

const StatusTag = ({ tag, className, content }: StatusTagProps) => {
  if (!STATUS_TAG[tag]?.title)
    return (
      <p
        className={`text-xs leading-4 font-medium ${styles.tag} ${styles.default_tag} ${className}`}
      >
        {tag}
      </p>
    );
  return (
    <p
      style={{
        color: STATUS_TAG[tag].color,
        background: STATUS_TAG[tag].background,
        width: 'max-content',
      }}
      className={`text-xs leading-4 font-medium ${styles.tag} ${className}`}
    >
      {content || STATUS_TAG[tag]?.title}
    </p>
  );
};

export default StatusTag;
