import { forwardRef } from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { styled } from '../../stitches.config';

export const StyledLabel = styled(LabelPrimitive.Root, {
  color: '$text-secondary',
  fontSize: '$sm',
  display: 'block',

  variants: {
    inline: {
      true: {
        display: 'inline-block',
      },
    },
    required: {
      true: {
        after: {
          content: `" *"`,
        },
      },
    },
  },
});

export interface LabelProps extends React.ComponentProps<typeof StyledLabel> {
  as?: React.ElementType;
}

export const Label = forwardRef<HTMLElement, LabelProps>(({ ...rest }, ref) => (
  <StyledLabel ref={ref} {...rest} />
));

Label.displayName = 'Label';
