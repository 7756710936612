import React, { forwardRef, useContext } from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '../Checkbox';

import { useMergeRefs } from '../../hooks/useMergeRefs';
import { BoundComponentProps } from './types';
import { FormContext } from './Form';

export type BoundCheckboxProps = CheckboxProps & BoundComponentProps;

export const BoundCheckbox = forwardRef<HTMLButtonElement, BoundCheckboxProps>(
  (
    {
      id,
      control,
      name,
      defaultValue,
      rules,
      required,
      onChange: externalOnChange,
      ...rest
    },
    ref,
  ) => {
    const { control: contextControl } = useContext(FormContext);

    const {
      field: { ref: internalRef, value, onChange, ...checkboxProps },
    } = useController({
      name,
      control: control ?? contextControl,
      defaultValue,
      rules: {
        required: required && 'This field is required',
        ...rules,
      },
    });

    const combinedRef = useMergeRefs(ref, internalRef);

    return (
      <Checkbox
        ref={combinedRef}
        id={id}
        checked={value}
        required={required}
        onChange={(checked: boolean) => {
          onChange(checked);
          externalOnChange?.(checked);
        }}
        {...checkboxProps}
        {...rest}
      />
    );
  },
);

BoundCheckbox.displayName = 'BoundCheckbox';
