import { Flex, Icon } from '@betterleap/ui';

export interface SortIndicatorProps {
  column: {
    sortable?: boolean;
    isSorted?: boolean;
    isSortedDesc?: boolean;
    toggleSortBy: (desc: boolean) => void;
    clearSortBy: () => void;
  };
}

export const SortIndicator = ({ column }: SortIndicatorProps) => {
  if (!column.sortable) {
    return null;
  }

  const isDesc = column.isSorted && column.isSortedDesc;
  const isAsc = column.isSorted && !column.isSortedDesc;

  return (
    <Flex>
      <Icon
        role='button'
        onClick={(e) => {
          e.stopPropagation();

          if (!column.sortable) {
            return;
          }

          if (isAsc) {
            column.clearSortBy();
          } else {
            column.toggleSortBy(false);
          }
        }}
        data-sortable={column.sortable}
        color={isAsc ? '$blue-600' : '$neutral-blue-500'}
        size={16}
        css={{
          '&[data-sortable=true]': {
            cursor: 'pointer',
          },
        }}
        name='arrow-up'
      />
      <Icon
        role='button'
        onClick={(e) => {
          e.stopPropagation();

          if (!column.sortable) {
            return;
          }

          if (isDesc) {
            column.clearSortBy();
          } else {
            column.toggleSortBy(true);
          }
        }}
        data-sortable={column.sortable}
        color={isDesc ? '$blue-600' : '$neutral-blue-500'}
        size={16}
        css={{
          '&[data-sortable=true]': {
            cursor: 'pointer',
          },
        }}
        name='arrow-down'
      />
    </Flex>
  );
};
