/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Chat;
(function (Chat) {
    let type;
    (function (type) {
        type["DM"] = "dm";
        type["GROUP"] = "group";
    })(type = Chat.type || (Chat.type = {}));
})(Chat || (Chat = {}));
