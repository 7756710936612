export class ProjectTagApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get matching tags
     * @returns FindProjectTagsResponse
     * @throws ApiError
     */
    findTags({ search, ids, pageSize, page, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/project-tag',
            query: {
                'search': search,
                'ids': ids,
                'pageSize': pageSize,
                'page': page,
            },
        });
    }
    /**
     * Create a new tag and add it to a project
     * @returns any
     * @throws ApiError
     */
    createTag({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/project-tag',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add a tag to a project
     * @returns any
     * @throws ApiError
     */
    addTag({ projectId, requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/project-tag/{projectId}',
            path: {
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove all tags from a project
     * @returns void
     * @throws ApiError
     */
    removeAllTags({ projectId, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/project-tag/{projectId}',
            path: {
                'projectId': projectId,
            },
        });
    }
    /**
     * Remove a tag from a project
     * @returns void
     * @throws ApiError
     */
    removeTag({ projectId, tagId, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/project-tag/{projectId}/{tagId}',
            path: {
                'projectId': projectId,
                'tagId': tagId,
            },
        });
    }
}
