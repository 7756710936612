/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var LicensedState;
(function (LicensedState) {
    let status;
    (function (status) {
        status["ACTIVE"] = "Active";
        status["INACTIVE"] = "Inactive";
        status["NOT_VERIFIED"] = "Not Verified";
    })(status = LicensedState.status || (LicensedState.status = {}));
})(LicensedState || (LicensedState = {}));
