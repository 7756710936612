import classNames from 'classnames';
import { camelCase, startCase, toLower } from 'lodash';
import 'react-toggle/style.css';
import { Company, COMPANY_VERSION, CompanyAdmin } from '../../../types';
import StatusTag from '../../elements/StatusTag/StatusTag';
import styles from './CompanyInfo.module.scss';

const cn = classNames.bind(styles);

interface CompanyInfoProps {
  data: Company;
  showCreator?: boolean;
  isAdmin?: boolean;
  onVersionChange?: (e: COMPANY_VERSION) => void;
}

const CompanyInfo = ({
  data,
  showCreator = false,
  isAdmin,
  onVersionChange,
}: CompanyInfoProps) => {
  let companyAdmins: CompanyAdmin[] = [];
  if (data?.companyAdmins) {
    companyAdmins = data?.companyAdmins.sort((a, b) => {
      if (
        new Date(a.user.createdAt).getTime() >
        new Date(b.user.createdAt).getTime()
      )
        return 1;
      return -1;
    });
  }

  return (
    <div
      className={cn(styles.bottom_radius, {
        [styles.preview_role_container_admin]: isAdmin,
        [styles.preview_role_container]: !isAdmin,
      })}
    >
      {showCreator && (
        <>
          <div
            className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
          >
            <p
              className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
            >
              Creator Name
            </p>
            <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900'>
              {companyAdmins?.[0]?.user?.firstName}{' '}
              {companyAdmins?.[0]?.user?.lastName}
            </p>
          </div>
          <div
            className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
          >
            <p
              className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
            >
              Creator Email
            </p>
            <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900'>
              <a href={`mailto:${companyAdmins?.[0]?.user?.email}`}>
                {companyAdmins?.[0]?.user?.email}
              </a>
            </p>
          </div>
          {isAdmin && (
            <div
              className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
            >
              <p
                className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
              >
                Creator Role
              </p>
              <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900'>
                {companyAdmins?.[0]?.role}
              </p>
            </div>
          )}
        </>
      )}
      <div
        className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
      >
        <p
          className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
        >
          Team Size
        </p>
        <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900'>
          {data.teamSize}
        </p>
      </div>
      <div
        className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
      >
        <p
          className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
        >
          Last Round Raised
        </p>
        <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900 bg-white-900'>
          {data.lastRoundRaised}
        </p>
      </div>
      <div
        className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
      >
        <p
          className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
        >
          Raised To Date
        </p>
        <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900'>
          {data.amountMoneyRaisedToDate}
        </p>
      </div>
      <div
        className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
      >
        <p
          className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
        >
          Notable Investors
        </p>

        <div className='flex flex-wrap'>
          {data.notableInvestors?.map(
            (investor) =>
              investor.value && (
                <StatusTag
                  tag='notable_investor'
                  content={investor.value}
                  className='mr-2 mb-1'
                />
              ),
          )}
        </div>
      </div>
      <div
        className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
      >
        <p
          className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
        >
          HQ Location
        </p>
        <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900'>
          {data.locationHQ}
        </p>
      </div>
      <div
        className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
      >
        <p
          className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
        >
          Remote Policy
        </p>
        <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900 bg-white-900'>
          {data.postCovidPlan}
        </p>
      </div>

      {isAdmin && (
        <div
          className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
        >
          <p
            className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
          >
            Status
          </p>
          <p className='w-full not-italic font-normal text-sm leading-5 text-gray-900 bg-white-900'>
            <StatusTag tag={data.status} />
          </p>
        </div>
      )}
      <div className={cn(styles.container_spacing, styles.bottom_radius)}>
        <div className='tablet:flex tablet:items-center'>
          <p
            className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
          >
            Perks
          </p>
          <div className='flex flex-wrap'>
            {Object.keys(data.perks || {})?.map(
              (key) =>
                data.perks[key] &&
                key !== 'others' && (
                  <StatusTag
                    tag='perk'
                    content={startCase(camelCase(key))}
                    className='mr-2 mb-1'
                  />
                ),
            )}
            {(data?.perks || {}).others && (
              <StatusTag
                tag='perk'
                content={startCase(toLower(data.perks.other as string))}
                className='mr-2 mb-1'
              />
            )}
          </div>
        </div>
      </div>

      {isAdmin && (
        <div
          className={`tablet:flex tablet:items-center ${styles.container_spacing}`}
        >
          <p
            className={`w-full not-italic font-medium text-sm leading-5 text-gray-600 mobile:mb-1 ${styles.label}`}
          >
            Company version
          </p>
          <select
            defaultValue={data.version}
            onChange={(e) =>
              onVersionChange &&
              onVersionChange(e.target.value as COMPANY_VERSION)
            }
            className={`cursor-pointer bg-white rounded-md outline-none mobile:mt-1 w-full text-sm leading-5
				${styles.dropdown_wrapper}`}
          >
            <option
              key={COMPANY_VERSION.ONE_TO_ONE_MATCHING}
              value={COMPANY_VERSION.ONE_TO_ONE_MATCHING}
            >
              V1.0
            </option>
            <option key={COMPANY_VERSION.ATS} value={COMPANY_VERSION.ATS}>
              V2.0
            </option>
          </select>
        </div>
      )}
    </div>
  );
};

export default CompanyInfo;
