/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Flex,
  FlexProps,
  IconButton,
  Spinner,
  Text,
  TextArea,
} from '@betterleap/ui';
import moment from 'moment';
import { useState } from 'react';
import { ContactNote, ContactProjectNote } from '@betterleap/client';
import NotesImage from '../../../assets/images/notes.svg';

import {
  noteAuthorFirstName,
  noteAuthorLastName,
} from './NoteManager.functions';

export enum NoteManagerStyle {
  CONTACT,
  CONTACT_PROJECT,
  EXTENSION,
}

export interface NoteManagerProps {
  myUserId: string;
  style: NoteManagerStyle;
  noteTerm?: string;
  showNotes: boolean;
  isNotesLoading: boolean;
  notes: ContactProjectNote[] | ContactNote[];
  handleAddNote: (note: string) => Promise<void>;
  noteTextSize?: string;
}

export interface NoteProps extends FlexProps {
  item: ContactProjectNote | ContactNote;
  viewingUserId: string;
  noteTextSize?: string;
  noteStyle?: NoteManagerStyle;
}

export const Note = ({
  item,
  css,
  viewingUserId,
  noteTextSize = 'normal',
  noteStyle = NoteManagerStyle.CONTACT,
  ...rest
}: NoteProps) => {
  const firstName = noteAuthorFirstName(item);
  const lastName = noteAuthorLastName(item);
  return (
    <Flex
      css={{
        display: 'flex',
        gap: 8,
        alignItems: 'end',
        marginBottom: 32,
        ...css,
      }}
      {...rest}
    >
      <Flex
        css={{
          width: '100%',
          maxWidth: 24,
          height: 24,
          borderRadius: 8,
          border:
            item.userId === viewingUserId
              ? '1px solid $purple-300'
              : '1px solid $neutral-blue-200',
          background:
            item.userId === viewingUserId
              ? '$purple-100'
              : noteStyle === NoteManagerStyle.CONTACT
              ? '$neutral-blue-50'
              : '$background-component',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Text
          as='p'
          css={{
            color:
              item.userId === viewingUserId
                ? '$purple-500'
                : '$neutral-blue-500',
            fontSize: 'x-small',
            padding: 4,
            fontWeight: 500,
          }}
        >
          {firstName?.trim()?.charAt(0)}
          {lastName?.trim()?.charAt(0)}
        </Text>
      </Flex>
      <Flex vertical css={{ gap: 8, width: '100%' }}>
        <Text
          as='p'
          css={{
            color:
              item.userId === viewingUserId
                ? '$purple-700'
                : '$neutral-blue-600',
            fontSize: 'x-small',
            fontWeight: 400,
          }}
        >
          {firstName} {lastName}
          {'  '}
          {moment(item.createdAt).format('MMMM D, YYYY h:mm A')}
        </Text>
        <Box
          css={{
            width: '100%',
            borderRadius: 16,
            border:
              item.userId === viewingUserId
                ? '1px solid $purple-300'
                : '1px solid $neutral-blue-200',
            background:
              item.userId === viewingUserId
                ? '$purple-100'
                : '$background-component',
            padding: 20,
          }}
        >
          <Text
            as='p'
            css={{
              color: '$neutral-blue-1000',
              fontSize: noteTextSize,
            }}
            dangerouslySetInnerHTML={{
              __html: item.note.replace(/\n/g, '<br>'),
            }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export const NoteManager = (props: NoteManagerProps) => {
  const {
    style,
    noteTerm = 'note',
    showNotes,
    isNotesLoading,
    notes,
    handleAddNote,
    noteTextSize = 'normal',
  } = props;
  const [note, setNoteValue] = useState<string>('');

  const myUserId =
    style === NoteManagerStyle.CONTACT_PROJECT ? props.myUserId : '';

  const addNote = () => {
    handleAddNote(note);
    setNoteValue('');
  };

  const addNotesHeight = showNotes ? 120 : 160;
  return (
    <Flex
      id='notes-manager'
      css={{
        alignItems: 'flex-start',
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      {showNotes && (
        <Flex vertical css={{ width: '100%' }}>
          <Box
            id='scrollable-notes-area'
            css={{
              position: 'absolute',
              top: 0,
              bottom: addNotesHeight,
              left: 0,
              right: 0,
              width: '100%',
              borderTopLeftRadius: 24,
              borderTopRightRadius: 24,
              border: '1px solid $neutral-blue-200',
              borderBottom: 'none',
              padding: 24,
              overflowY: 'auto',
              ...(style === NoteManagerStyle.CONTACT
                ? { backgroundColor: '$background-component' }
                : {}),
            }}
          >
            {notes.length ? (
              notes.map((item: ContactProjectNote | ContactNote) => (
                <Note
                  key={item.id}
                  item={item}
                  noteStyle={style}
                  noteTextSize={noteTextSize}
                  viewingUserId={myUserId}
                />
              ))
            ) : isNotesLoading ? (
              <Box
                css={{
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Spinner />
              </Box>
            ) : (
              <Flex
                vertical
                css={{
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={NotesImage} alt='no-notes' />
                <Flex vertical css={{ gap: 10, alignItems: 'center' }}>
                  <Text
                    css={{
                      fontSize: '20px',
                      fontWeight: 500,
                      textTransform: 'capitalize',
                    }}
                  >
                    {`No ${noteTerm}s Yet`}
                  </Text>
                  <Text
                    css={{
                      fontWeight: 300,
                      fontSize: '14px',
                      color: '$neutral-blue-700',
                    }}
                  >
                    {`Add a ${noteTerm} below!`}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Box>
        </Flex>
      )}

      <Flex
        css={{
          gap: 12,
          width: '100%',
          height: addNotesHeight,
          position: 'absolute',
          ...(showNotes ? { bottom: 0 } : { top: 0 }),
        }}
      >
        <form
          style={{ height: '100%', width: '100%', position: 'relative' }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <TextArea
            autoFocus
            value={note}
            placeholder={`Add a ${noteTerm} here`}
            css={{
              border: '1px solid $neutral-blue-200',
              width: '100%',
              height: addNotesHeight,
              borderRadius: showNotes ? 0 : 16,
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              padding: 16,
              paddingRight: 48,
              alignItems: 'start',
              resize: 'none',
              outlineColor: '$neutral-blue-200',
            }}
            onChange={(value: unknown) => setNoteValue(value as string)}
          />
          {style === NoteManagerStyle.EXTENSION ? (
            <Flex
              css={{
                padding: '20px 0',
                alignItems: 'stretch',
                gap: 10,
              }}
            >
              <Button
                variant='primary'
                onClick={addNote}
                disabled={!note}
                css={{
                  flex: 1,
                }}
              >
                Add Note
              </Button>
            </Flex>
          ) : (
            <IconButton
              label={`Add ${noteTerm}`}
              name='paper-airplane'
              size='sm'
              css={{
                position: 'absolute',
                bottom: 12,
                right: 20,
                transform: 'rotate(90deg)',
                fill: 'white',
                backgroundColor: '$blue-600',
                hover: {
                  backgroundColor: '$blue-700',
                },
              }}
              onClick={addNote}
            />
          )}
        </form>
      </Flex>
    </Flex>
  );
};
