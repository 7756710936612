import { Types } from '@betterleap/shared';
import { isEqual } from 'lodash';

const { ROLE } = Types;

export const getCustomerType = (organization: Types.Organization) => {
  let type: string;
  if (isEqual(organization.orgType, Types.ORG_TYPE.VC)) {
    type = 'VC';
  } else if (isCompany(organization.createdByUser?.role)) {
    type = 'Company';
  } else if (isRecruiter(organization.createdByUser?.role)) {
    type = 'Recruiter';
  } else if (organization.createdByUser?.role === ROLE.NONE) {
    type = '(not selected yet)';
  } else {
    type = organization.createdByUser?.role;
  }
  return type;
};

function isCompany(role?: Types.ROLE): boolean {
  const roles = [ROLE.COMPANY_ADMIN];
  return !!role && roles.includes(role);
}

function isRecruiter(role?: Types.ROLE): boolean {
  const roles = [ROLE.RECRUITER];
  return !!role && roles.includes(role);
}
