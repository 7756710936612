import * as Separator from '@radix-ui/react-separator';
import { forwardRef } from 'react';
import { styled } from '../../stitches.config';

export const StyledDivider = styled(Separator.Root, {
  // styles here
  backgroundColor: '$border-light',
  '&[data-orientation=horizontal]': { height: 1, width: '100%' },
  '&[data-orientation=vertical]': { height: '100%', width: 1 },
});

export interface DividerProps
  extends React.ComponentProps<typeof StyledDivider> {
  as?: React.ElementType;
}

export const Divider = forwardRef<HTMLElement, DividerProps>(
  ({ orientation = 'horizontal', ...rest }, ref) => (
    <StyledDivider ref={ref} orientation={orientation} {...rest} />
  ),
);

Divider.displayName = 'Divider';
