/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Organization;
(function (Organization) {
    /**
     * Paid indicates a paid user.
     */
    let tier;
    (function (tier) {
        tier["PAYWALL"] = "Paywall";
        tier["FREE_TRIAL_REQUESTED"] = "Free Trial Requested";
        tier["FREE_TRIAL"] = "Free Trial";
        tier["PAID"] = "Paid";
    })(tier = Organization.tier || (Organization.tier = {}));
    let orgType;
    (function (orgType) {
        orgType["ADMIN"] = "Admin";
        orgType["CLIENT_USER"] = "Client User";
        orgType["VC"] = "VC";
        orgType["COMPANY"] = "Company";
        orgType["RECRUITER"] = "Recruiter";
    })(orgType = Organization.orgType || (Organization.orgType = {}));
    let creationType;
    (function (creationType) {
        creationType["REGULAR"] = "Regular";
        creationType["INVITED"] = "Invited";
    })(creationType = Organization.creationType || (Organization.creationType = {}));
    let atsSource;
    (function (atsSource) {
        atsSource["LEVER"] = "Lever";
        atsSource["GREENHOUSE"] = "Greenhouse";
        atsSource["ASHBY"] = "Ashby";
        atsSource["RECRUITERFLOW"] = "Recruiterflow";
        atsSource["CLOCKWORK"] = "Clockwork";
        atsSource["BULLHORN"] = "Bullhorn";
        atsSource["ORACLE_TALEO"] = "Oracle Taleo";
    })(atsSource = Organization.atsSource || (Organization.atsSource = {}));
    let crmSource;
    (function (crmSource) {
        crmSource["SALESFORCE"] = "Salesforce";
    })(crmSource = Organization.crmSource || (Organization.crmSource = {}));
    let goal;
    (function (goal) {
        goal["REDUCE_COSTS"] = "REDUCE_COSTS";
        goal["INCREASE_EMAIL_COVERAGE"] = "INCREASE_EMAIL_COVERAGE";
        goal["IMPROVE_EFFICIENCY"] = "IMPROVE_EFFICIENCY";
        goal["AI"] = "AI";
        goal["OTHER"] = "OTHER";
    })(goal = Organization.goal || (Organization.goal = {}));
})(Organization || (Organization = {}));
