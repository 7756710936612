export class MergeApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns MergeSyncDto
     * @throws ApiError
     */
    checkSyncStatus() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/merge/sync/ping',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    onLinkedAccountLinked() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/merge/linked-account-linked',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    onCommonModelSynced() {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/merge/common-model-synced',
        });
    }
}
