import React, { forwardRef } from 'react';
import { Button, ButtonProps, Text } from '@betterleap/ui';
import { capitalize } from 'lodash';
import googleLogo from '../../../assets/images/google-logo.svg';
import microsoftLogo from '../../../assets/images/microsoft-logo.svg';

interface SignInButtonProps extends ButtonProps {
  provider: 'google' | 'microsoft';
}

const SignInButton = forwardRef<HTMLButtonElement, SignInButtonProps>(
  ({ provider, css, ...props }, ref) => {
    const logo = provider === 'google' ? googleLogo : microsoftLogo;
    const alt = `${provider} logo`;
    const name = capitalize(provider);
    return (
      <Button
        variant='headless'
        ref={ref}
        css={{
          borderRadius: 2,
          backgroundColor: '#4285F4',
          justifyContent: 'flex-start',
          color: 'white',
          height: 44,
          padding: 0,
          focus: {
            boxShadow: 'none',
          },
          minWidth: 235,
          ...css,
        }}
        {...props}
      >
        <img src={logo} alt={alt} style={{ marginLeft: -1 }} />
        <Text inherit css={{ px: 12, fontWeight: '$medium' }} size='base'>
          Sign in with {name}
        </Text>
      </Button>
    );
  },
);

SignInButton.displayName = 'SignInButton';

export default SignInButton;
