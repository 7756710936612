import { MiniTable, paginationConfig, Types } from '@betterleap/shared';
import { Badge, Text } from '@betterleap/ui';
import { get } from 'lodash';
import qs from 'qs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCustomerType } from '../../../helper/getCustomerType';
import routeNames from '../../../constants/routeNames';
import useFetch from '../../../hooks/fetch';
import { apiActions } from '../../../store/api/actions';
import SearchContainer from '../../modules/SearchContainer/SearchContainer';
import styles from './SearchTemplate.module.scss';

const organizationColumns = () => ({
  columns: [
    {
      Header: 'NAME',
      accessor: (value: Types.Organization) => (
        <div style={{ display: 'flex' }}>
          <Text css={{ fontSize: '$sm', fontWeight: '$medium' }}>
            {value.name}
          </Text>
          {value.test && (
            <Badge
              className='ml-2'
              css={{ fontWeight: '$medium' }}
              size='sm'
              variant='info'
            >
              Test
            </Badge>
          )}
        </div>
      ),
      slot: 'custom',
    },
    {
      Header: 'TYPE',
      accessor: (value: Types.Organization) => (
        <Text css={{ fontSize: '$sm', fontWeight: '$medium' }}>
          {getCustomerType(value)}
        </Text>
      ),
    },
    {
      Header: 'TIER',
      accessor: 'tier',
      slot: 'tag',
    },
    {
      Header: 'CREATOR EMAIL',
      accessor: 'createdByUser.email',
      slot: 'bold',
    },
    {
      Header: 'DATE JOINED',
      accessor: 'createdAt',
      slot: 'date',
    },
  ],
});

const SearchTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchKey = qs.parse(location.search.slice(1));
  const [organizations, doFetchOrganizations] = useFetch<Types.Organization[]>(
    'organizations_search',
  );

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(apiActions.remove('organizations_search'));
    },
    [],
  );

  useEffect(() => {
    if (!organizations.loading) {
      doFetchOrganizations({
        search: searchKey.search,
        page:
          searchKey.search ===
          get(organizations, 'fetchParams.search', searchKey.search)
            ? get(searchKey, 'organizationpage', '1')
            : '1',
        pageSize: get(
          searchKey,
          'organizationpageSize',
          paginationConfig.pageSize,
        ),
      });
    }
  }, [JSON.stringify(searchKey)]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRowClick = (commandPressed: boolean, row: any, type: string) => {
    switch (type) {
      case 'organization':
        if (commandPressed)
          return window.open(routeNames.organization({ id: row.id }), '_blank');
        return navigate(routeNames.organization({ id: row.id }));
      default:
        return false;
    }
  };

  return (
    <div>
      <SearchContainer title='Search' />
      <div className={`w-full ${styles.bottom_border}`} />
      <div className='flex w-full justify-between mobile:mt-5 tablet:mt-10 mobile:mb-5 tablet:mb-10'>
        <h2 className='not-italic font-semibold text-2xl leading-7 text-black'>
          Search Results
        </h2>
        <span className='not-italic font-medium text-lg leading-5 text-blue-500'>
          {`${organizations?.meta?.count || 0} 
					results`}
        </span>
      </div>

      {organizations.data && (
        <MiniTable
          title='Organization'
          data={get(organizations, 'data') || []}
          columns={organizationColumns().columns}
          count={organizations.meta.count}
          paginationKey='organization'
          loading={organizations.loading}
          onRowClick={(commandPressed, row) =>
            onRowClick(commandPressed, row, 'organization')
          }
        />
      )}
    </div>
  );
};

export default SearchTemplate;
