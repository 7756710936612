import { keyframes, styled } from '../../stitches.config';

export const skeletonBgFlash = keyframes({
  '0%': {
    borderColor: '#F1F2F4',
    background: '#F1F2F4',
  },
  '100%': {
    borderColor: '#E1E4E8',
    background: '#E1E4E8',
  },
});

export const Skeleton = styled('div', {
  animation: `0.8s linear infinite alternate ${skeletonBgFlash}`,
  width: 'fit-content',
  borderRadius: '$lg',
});

export const TextSkeleton = styled(Skeleton, {
  borderRadius: '$md',

  variants: {
    size: {
      xs: {
        height: 12,
      },
      sm: {
        height: 14,
      },
      md: {
        height: 16,
      },
      lg: {
        height: 18,
      },
      xl: {
        height: 20,
      },
    },
  },
});

export const SquareSkeleton = styled(Skeleton, {
  variants: {
    size: {
      xs: {
        borderRadius: '$md',
        height: 24,
        width: 24,
      },
      sm: {
        borderRadius: '$lg',
        height: 32,
        width: 32,
      },
      md: {
        borderRadius: '$xl',
        height: 40,
        width: 40,
      },
      lg: {
        borderRadius: '$2xl',
        height: 48,
        width: 48,
      },
      xl: {
        borderRadius: '$3xl',
        height: 56,
        width: 56,
      },
    },
  },
});

export const CircleSkeleton = styled(Skeleton, {
  borderRadius: '$full',
  variants: {
    size: {
      xs: {
        height: 24,
        width: 24,
      },
      sm: {
        height: 32,
        width: 32,
      },
      md: {
        height: 40,
        width: 40,
      },
      lg: {
        height: 48,
        width: 48,
      },
      xl: {
        height: 56,
        width: 56,
      },
    },
  },
});
