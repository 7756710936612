import { getEnvVar } from './helper/getEnvVar';
import './styles/_main.scss';

// components

export * from './components';

// constants

export * from './constants';

// helper

export * from './helper';

// hooks

export * from './hooks';

// lib

export * from './lib';

// types

export * as Types from './types';

const appName = getEnvVar('REACT_APP_NAME') || 'shared';
const version = getEnvVar('REACT_APP_VERSION') || '0.1';
const buildTime = getEnvVar('REACT_APP_BUILD_TIME')
  ? `, built at: ${getEnvVar('REACT_APP_BUILD_TIME')}`
  : '';
// eslint-disable-next-line no-console
console.log(
  `Betterleap application: ${appName}, version: ${version}${buildTime}`,
);
