/* eslint-disable no-unused-vars */

export interface IAction<T extends string> {
  type: T;
}

export interface IActionWithPayload<T extends string, P, M, H>
  extends IAction<T> {
  payload: P;
  meta: M;
  helpers?: H;
}

export function createAction<T extends string>(type: T): IAction<T>;
export function createAction<T extends string, P, M, H>(
  type: T,
  payload: P,
  meta?: M,
  helpers?: H | null,
): IActionWithPayload<T, P, M, H>;

export function createAction<T extends string, P, M, H>(
  type: T,
  payload?: P,
  meta?: M,
  helpers?: H,
): IAction<T> | IActionWithPayload<T, P, M, H> {
  return payload === undefined ? { type } : { type, payload, meta, helpers };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionType = (...args: any[]) => any;

interface IActionCreatorsMapObject {
  [actionCreator: string]: FunctionType;
}

export type ActionsUnion<A extends IActionCreatorsMapObject> = ReturnType<
  A[keyof A]
>;
