export class ChatApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @returns any
     * @throws ApiError
     */
    me() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat/user',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getWorkspaces() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat/workspaces',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    getWorkspace({ workspaceIdOrSlug, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat/workspaces/{workspaceIdOrSlug}',
            path: {
                'workspaceIdOrSlug': workspaceIdOrSlug,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    joinWorkspace({ workspaceIdOrSlug, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/chat/workspaces/{workspaceIdOrSlug}/join',
            path: {
                'workspaceIdOrSlug': workspaceIdOrSlug,
            },
        });
    }
    /**
     * @returns ChatMessage
     * @throws ApiError
     */
    chatMessages({ chatId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/chat/{chatId}/messages',
            path: {
                'chatId': chatId,
            },
        });
    }
    /**
     * @returns ChatMessageAttachment
     * @throws ApiError
     */
    saveAttachment({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/chat/attachments',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
