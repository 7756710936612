/* eslint-disable jsx-a11y/tabindex-no-positive */
import { forwardRef } from 'react';
import { styled } from '../../stitches.config';
import { Text } from '../Text';
import { Icon } from '../Icon';
import { Flex } from '../Flex';

export const StyledBackButton = styled('button', {
  cursor: 'pointer',
  gap: 8,
  display: 'flex',
  alignItems: 'center',
  width: 'max-content',
  background: 'transparent',
  border: 'unset',
  marginBottom: 16,
  hover: {
    textDecoration: 'underline',
    textDecorationColor: '$violet-700',
  },
  focus: {
    '& div': {
      boxShadow: '$breadcrumbfocus',
      transition: 'box-shadow 0.3s ease-in-out',
    },
  },
});

export interface BackButtonProps
  extends React.ComponentProps<typeof StyledBackButton> {
  as?: React.ElementType;
  route?: string;
}

export const BackButton = forwardRef<HTMLElement, BackButtonProps>(
  ({ route, onClick, ...rest }, ref) => (
    <StyledBackButton ref={ref} {...rest} onClick={onClick}>
      <Flex
        justify='center'
        align='center'
        css={{
          background: '$violet-200',
          borderRadius: '$lg',
          width: 24,
          height: 24,
        }}
      >
        <Icon name='arrow-left' color='$violet-700' size={12} />
      </Flex>
      <Text css={{ color: '$violet-700', fontSize: '$xs' }}>
        {route ?? 'Back'}
      </Text>
    </StyledBackButton>
  ),
);

BackButton.displayName = 'BackButton';
