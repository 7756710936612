/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CandidateLanguage;
(function (CandidateLanguage) {
    let proficiency;
    (function (proficiency) {
        proficiency["ELEMENTARY"] = "ELEMENTARY";
        proficiency["LIMITED_WORKING"] = "LIMITED_WORKING";
        proficiency["PROFESSIONAL_WORKING"] = "PROFESSIONAL_WORKING";
        proficiency["FULL_PROFESSIONAL"] = "FULL_PROFESSIONAL";
        proficiency["NATIVE_OR_BILINGUAL"] = "NATIVE_OR_BILINGUAL";
    })(proficiency = CandidateLanguage.proficiency || (CandidateLanguage.proficiency = {}));
})(CandidateLanguage || (CandidateLanguage = {}));
