/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var JobRequestDto;
(function (JobRequestDto) {
    let job;
    (function (job) {
        job["JOB_START_THREAD_SCANNER"] = "job.start_thread_scanner";
        job["JOB_START_THREAD_PROCESSOR"] = "job.start_thread_processor";
        job["JOB_CREATE_CRM_LEAD"] = "job.create_crm_lead";
        job["JOB_DOWNLOAD_ATS_NOTES"] = "job.download_ats_notes";
        job["JOB_DOWNLOAD_ATS_CANDIDATES"] = "job.download_ats_candidates";
    })(job = JobRequestDto.job || (JobRequestDto.job = {}));
})(JobRequestDto || (JobRequestDto = {}));
