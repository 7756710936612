import { OrganizationApiKey } from '@betterleap/client';
import { Text } from '@betterleap/ui';

export const CreditColumn = ({ apiKey }: { apiKey: OrganizationApiKey }) => {
  const availableCredits =
    (apiKey?.totalCredits ?? 0) - (apiKey?.creditsUsed ?? 0);

  return (
    <Text
      size='sm'
      css={{
        color: '$text-secondary',
        '&[data-overdraft=true]': {
          color: '$danger-700',
        },
      }}
      data-overdraft={availableCredits < 0}
    >
      {availableCredits}
    </Text>
  );
};
