/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var CreateStepDto;
(function (CreateStepDto) {
    let type;
    (function (type) {
        type["X_DIRECT_MESSAGE"] = "X direct message";
        type["SMS"] = "sms";
        type["PHONE_CALL"] = "phone call";
        type["EMAIL"] = "email";
        type["INMAIL"] = "inmail";
    })(type = CreateStepDto.type || (CreateStepDto.type = {}));
})(CreateStepDto || (CreateStepDto = {}));
