import { Flex, Toggle } from '@betterleap/ui';

interface LivePauseButtonProps {
  isPaused: boolean;
  onClick: (paused: boolean) => void;
}

export const LivePauseButton = ({
  isPaused,
  onClick,
}: LivePauseButtonProps) => {
  return (
    <Flex>
      <Toggle
        style={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}
        pressed={!isPaused}
        onChange={(pressed) => onClick(!pressed)}
        css={{ borderRadius: 'unset', border: '1px solid $neutral-blue-500' }}
      >
        Live
      </Toggle>
      <Toggle
        style={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}
        pressed={isPaused}
        onChange={(pressed) => onClick(pressed)}
        css={{ borderRadius: 'unset', border: '1px solid $neutral-blue-500' }}
      >
        Pause
      </Toggle>
    </Flex>
  );
};
