export class AtsCandidateApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get contact with ATS data by linkedin url
     * @returns ContactWithAtsDataDto
     * @throws ApiError
     */
    getContactWithAtsData({ linkedinUrl, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ats-candidate',
            query: {
                'linkedinUrl': linkedinUrl,
            },
        });
    }
}
