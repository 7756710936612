import React from 'react';
import withSidebar from 'components/HOC/withSidebar';
import SearchTemplate from '../components/templates/Search/Search.template';

const SearchPage = () => (
  <React.StrictMode>
    <SearchTemplate />
  </React.StrictMode>
);

export default withSidebar(SearchPage);
