export var AUTH_ACTIONS;
(function (AUTH_ACTIONS) {
    AUTH_ACTIONS["read"] = "read";
    AUTH_ACTIONS["create"] = "create";
    AUTH_ACTIONS["update"] = "update";
    AUTH_ACTIONS["delete"] = "delete";
    AUTH_ACTIONS["manage"] = "manage";
    AUTH_ACTIONS["export"] = "export";
    AUTH_ACTIONS["access"] = "access";
})(AUTH_ACTIONS || (AUTH_ACTIONS = {}));
export var AUTH_ROLE;
(function (AUTH_ROLE) {
    AUTH_ROLE["SuperAdmin"] = "superadmin";
    AUTH_ROLE["Admin"] = "admin";
    // eslint-disable-next-line @typescript-eslint/no-shadow
    AUTH_ROLE["User"] = "user";
})(AUTH_ROLE || (AUTH_ROLE = {}));
