/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ProjectSuggestedContactDto;
(function (ProjectSuggestedContactDto) {
    let status;
    (function (status) {
        status["ACTIVE"] = "Active";
        status["ADDED"] = "Added";
        status["REMOVED"] = "Removed";
    })(status = ProjectSuggestedContactDto.status || (ProjectSuggestedContactDto.status = {}));
})(ProjectSuggestedContactDto || (ProjectSuggestedContactDto = {}));
