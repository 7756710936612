export class ReportingApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Count entities.
     * @returns number
     * @throws ApiError
     */
    count({ entity, startTime, endTime, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/reporting',
            query: {
                'entity': entity,
                'startTime': startTime,
                'endTime': endTime,
            },
        });
    }
}
