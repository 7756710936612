/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var Company;
(function (Company) {
    let teamSize;
    (function (teamSize) {
        teamSize["LESS_THAN_5"] = "Less than 5";
        teamSize["_5_25"] = "5-25";
        teamSize["_25_50"] = "25-50";
        teamSize["_50_100"] = "50-100";
        teamSize["_100_"] = "100+";
    })(teamSize = Company.teamSize || (Company.teamSize = {}));
    let lastRoundRaised;
    (function (lastRoundRaised) {
        lastRoundRaised["BOOTSTRAPPED"] = "Bootstrapped";
        lastRoundRaised["PRE_SEED"] = "Pre-seed";
        lastRoundRaised["SEED"] = "Seed";
        lastRoundRaised["A"] = "A";
        lastRoundRaised["B"] = "B";
        lastRoundRaised["C"] = "C";
        lastRoundRaised["D"] = "D";
        lastRoundRaised["E"] = "E";
        lastRoundRaised["F"] = "F";
        lastRoundRaised["PUBLIC"] = "PUBLIC";
    })(lastRoundRaised = Company.lastRoundRaised || (Company.lastRoundRaised = {}));
    let amountMoneyRaisedToDate;
    (function (amountMoneyRaisedToDate) {
        amountMoneyRaisedToDate["LESS_THAN_500K"] = "Less than $500K";
        amountMoneyRaisedToDate["_500K_5M"] = "$500K - $5M";
        amountMoneyRaisedToDate["_5_10M"] = "$5-10M";
        amountMoneyRaisedToDate["_10_50M"] = "$10-50M";
        amountMoneyRaisedToDate["_50_100M"] = "$50-100M";
        amountMoneyRaisedToDate["_100M_"] = "$100M +";
    })(amountMoneyRaisedToDate = Company.amountMoneyRaisedToDate || (Company.amountMoneyRaisedToDate = {}));
    let postCovidPlan;
    (function (postCovidPlan) {
        postCovidPlan["FULLY_IN_OFFICE"] = "Fully in-office";
        postCovidPlan["FULLY_REMOTE"] = "Fully Remote";
        postCovidPlan["HYBRID"] = "Hybrid";
        postCovidPlan["OTHER"] = "Other";
    })(postCovidPlan = Company.postCovidPlan || (Company.postCovidPlan = {}));
    let partnersFeature;
    (function (partnersFeature) {
        partnersFeature["DISABLED"] = "disabled";
        partnersFeature["INTERESTED"] = "interested";
        partnersFeature["ENABLED"] = "enabled";
    })(partnersFeature = Company.partnersFeature || (Company.partnersFeature = {}));
    let status;
    (function (status) {
        status["ACTIVE"] = "active";
        status["PENDING"] = "pending";
    })(status = Company.status || (Company.status = {}));
    let version;
    (function (version) {
        version["_1_0"] = "1.0";
        version["_2_0"] = "2.0";
    })(version = Company.version || (Company.version = {}));
})(Company || (Company = {}));
