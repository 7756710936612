import { VariantProps } from '@mpalmerlee/stitches-react';
import { createContext, forwardRef, useContext } from 'react';
import { styled } from '../../stitches.config';
import { AlertIcon, AlertIconProps } from '../Icon';
import { StyledText } from '../Text';
import { Button, ButtonProps } from '../Button';

const AlertContext = createContext<VariantProps<typeof StyledAlert>>({
  variant: 'info',
});

const useAlertContext = () => useContext(AlertContext);

export const StyledAlert = styled('div', {
  p: 16,
  borderRadius: 20,
  display: 'grid',
  gridTemplateAreas: `
  "icon heading action"
  "icon description action"
  `,
  gridTemplateColumns: 'max-content auto max-content',
  columnGap: 12,
  alignItems: 'center',

  variants: {
    variant: {
      danger: {
        color: '$danger-700',
        backgroundColor: '$danger-100',
      },
      info: {
        color: '$primary-600',
        backgroundColor: '$primary-50',
      },
      success: {
        color: '$success-800',
        backgroundColor: '$success-100',
      },
      warning: {
        color: '$warning-700',
        backgroundColor: '$warning-100',
      },
      purple: {
        color: '$purple-700',
        backgroundColor: '$purple-100',
      },
    },
    blockQuote: {
      true: {
        borderLeftWidth: 4,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeftStyle: 'solid',
      },
    },
  },

  compoundVariants: [
    {
      variant: 'danger',
      blockQuote: true,
      css: {
        borderLeftColor: '$danger-500',
      },
    },
    {
      variant: 'success',
      blockQuote: true,
      css: {
        borderLeftColor: '$success-500',
      },
    },
    {
      variant: 'info',
      blockQuote: true,
      css: {
        borderLeftColor: '$primary-400',
      },
    },
    {
      variant: 'warning',
      blockQuote: true,
      css: {
        borderLeftColor: '$warning-500',
      },
    },
  ],

  defaultVariants: {
    variant: 'info',
  },
});

export const AlertingIcon = forwardRef<HTMLElement, Partial<AlertIconProps>>(
  ({ css, ...props }, ref) => {
    const { variant } = useAlertContext();

    let iconProps: AlertIconProps;

    switch (variant) {
      case 'danger':
        iconProps = {
          name: 'x-circle',
        };
        break;
      case 'info':
        iconProps = {
          name: 'information-circle',
        };
        break;
      case 'success':
        iconProps = {
          name: 'check-circle',
        };
        break;
      case 'warning':
        iconProps = {
          name: 'exclamation',
        };
        break;
      default:
        iconProps = {
          name: 'information-circle',
        };
    }

    return (
      <AlertIcon
        css={{ minWidth: 18, gridArea: 'icon', ...css }}
        variant={variant}
        shape='rounded-square'
        ref={ref}
        {...iconProps}
        {...props}
      />
    );
  },
);

AlertingIcon.displayName = 'AlertingIcon';

const StyledAlertHeading = styled(StyledText, {
  fontWeight: '$medium',
  fontSize: '$sm',
  gridArea: 'heading',

  variants: {
    variant: {
      danger: {
        color: '$danger-800',
      },
      info: {
        color: '$primary-800',
      },
      success: {
        color: '$success-900',
      },
      warning: {
        color: '$warning-900',
      },
      purple: {
        color: '$purle-700',
      },
    },
  },
});

const StyledAlertDescription = styled(StyledText, {
  fontSize: '$sm',
  gridArea: 'description',
  variants: {
    variant: {
      danger: {
        color: '$danger-700',
      },
      info: {
        color: '$primary-600',
      },
      success: {
        color: '$success-800',
      },
      warning: {
        color: '$warning-700',
      },
      purple: {
        color: '$purle-700',
      },
    },
  },
});

export interface AlertHeadingProps
  extends React.ComponentProps<typeof StyledAlertHeading> {
  as?: React.ElementType;
}

export const AlertHeading = forwardRef<HTMLElement, AlertHeadingProps>(
  ({ ...rest }, ref) => {
    const { variant } = useAlertContext();
    return <StyledAlertHeading ref={ref} variant={variant} {...rest} />;
  },
);

AlertHeading.displayName = 'AlertHeading';

export interface AlertDescriptionProps
  extends React.ComponentProps<typeof StyledAlertDescription> {
  as?: React.ElementType;
}

export const AlertDescription = forwardRef<HTMLElement, AlertDescriptionProps>(
  ({ ...rest }, ref) => {
    const { variant } = useAlertContext();
    return <StyledAlertDescription ref={ref} variant={variant} {...rest} />;
  },
);

AlertDescription.displayName = 'AlertDescription';

export const AlertAction = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ css, ...rest }, ref) => {
    const { variant } = useAlertContext();
    return (
      <Button
        ref={ref}
        size='sm'
        css={{ gridArea: 'action', ...css }}
        variant={
          variant === 'info'
            ? 'empty-primary'
            : (`empty-${variant}` as ButtonProps['variant'])
        }
        {...rest}
      />
    );
  },
);

AlertAction.displayName = 'AlertAction';

export interface AlertProps extends React.ComponentProps<typeof StyledAlert> {
  as?: React.ElementType;
}

export const Alert = forwardRef<HTMLElement, AlertProps>(
  ({ variant = 'info', ...rest }, ref) => {
    return (
      <AlertContext.Provider value={{ variant }}>
        <StyledAlert ref={ref} variant={variant} {...rest} />
      </AlertContext.Provider>
    );
  },
);

Alert.displayName = 'Alert';
