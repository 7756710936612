import { cloneElement, useCallback } from 'react';
import { ModalContext, useModalContext } from './ModalProvider';

export const useModal = <P, T = boolean>(
  ModalComponent: React.FC<any>,
  options = { isDismissable: true },
) => {
  const context = useModalContext();
  const { closeModal, openModal } = context;
  const { isDismissable } = options;

  const openModalFunc = useCallback(
    ({ ...restProps }: P = {} as P) =>
      new Promise<T | undefined>((resolve, reject) => {
        const onSubmit = (data?: T) => {
          closeModal();
          resolve((data ?? true) as T);
        };

        const onDismiss = () => {
          closeModal();
          resolve(undefined);
        };

        const onReject = (err: any) => {
          closeModal();
          reject(err);
        };

        openModal(
          <ModalContext.Provider
            value={{
              onDismiss,
              isDismissable,
              ...context,
            }}
          >
            {cloneElement(<ModalComponent />, {
              onSubmit,
              onReject,
              onDismiss,
              ...restProps,
            })}
          </ModalContext.Provider>,
        );
      }),
    [],
  );

  return openModalFunc;
};
