import { getEnvVar } from './getEnvVar';

export class EnvironmentInfoFactory {
  env: string = (getEnvVar('REACT_APP_ENV') as string) || 'prod';

  public get isProductionZone(): boolean {
    return ['prod', 'production'].includes(this.env);
  }

  public get isTestZone(): boolean {
    return ['dev', 'development', 'qa'].includes(this.env);
  }

  public get isNonProductionZone(): boolean {
    return !this.isProductionZone;
  }

  public get isLocalHost(): boolean {
    return this.env === 'development';
  }
}
