/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ChatUser;
(function (ChatUser) {
    let role;
    (function (role) {
        role["CANDIDATE"] = "candidate";
        role["RECRUITER"] = "recruiter";
    })(role = ChatUser.role || (ChatUser.role = {}));
})(ChatUser || (ChatUser = {}));
