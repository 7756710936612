/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ContactDto;
(function (ContactDto) {
    let type;
    (function (type) {
        type["PERSONAL"] = "Personal";
        type["WORK"] = "Work";
    })(type = ContactDto.type || (ContactDto.type = {}));
})(ContactDto || (ContactDto = {}));
