import { forwardRef } from 'react';
import { styled } from '../../stitches.config';

export const StyledBox = styled('div', {
  boxSizing: 'border-box',
});

export interface StyledBoxProps extends React.ComponentProps<typeof StyledBox> {
  as?: React.ElementType;
  dataCy?: string;
}

type OtherHtmlProps = Omit<
  React.AllHTMLAttributes<HTMLElement>,
  keyof StyledBoxProps
>;

export type BoxProps = StyledBoxProps & OtherHtmlProps;

export const Box = forwardRef<HTMLElement, BoxProps>(
  ({ dataCy, ...rest }, ref) => (
    <StyledBox ref={ref} {...rest} data-cy={dataCy} />
  ),
);

Box.displayName = 'Box';
