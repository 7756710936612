/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var AiDatabaseAgentFeedbackDto;
(function (AiDatabaseAgentFeedbackDto) {
    let sentiment;
    (function (sentiment) {
        sentiment["POSITIVE"] = "POSITIVE";
        sentiment["NEGATIVE"] = "NEGATIVE";
    })(sentiment = AiDatabaseAgentFeedbackDto.sentiment || (AiDatabaseAgentFeedbackDto.sentiment = {}));
})(AiDatabaseAgentFeedbackDto || (AiDatabaseAgentFeedbackDto = {}));
