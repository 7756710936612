export class ContactPhoneNumberApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Find contact phone numbers
     * @returns ContactPhoneNumber
     * @throws ApiError
     */
    find({ pageSize, page, contactId, type, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/contact-phone-number',
            query: {
                'pageSize': pageSize,
                'page': page,
                'contactId': contactId,
                'type': type,
            },
        });
    }
    /**
     * Create new contact phone number.
     * @returns ContactPhoneNumber
     * @throws ApiError
     */
    create({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/contact-phone-number',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete an existing contact phoone number
     * @returns any
     * @throws ApiError
     */
    delete({ id, }) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/contact-phone-number/{id}',
            path: {
                'id': id,
            },
        });
    }
}
