import React, { forwardRef } from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import { IconButton, IconButtonProps } from '../IconButton';

export interface ToastCloseProps
  extends Omit<
      React.ComponentProps<typeof ToastPrimitive.Close>,
      'name' | 'color' | 'ref'
    >,
    Partial<IconButtonProps> {}

export const ToastClose = forwardRef<HTMLButtonElement, ToastCloseProps>(
  ({ css, ...rest }, ref) => (
    <ToastPrimitive.Close asChild {...rest}>
      <IconButton
        ref={ref}
        css={{
          position: 'absolute',
          right: 8,
          top: 8,
          ...css,
        }}
        label='close-toast'
        name='x'
        color='$neutral-blue-400'
        size='sm'
      />
    </ToastPrimitive.Close>
  ),
);

ToastClose.displayName = 'ToastClose';
