import merge from 'lodash/merge.js';
import ImageResize from 'quill-image-resize-module-react';
import ImageUploader from 'quill-image-uploader';
import { forwardRef, useMemo, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { styled } from '../../stitches.config';
import { BoxProps } from '../Box';
import { Text } from '../Text';
import { EDITOR_MODULES, FORMATS } from './constants';
import { EditorComponentProps } from './types';

export const StyledEditor = styled('div', {});

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageUploader', ImageUploader);
// use DIV instead of P tags
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

const Link = Quill.import('formats/link');
Link.sanitize = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export interface EditorProps extends EditorComponentProps {
  css?: BoxProps['css'];
  invalid?: boolean;
  dataCy?: string;
}

export const Editor = forwardRef<ReactQuill, EditorProps>(
  (
    {
      css,
      onChange,
      invalid,
      modules,
      onFileUpload,
      readOnly,
      dataCy,
      ...rest
    },
    ref,
  ) => {
    const [imageLoader, setImageLoader] = useState(false);
    const uploadImage = async (file: File) => {
      setImageLoader(true);
      if (onFileUpload) {
        const url = await onFileUpload(file);
        if (url) {
          setImageLoader(false);
          return url;
        }
        setImageLoader(false);
      }
      return 'Something went wrong';
    };

    const modulesMemo = useMemo(
      () =>
        !readOnly
          ? {
              ...EDITOR_MODULES,
              ...modules,
              imageUploader: {
                upload: uploadImage,
              },
            }
          : { ...modules },
      [],
    );
    return (
      <StyledEditor
        data-cy={dataCy}
        aria-readonly={readOnly}
        css={merge(
          {
            readOnly: {
              '& .ql-toolbar.ql-snow': {
                display: 'none',
              },
              '& .ql-toolbar.ql-snow + .ql-container.ql-snow': {
                borderTop: '1px solid $border-light',
              },
            },
            '& .ql-toolbar.ql-snow': {
              borderColor: '$border-light',
            },
            '&[aria-invalid=true] .ql-toolbar.ql-snow': {
              borderBottomColor: '$danger-base',
            },
            '&[aria-invalid=true] .ql-container.ql-snow': {
              borderColor: '$danger-base',
            },
            '& .ql-container.ql-snow': {
              borderColor: '$border-light',
              height: 'auto',
              fontSize: '$sm',
            },
            '& .ql-editor': {
              minHeight: 300,
              height: 'auto',
            },
            '& .ql-picker-label': {
              fontWeight: 400,
            },
            '& .ql-snow .ql-stroke': {
              strokeWidth: 1.6,
            },
            '&  .ql-editor ol, .ql-editor ul': {
              paddingLeft: 0,
            },
            position: 'relative',
          },
          css,
        )}
        aria-invalid={invalid}
      >
        {imageLoader && (
          <Text
            css={{
              position: 'absolute',
              background: 'White',
              p: 12,
              zIndex: 10,
              fontSize: '$lg',
              top: 500,
              left: '22%',
              border: '1px solid $neutral-blue-300',
              color: '$neutral-blue-900',
              mediaSm: {
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                p: 24,
              },
            }}
          >
            Uploading image ...
          </Text>
        )}
        <ReactQuill
          ref={ref}
          modules={modulesMemo}
          formats={FORMATS}
          readOnly={readOnly}
          style={{
            cursor: readOnly ? 'not-allowed' : 'text',
          }}
          onChange={onChange}
          {...rest}
        />
      </StyledEditor>
    );
  },
);

Editor.displayName = 'Editor';

export * from './constants';
