import React, { useEffect } from 'react';
import { Spinner, Types } from '@betterleap/shared';
import { Navigate } from 'react-router-dom';
import routeNames from '../../../constants/routeNames';
import useFetch from '../../../hooks/fetch';

type AdminRouteProps = { element: JSX.Element };

const AdminRoute = ({ element }: AdminRouteProps) => {
  const [me, fetchMe] = useFetch<Types.User>('me');

  useEffect(() => {
    fetchMe();
  }, []);

  if (!me.data) {
    return (
      <div className='flex items-center justify-center h-96'>
        <Spinner variant='blue' />
      </div>
    );
  }

  const isNotAdmin = me.data && me.data?.role !== 'Admin';

  if (isNotAdmin) {
    return <Navigate to={routeNames.signin()} />;
  }

  return element;
};

export default AdminRoute;
