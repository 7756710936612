import React, { forwardRef, useContext } from 'react';
import { useController } from 'react-hook-form';
import { InputField, InputFieldProps } from '../Input';

import { useMergeRefs } from '../../hooks/useMergeRefs';
import { BoundComponentProps } from './types';
import { FormContext } from './Form';

export type BoundInputProps = InputFieldProps & BoundComponentProps;

export const BoundInput = forwardRef<HTMLInputElement, BoundInputProps>(
  (
    {
      id,
      control,
      type,
      name,
      defaultValue,
      rules,
      label,
      required,
      onChange: externalOnChange,
      ...rest
    },
    ref,
  ) => {
    const { control: contextControl } = useContext(FormContext);

    const {
      field: { ref: internalRef, value, onChange, ...inputProps },
      fieldState: { error },
    } = useController({
      name,
      control: control ?? contextControl,
      defaultValue,
      rules: {
        required: required && 'This field is required',
        ...rules,
      },
    });

    const combinedRef = useMergeRefs(ref, internalRef);

    return (
      <InputField
        ref={combinedRef}
        id={id}
        label={label}
        value={value}
        type={type}
        required={required}
        error={error?.message}
        onChange={(newValue: string) => {
          onChange(externalOnChange ? externalOnChange(newValue) : newValue);
        }}
        {...inputProps}
        {...rest}
      />
    );
  },
);

BoundInput.displayName = 'BoundInput';
