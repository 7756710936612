export class RoleApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Get all synced ATS roles
     * @returns Role
     * @throws ApiError
     */
    getMergeRoles({ xTestUser, pageSize, page, deleted, status, today, withoutTest = false, search, recruiterId, companyId, isDemo, orderBy, source, organizationId, }) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/merge-roles',
            headers: {
                'x-test-user': xTestUser,
            },
            query: {
                'pageSize': pageSize,
                'page': page,
                'deleted': deleted,
                'status': status,
                'today': today,
                'withoutTest': withoutTest,
                'search': search,
                'recruiterId': recruiterId,
                'companyId': companyId,
                'isDemo': isDemo,
                'orderBy': orderBy,
                'source': source,
                'organizationId': organizationId,
            },
        });
    }
}
