export class PersonIngestionApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Stages a person data file in Postgres for further processing.
     * @returns any
     * @throws ApiError
     */
    stageDataFile({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-ingestion/stage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Starts the person matching process.
     * @returns PersonIngestionJobResponse
     * @throws ApiError
     */
    matchStagedData({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-ingestion/match',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Generates a report for match evaluation.
     * @returns any
     * @throws ApiError
     */
    generateMatchEvaluationReport({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-ingestion/report/match-evaluation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Generates a report of field differences.
     * @returns any
     * @throws ApiError
     */
    generateFieldDifferenceReport({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-ingestion/report/field-difference',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Starts the person ingestion planning process.
     * @returns PersonIngestionJobResponse
     * @throws ApiError
     */
    createPlan({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-ingestion/plan',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Starts the person ingestion plan execution process.
     * @returns PersonIngestionJobResponse
     * @throws ApiError
     */
    executePlan({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/person-ingestion/execute',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
