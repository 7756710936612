export class DoNotContactDomainApi {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Replaces the domain DNC of an organization
     * @returns void
     * @throws ApiError
     */
    replaceDomains({ requestBody, }) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/do-not-contact-domain/replace',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the domain DNC of an organization
     * @returns string
     * @throws ApiError
     */
    getDomains() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/do-not-contact-domain',
        });
    }
}
