import { AriaTextFieldOptions, useTextField } from '@react-aria/textfield';
import { forwardRef, ReactNode, RefObject, useRef } from 'react';
import omit from 'lodash/omit.js';

import { useMergeRefs } from '../../hooks/useMergeRefs';
import { Box, BoxProps } from '../Box';
import { Label } from '../Label';
import { Text } from '../Text';
import { TextArea, TextAreaProps } from './TextArea';

export interface TextAreaFieldProps extends TextAreaProps {
  /** Input helper text */
  description?: string;
  /** Input label text */
  label?: ReactNode;
}

export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(
  (
    {
      disabled,
      readOnly,
      required,
      error,
      description,
      label,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const internalRef = useRef<HTMLTextAreaElement>();
    const combinedRef = useMergeRefs(internalRef, ref);

    const { labelProps, errorMessageProps, descriptionProps, inputProps } =
      useTextField(
        {
          label,
          isDisabled: disabled,
          isReadOnly: readOnly,
          isRequired: required,
          validationState: error ? 'invalid' : 'valid',
          inputElementType: 'textarea',
          ...(rest as AriaTextFieldOptions<'textarea'>),
        },
        internalRef as RefObject<HTMLTextAreaElement>,
      );

    return (
      <Box {...omit(rest as BoxProps, Object.keys(inputProps))}>
        {label && typeof label === 'string' ? (
          <Label
            css={{
              mb: 8,
            }}
            {...labelProps}
            required={required}
          >
            {label}
          </Label>
        ) : (
          label
        )}
        <TextArea
          ref={combinedRef}
          readOnly={readOnly}
          disabled={disabled}
          required={required}
          error={error}
          {...inputProps}
          onChange={onChange}
        />
        <Box css={{ minHeight: 24, marginTop: 4 }}>
          {description && !error && (
            <Text
              css={{
                color: '$text-lightest',
                fontSize: '$sm',
              }}
              noBreak
              {...descriptionProps}
            >
              {description}
            </Text>
          )}
          {error && (
            <Text
              css={{
                color: '$text-danger',
                fontSize: '$sm',
              }}
              noBreak
              {...errorMessageProps}
            >
              {error}
            </Text>
          )}
        </Box>
      </Box>
    );
  },
);

TextAreaField.displayName = 'TextAreaField';
