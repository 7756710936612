/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export var ExtendedContactProject;
(function (ExtendedContactProject) {
    let contactEmailEnrichmentMode;
    (function (contactEmailEnrichmentMode) {
        contactEmailEnrichmentMode["PERSONAL"] = "Personal";
        contactEmailEnrichmentMode["PROFESSIONAL"] = "Professional";
    })(contactEmailEnrichmentMode = ExtendedContactProject.contactEmailEnrichmentMode || (ExtendedContactProject.contactEmailEnrichmentMode = {}));
    let contactSequenceFinishedReason;
    (function (contactSequenceFinishedReason) {
        contactSequenceFinishedReason["ARCHIVED"] = "Archived";
        contactSequenceFinishedReason["SENT_EMAIL"] = "Sent Email";
        contactSequenceFinishedReason["CONTACT_REPLIED"] = "Contact Replied";
        contactSequenceFinishedReason["ERROR"] = "Error";
        contactSequenceFinishedReason["NO_EMAIL_FOUND"] = "No Email Found";
        contactSequenceFinishedReason["STEP_REMOVED"] = "Step Removed";
        contactSequenceFinishedReason["MARKED_DONE"] = "Marked Done";
        contactSequenceFinishedReason["SEQUENCE_COMPLETED"] = "Sequence Completed";
    })(contactSequenceFinishedReason = ExtendedContactProject.contactSequenceFinishedReason || (ExtendedContactProject.contactSequenceFinishedReason = {}));
    let contactSequenceDeactivatedReason;
    (function (contactSequenceDeactivatedReason) {
        contactSequenceDeactivatedReason["ARCHIVED"] = "Archived";
        contactSequenceDeactivatedReason["PAUSED"] = "Paused";
        contactSequenceDeactivatedReason["MISSING_VARIABLE"] = "Missing Variable";
        contactSequenceDeactivatedReason["CONTACT_REPLIED"] = "Contact Replied";
        contactSequenceDeactivatedReason["ERROR"] = "Error";
        contactSequenceDeactivatedReason["STEP_REMOVED"] = "Step Removed";
        contactSequenceDeactivatedReason["MARKED_DONE"] = "Marked Done";
        contactSequenceDeactivatedReason["NO_REPLY"] = "No Reply";
    })(contactSequenceDeactivatedReason = ExtendedContactProject.contactSequenceDeactivatedReason || (ExtendedContactProject.contactSequenceDeactivatedReason = {}));
    let status;
    (function (status) {
        status["SOURCED"] = "Sourced";
        status["SCHEDULED"] = "Scheduled";
        status["MESSAGED"] = "Messaged";
        status["OPENED"] = "Opened";
        status["BOUNCED"] = "Bounced";
        status["REPLIED"] = "Replied";
        status["NO_REPLY"] = "No Reply";
        status["NO_EMAIL_FOUND"] = "No Email Found";
        status["DO_NOT_CONTACT"] = "Do Not Contact";
    })(status = ExtendedContactProject.status || (ExtendedContactProject.status = {}));
    let externallyContactedStatus;
    (function (externallyContactedStatus) {
        externallyContactedStatus["CONTACTED"] = "Contacted";
        externallyContactedStatus["REPLIED"] = "Replied";
    })(externallyContactedStatus = ExtendedContactProject.externallyContactedStatus || (ExtendedContactProject.externallyContactedStatus = {}));
    let lastStepType;
    (function (lastStepType) {
        lastStepType["X_DIRECT_MESSAGE"] = "X direct message";
        lastStepType["SMS"] = "sms";
        lastStepType["PHONE_CALL"] = "phone call";
        lastStepType["EMAIL"] = "email";
        lastStepType["INMAIL"] = "inmail";
    })(lastStepType = ExtendedContactProject.lastStepType || (ExtendedContactProject.lastStepType = {}));
    let currentStepType;
    (function (currentStepType) {
        currentStepType["X_DIRECT_MESSAGE"] = "X direct message";
        currentStepType["SMS"] = "sms";
        currentStepType["PHONE_CALL"] = "phone call";
        currentStepType["EMAIL"] = "email";
        currentStepType["INMAIL"] = "inmail";
    })(currentStepType = ExtendedContactProject.currentStepType || (ExtendedContactProject.currentStepType = {}));
})(ExtendedContactProject || (ExtendedContactProject = {}));
