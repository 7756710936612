import { Spinner, Types } from '@betterleap/shared';
import { Organization, UpdateOrganizationDto } from '@betterleap/client';
import { BackButton, Badge, Box, Flex, showToast, Text } from '@betterleap/ui';
import qs from 'qs';
import { useEffect, useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import Toggle from 'react-toggle';
import { apiClient } from 'lib/apiClient';
import get from 'lodash/get';
import set from 'lodash/set';
import api from '../../../lib/api';
import OrganizationMembers from '../../modules/OrganizationMembers/OrganizationMembers';
import OrganizationTierManagement from '../../modules/Organization/OrganizationTierManagement';
import { organizationToggles } from './Organization.toggles';
import OrganizationApiKeys from './OrganizationApiKeys';

interface Member {
  name?: string;
  email?: string;
  status?: Types.INVITATION_STATUS;
  acceptedDate?: Date;
}

interface MemberResponse {
  firstName?: string;
  lastName?: string;
  email?: string;
  status?: Types.INVITATION_STATUS;
  createdAt?: Date;
}
const OrganizationTemplate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split('/').pop() || '';
  const querySearch = qs.parse(location.search.slice(1));
  const pageNumber = Number(`${querySearch.page}0`);
  const {
    data: organizationData,
    isLoading: isOrganizationLoading,
    refetch,
  } = useQuery(['get_organization', id], () =>
    apiClient.organization.findOneById({ id }),
  );
  const organization = organizationData?.data;
  const { data: organizationMembersData } = useQuery(['get_members', id], () =>
    api.fetch<{
      data: MemberResponse[];
      meta: Types.ApiPaginationResponse;
    }>('get_members', {
      organizationId: id,
    }),
  );

  const { data: apiKeysResponse, refetch: refetchApiKeys } = useQuery(
    ['get_api_keys', id],
    () => {
      return apiClient.apiKeyManagement.getOrganizationApiKeys({
        organizationId: id,
      });
    },
  );

  const updateOrganization = useMutation(
    (data: UpdateOrganizationDto) =>
      apiClient.organization.update({ id, requestBody: data }),
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        showToast({
          variant: 'danger',
          title: 'Something went wrong!',
          description: 'Failed to update organization. Please try again.',
        });
      },
    },
  );

  const apiKeys = apiKeysResponse?.data ?? [];
  const members: Member[] = useMemo(() => {
    const organizationMembers = (organizationMembersData?.data?.data || []).map(
      (el) => ({
        email: el.email,
        name: `${el.firstName || ''} ${el.lastName || ''} `,
        status: el.status ?? Types.INVITATION_STATUS.JOINED,
        acceptedAt: el.createdAt,
      }),
    );

    return organizationMembers || [];
  }, [organizationMembersData?.data?.data, organization]);

  useEffect(() => {
    refetch();
  }, []);

  const toggleOrganizationProperty = (field: string) => {
    const update: UpdateOrganizationDto = {};
    set(update, field, !get(organization, field));
    updateOrganization.mutate(update);
  };

  if (isOrganizationLoading)
    return (
      <Flex justify='center' align='center' css={{ height: 384 }}>
        <Spinner variant='blue' />
      </Flex>
    );

  return (
    <>
      <BackButton route='Back' onClick={() => navigate(-1)} />
      <Flex
        css={{
          mb: 24,
          flexDirection: 'column',
          alignItems: 'flex-start',
          mediaSm: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        }}
      >
        <Flex align='center' css={{ gap: 8 }}>
          <Text
            as='h1'
            css={{
              fontSize: '$3xl',
              lineHeight: '$4xl',
              fontWeight: '$medium',
              color: '$neutral-blue-900',
              maxWidth: 436,
              mb: 16,
              mediaSm: { mb: 'unset' },
            }}
          >
            {organization?.name || 'N/A'}
          </Text>
          {organization?.tier === Organization.tier.PAYWALL && (
            <Badge variant='info' size='sm'>
              {Organization.tier.PAYWALL}
            </Badge>
          )}
          {organization?.tier === Organization.tier.PAID && (
            <Badge variant='success' size='sm'>
              {Organization.tier.PAID}
            </Badge>
          )}
          {organization?.tier === Organization.tier.FREE_TRIAL && (
            <Badge variant='secondary' size='sm'>
              {Organization.tier.FREE_TRIAL}
            </Badge>
          )}
          {organization?.test && (
            <Badge variant='info' size='sm'>
              Test
            </Badge>
          )}
          <Flex css={{ ml: 8 }}>
            {updateOrganization.isLoading && (
              <Box css={{ ml: 8 }}>
                <Spinner variant='blue' />
              </Box>
            )}
          </Flex>
        </Flex>

        <Flex
          css={{
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 8,
            mediaMd: {
              flexDirection: 'row',
              justifyContent: 'unset',
              width: 'unset',
            },
          }}
        >
          {organization?.tier === Organization.tier.PAID && (
            <Flex align='center' css={{ mr: 16 }}>
              <Text css={{ fontSize: '$sm', color: '$neutral-blue-500' }}>
                Seats:
                <span
                  style={{
                    fontSize: '$sm',
                    color: '#3A83F7',
                    marginLeft: '6px',
                  }}
                >
                  {organization?.seatsGranted - (organization?.seatsUsed ?? 0)}/
                  {organization?.seatsGranted} available
                </span>
              </Text>
            </Flex>
          )}
          <OrganizationTierManagement
            organization={organization}
            membersCount={members.length}
            refetchOrganization={refetch}
            updateOrganization={updateOrganization.mutate}
          />
        </Flex>
      </Flex>

      <Flex
        className='toggle'
        css={{
          mb: 24,
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {organizationToggles.map((toggle) => (
          <Flex css={{ mb: 8 }}>
            <Toggle
              checked={!!get(organization, toggle.field)}
              onChange={() => toggleOrganizationProperty(toggle.field)}
            />
            <Text css={{ fontWeight: '$medium', fontSize: '$base', ml: 8 }}>
              {toggle.label}
            </Text>
          </Flex>
        ))}
      </Flex>
      {apiKeys.length > 0 && (
        <OrganizationApiKeys
          data={apiKeys}
          onEdit={() => {
            refetchApiKeys();
          }}
        />
      )}
      {members && (
        <OrganizationMembers
          data={
            pageNumber === 10 ? members : members.slice(pageNumber - 10) || ''
          }
          countNumber={(organizationMembersData?.data?.meta?.count ?? 0) + 1}
        />
      )}
    </>
  );
};

export default OrganizationTemplate;
