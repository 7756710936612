import { AutocompleteResult } from '@betterleap/client';
import { Box, Combobox, Icon, ListSection, Option } from '@betterleap/ui';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { extractUrlPath } from 'helper/exctractUrlPath';
import { isUUID } from 'helper/isUUID';
import { clearSearchParams } from 'helper/clearSearchParams';
import { apiClient } from 'lib/apiClient';
import { debounce, groupBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

interface AutocompleteSelection extends AutocompleteResult {
  _section: string;
}

const selectionsAtom = atomWithStorage<AutocompleteSelection[]>(
  'recentSelections',
  [],
);

const recentSelectionsAtom = atom(
  (get) => get(selectionsAtom),
  (get, set, newSelection: AutocompleteSelection) => {
    const currentSelections = get(selectionsAtom);
    // only take last 5
    const updatedSelections = [...currentSelections, newSelection].slice(-5);
    set(selectionsAtom, updatedSelections);
  },
);

const recentSelectionOptionsSelector = atom((get) => {
  const recentSelections = get(selectionsAtom);

  const grouped = groupBy(recentSelections, '_section');

  const options = Object.entries(grouped).map(([key, value]) => {
    return {
      name: key,
      children: value,
    };
  });

  return options;
});

export const ApiLogSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterTerm, setFilterTerm] = useState('');
  const recentSelectionOptions = useAtomValue(recentSelectionOptionsSelector);
  const setRecentQueries = useSetAtom(recentSelectionsAtom);

  const { data: apiLogsResponse, isLoading: isApiLogsLoading } = useQuery(
    ['api-logs-autocomplete', filterTerm],
    () => {
      return apiClient.apiKeyManagement.getApiKeyRequestLogs({
        requestId: filterTerm,
      });
    },
    {
      enabled: isUUID(filterTerm),
    },
  );

  const {
    data: organizationAutocompleteResponse,
    isLoading: isOrganizationAutocompletLoading,
  } = useQuery(['organization-autocomplete', filterTerm], () => {
    return apiClient.autocomplete.autocomplete({
      entity: 'organization',
      field: 'name',
      value: filterTerm,
      size: 5,
    });
  });

  const {
    data: apiKeyAutocompleteResponse,
    isLoading: isApiKeyAutocompletLoading,
  } = useQuery(['api-key-autocomplete', filterTerm], () => {
    return apiClient.autocomplete.autocomplete({
      entity: 'apiKey',
      field: 'key',
      value: filterTerm,
      size: 5,
    });
  });

  const options = useMemo(() => {
    const opts: { name: string; children: AutocompleteResult[] }[] = [];

    if (organizationAutocompleteResponse?.data?.length) {
      opts.push({
        name: 'Organizations',
        children: organizationAutocompleteResponse?.data,
      });
    }

    if (apiKeyAutocompleteResponse?.data?.length) {
      opts.push({
        name: 'API Keys',
        children: apiKeyAutocompleteResponse?.data,
      });
    }

    if (apiLogsResponse?.data?.length) {
      opts.push({
        name: 'API Logs',
        children: apiLogsResponse?.data.map((log) => {
          return {
            value: `${log.requestMethod} ${extractUrlPath(log.requestPath)} - ${
              log.requestId
            }`,
            id: log.requestId,
          };
        }),
      });
    }

    if (!filterTerm && !opts.length) {
      return recentSelectionOptions;
    }

    return opts;
  }, [
    apiLogsResponse,
    organizationAutocompleteResponse,
    apiKeyAutocompleteResponse,
    filterTerm,
  ]);

  const handleFilterChange = useCallback(
    debounce((term) => {
      setFilterTerm(term);
    }, 30),
    [setFilterTerm],
  );

  const handleChange = (
    keys: (string | number)[],
    newValues: AutocompleteSelection[],
  ) => {
    const selection = newValues[0];

    const clearedParams = clearSearchParams(searchParams);
    if (selection) {
      setRecentQueries(selection);

      switch (selection._section) {
        case 'Organizations':
          clearedParams.set('organizationId', selection.id);
          setSearchParams(clearedParams);
          break;
        case 'API Keys':
          clearedParams.set('apiKeyId', selection.id);
          setSearchParams(clearedParams);
          break;
        case 'API Logs':
          clearedParams.set('requestId', selection.id);
          clearedParams.set('filterByRequestId', 'true');
          setSearchParams(clearedParams);
          break;
        default:
          break;
      }
    } else {
      setSearchParams(clearedParams);
    }
  };

  const isLoading =
    isApiLogsLoading ||
    isOrganizationAutocompletLoading ||
    isApiKeyAutocompletLoading;

  return (
    <Combobox
      leftIcon={<Icon name='search' />}
      loading={isLoading}
      onFilterChange={handleFilterChange}
      placeholder='Search'
      emptyState={
        !isLoading && !!filterTerm ? (
          <Box
            css={{
              p: 16,
              color: 'gray',
              textAlign: 'center',
              fontSize: '$sm',
            }}
          >
            No Results
          </Box>
        ) : null
      }
      onChange={handleChange}
      options={options}
      value={[]}
      clearable={false}
      css={{
        '& .combobox-toggle-open-button': {
          visibility: 'hidden',
        },
      }}
    >
      {(section) => (
        <ListSection
          key={section.name}
          title={section.name}
          items={section.children}
        >
          {(option) => <Option>{option.value}</Option>}
        </ListSection>
      )}
    </Combobox>
  );
};
